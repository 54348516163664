import React, { useReducer, useState } from "react";
import { get as _get, has as _has, findIndex } from "lodash";
import { getRecordReducer } from "@sw-sw/lib-ui";
import apiResources from "../utils/apiResources";

const ApiDataContext = React.createContext();

/**
 * generic storage controller for api data
 *
 * used to track lists of top-level api resources
 *
 * @see utils/apiResources
 */
export function ApiDataStore(props) {
  const [state, dispatch] = useReducer(getRecordReducer(), {});

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(undefined);
  const [totalItems, setTotalItems] = useState(undefined);

  // search
  const [searchQuery, setSearchQuery] = useState(null);

  const set = (key, val) =>
    dispatch({
      type: "mergeDeep",
      payload: { key, val },
    });
  const get = key => (key ? _get(state, key) : state);
  const has = key =>
    key &&
    _has(state, key) &&
    get(state, key) !== null &&
    get(state, key) !== undefined;

  const value = {
    set,
    has,
    get,
    clear: key => set(key, null),

    patchResource: (resourceKey, id, patch) => {
      const items = get(resourceKey);

      if (!items) {
        throw new Error(`Specified resource (${resourceKey}) is not loaded`);
      }

      const itemIndex = findIndex(items, { id: id });

      if (itemIndex === -1) {
        throw new Error(
          `Specified id (${id}) does not exist in resource (${resourceKey})`,
        );
      }

      set(resourceKey, [
        ...items.slice(0, itemIndex),
        Object.assign({ ...items[itemIndex] }, { ...patch }),
        ...items.slice(itemIndex + 1),
      ]);
    },

    fetchIndex: (
      name,
      divisionId,
      useCache = false,
      page = null,
      search = null,
    ) => {
      if (validateApiResourceName(name)) {
        if (useCache && has(name)) {
          return Promise.resolve(get(state, name));
        }

        return apiResources[name]
          .call(null, divisionId, page ? page : null, search ? search : null)
          .then(data => {
            if (page && data.paginationState) {
              const s = data.paginationState;

              console.log("pagination xhr callback", currentPage, s);

              setCurrentPage(s.currentPage);
              setTotalItems(s.numModels);
              setPageSize(s.pageSize);

              set(name, data.pageData);
            } else {
              set(name, data);
            }

            return data;
          });
      }
    },

    // pagination

    currentPage,
    totalItems,
    pageSize,

    setPage: nextPage => {
      setCurrentPage(nextPage);
    },

    resetPagination: () => {
      setCurrentPage(1);
      setTotalItems(undefined);
      setPageSize(undefined);
    },

    // search
    searchQuery,

    setSearchQuery: q => {
      setSearchQuery(q);
    },
  };

  return (
    <ApiDataContext.Provider value={value}>
      {props.children}
    </ApiDataContext.Provider>
  );
}

export default ApiDataContext;

function validateApiResourceName(name) {
  if (!name || !apiResources[name]) {
    throw new Error(`'name' must be a supported API resource:
    ${Object.keys(apiResources).join(
      ", ",
    )}. "${name}" is not a supported value.`);
  }

  return true;
}
