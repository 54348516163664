import React, { useContext } from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import classnames from "classnames";

import { Context as DataSourceContext } from "../DataSourceContext";
import { Context as InteractionContext } from "../Interaction/InteractionContext";
import PositionableList from "./List";
import { MapEditorRouteContext } from "../../CustomRoutes/MapEditor";

const toggle = (activeType, nextType, setActive) => {
  if (activeType === nextType) {
    setActive(null);
  } else {
    setActive(nextType);
  }
};

const positionableType2PageName = {
  findings: "Findings",
  control_measure: "Project Legend Item",
  pollutant: "Project Legend Item",
  commentables: "Annotations",
};

const actionsWithAllPerm = {
  read: "all",
  create: "all",
  update: "all",
};

/**
 * Render collapsible list for each data source type
 */
function PositionableNavigation(props) {
  const mapEditorRouteContext = useContext(MapEditorRouteContext);
  const dataSourceContext = useContext(DataSourceContext);
  const interactionContext = useContext(InteractionContext);
  const { className, permCheck, dropMenu } = props;

  if (!dataSourceContext || !interactionContext) {
    return null;
  }

  const {
    get,
    getDataTypeLabel,
    getDataTypes,
    isReadOnlyDataType,
  } = dataSourceContext;
  const {
    positionableType,
    setPositionableType,
    positionableSourceModel,
    setPositionableSourceModel,
    mode,
  } = interactionContext;

  // curry and switch args, for compat. with {toggle}
  const setMode = modeData => newMode => {
    if (mode === "readOnly") {
      return;
    }

    return interactionContext.setMode(newMode, modeData);
  };

  const dataSources = sortBy(
    mapEditorRouteContext.dataSources,
    "order",
  ).filter(positionable =>
    getDataTypes().includes(positionable.positionableType),
  );

  const classes = ["positionable-navigation", className || ""];

  return (
    <div className={classnames(classes)}>
      {dataSources.map((source, d) => {
        const dataType = source.positionableType;
        const isActive = positionableType === dataType;
        const items = get(dataType);
        
        if ( dataType === "findings" ) {
          items.sort((a, b) => b.id - a.id);
        }
        const label = positionableType2PageName[source.positionableType];
        const actions =
          label === "Annotations" ? actionsWithAllPerm : undefined;

        return (
          <PositionableList
            key={dataType}
            title={getDataTypeLabel(dataType)}
            type={dataType}
            items={items}
            toggleActive={forceActive =>
              toggle(
                forceActive === true ? "" : positionableType,
                dataType,
                setPositionableType,
              )
            }
            toggleActiveItem={itemIndex =>
              toggle(
                positionableSourceModel,
                itemIndex,
                setPositionableSourceModel,
              )
            }
            isActive={isActive}
            activeItemIndex={isActive ? positionableSourceModel : null}
            setInteractionMode={(newMode, modeData = null) =>
              toggle(mode, newMode, setMode(modeData))
            }
            permCheck={permCheck}
            label={label}
            actions={actions}
            enableSearch={source.enableSearch}
            mode={mode}
            readOnly={isReadOnlyDataType(dataType)}
            dropMenu={dropMenu}
          />
        );
      })}
    </div>
  );
}

PositionableNavigation.propTypes = {
  className: PropTypes.string,
  permCheck: PropTypes.func.isRequired,
};

export default PositionableNavigation;
