import xhrService from "../xhrService";
import { Address } from "./address";
import { Client, Contact } from "./client";

/**
 * Finish types
 *
 */
export type Project = {
    id: number;
    name: string;
    client_id: number;
    address_id: number;
    inspection_template_id: number;
    permittee?: string;
    notes?: string;
    timezone: string;
    document_group: number;
    start_date: string;
    end_date: string;
    inspection_compliance_required: boolean;
    cgp_tracking_id?: number;
    next_inspection_date: string;
    archivedAt?: string;
    findingComplianceRequired: boolean;
    isCcrProject: boolean;
    address?: Address;
    client?: Client;
};

/**
 * Go through types
 */
type PublicProjectApi = {
    getHeader: (slugID: string) => Promise<Project>;
    getContacts: (slug: string) => Promise<Array<Contact>>;
    getFindings: (slug: string) => Promise<any>;
    getAllInspections: (slug: string) => Promise<any>;
    getPublicLink: (id: number) => Promise<any>;
    getCert: (slug: string) => Promise<any>;
    getHeaderInspection: (slugID: string, inspectionId: number) => Promise<any>;
    getMap: (slug: string) => Promise<any>;
    getInspectionQuestions: (slug: string) => Promise<any>;
};

export const publicProjectApi: PublicProjectApi = {
    getHeader: (slugID: string) => {
        return xhrService.get(`api/publicUrl/${slugID}/header`).then(response => response.data);
    },
    getContacts: (slug: string) => {
        return xhrService.get(`api${slug}`).then(response => response.data);
    },
    getFindings: (slug: string) => {
        return xhrService.get(`api${slug}`).then(response => response);
    },
    getAllInspections: (slug: string) => {
        return xhrService.get(`api${slug}`).then(response => response.data);
    },
    getPublicLink: (id: number) => {
        return xhrService.get(`api/publicUrl/${id}`).then(response => response.data)
    },
    getCert: (slug: string) => {
        return xhrService.get(`api${slug}`).then(response => response.data);
    },
    getHeaderInspection: (slugID: string, inspectionId: number) => {
        return xhrService.get(`api/publicUrl/${slugID}/inspection/${inspectionId}/header`).then(response => response.data)
    },
    getMap: (slug: string) => {
        return xhrService.get(`api${slug}`).then(response => response);
    },
    getInspectionQuestions: (slug: string) => {
        return xhrService.get(`api${slug}`).then(response => response);
    }
};

export default publicProjectApi;
