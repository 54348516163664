import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";

import GroupDetailsForm from "./Forms/GroupDetailsForm";
import groupApi from "../../utils/api/group";
import AppDivisionContext from "../../contexts/AppDivisionContext";

export interface IGroupAddProps {
  onAdd?: (data: any) => void;
  show?: Boolean;
  handleClose: () => void;
  enableRedirect?: Boolean;
}

const GroupAdd: React.FC<IGroupAddProps> = ({
  onAdd,
  show = true,
  handleClose,
  enableRedirect,
}) => {
  const [redirectId, setRedirectId] = useState(null);
  const { getPath } = useContext(AppDivisionContext);

  const handleSubmit = (formState: any) => {

    if(!formState.name.trim()){
      throw new Error("Regulation name cannot be empty")
    }

    return groupApi.create(formState).then(data => {

      if (typeof onAdd === "function") {
        onAdd(data);
      }

      if (enableRedirect === true) {
        setRedirectId(data.id);
      }

      handleClose();

      return Promise.resolve();
    });
  };

  if (redirectId) {
    return <Redirect to={getPath(`/groups/${redirectId}`)} push />;
  }

  if (show)
    return (
      <GroupDetailsForm
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        title="Add Regulation"
      />
    );

  return null;
};

export default GroupAdd;
