import React, { useContext } from 'react';
import RolesContext from '../../contexts/RolesContext';

export interface ISiteMapCard {
  imgSrc: string;
  title: string;
  label: string;
  handleImgClick?: () => void;
  handleDelete?: () => void;
  handleArchive?: () => void;
  handlePrint?: () => void;
  showActions?: boolean;
  archiveView?: boolean;
}

const SiteMapCard: React.FC<ISiteMapCard> = ({
  imgSrc,
  title,
  label,
  handleImgClick,
  handleArchive,
  handlePrint,
  showActions,
  archiveView
}) => {
  const permCheck = useContext(RolesContext).userHasPermission;
  const canUpdate = permCheck('update', label);
  const canDelete = permCheck('delete', "Uploads");
  const canPrint = permCheck('all','Download');

  return (
    <section className='card'>
      <div
        className='card-img card-cap'
        style={{
          backgroundImage: `url(${imgSrc})`,
          cursor: handleImgClick && canUpdate ? 'pointer' : 'initial',
        }}
        title={title}
        onClick={() => (handleImgClick && canUpdate ? handleImgClick() : null)}
      />

      <div className='card-body'>
        <p className='card-title'>{title}</p>
        {showActions && (
          <div className='card-toolbar'>
            {canUpdate && canDelete && (
              <button className='outline small' onClick={handleArchive}>
                {archiveView ? <i className="fa fa-undo" /> : <i className='fa fa-archive' />}
              </button>
            )}
          </div>
        )}
      </div>

      <div className='card-body-actions card-actions'>
        <button
          className='card-action pure-button button-primary'
          onClick={handleImgClick}
        >
          {canUpdate && showActions ? 'Update Site Map' : 'View Site Map'}
        </button>

        {
          canPrint && 
          (
            <button
              className='card-action pure-button button-outline-blue '
              onClick={handlePrint}
            >
              Print Site Map
            </button>
          )
        }
        
      </div>
    </section>
  );
};

export default SiteMapCard;
