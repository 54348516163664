import React, { useContext, useState } from "react";
import { isEmpty, values } from "lodash";
// import { Changelog } from "@sw-sw/common";
import { useProjectLog } from "../../../hooks/projectDetails";
import Loading from "../../Shared/ResourceIndex/Loading";
import ChangeLog from "../Details/ChangeLog";
import PrecipitationLog from "./PrecipitationLog/PrecipitationLog";
import Select from "react-select";
import { FormContextProvider } from "@sw-sw/lib-form";
import DocumentsLog from "./DocumentsLog/DocumentsLog";
import { RolesContext } from "../../../contexts/RolesContext";

export interface IProjectLogProps {
  projectId: number;
}

const ProjectLog: React.FC<IProjectLogProps> = ({ projectId }) => {
  const projectLogQuery = useProjectLog(projectId);
  const [logType, setLogType] = useState("Site Map Log")
  const permCheck = useContext(RolesContext).userHasPermission;
  const userCanAccessDocumentsLog = permCheck('all', 'Documents Log');

  /*
  * "Site Map Log" was formarly known as "Project Log"
  * within code, along with "Site Map Log", also look for the words "Project Log"
  */
  const alwaysAvailableOptions = [
    {value: 1, label: 'Site Map Log'},
    {value: 2, label: 'Precipitation Log'},
  ]
  const conditionallyAvailableOptions = [
    {value: 3, label: 'Documents Log'}
  ]

  const logTabOptions = userCanAccessDocumentsLog ? [...alwaysAvailableOptions, ...conditionallyAvailableOptions ] : [...alwaysAvailableOptions ]

  const onChangeFunc = (data:any) => {
    setLogType(data.label)
  }

  if (!projectLogQuery.data) {
    return <Loading what="Site Map Log" />;
  }

  const changeLogDisplay = () => {
    if (values(projectLogQuery.data).every(value => isEmpty(value))) {
      return <p>There are no logs for this project</p>;
    }

    return <ChangeLog changelogs={projectLogQuery.data} projectId={projectId} />
  }

  const displayedLogType = () => {
    switch(logType){
      case 'Site Map Log': return changeLogDisplay()

      case 'Precipitation Log': return(
        <FormContextProvider>
          <PrecipitationLog/>
        </FormContextProvider>
      )

      case 'Documents Log': return(
        <FormContextProvider>
          <DocumentsLog/>
        </FormContextProvider>
      )

      default: return <ChangeLog changelogs={projectLogQuery.data} projectId={projectId} />
    }
  }

  return (
    <section className="project-log">
      <div className="project-log__select-wrapper">
        <Select
          closeMenuOnSelect={true}
          defaultValue={logTabOptions[0]}
          options={logTabOptions}
          onChange={(data) => { onChangeFunc(data) }}
          isClearable={false}
          className='project-log__select'
          classNamePrefix='project-log'
          isSearchable={false}
        />
      </div>
      <div>
        {displayedLogType()}
      </div>
    </section>
  );
};

export default ProjectLog;
