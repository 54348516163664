import classnames from "classnames";
import React, { PropsWithChildren } from "react";
import DataPlaceholder from "../../Shared/Loading/DataPlaceholder";

export interface ISignatureDateProps {
  date: Date | string | undefined | null;
  disabled?: boolean;
}

const SignatureDate: React.FC<PropsWithChildren<ISignatureDateProps>> = ({
  date,
  disabled = false,
  children,
}) => {
  if (disabled) {
    return null;
  }

  return (
    <p className="questions">
      {children} |{" "}
      {date === undefined ? (
        <DataPlaceholder />
      ) : (
        <span className={classnames(date ? "green-light" : "red-light")}>
          {date ? "Complete" : "Incomplete"}
        </span>
      )}
    </p>
  );
};

export default SignatureDate;
