import React, { Fragment, useEffect, useState } from 'react';
import projectApi from '../../../../utils/api/project';
import { map } from 'lodash';
import moment from 'moment';
import Loading from '../../../Shared/ResourceIndex/Loading';
import './index.scss';

type Props = {
    projectId: number
}

function Notes(props: Props) {

    const [notes, setNotes] = useState<any>([]);
    const [showMoreLessBtnStatus, setShowMoreLessBtnStatus] = useState<boolean>(true);
    const [showMoreLessBtnVisibility, setShowMoreLessBtnVisibility] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchNotes = async (showFull = false) => {
        if(props.projectId){
            setLoading(true);
            const projectNotes = await projectApi.getNotes(props.projectId, showFull);
            const result = (projectNotes && projectNotes.notes) ? projectNotes.notes : [];
            setNotes(result);
            setShowMoreLessBtnVisibility(true);
            setLoading(false);
        }
    }
    
    const toggleShowMoreLessClick = () => {
        fetchNotes(showMoreLessBtnStatus);
        setShowMoreLessBtnStatus(!showMoreLessBtnStatus);
    }

    useEffect(() => {
        fetchNotes();
    }, [props.projectId]);

    return (
        <table className="list-table pure-table changelog-table">
            <thead>
                <tr>
                    <th colSpan={6}>Notes</th>
                </tr>
            </thead>
            <tbody>
                <Fragment>
                    <tr className="group-header-row">
                        <td>Note</td>
                        <td>Title</td>
                        <td>Content</td>
                        <td>Status</td>
                        <td>User</td>
                        <td>Date</td>
                    </tr>
                    {
                    (loading)? 
                    <tr className='log-row last'>
                        <td colSpan={6}>
                            <Loading what={`Notes`} />
                        </td>
                    </tr>
                    :

                    (!notes || notes.length === 0)?
                    <tr className='log-row last'>
                        <td colSpan={6}>
                            <p>No Notes Found</p>   
                        </td>
                    </tr>
                    :
                    map(notes, (note:any, i:any) => {
                        return (
                            <tr
                                key={`${note.id}-${i}`}
                                className={`log-row ${(notes.length < 2 && notes.length - 1 === i ) ? 'last' : ''}`}
                            >
                                <td>{`${note.noteIdentifier}`}</td>
                                <td>{`${note.note.title}`}</td>
                                <td>{`${note.note.content}`}</td>
                                <td>{note.status}</td>
                                <td>{note.userInitials}</td>
                                <td>
                                    {moment(note.statusDate.substring(0, 10)).format(
                                        'MM-DD-YYYY',
                                    )}
                                </td>
                            </tr>
                        );
                    })
                    
                    }
                    {
                        (notes.length >= 2) &&
                        <tr className={`log-row last`}>
                            <td colSpan={6} className='showMoreLinkWrapper'>
                                {
                                    (showMoreLessBtnVisibility) ? <a href='javascript:void(0)' onClick={toggleShowMoreLessClick} >Show {(showMoreLessBtnStatus) ? 'More' : 'Less'}</a> : <></>
                                }
                            </td>
                        </tr>
                    }
                </Fragment>
            </tbody>
        </table>
    )
}

export default Notes