import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import AppDivisionContext from "../../../contexts/AppDivisionContext";
import { Project } from "../../../utils/api/project";

const ProjectCard = ({ project, custom_tab }: { project?: Project, custom_tab: boolean }) => {
  const { getPath } = useContext(AppDivisionContext);
  const history = useHistory();

  if (!project) {
    throw new Error();
  }

  return (
    <div className="project" key={project.id}>
      <div
        className="project-main"
        onClick={() =>
          custom_tab ? history.push(getPath(`/projects/${project.id}/project-docs`)) : history.push(getPath(`/projects/${project.id}/inspections`))
        }
        style={{ justifyContent: "space-between"}}
      >
        <div>
          <h3>{project.name}</h3>
          <h5>Next Inspection: {project.next_inspection_date}</h5>
        </div>

        <h4>Created On: {new Date(project.createdAt).toLocaleDateString("en-US")}</h4>
      </div>
    </div>
  );
};

export default ProjectCard;
