import React from "react";

import { never } from "ol/events/condition";
import SelectFeature from "./SelectFeature";
import useFeature from "../../hooks/useFeature";

export const Context = React.createContext();

/**
 * Controlled selection based on interaction mode data
 */
function StaticSelectFeature({ children }) {
  const feature = useFeature();

  if (feature) {
    return (
      <SelectFeature condition={never} selectedFeatures={[feature]}>
        {children}
      </SelectFeature>
    );
  }

  return null;
}

export default StaticSelectFeature;
