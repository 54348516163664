import React, { useState, createRef, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InspectionQuestions from "./InspectionQuestions";
import EditQuestionGroupForm from "./Forms/EditQuestionGroupForm";
import { useQuestionGroup } from "../../../hooks/questionGroups";
import TemplateEditorContext, {
  WarningMessages,
} from "../../../contexts/TemplateEditorContext";
import QuestionGroupContext, {
  QuestionGroupProvider,
} from "../../../contexts/QuestionGroupContext";
import QuestionGroupDelete from "./Forms/QuestionGroupDelete";
import classnames from "classnames";
import { Button } from "@sw-sw/lib-ui";
import QuestionGroupPopper from "./QuestionGroupPopper";
import { QuestionFeature } from "@sw-sw/lib-inspection-templates";
import _ from "lodash";

export interface IQuestionGroupProps {
  groupId: number;
}

const QuestionGroup: React.FC<IQuestionGroupProps> = ({ groupId }) => {
  const templateEditorContext = useContext(TemplateEditorContext);
  const [edit, setEdit] = useState<boolean>(false);
  const [showPopper, setShowPopper] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<any>({
    bottom: null,
    right: null,
    left: null,
  });
  const targetRef = createRef<any>();

  const questionGroupQuery = useQuestionGroup(
    templateEditorContext.templateId,
    groupId,
  );

  const handlePopperClick = () => {
    const { right, bottom, left } = targetRef.current.getBoundingClientRect();

    setCoordinates(
      Object.assign(
        { top: bottom + window.scrollY },
        right < 100
          ? { left: left + window.scrollX }
          : { right: window.innerWidth - right },
      ),
    );
    setShowPopper(true);
  };

  useEffect(() => {
    if (questionGroupQuery.data && questionGroupQuery.data.questions) {
      let warnings: WarningMessages[] = templateEditorContext.warnings;

      questionGroupQuery.data.questions.forEach(question => {
        if (
          question.features &&
          question.features.includes(QuestionFeature.isInspectionType)
        ) {
          warnings = warnings.filter(w => w !== "type");
        }

        if (
          question.features &&
          question.features.includes(QuestionFeature.isInspectionDate)
        ) {
          warnings = warnings.filter(w => w !== "date");
        }
      });

      templateEditorContext.setWarnings(warnings);
    }
  }, [questionGroupQuery.data]);

  return (
    <QuestionGroupProvider
      initialValue={{
        id: groupId,
      }}
    >
      <QuestionGroupContext.Consumer>
        {questionGroupContext => (
          <React.Fragment>
            <div
              className={classnames(
                "question-group",
                "container",
                questionGroupContext.moveActive ? "question-group-move" : "",
              )}
              style={{ order: questionGroupContext.questionGroup.order }}
            >
              <div className="question-group-header">
                {!edit ? (
                  <React.Fragment>
                    <div>
                      <h3>{questionGroupContext.questionGroup.name}</h3>
                    </div>
                    <div>
                      {
                        questionGroupContext.questionGroup.name === "Condition Group" || (
                          <FontAwesomeIcon
                            className="group-action"
                            icon="edit"
                            onClick={() => setEdit(true)}
                          />
                        )
                      }
                      <span ref={targetRef}>
                        <FontAwesomeIcon
                          className="group-action"
                          icon="ellipsis-v"
                          onClick={handlePopperClick}
                        />
                      </span>
                    </div>
                  </React.Fragment>
                ) : (
                  <EditQuestionGroupForm
                    initialValue={{
                      name: questionGroupContext.questionGroup.name,
                      layout_type:
                        questionGroupContext.questionGroup.layout_type,
                    }}
                    questionGroup={questionGroupContext.questionGroup}
                    onCancel={() => setEdit(false)}
                    onSubmit={() => setEdit(false)}
                  />
                )}
              </div>
              <InspectionQuestions
                questions={questionGroupContext.questionGroup.questions}
                onReorder={questionGroupQuery.refetch}
              />
              {showPopper && (
                <QuestionGroupPopper
                  groupName={questionGroupContext.questionGroup.name}
                  coordinates={coordinates}
                  handleClose={() => setShowPopper(false)}
                  showDeleteForm={() => setShowDelete(true)}
                />
              )}
              {questionGroupContext.moveActive && (
                <Button
                  primary
                  onClick={() => {
                    questionGroupContext.setSelectedQuestionId(null);
                    questionGroupContext.setMoveActive(false);
                  }}
                >
                  Cancel
                </Button>
              )}
            </div>
            <QuestionGroupDelete
              show={showDelete}
              onClose={() => setShowDelete(false)}
              name={questionGroupContext.questionGroup.name}
            />
          </React.Fragment>
        )}
      </QuestionGroupContext.Consumer>
    </QuestionGroupProvider>
  );
};

export default QuestionGroup;
