import React, { useState, useContext, useRef, useEffect } from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button
} from '@mui/material';
import {
    ProjectContext,
    ProjectContextValue,
} from '../../../contexts/ProjectContext';
import moment from 'moment';
import ExternalProjectsInspectionSubmitModal from './ExternalProjectsInspectionSubmitModal';
import Pagination from '../../Shared/Pagination/Pagination';
import xhrService from '../../../utils/xhrService';
import AppContext from '../../../contexts/AppContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom';
import Loading from '../../Shared/ResourceIndex/Loading';

type Props = {
    inspections: ProjectContextValue['inspections'];
    pagination?: any;
    data?: any;
    setPageval?: Function;
    pageVal?: number
}

const ExternalProjectsInspectionListing = (props: Props) => {

    const history = useHistory();

    const [showExternalProjectInspectionModal, setShowExternalProjectInspectionModal] = useState(false);
    const [modalInspection, setModalInspection] = useState<any>({});
    const [inspectionTypes, setInspectionTypes] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const projectStore = useContext(ProjectContext);
    const appContext = useContext(AppContext);
    const { page, setPage, paginationState } = props.pagination;
    const options = useRef(['10', '25', '50']);

    projectStore.setInspections(props.data);

    const handleSubmit = async (formData: any) => {

        setLoading(true);
        appContext.loadData(async () => {
            await xhrService.put(`/api/inspections/${modalInspection.id}/update-evp-inspection`, {
                ...formData
            }).then(()=>{
                setShowExternalProjectInspectionModal(false);
                setModalInspection({});
                setLoading(false);
                window.location.reload();
            });
            
        });

    }

    const handleDelete = async () => {
        
        setLoading(true);
        appContext.loadData(async () => {
            await xhrService.delete(`/api/inspections/${modalInspection.id}/delete`).then(()=>{
                setShowExternalProjectInspectionModal(false);
                setModalInspection({});
                setLoading(false);
                window.location.reload();
            });
        });

    }

    const onChange = async (event: any) => {
        const newVal = event.target.value;
        if (props.setPageval) {
            console.log(newVal)
            props.setPageval(newVal)
        }
    };

    const setInspection = (inspection: any) => {
        setShowExternalProjectInspectionModal(true);
        setModalInspection(inspection)
    }

    const getInspectionTypes = async () => {
        const dataTypes = await xhrService.get(`/api/inspection-types`);
        const mappedData = dataTypes.data.map((e: string) => ({ type: e }));
        setInspectionTypes(mappedData);
    }

    useEffect(() => {
        const params = new URLSearchParams();

        if (page) {
            params.append('page', String(page));
            history.push({ search: params.toString() });
        } else {
            params.delete('page');
        }
    }, [page, history]);

    useEffect(() => {
        getInspectionTypes();
    }, []);

    return (
        <>

            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='evp-inspection-listing'>
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" >#</TableCell>
                                    <TableCell align="center">Date of Inspection</TableCell>
                                    <TableCell align="center">Inspection Type</TableCell>
                                    <TableCell align="center">Notes</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(loading) ?
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="td" scope="row" className='no-border text-center' colSpan={6}> <Loading /> </TableCell>
                                    </TableRow>
                                    :
                                    props.inspections.map((inspection) => (
                                        <TableRow
                                            key={inspection.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="td" scope="row" className='no-border'>{inspection.inspectionNumber} </TableCell>
                                            <TableCell align="center" className='no-border' >{moment(inspection.created_date).format('MM-DD-YYYY')}</TableCell>
                                            <TableCell align="center" className='no-border' >{inspection.type}</TableCell>
                                            <TableCell align="center" className='no-border' >{(inspection.certification_notes) ? inspection.certification_notes : '-'}</TableCell>
                                            <TableCell align="center" className='no-border' >{inspection.status}</TableCell>
                                            <TableCell align="center" className='no-border' ><Button onClick={() => setInspection(inspection)} className={(inspection.status === 'new') ? 'list-view-submit-btn' : ''}>  {(inspection.status === 'new') ? 'Submit' : <FontAwesomeIcon icon={faEdit} />} </Button></TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {
                showExternalProjectInspectionModal ?
                    <ExternalProjectsInspectionSubmitModal
                        handleClose={() => setShowExternalProjectInspectionModal(false)}
                        handleSubmit={handleSubmit}
                        handleDelete={handleDelete}
                        modalInspection={modalInspection}
                        title="Submit EVP Inspection"
                        inspectionTypes={inspectionTypes}
                        project_name={projectStore.projectName}
                    />
                    :
                    <></>
            }

            {
                (!loading) ?

                    <div className='inspection-pagesize'>
                        <Pagination
                            page={page || 1}
                            setPage={setPage}
                            paginationState={paginationState}
                        />
                        {props.data.length ? <select
                            value={props.pageVal}
                            onChange={onChange}
                            className='pagesize-item'
                        >
                            {options.current.map((val, index) => {
                                return (
                                    <option key={index} value={val}>
                                        {val}
                                    </option>
                                );
                            })}
                        </select> : null}
                    </div>

                    :

                    <></>
            }


        </>
    )
}

export default ExternalProjectsInspectionListing