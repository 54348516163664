import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Modal } from "@sw-sw/lib-ui";
import convertApi from "../../../utils/api/convert";
import ConvertJobRunner from "./ConvertJobRunner";
import { FormHelpText } from "@sw-sw/lib-form";
const ConvertDocModal = ({ docs, handleConverted, dequeue, ...modalProps }) => {
  const [connected, setConnected] = useState(false);
  const [status, setStatus] = useState();
  const [error, setError] = useState(null);

  /** Initialize printing api */
  useEffect(() => {
    setStatus("connecting");

    convertApi
      .init()
      .then(sk => {
        setStatus("connected");
        setConnected(true);
      })
      .catch(err => {
        setConnected(false);
        setStatus("error");
        setError(err.message || "Unknown error establishing connection");
      });

    return () => {
      convertApi.deinit();
    };
  }, []);

  useEffect(() => {
    // close when the queue is empty
    if (connected && !docs.length) {
      modalProps.handleClose();
    }
  }, [docs]);

  return (
    <Modal
      {...modalProps}
      classes="convert-doc-modal"
      show={true}
      hideButtons
      isSmall
    >
      <div className="convert-doc-modal-ui">
        {!connected && <FormHelpText content="connecting to server" />}
        {error ? (
          <FormHelpText content={`${status} - ${error}`} theme="error" />
        ) : (
          docs.map(doc => (
            <ConvertJobRunner
              connected={connected}
              doc={doc}
              key={doc.id}
              onCompletePage={handleConverted}
              onCompleteQueue={dequeue}
            />
          ))
        )}
      </div>
    </Modal>
  );
};

ConvertDocModal.propTypes = {
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      jtModelName: PropTypes.string.isRequired,
      jtAtts: PropTypes.object.isRequired,
    }).isRequired,
  ).isRequired,
  dequeue: PropTypes.func.isRequired,
  handleConverted: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

ConvertDocModal.defaultProps = {
  title: "Convert Document",
};

export default ConvertDocModal;
