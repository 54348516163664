import React, { MouseEventHandler, useContext , useEffect} from "react";
import AppDivisionContext from "../../../contexts/AppDivisionContext";
import Popper from "../../Shared/Popper/Popper";

export interface IDivisionPopperProps {
  handleClose: MouseEventHandler;
  coordinates: {
    right: number;
    top: number;
  };
  onChange: (division?: number) => void;
}

const DivisionPopper: React.FC<IDivisionPopperProps> = ({
  handleClose,
  coordinates,
  onChange = null,
}) => {
  const appDivisionContext= useContext(AppDivisionContext);
  const {refetchDivisions} = useContext(AppDivisionContext);

  useEffect(()=>{
    refetchDivisions()
  },[])

  const handleClick = (divisionId: number) => (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    appDivisionContext.saveAppDivisionId(divisionId);
    if (onChange) {
      onChange(divisionId);
    }
    handleClose(e);
  };

  return (
    <Popper coordinates={coordinates} handleClose={handleClose}>
      <ul>
        {appDivisionContext.divisions.map(
          (division: { id: number; name: string }) => {
            return (
              <li
                className="pointer"
                onClick={handleClick(division.id)}
                key={division.id}
                value={division.id}
              >
                {division.name}
              </li>
            );
          },
        )}
      </ul>
    </Popper>
  );
};

export default DivisionPopper;
