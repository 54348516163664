import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import AuthenticationContext from '../../contexts/AuthenticationContext';

function Logout() {
  const authStore = useContext(AuthenticationContext);

  useEffect(() => {
    window.location.reload();
    authStore.logout();
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}/login`;
  }, []);

  return <Redirect push to='/login' />;
}

export default Logout;
