/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Switch } from '@mui/material';
import {
  AnySignatureLineGroupData,
  CertFormSchema,
  CertSignature,
  SignatureGroupAttestations,
  SignatureGroupOptions,
  SignatureGroupType,
  SignatureLineGroups,
} from '@sw-sw/lib-certification';
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  SignatureEditorFormType,
  SignatureResourceType,
} from '../../../contexts/SignatureEditorContext';
import { certificationApi } from '../../../utils/api/certification';
import inspectionTemplateApi from '../../../utils/api/inspectionTemplate';
import { SignatureEditorButton } from '../../InspectionTemplates/SignatureEditor/Buttons/SignatureEditorButton';
import CertificationForm from './CertificationForm';
import ComplianceCertificationInstructions from './ComplianceCertificationInstructions';
import InspectionCertificationStatement from './InspectionCertificationStatement';
import InspectionCertificationContext from '../../../contexts/InspectionCertificationContext';
import { toast } from 'react-toastify';
import { InspectionContext } from '../../../contexts/InspectionContext';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import { RolesContext } from '../../../contexts/RolesContext';

type CertUIProps = {
  attestationGroups: SignatureGroupAttestations;
  signatureGroups: SignatureLineGroups;
  optionGroups?: AnySignatureLineGroupData<SignatureGroupOptions>;
  userDataGroups?: AnySignatureLineGroupData<CertSignature[]>;
  onSave(data: {
    group: SignatureGroupType;
    lineType: string;
    formData: CertFormSchema;
  }): Promise<void>;
  isCcr?: boolean;
  isPe?: boolean;
  inspectorMinCertificationDate?: Date;
  minCertificationDate?: Date;
  readOnly?: boolean;
  Acknowledgement?: boolean;
  showAck?: boolean;
  renderEditButton?: SignatureEditorButton;
  renderDeleteButton?: SignatureEditorButton;
  renderReorderButtons?: SignatureEditorButton;
  templateId?: number;
  templateData?: any;
  showCertificate?: boolean;
  showComplianceCert?: any;
  setShowComplianceCert?: any;
  fromSignature?: boolean;
  findingTypeData?: any;
  ackName?: string;
};

const handleUpdate = async (
  id: number | undefined,
  templateData: any,
  showAck?: boolean,
) => {
  templateData.has_acknowledgement = !showAck;

  return await inspectionTemplateApi.updateAck(id, templateData);
};

export const CertificationUI: FC<PropsWithChildren<CertUIProps>> = ({
  attestationGroups,
  signatureGroups,
  optionGroups = {},
  userDataGroups = {},
  children,
  onSave,
  isCcr = false,
  isPe = false,
  inspectorMinCertificationDate,
  minCertificationDate,
  readOnly = false,
  renderEditButton,
  renderDeleteButton,
  renderReorderButtons,
  showAck,
  templateId,
  templateData,
  showCertificate = true,
  showComplianceCert,
  setShowComplianceCert,
  fromSignature = false,
  findingTypeData,
  ackName,
}) => {
  const showActions = renderEditButton || renderDeleteButton;
  const [togglevalue, setToggleValue] = useState<any>();
  const [intialLoad, setIntialLoad] = useState(true);
  const [toggleComplianceCert, setToogleComplianceCert] = useState<boolean>();
  const [selectedCerts, setSelectedCerts] = useState<any>({
    inspection: [],
    acknowledgement: [],
    compliance: [],
  });

  const [showModal, setShowModal] = useState<string | null>(null);

  const [complianceToggleDisabled, setComplianceToggleDisabled] =
    useState<boolean>(false);
  const [complianceToggleErrorMessage, setcomplianceToggleErrorMessage] =
    useState<string>('');

  const handleToggleComplianceCertification = (currentState: boolean) => {
    setToogleComplianceCert(currentState);
    setShowComplianceCert(currentState);
  };
  const inspectionCertificationContext = useContext(
    InspectionCertificationContext,
  );
  const inspectionContext = useContext(InspectionContext);
  const [mailSent, setMailSent] = useState(false);
  const permCheck = useContext(RolesContext).userHasPermission;

  useEffect(() => {
    if (showAck && intialLoad) {
      setToggleValue(showAck);
      setIntialLoad(false);
    }
  }, [showAck]);

  useEffect(() => {
    if (showComplianceCert) {
      setToogleComplianceCert(showComplianceCert);
    }
  }, [showComplianceCert]);

  useEffect(() => {
    if (findingTypeData) {
      const data = findingTypeData.filter(
        (item: any) => item.details.is_required_for_cert === true,
      );

      if (data.length > 0) {
        setComplianceToggleDisabled(true);
        let tempMsg =
          '* Following finding types are marked as required for sign compliance certification, Please unmark them to proceed : \n ';

        data.map((item: any, index: number) => {
          switch (item.name) {
            case 'CA':
              tempMsg += ` ${index + 1}) Corrective Action`;
              break;
            case 'MI':
              tempMsg += ` ${index + 1}) Maintenance Item`;
              break;
            case 'A':
              tempMsg += ` ${index + 1}) Achievement`;
              break;
            default:
              tempMsg += ` ${index + 1}) ${item.name}`;
          }
        });
        setcomplianceToggleErrorMessage(tempMsg);
      }
    }
  }, [findingTypeData]);

  const resendInsp = () => {
    const inspectionId = inspectionCertificationContext.state.inspectionId;
    const group = SignatureGroupType.inspection;

    let certDate: string | Date = '';

    let lineType: string = '';

    inspectionCertificationContext.state.userSignatures.inspection.map(
      (e: { date: string | Date }) => {
        certDate = e.date;

        return certDate;
      },
    );

    if (certDate === '') {
      inspectionCertificationContext.state.userSignatures.acknowledgement.map(
        (e: { date: string | Date }) => {
          certDate = e.date;

          return certDate;
        },
      );
    }

    signatureGroups.inspection.map((e) => {
      return (lineType = e.type);
    });
    const toastId = toast('Sending inspection');

    toast.update(toastId, {
      render: 'Inspection resent!',
      type: 'success',
    });
    certificationApi.create(
      { inspectionId, group, date: certDate, lineType: lineType },
      true,
    );
    setTimeout(() => {
      setMailSent(false);
    }, 5000);
    setMailSent(true);
  };

  const clearCertification = (type: SignatureGroupType) => {
    if (type === SignatureGroupType.inspection) {
      if (
        (
          userDataGroups[
            SignatureGroupType.compliance as keyof typeof userDataGroups
          ] as any[]
        ).length > 0
      ) {
        const complianceIds =
          inspectionCertificationContext.state.userSignatures.compliance.map(
            (s) => s.id,
          );

        certificationApi
          .removeCertification(
            inspectionCertificationContext.state.inspectionId,
            {
              certIds: complianceIds,
              signatureGroup: SignatureGroupType.compliance,
            },
          )
          .then(() => {});
      }
      if (
        (
          userDataGroups[
            SignatureGroupType.acknowledgement as keyof typeof userDataGroups
          ] as any[]
        ).length > 0
      ) {
        const ackIds =
          inspectionCertificationContext.state.userSignatures.acknowledgement.map(
            (s) => s.id,
          );

        certificationApi
          .removeCertification(
            inspectionCertificationContext.state.inspectionId,
            {
              certIds: ackIds,
              signatureGroup: SignatureGroupType.acknowledgement,
            },
          )
          .then(() => {});
      }

      certificationApi
        .removeCertification(
          inspectionCertificationContext.state.inspectionId,
          {
            certIds: selectedCerts[type],
            signatureGroup: type,
          },
        )
        .then(() => {
          inspectionCertificationContext.flashSuccess();
          inspectionCertificationContext.reload();
          let copy = { ...selectedCerts };

          copy[type] = [];
          setSelectedCerts(copy);
        });
    } else {
      certificationApi
        .removeCertification(
          inspectionCertificationContext.state.inspectionId,
          {
            certIds: selectedCerts[type],
            signatureGroup: type,
          },
        )
        .then(() => {
          inspectionCertificationContext.flashSuccess();
          inspectionCertificationContext.reload();
          let copy = { ...selectedCerts };

          copy[type] = [];
          setSelectedCerts(copy);
        });
    }
    setShowModal(null);
  };

  const getIsChecked = (type: SignatureGroupType, lineType?: string) => {
    if (!inspectionCertificationContext.state.userSignatures) return false;
    
    return (
      inspectionCertificationContext.state.userSignatures[type].filter((s) =>
        lineType ? s.lineType === lineType : s.id !== null,
      ).length > 0
    );
  };

  const getCheckedCertId = (lineType: string, type: SignatureGroupType) => {
    return inspectionCertificationContext.state.userSignatures[type].filter(
      (s) => s.lineType === lineType,
    )[0]?.id;
  };

  const canResend =
    inspectionCertificationContext.state.userSignatures &&
    (inspectionCertificationContext.state.userSignatures.inspection.length >
      0 ||
      inspectionCertificationContext.state.userSignatures.acknowledgement
        .length > 0);

  const isPublic = window.location.pathname.startsWith('/public');

  return (
    <section className='inspection-certification'>
      <section>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '10px',
          }}
        >
          {canResend && !isPublic && (
            <button
              className={'action-buttons-primary primary'}
              onClick={() => resendInsp()}
              disabled={mailSent}
            >
              Resend Inspection
            </button>
          )}
        </div>
        <div className='inspection-space-between'>
          <h3>Inspection Certification</h3>
          {permCheck('all', 'Manage Certification') &&
            getIsChecked(SignatureGroupType.inspection) &&
            selectedCerts.inspection.length !== 0 && (
              <div>
                <button
                  style={{ padding: '0.1rem 0.8rem', border: 'solid thin' }}
                  onClick={() => setShowModal(SignatureGroupType.inspection)}
                >
                  Clear Certification
                </button>
              </div>
            )}
        </div>
        <InspectionCertificationStatement
          attestations={attestationGroups[SignatureGroupType.inspection]}
        />

        {showActions
          ? (() => {
              const buttonProps = {
                resourceType: SignatureResourceType.ATTESTATION,
                resourceIndex: 0,
                groupType: SignatureGroupType.inspection,
              };

              return (
                <div className='attestation-edit-mode'>
                  {renderEditButton
                    ? renderEditButton({
                        ...buttonProps,
                        formType: attestationGroups[
                          SignatureGroupType.inspection
                        ].length
                          ? SignatureEditorFormType.EDIT
                          : SignatureEditorFormType.ADD,
                      })
                    : null}
                  {renderDeleteButton &&
                  attestationGroups[SignatureGroupType.inspection].length > 1
                    ? renderDeleteButton(buttonProps)
                    : null}
                </div>
              );
            })()
          : null}

        {signatureGroups.inspection.map((line, index) => {
          const { type, label } = line;
          const buttonProps = {
            resourceType: SignatureResourceType.LINE,
            resourceIndex: index,
            groupType: SignatureGroupType.inspection,
          };

          return (
            <React.Fragment key={`${type}_${index}`}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                {permCheck('all', 'Manage Certification') &&
                  getIsChecked(SignatureGroupType.inspection, type) && (
                    <form style={{ marginRight: '10px' }}>
                      <input
                        type='checkbox'
                        style={{ height: '13px', width: '13px' }}
                        onClick={() => {
                          let copy = { ...selectedCerts };
                          let id = getCheckedCertId(
                            type,
                            SignatureGroupType.inspection,
                          );

                          if (!copy.inspection.includes(id)) {
                            copy.inspection.push(id);
                          } else {
                            copy.inspection = copy.inspection.filter(
                              (i: number) => i !== id,
                            );
                          }
                          setSelectedCerts(copy);
                        }}
                        defaultChecked={false}
                      />
                    </form>
                  )}
                <div className='pure-g' style={{ width: '100%' }}>
                  {label ? <h4 className='pure-u-1'>{label}</h4> : null}
                  <CertificationForm
                    line={line}
                    type={SignatureGroupType.inspection}
                    options={
                      optionGroups[
                        SignatureGroupType.inspection as keyof typeof optionGroups
                      ]
                    }
                    userData={
                      userDataGroups[
                        SignatureGroupType.inspection as keyof typeof userDataGroups
                      ]
                    }
                    isCcr={isCcr && line.userFieldLabel === 'Inspector Name'}
                    isPe={isPe && line.label === 'Supervising Engineer'}
                    minCertificationDate={inspectorMinCertificationDate}
                    onSave={(data) => {
                      inspectionContext.setIsInspectionReadOnly(true);

                      return onSave({
                        ...data,
                        group: SignatureGroupType.inspection,
                      });
                    }}
                    readOnly={readOnly}
                  />
                  {renderEditButton && renderEditButton(buttonProps)}
                  {renderDeleteButton && renderDeleteButton(buttonProps)}
                  {renderReorderButtons && renderReorderButtons(buttonProps)}
                </div>
              </div>
            </React.Fragment>
          );
        })}
        {renderEditButton &&
          renderEditButton({
            resourceType: SignatureResourceType.LINE,
            resourceIndex: signatureGroups.inspection.length,
            formType: SignatureEditorFormType.ADD,
            groupType: SignatureGroupType.inspection,
          })}
        <hr />
      </section>

      {showCertificate && (
        <section>
          <div className='inspection-space-between'>
            <h3>{ackName}</h3>
            {permCheck('all', 'Manage Certification') &&
              getIsChecked(SignatureGroupType.acknowledgement) &&
              selectedCerts.acknowledgement.length !== 0 && (
                <div>
                  <button
                    style={{ padding: '0.1rem 0.8rem', border: 'solid thin' }}
                    onClick={() =>
                      setShowModal(SignatureGroupType.acknowledgement)
                    }
                  >
                    Clear Certification
                  </button>
                </div>
              )}
          </div>
          <InspectionCertificationStatement
            attestations={attestationGroups[SignatureGroupType.acknowledgement]}
          />

          {showActions
            ? (() => {
                const buttonProps = {
                  resourceType: SignatureResourceType.ATTESTATION,
                  resourceIndex: 0,
                  groupType: SignatureGroupType.acknowledgement,
                };

                return (
                  <div className='attestation-edit-mode'>
                    {renderEditButton
                      ? renderEditButton({
                          ...buttonProps,
                          formType: attestationGroups[
                            SignatureGroupType.acknowledgement
                          ].length
                            ? SignatureEditorFormType.EDIT
                            : SignatureEditorFormType.ADD,
                        })
                      : null}
                    {renderDeleteButton &&
                    attestationGroups[SignatureGroupType.acknowledgement]
                      .length > 1
                      ? renderDeleteButton(buttonProps)
                      : null}
                  </div>
                );
              })()
            : null}

          {signatureGroups.acknowledgement.map((line, index) => {
            const { type, label } = line;
            const buttonProps = {
              resourceType: SignatureResourceType.LINE,
              resourceIndex: index,
              groupType: SignatureGroupType.acknowledgement,
            };

            return (
              <React.Fragment key={`${type}_${index}`}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  {permCheck('all', 'Manage Certification') &&
                    getIsChecked(SignatureGroupType.acknowledgement, type) && (
                      <form style={{ marginRight: '10px' }}>
                        <input
                          type='checkbox'
                          style={{ height: '13px', width: '13px' }}
                          onClick={() => {
                            let copy = { ...selectedCerts };
                            let id = getCheckedCertId(
                              type,
                              SignatureGroupType.acknowledgement,
                            );

                            if (!copy.acknowledgement.includes(id)) {
                              copy.acknowledgement.push(id);
                            } else {
                              copy.acknowledgement =
                                copy.acknowledgement.filter(
                                  (i: number) => i !== id,
                                );
                            }
                            setSelectedCerts(copy);
                          }}
                          defaultChecked={false}
                        />
                      </form>
                    )}
                  <div className='pure-g'>
                    {label ? <h4 className='pure-u-1'>{label}</h4> : null}
                    <CertificationForm
                      line={line}
                      type={SignatureGroupType.acknowledgement}
                      options={
                        optionGroups[
                          SignatureGroupType.acknowledgement as keyof typeof optionGroups
                        ]
                      }
                      userData={
                        userDataGroups[
                          SignatureGroupType.acknowledgement as keyof typeof userDataGroups
                        ]
                      }
                      isCcr={isCcr && line.userFieldLabel === 'Inspector Name'}
                      isPe={isPe && line.label === 'Supervising Engineer'}
                      minCertificationDate={inspectorMinCertificationDate}
                      onSave={(data) => {
                        return onSave({
                          ...data,
                          group: SignatureGroupType.acknowledgement,
                        });
                      }}
                      readOnly={readOnly}
                    />
                    {renderEditButton && renderEditButton(buttonProps)}
                    {renderDeleteButton && renderDeleteButton(buttonProps)}
                    {renderReorderButtons && renderReorderButtons(buttonProps)}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
          {renderEditButton &&
            renderEditButton({
              resourceType: SignatureResourceType.LINE,
              resourceIndex: signatureGroups.acknowledgement.length,
              formType: SignatureEditorFormType.ADD,
              groupType: SignatureGroupType.acknowledgement,
            })}
          {renderEditButton && (
            <>
              <br />
              <div className={'form-group form-group--text'}>
                <label>
                  <br />
                  <span>Show Acknowledgement</span>
                </label>
                <Switch
                  checked={togglevalue}
                  onChange={() =>
                    handleUpdate(templateId, templateData, showAck).then(
                      (res: any) => {
                        if (res) {
                          setToggleValue(res.has_acknowledgement);
                        }
                      },
                    )
                  }
                />
              </div>
            </>
          )}
          <hr />
        </section>
      )}

      {(showComplianceCert || fromSignature) && (
        <section>
          <div className='inspection-space-between'>
            <h3>Compliance Certification</h3>
            {permCheck('all', 'Manage Certification') &&
              getIsChecked(SignatureGroupType.compliance) &&
              selectedCerts.compliance.length !== 0 && (
                <div>
                  <button
                    style={{ padding: '0.1rem 0.8rem', border: 'solid thin' }}
                    onClick={() => setShowModal(SignatureGroupType.compliance)}
                  >
                    Clear Certification
                  </button>
                </div>
              )}
          </div>
          {readOnly ? null : <ComplianceCertificationInstructions />}
          <InspectionCertificationStatement
            attestations={attestationGroups[SignatureGroupType.compliance]}
          />

          {renderEditButton && (
            <div className='attestation-edit-mode'>
              {renderEditButton({
                resourceType: SignatureResourceType.ATTESTATION,
                resourceIndex: 0,
                groupType: SignatureGroupType.compliance,
              })}
              {renderDeleteButton &&
                renderDeleteButton({
                  resourceType: SignatureResourceType.ATTESTATION,
                  resourceIndex: 0,
                  groupType: SignatureGroupType.compliance,
                })}
            </div>
          )}

          {signatureGroups.compliance.map((line, index) => {
            const { type, label } = line;
            const buttonProps = {
              resourceType: SignatureResourceType.LINE,
              resourceIndex: index,
              groupType: SignatureGroupType.compliance,
            };

            return (
              <React.Fragment key={`${type}_${index}`}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  {permCheck('all', 'Manage Certification') &&
                    getIsChecked(SignatureGroupType.compliance, type) && (
                      <form style={{ marginRight: '10px' }}>
                        <input
                          type='checkbox'
                          style={{ height: '13px', width: '13px' }}
                          onClick={() => {
                            let copy = { ...selectedCerts };
                            let id = getCheckedCertId(
                              type,
                              SignatureGroupType.compliance,
                            );

                            if (!copy.compliance.includes(id)) {
                              copy.compliance.push(id);
                            } else {
                              copy.compliance = copy.compliance.filter(
                                (i: number) => i !== id,
                              );
                            }
                            setSelectedCerts(copy);
                          }}
                          defaultChecked={false}
                        />
                      </form>
                    )}
                  <div className='pure-g'>
                    {label && <h4 className='pure-u-1'>{label}</h4>}

                    <CertificationForm
                      line={line}
                      type={SignatureGroupType.compliance}
                      options={
                        optionGroups[
                          SignatureGroupType.compliance as keyof typeof optionGroups
                        ]
                      }
                      userData={
                        userDataGroups[
                          SignatureGroupType.compliance as keyof typeof userDataGroups
                        ]
                      }
                      minCertificationDate={minCertificationDate}
                      onSave={async (data) => {
                        await onSave({
                          ...data,
                          group: SignatureGroupType.compliance,
                        });
                        await inspectionContext.reloadInspection();
                      }}
                      readOnly={readOnly}
                    />

                    {renderEditButton && renderEditButton(buttonProps)}
                    {renderDeleteButton && renderDeleteButton(buttonProps)}
                    {renderReorderButtons && renderReorderButtons(buttonProps)}
                  </div>
                </div>
              </React.Fragment>
            );
          })}

          {renderEditButton &&
            renderEditButton({
              formType: SignatureEditorFormType.ADD,
              resourceType: SignatureResourceType.LINE,
              resourceIndex: signatureGroups.compliance.length,
              groupType: SignatureGroupType.compliance,
            })}

          {renderEditButton && (
            <div
              className={'form-group form-group--text'}
              style={{ marginTop: '15px' }}
            >
              <label>
                <span>Show Compliance Certification</span>
              </label>
              <Switch
                checked={toggleComplianceCert}
                disabled={complianceToggleDisabled}
                onChange={() =>
                  handleToggleComplianceCertification(!toggleComplianceCert)
                }
              />
            </div>
          )}

          {renderEditButton &&
            complianceToggleErrorMessage &&
            complianceToggleDisabled && (
              <p style={{ color: 'red' }}>{complianceToggleErrorMessage}</p>
            )}
          <hr />
        </section>
      )}

      {children}

      <ConfirmationModal
        show={showModal !== null}
        handleClose={() => setShowModal(null)}
        handleConfirm={() =>
          clearCertification(showModal as SignatureGroupType)
        }
        title='Clear Certification'
        subTitle={`Are you sure you want to remove the ${showModal}
          certification(s)? ${
            showModal === SignatureGroupType.inspection
              ? 'This will also remove any signed Compliance Certifications.'
              : ''
          } `}
        buttonText='Clear Certification'
      />
    </section>
  );
};

/** @todo Add to `certificiationApi.index` or profile. */
export function getcDOTObservationTemplates(clientName: string) {
  return [
    {
      label: '"Green Book" Statement',
      content: `${
        clientName || 'Client'
      } is under contract to use the CDOT "Green Book". However, for ease of use with our electronic inspection platform, CDOT has allowed ${
        clientName || 'client'
      } to use the 1176 form for the inspection documentation from the "Red Book"`,
    },
  ];
}
