import React, { Component } from "react";

import { Context } from "./Context";

class ShapeEditorSelectionActions extends Component {
  render() {
    const { selectedObject, deleteObject } = this.context;

    return (
      <div className="shape-editor-selection-actions">
        {selectedObject && (
          <button
            className="pure-button"
            onClick={() => deleteObject(selectedObject)}
          >
            Delete Selected
          </button>
        )}
      </div>
    );
  }
}

ShapeEditorSelectionActions.contextType = Context;

export default ShapeEditorSelectionActions;
