import React, { useState, useCallback } from "react";
import classnames from "classnames";

function useDropdownTimeout(time = 1000) {
  const [isOpen, setOpen] = useState(false);
  const [timerId, setTimerId] = useState(null);

  const startTimer = useCallback(() => {
    const timerId = setTimeout(() => {
      setOpen(false);
    }, time);

    setTimerId(timerId);
  }, [time, timerId]);

  const stopTimer = useCallback(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
  }, [time, timerId]);

  return [
    isOpen,
    {
      setOpen,
      startTimer,
      stopTimer,
    },
  ];
}

export default function ({ label, children, disabled }) {
  const [isOpen, timer] = useDropdownTimeout(300);

  return (
    <span
      className={`sw-dropdown ${disabled ? `sw-dropdown--disabled` : ``}`}
      onMouseEnter={() => timer.stopTimer()}
      onMouseLeave={() => timer.startTimer()}
    >
      <span onClick={() => !disabled && timer.setOpen(!isOpen)}>{label}</span>

      <div
        className={classnames({
          "sw-dropdown__menu--active": isOpen,
          "sw-dropdown__menu": !isOpen,
        })}
      >
        <div
          className="sw-dropdown__menu-inner"
          onClick={() => timer.setOpen(false)}
        >
          {children}
        </div>
      </div>
    </span>
  );
}
