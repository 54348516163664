import { Button } from "@sw-sw/lib-ui";
import React from "react";
import { SignatureEditorButtonProps } from "./SignatureEditorButton";

export type DeleteButtonProps = SignatureEditorButtonProps & {
  onDelete: (payload: SignatureEditorButtonProps) => void;
};

/** @todo confirmation modal via `SignatureEditorContextValue['formType']` */
export const DeleteButton: React.FC<DeleteButtonProps> = ({
  groupType,
  resourceType,
  resourceIndex,
  onDelete,
}) => {
  return (
    <Button
      outline
      small
      className="signature-editor-btn"
      onClick={() =>
        onDelete({
          resourceType,
          groupType,
          resourceIndex,
        })
      }
    >
      <i className="fa fa-trash" />
    </Button>
  );
};

export default DeleteButton;
