import React from 'react';

// components
import Dashboard from "./components/Dashboard/Dashboard";
import Clients from "./components/Client/Clients";
import ClientDetail from "./components/Client/ClientDetail";
import Divisions from "./components/Divisions/Divisions";
import DivisionDetail from "./components/Divisions/DivisionDetail";
import Groups from "./components/Groups/Groups";
import GroupDetail from "./components/Groups/GroupDetail";
import LegendItems from "./components/LegendItems/LegendItems";
import Projects from "./components/Projects/Projects";
import ProjectDetail from "./components/Projects/ProjectDetail";
import PostStormForm from "./components/Projects/PostStormForm/PostStormForm";
import InspectionDetail from "./components/Inspections/Details/InspectionDetail";
import Users from "./components/Users/Users";
import { EditUserPage } from "./components/Users/EditUser/EditUserPage"
import {
  InspectionReportDownloadPage,
  InspectionReportNotFound,
} from './components/InspectionReport';
import {
  PrivateRoute,
  GenericRoute,
  PublicRoute,
  DivisionRoute,
  RedirectRoute,
  PublicUrlRoute,
} from './components/CustomRoutes';
import SidebarLayout from './components/Layouts/Sidebar';
import FullScreenLayout from './components/Layouts/FullScreen';
import MapEditor from './components/CustomRoutes/MapEditor';
import IdentityAuth from './components/CustomRoutes/IdentityAuth';
import Logout from './components/Users/Logout';
import GradientLayout from './components/Layouts/Gradient';
import NarrowLayout from './components/Layouts/Narrow';
import ForgotPassword from './components/Login/ForgotPassword/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword/ResetPassword';
import LoginForm from './components/Login/LoginForm/LoginForm';
// import PublicUrl from "./components/PublicUrl/PublicUrl"
// import PublicInspectionDetails from "./components/PublicUrl/Component/PublicInspectionDetails"
import EmptySidebarLayout from './components/Layouts/EmptySidebar';
import InspectorRegistrationForm from './components/Users/Register/InspectorRegistrationForm';
import HomePage from './components/CustomRoutes/Home';
import UserProfile from './components/UserProfile/Profile';
import StartInspection from './components/Projects/StartInspection';
import NoLayout from './components/Layouts/None';
import PublicUrlLayout from './components/Layouts/PublicURLLayout';
import { RouteProps } from 'react-router-dom';
import InspectionTemplates from './components/InspectionTemplates/InspectionTemplates';
import InspectionTemplateDetail from './components/InspectionTemplates/InspectionTemplateDetail';
import InspectionDetailsLayout from './components/Layouts/InspectionDetailsLayout';
import publicInspectionLayout from './components/Layouts/publicInspectionLayout';
import getArchiveApi from './utils/api/division';

import ManualList from './components/UserManuals/ManualList';
import ManualDetail from './components/UserManuals/ManualDetail';
import Reports  from './components/Report/Reports';
import Administrations from './components/Administration/Administration';
import ReportDetails from './components/Report/ReportDetails';
/**
 * (path, component), grouped by (Layout, Route)
 */
interface RouteConfig {
  Layout: React.ReactNode;
  Route: React.ElementType;
  routes: Array<
    {
      path: string;
      Component: React.ReactNode;
      exact?: boolean;
    } & RouteProps
  >;
}

const routes: RouteConfig[] = [
  {
    Layout: null,
    Route: RedirectRoute,
    routes: [
      {
        path: '/projects/:id/start-inspection',
        Component: null,
      },
      {
        path: '/groups/:id',
        Component: null,
      },
      {
        path: '/clients/:uuid',
        Component: null,
      },
      {
        path: '/projects/:id',
        Component: null,
      },
      {
        path: '/clients',
        Component: null,
        exact: true,
      },
      {
        path: '/projects/post-storm',
        Component: null,
      },
      {
        path: '/projects',
        Component: null,
        exact: true,
      },
      {
        path: '/groups',
        Component: null,
        exact: true,
      },
      {
        path: '/map-legend',
        Component: null,
      },
      {
        path: '/admin',
        Component: null,
      },
      // {
      //   path: "/user-profiles/:slug/edit",
      //   Component: null,
      // },
    ],
  },
  {
    Layout: GradientLayout,
    Route: GenericRoute, 
    routes: [
      {
        path: '/inspection/report/download',
        Component: InspectionReportDownloadPage,
      },
      {
        path: '/inspection/report/not-found',
        Component: InspectionReportNotFound,
      },
    ],
  },
  {
    Layout: FullScreenLayout,
    Route: DivisionRoute,
    routes: [
      {
        path: '/divisions/:division_id/projects/:projectId/map/:mapId/edit',
        Component: MapEditor,
      },
    ],
  },
  {
    Layout: FullScreenLayout,
    Route: PublicUrlRoute,
    routes: [
      {
        path: '/public/divisions/:division_id/projects/:projectId/map/:mapId/edit',
        Component: MapEditor,
      },
    ],
  },
  {
    Layout: SidebarLayout,
    Route: DivisionRoute,
    routes: [
      {
        path: '/divisions/:division_id/projects/:id/start-inspection',
        Component: StartInspection,
      },
      {
        path: '/divisions/:division_id/groups/:id',
        Component: GroupDetail,
      },
      {
        path: '/divisions/:division_id/clients/:uuid',
        Component: ClientDetail,
      },

      {
        path: '/divisions/:division_id/clients',
        Component: Clients,
        exact: true,
      },
      {
        path: '/divisions/:division_id/projects/post-storm',
        Component: PostStormForm,
      },
      {
        path: '/divisions/:division_id/projects',
        Component: Projects,
        exact: true,
      },
      {
        path: '/divisions/:division_id/projects/archive/:id',
        Component: ProjectDetail,
      },
      {
        path: '/divisions/:divisionId/projects/archive',
        Component: getArchiveApi,
      },
      {
        path: '/divisions/:division_id/projects/:id',
        Component: ProjectDetail,
      },
      {
        path: '/divisions/:division_id/groups',
        Component: Groups,
        exact: true,
      },
      {
        path: '/divisions/:division_id/map-legend',
        Component: LegendItems,
      },
      {
        path: '/divisions/:division_id/admin',
        Component: Users,
      },
      {
        path: '/divisions/:division_id/inspection-templates/:id',
        Component: InspectionTemplateDetail,
      },
      {
        path: '/divisions/:division_id/inspection-templates',
        Component: InspectionTemplates,
      },
      
    ],
  },
  {
    Layout: InspectionDetailsLayout,
    Route: PrivateRoute,
    routes: [
      {
        path: '/inspection/:id',
        Component: InspectionDetail,
      },
    ],
  },
  {
    Layout: SidebarLayout,
    Route: PrivateRoute,
    routes: [
      {
        path: "/divisions/:division_id/user-profiles/:slug/edit",
        Component: EditUserPage,
      },
      {
        path: "/divisions/:division_id/dashboard",
        Component: Dashboard,
      },
      {
        path: '/divisions',
        Component: Divisions,
        exact: true,
      },
      {
        path: '/divisions/:division_id/reports/:slug',
        Component: ReportDetails,
      },
      {
        path: '/divisions/:division_id/reports',
        Component: Reports,
      },
      {
        path: "/divisions/:id",
        Component: DivisionDetail,
      },
      {
        path: '/administration',
        Component: Administrations,
      },
      
      {
        path: '/user-manuals',
        Component: ManualList,
        exact: true,
      },
      {
        path: "/user-manuals/:slug",
        Component: ManualDetail,
      },
      {
        path: '/my-profile',
        Component: UserProfile,
      },
      
    ],
  },

  /** public routes */

  {
    Layout: NarrowLayout,
    Route: PublicRoute,
    routes: [
      {
        path: '/forgot',
        Component: ForgotPassword,
      },
      {
        path: '/reset-password/:uuid',
        Component: ResetPassword,
      },
      {
        path: '/login',
        Component: LoginForm,
      },
    ],
  },
  {
    Layout: EmptySidebarLayout,
    Route: PublicRoute,
    routes: [
      {
        path: '/user/register/:uuid',
        Component: InspectorRegistrationForm,
      },
    ],
  },
  {
    Layout: PublicUrlLayout,
    Route: PublicUrlRoute,
    routes: [
      {
        path: "/public/divisions/:division_id/projects/:id",
        Component: ProjectDetail,
      },
    ]
  },
  {
    Layout: publicInspectionLayout,
    Route: PublicUrlRoute,
    routes: [
      {
        path: "/public/inspection/:inspectionId",
        Component: InspectionDetail
      }
    ]
  },

  /** redirection routes (public. no layout) */
  {
    Layout: NoLayout,
    Route: GenericRoute,
    routes: [
      {
        path: '/logout',
        Component: Logout,
      },
      {
        path: '/identity-auth',
        Component: IdentityAuth,
      },
      {
        path: '/',
        Component: HomePage,
      },
    ],
  },
];

export default routes;
