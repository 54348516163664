import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormContext } from "@sw-sw/lib-form";
import { get } from "lodash";
import React, { useContext, useState } from "react";
import DataPrivacyPolicyModal from "../../Shared/DataPrivacyPolicyModal";


const DataPrivacyPolicyControl: React.FC<{}> = () => {
  const formContext = useContext(FormContext);
  const [showDataPrivacyPolicyModal, setDataPrivacyPolicyModal] = useState<boolean>(false);

  return (
    <div className="data-privacy-policy-control">
      <input
        type="checkbox"
        checked={get(formContext.value, "dataPolicySignature")}
        onChange={() => 
          formContext.set(
            "dataPolicySignature",
            !get(formContext.value, "dataPolicySignature"),
          )
        }
      />
      <p>
        I agree to the Data privacy policy.{" "}
        <FontAwesomeIcon
          className="modal-icon"
          icon={faQuestionCircle}
          onClick={() => setDataPrivacyPolicyModal(true)}
        />
      </p>
      {showDataPrivacyPolicyModal && (
        <DataPrivacyPolicyModal
          show={showDataPrivacyPolicyModal}
          handleClose={() => setDataPrivacyPolicyModal(false)}
        />
      )}
    </div>
  );
};

export default DataPrivacyPolicyControl;
