import React, { useContext } from "react";
import {
  SignatureEditorContext,
  SignatureResourceType,
} from "../../../contexts/SignatureEditorContext";
import Error from "../../Shared/ResourceIndex/Error";
import AttestationForm from "./Forms/AttestationForm";
import LineForm from "./Forms/LineForm";
import { SignatureEditorFormProps } from "./Forms/SignatureEditorForm";

/**
 * Generic form modal modal, used to edit any signature resource.
 */
export const EditModal: React.FC<SignatureEditorFormProps> = formProps => {
  const signatureEditorContext = useContext(SignatureEditorContext);

  switch (signatureEditorContext.modal) {
    case SignatureResourceType.ATTESTATION:
      return <AttestationForm {...formProps} />;
    case SignatureResourceType.LINE:
      return <LineForm {...formProps} />;
    default:
      return <Error error={"Invalid `SignatureResourceType`"} />;
  }
};

export default EditModal;
