import React from "react";

import { LocalSearchProps, LocalSearch } from "../Search/LocalSearch";
import SearchInput, { SearchInputProps } from "../Search/SearchInput";

export const ResourceSearch: React.FC<
  SearchInputProps & {
    localSearch?: LocalSearchProps;
    local?: boolean;
  }
> = ({ localSearch, local = false, ...searchProps }) => {
  if (local) {
    if (!localSearch) {
      throw new Error("`localSearch` is required when `remote` is false");
    }

    return <LocalSearch {...localSearch} {...searchProps} />;
  } else {
    return <SearchInput {...searchProps} />;
  }
};

ResourceSearch.propTypes = {};

export default ResourceSearch;
