import React, { useContext, useState } from "react";

import ResourceDetailUpdate from "../Shared/ResourceDetail/ResourceDetailUpdate";
import DivisionDetailsForm from "./Forms/DivisionDetailsForm";
import DivisionContext from "../../contexts/DivisionContext";
import divisionApi from "../../utils/api/division";

export interface IDivisionUpdateProps {
  onUpdate: (id: number) => void;
}

const DivisionUpdate: React.FC<IDivisionUpdateProps> = ({ onUpdate }) => {
  const { selectedDivision } = useContext(DivisionContext);
  const [show, setShow] = useState(false);

  if (!selectedDivision) {
    throw new Error("Selected Division is required");
  }

  const handleSubmit = (formState: any) => {
    return divisionApi
      .update(selectedDivision.id, formState)
      .then(() => setShow(false))
      .then(() => onUpdate(selectedDivision.id))
      .catch(() => console.error("Error updating division"));
  };

  return (
    <ResourceDetailUpdate
      resource="Division"
      onButtonClick={() => setShow(true)}
    >
      {show && (
        <DivisionDetailsForm
          handleSubmit={handleSubmit}
          handleClose={() => setShow(false)}
          title="Edit Division Details"
          division={{ name: selectedDivision.name }}
        />
      )}
    </ResourceDetailUpdate>
  );
};

export default DivisionUpdate;
