import { QuestionConfigValueApi } from "@sw-sw/lib-inspection-templates";
import xhrService from "../api";

export const questionConfigValueApi: QuestionConfigValueApi = {
  index: (questionId: number) => {
    return xhrService
      .get(`/api/questions/${questionId}/config`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
};
