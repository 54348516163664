import React, { Fragment, useContext } from "react";
import { ProjectProvider } from "../../contexts/ProjectContext";
import { RegulationStore } from "../../contexts/RegulationContext";
import { InspectionStore } from "../../contexts/InspectionContext";
import { NotificationStore } from "../../contexts/NotificationsContext";
import AppContext from "../../contexts/AppContext";

export interface ISidebarLayoutProps {
    checking: boolean;
}

const SidebarLayout: React.FC<ISidebarLayoutProps> = props => {
    const appContext = useContext(AppContext)
    const { panel } = appContext

    return (
        <section className="layout pure-g">
            <ProjectProvider>
                <RegulationStore>
                    <InspectionStore>
                        <NotificationStore>
                            <section className={`content pure-u ${panel === "close" ? "contentShift" : ""}`} style={{ width: "100%" }}>
                                <Fragment>
                                    {!props.checking && props.children}
                                </Fragment>
                            </section>
                        </NotificationStore>
                    </InspectionStore>
                </RegulationStore>
            </ProjectProvider>
        </section>
    );
};

export default SidebarLayout;

export function withoutSidebarLayout(Component: any) {
    return ({ children, ...props }: { children: any; checking: any }) => {
        return (
            <SidebarLayout {...props}>
                <Component>{children}</Component>
            </SidebarLayout>
        );
    };
}
