import { Inspection } from '@sw-sw/lib-inspection-templates';
import xhrService from '../xhrService';
import { Finding } from './finding';
const baseUrl = '/api/projects';

export type ProjectInspection = Omit<Inspection, 'id'> & {
  id: number;
  findings: Array<Finding>;
};

export const customPrintInspectionApi = {
  index: (id: number | null): any => {

    return xhrService
      .get(`${baseUrl}/${id}/custominspections`)
      .then((response) => response.data );
  },
};

export default customPrintInspectionApi;
