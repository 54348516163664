import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import AppContext from "../../../../contexts/AppContext";
import ObservationsList from "./ObservationsList";
import ObservationEditModal from "./ObservationEditModal";
import ObservationDeleteModal from "./ObservationDeleteModal";
import commentableApi from "../../../../utils/api/commentable";
import { FormContext } from "@sw-sw/lib-form";
import { get } from "lodash";
import { InspectionContext } from "../../../../contexts/InspectionContext";

export const getUpdatedNoteMapper = updatedNote => note => {
  if (note.id === updatedNote.id) {
    return Object.assign({}, note, { note: updatedNote.note });
  }

  return note;
};

export const getDeletedNoteFilter = deletedNoteId => note => {
  return note.id !== deletedNoteId;
};

/**
 * A list of commentables with optional edit and delete actions
 */
function ObservationsFormControl({ value, className, ...props }) {
  // only render data is set
  if (!Array.isArray(value)) {
    return null;
  }

  const formContext = useContext(FormContext);
  const appContext = useContext(AppContext);
  const inspectionContext = useContext(InspectionContext);

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);

  const onSuccess = () => {
    appContext.triggerSuccessNotification(true);
  };

  const onEdit = data =>
    commentableApi
      .update("findings", formContext.value.id, data)
      .then(updatedNote => {
        const mapNotes = getUpdatedNoteMapper(updatedNote);

        // update form and inspection contexts

        formContext.set(
          "observations",
          get(formContext.value, "observations", []).map(mapNotes),
        );

        inspectionContext.setFindings(
          inspectionContext.findings.map(finding => {
            if (finding.id === formContext.value.id) {
              return Object.assign({}, finding, {
                commentables: finding.commentables.map(mapNotes),
              });
            }

            return finding;
          }),
        );
      })
      .then(onSuccess);

  const onDelete = () =>
    commentableApi
      .destroy(selected.id, "findings", formContext.value.id)
      .then(() => {
        const filterNotes = getDeletedNoteFilter(selected.id);

        // update form and inspection contexts

        formContext.set(
          "observations",
          get(formContext.value, "observations", []).filter(filterNotes),
        );

        inspectionContext.setFindings(
          inspectionContext.findings.map(finding => {
            console.log(finding);
            if (finding.id === formContext.value.id && finding.commentables) {
              return Object.assign({}, finding, {
                commentables: finding.commentables.filter(filterNotes),
              });
            }

            return finding;
          }),
        );
      })
      .then(onSuccess);

  useEffect(() => {
    if (!selected) {
      if (showEdit) {
        setShowEdit(false);
      }

      if (showDelete) {
        setShowDelete(false);
      }
    }
  }, [selected, showEdit, showDelete]);

  return (
    /** @todo remove extra border style */
    <div className={classNames("form-control--no-theme", className)}>
      <ObservationsList
        data={value}
        onEdit={data => {
          setShowEdit(true);
          setSelected(data);
        }}
        onDelete={data => {
          setShowDelete(true);
          setSelected(data);
        }}
        {...props}
      />
      <ObservationEditModal
        show={showEdit}
        value={selected}
        onCancel={() => setSelected(null)}
        onSubmit={onEdit}
      />
      <ObservationDeleteModal
        show={showDelete}
        value={selected}
        onCancel={() => setSelected(null)}
        onSubmit={onDelete}
      />
    </div>
  );
}

ObservationsFormControl.propTypes = {
  value: PropTypes.any,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
};
ObservationsFormControl.defaultProps = {
  value: [],
  canEdit: false,
  canDelete: false,
};

export default ObservationsFormControl;
