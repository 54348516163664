import React, { useState, useContext, useEffect } from 'react'
import { useMediaQuery } from "react-responsive"
import {Context as DataSourceContext} from '../../DataSourceContext'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { MapEditorRouteContext } from "../../../CustomRoutes/MapEditor";
import TinyIconButton from '../../../Shared/TinyIconButton';
import AppContext from '../../../../contexts/AppContext';
import positionableApi from '../../../../utils/api/positionable';


const LegendCardContent = ({ options, feature, handleBackgroundClick, close, readOnly = false }) => {
    const dataSourceContext = useContext(DataSourceContext)
    const { mapMobileView, setMapMobileView } = useContext(MapEditorRouteContext)
    const isTabletOrLess = useMediaQuery({ maxWidth: 768 })
    const [ showMethods, setShowMethods ] = useState(true)
    const [ isEditModeForAO, setIsEditModeForAO ] = useState(false)
    const [ updatedAddedOnDate, setUpdatedAddedOnDate ] = useState('')
    const appContext = useContext(AppContext);
    const isAdmin = appContext.state.isAdmin
    const otherAllowedRoles = [ "Area / Assistant Manager", "Client Inspector", "Client Manager", "Divisions Admin", "Inspector", "Regional Manager", "Regional Manager (External)", "SWMP Admin" ]
    const isAllowed = otherAllowedRoles.includes(appContext.state.user.roleName)
    const [ authorName, setAuthorName ] = useState('')

    let inputDate
    
    const { positionable_type, positionable_source_id, positionables_id } =  feature || {}

    //get all available Pollutants and Control measures in one object
    const filteredDataSource = dataSourceContext.get()

    //for Legend Name
    const regexForName = new RegExp(/^pollutant$|^control_measure$/)
    const relevantKeysForName = Object.keys(filteredDataSource).filter(ele => regexForName.test(ele))
    const allAvailableLegendsForName = relevantKeysForName.length && filteredDataSource && filteredDataSource[`${relevantKeysForName[0]}`].map((ele) => ele)

    relevantKeysForName.length && relevantKeysForName[1] && allAvailableLegendsForName.push(...filteredDataSource[`${relevantKeysForName[1]}`].map((ele) => ele))

    //for Legend
    let allAvailableLegends = [], legendObject = []

    let addedOnDate, createdDate, displayDate

    const regex = new RegExp(/^pollutant--.*|^control_measure--.*/)
    const relevantKeys = Object.keys(filteredDataSource).filter(ele => regex.test(ele))

    if(relevantKeys.length){
        relevantKeys.forEach((key) => {
            if(Array.isArray(filteredDataSource[`${key}`])){
                allAvailableLegends.push(...filteredDataSource[`${key}`].map((ele) => ele))
            }
        })
    }

    if(allAvailableLegends.length){
        legendObject = allAvailableLegends.filter(ele => ele.id === positionables_id)
        addedOnDate = legendObject[0] && new Date(legendObject[0].createdAt).toLocaleString('en-US').split(',')[0]
        createdDate = (legendObject[0] && legendObject[0].created_date) ? new Date(legendObject[0].created_date).toLocaleString('en-US').split(',')[0] : null

        displayDate = addedOnDate ? (createdDate ? createdDate : addedOnDate) : ``

        if(positionables_id in dataSourceContext.tempAddedOnDates){
            displayDate = dataSourceContext.tempAddedOnDates[positionables_id]
        }
    }

    const handleDelete = () => {
        options[1].onClick()
    }

    const handleEdit = () => {
        options[0].onClick()
    }

    const legendName = () => {
        const legendObjectForName = allAvailableLegendsForName.filter(ele => ele.id === positionable_source_id)

        return legendObjectForName.length ? `${legendObjectForName[0].name}` : null
    }

    const legendNumber = () => {
        return legendObject.length ? `${legendObject[0].number}` : null
    }

    const onAddedOnChangeHandler = (event) => {
        const date = new Date(event.target.value)
        const offset = date.getTimezoneOffset() * 60000

        inputDate = new Date(date.getTime() + offset).toLocaleString('en-US').split(",")[0]
    }

    const editAddedOnHandler = () => {
        if(inputDate && legendObject.length){
            positionableApi.editCreatedDate(legendObject[0].id, inputDate)
            .then((res) => {
                dataSourceContext.setTempAddedOnDates({...dataSourceContext.tempAddedOnDates, [positionables_id]: inputDate})
                setUpdatedAddedOnDate(inputDate)
            })
            .catch((error) => console.log(error))
        }
    }

    const addedOnEditMode = () => {
        return(
            <>
                <input 
                    type="date"
                    min={isAllowed ? new Date(new Date(addedOnDate).getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().split("T")[0] : null}
                    max={new Date(addedOnDate).toISOString().split("T")[0]}
                    onChange={(event) => onAddedOnChangeHandler(event)}
                    placeholder='Select Date'
                    onKeyDown={(event) => event.preventDefault()}
                />
                <TinyIconButton
                    iconName="check"
                    title="Click to save the added on date"
                    onClick={() => {
                        editAddedOnHandler()
                        setIsEditModeForAO(false)
                    }}
                />
            </>
        )
    }

    const addedOnDisplayMode = () => {
        return(
            <>
                {`${updatedAddedOnDate}`}
                {
                    (isAdmin || isAllowed) ?
                    <TinyIconButton
                    iconName="pencil"
                    title="Click to edit the added on date"
                    onClick={() => {
                        setIsEditModeForAO(true)
                    }}
                    /> : 
                    <></>
                }
                
            </>
        )
    }

    if(isTabletOrLess){
        setMapMobileView(true)
    }

    useEffect(() => {
        setUpdatedAddedOnDate(displayDate)
    },[displayDate])

    const fetchAddedByName = () => {
        if(positionables_id){
            positionableApi.getPositionablesAuthor(positionables_id)
            .then((res) => {
                setAuthorName(res.data.author_initials)
            })
            .catch((error) => console.log(error))
        }
    }

    useEffect(() => {
        fetchAddedByName()
    },[positionables_id])

    return(
        <>
            <div className='LegendCardContent'>
                <header>
                    <span>{legendName()}-{legendNumber()}</span>
                    <span onClick={(event) => handleBackgroundClick(event, close)}>CLOSE</span>
                </header>

                <div className='LegendCardContent__added'>
                    { 
                        allAvailableLegends.length ? 
                        <p className='LegendCardContent__added-left'>
                            <span>{`Added On: `}</span>
                            <span>{isEditModeForAO ? addedOnEditMode() : addedOnDisplayMode()}</span>
                        </p>
                        :
                        <></>
                    }

                    <p className='LegendCardContent__added-right'>
                        {`Added By: `}
                        <span>{`${authorName}`}</span>
                    </p>
                </div>

                <div className='LegendCardContent__center'>
                    <p>{`(${positionable_type})`}</p>
                    { !readOnly ? 
                        <p><FontAwesomeIcon className="modal-icon" icon={showMethods ? faAngleUp : faAngleDown} style={{ visibility: (mapMobileView || isTabletOrLess) ? "visible" : "hidden"}}  onClick={() => setShowMethods(!showMethods)}/></p>
                    : <></>}
                </div>

                {
                    !readOnly ? 
                    <footer style={{ display: !showMethods || !mapMobileView ? "none" : "flex" }}>
                        <p onClick={() => handleDelete()}><FontAwesomeIcon icon={faTrashAlt} color='rgba(250,0,0,0.6)'/> Remove</p>
                        <p onClick={() => handleEdit()}><FontAwesomeIcon icon={faEdit}/> Edit</p>
                    </footer> :
                    <></>
                }
                
            </div>
        </>
    )
}



export default LegendCardContent