import React, { useContext } from "react";
import { MapFeatures, MapContextType } from "@sw-sw/common";
import { Context as DataSourceContext } from "./DataSourceContext";
import { getUtil } from "../../utils/positionable";

/**
 * Render map features (positionable instances) from {DataSourceContext}
 */
export function DataSourceFeatures() {
  const dataSource = useContext(DataSourceContext);
  const mapContext = useContext(MapContextType);

  if (!mapContext || !dataSource || !mapContext.map) {
    return null;
  }

  const dataGroups = dataSource.getDataInstanceGroups();

  
return (
    <React.Fragment>
      {Object.keys(dataGroups).map(groupName => {
        return (<MapFeatures
          key={groupName}
          positionableInstances={dataGroups[groupName]}
          getUtil={(...a) => {
            return getUtil(...a);
          }}
          getSourceModel={(id, positionableType) => {
            return dataSource.getById(id, positionableType);
          }}
          getLayer={mapContext.getLayer}
          showFeatureLabels={false}
        />)
        })}
    </React.Fragment>
  );
}

export default DataSourceFeatures;
