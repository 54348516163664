import React, { Component } from "react";
import PropTypes from "prop-types";

import FeatureableEditorContext from "../Context";
import AreaEditorPreview from "./Preview";
import AreaEditorOptions, { optionDefaults } from "./Options";
import { withoutKeys } from "../../../../utils";

class FeatureableAreaEditor extends Component {
  static propTypes = {
    areaType: PropTypes.string.isRequired,
    initialData: PropTypes.object,
  };

  render() {
    const { areaType } = this.props;
    const formInitialData = {
      ...optionDefaults,
      ...withoutKeys(this.props.initialData || {}, ["strokeDashArray"]),
    };

    return (
      <FeatureableEditorContext
        editorName={areaType}
        formInitialData={formInitialData}
      >
        <AreaEditorPreview areaType={areaType} />
        <AreaEditorOptions />
      </FeatureableEditorContext>
    );
  }
}

export default FeatureableAreaEditor;
