import { QuestionConfigApi } from "@sw-sw/lib-inspection-templates";
import xhrService from "../api";

export const questionConfigApi: QuestionConfigApi = {
  index: () => {
    return xhrService
      .get(`/api/questions-config`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },

  //needs checked
  update: data => {
    //check data.length
    if (data.length > 0) {
      return xhrService
        .put(`/api/questions/${data[0].questionId}/config`, data)
        .then(res => res.data)
        .catch(({ response }) => {
          throw new Error(response.data.message);
        });
    } else {
      throw new Error("No data provided");
    }
  },
};
