import { minBy, get, isEmpty } from "lodash";
import { findingUtil } from "@sw-sw/common";

/**
 *
 * @param {{ number: number, location: string, commentables: { id: number, created_at: string, author: { name: string }, note: { content: string } }[] }} finding
 */
export const getFindingLabel = finding => {
  const d =
    Array.isArray(finding.commentables) && !isEmpty(finding.commentables)
      ? get(minBy(finding.commentables, "created_at"), "note.content")
      : finding.location;

  return `#${finding.number} - ${d}`;
};

export default findingUtil;
