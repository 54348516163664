import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactDOM from "react-dom";
import CheckmarkSVG from "../CheckmarkSVG";

const SuccessNotification = props => {
  return ReactDOM.createPortal(
    <div
      className={classnames("successNotification", {
        show: props.show,
      })}
    >
      {props.show ? <CheckmarkSVG /> : null}
    </div>,
    document.querySelector("#success"),
  );
};

SuccessNotification.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default SuccessNotification;
