import React from 'react';
import PropTypes from 'prop-types'

import { toNumber } from 'lodash';

import Range from '../../Fields/Range';

const extractValue = (v, index = 0) => {
  if (v && Array.isArray(v) && v.length && v[index]) {
    return toNumber(v[index]);
  }

  return 0;
}

/**
 * Field to set SVG stroke dash array
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray
 */
class ArrayItemField extends React.Component {
  render() {
    const { label, valueIndex, value, initialValue, ...rest } = this.props;

    return <Range
      label={label}
      value={extractValue(value, valueIndex)}
      initialValue={extractValue(initialValue, valueIndex)}
      step="2"
      cast={x => [
        ...value.slice(0, valueIndex),
        toNumber(x),
        ...value.slice(valueIndex + 1),
      ]}
      {...rest}
    />
  }
}

class StrokeDashArray extends React.Component {
  static propTypes = {
    strokeStyle: PropTypes.string.isRequired,
    strokeWidth: PropTypes.number.isRequired,
  };

  componentDidMount() {
    this.init(this.props.strokeStyle);
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.strokeStyle !== this.props.strokeStyle) {
      this.init(this.props.strokeStyle);
    }
  }

  init(strokeStyle) {
    switch (strokeStyle) {
      case 'dot':
        this.handleChangeToDot();
        break;
      // case 'tick':
      //   // this.handleChangeToTick();
      //   break;
      // case 'dash':
      //   // this.handleChangeToDash();
      //   break;
      default:
        break;
    }
  }

  handleChangeToDot = () => {
    const { onChange, value, strokeWidth } = this.props;

    return onChange([ 1, Math.max(
        strokeWidth + 2,
        extractValue(value, 1),
    )]);
  }

  handleChangeToTick = () => {
    const { onChange } = this.props;

    return onChange([4, 8]);
  }

  handleChangeToDash = () => {
    const { onChange } = this.props;

    return onChange([10, 10]);
  }

  render() {
    const { strokeStyle, strokeWidth,...props } = this.props;

    if (strokeStyle === 'solid') { return null; }

    const gapMin = strokeStyle === 'dot' ? strokeWidth + 2 : 2;
    const gapMax = strokeStyle === 'tick' ? strokeWidth * 9 : 25;
    const dashSizeLabel = strokeStyle === 'tick'
      ? 'Tick Width'
      : 'Dash Size';

    /**
     * @todo for tick stroke style...
     * dash size controls tick width
     * custom control for tick height
     * */
    return <React.Fragment>
      <ArrayItemField
        label="Gap Size"
        valueIndex={1}
        max={gapMax}
        min={gapMin}
        {...props}
      />
      { strokeStyle !== "dot" &&
        <ArrayItemField label={dashSizeLabel} valueIndex={0} max={25} min={2} {...props} />
      }
    </React.Fragment>
  }
}

export default StrokeDashArray;
