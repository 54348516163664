import xhrService from "../xhrService";


const Url = (templateId: number) => `/api/inspection-templat/${templateId}/upload-logo`;


type inspectionTemplateLogoApi = {
    get: (templateId: number) => Promise<any>;
    post: (templateId: number, formData: any) => Promise<any>;
    delete: (templateId: number) => Promise<any>;
}


export const inspectionTemplateLogoApi: inspectionTemplateLogoApi = {
    get: (templateId: number) => {
        return xhrService.get(Url(templateId))
        .then((res) => res.data.logo_GUID)
    },
    post: (templateId: number, formData: any) => {
        return xhrService.post(Url(templateId), formData)
        .then((res) => res.data.logo_GUID)
    },
    delete: (templateId: number) => {
        return xhrService.delete(Url(templateId))
        .then((res) => res)
    }
};