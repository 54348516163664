import { fabric } from "fabric";
import DraggableShapeTool from "./DraggableShape";

/**
 * rectangle/square tool
 */
class QuadTool extends DraggableShapeTool {
  makeShape(options) {
    return new fabric.Rect(options);
  }

  getDefaultShapeConfig() {
    return {
      selectable: false,
      width: 0.1,
      height: 0.1,
      stroke: "#333333",
      strokeWidth: 2,
      fill: "#cccccc",
      opacity: 0.8,
      strokeDashArray: [0, 0],
      strokeUniform: true,
    };
  }

  calculateDimensions(x, y, left, top) {
    const width = x - left;

    let height = y - top;

    if (this.regularQuad === true) {
      height = width;
    }

    return { width, height };
  }
}

export default QuadTool;
