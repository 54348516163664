import React from "react";
import c from "classnames";

const CheckmarkSVG = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      className={c("checkmark", className)}
    >
      <circle className="outline" cx="26" cy="26" r="25" fill="none" />
      <path
        className="checkmark"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  );
};

export default CheckmarkSVG;
