import {
  FormContext,
  FormContextProvider,
  FormSchemaFields,
} from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import React, { useContext } from "react";
import { ICommentable } from "../../../../utils/notes";
import { SubmitHandlerFn } from "../../../Shared/form";
import FormModal from "../../../Shared/form/modal/FormModal";

function ObservationEditModal({
  show,
  value,
  onCancel,
  onSubmit,
  noteType = "observation",
}: {
  show: Boolean;
  value: ICommentable | null;
  onCancel: () => void;
  onSubmit: SubmitHandlerFn;
  noteType?: string;
}) {
  return (
    show &&
    value && (
      <FormContextProvider
        initialValue={{ id: value.id, content: value.note.content }}
      >
        <EditModalUI
          onSubmit={onSubmit}
          onCancel={onCancel}
          noteType={noteType}
        />
      </FormContextProvider>
    )
  );
}

function EditModalUI({
  onSubmit,
  onCancel,
  noteType,
}: {
  onSubmit: SubmitHandlerFn;
  onCancel: () => void;
  noteType: string;
}) {
  const formContext = useContext(FormContext);

  const schema = {
    content: {
      controlType: UIControlType.textarea,
      validation: {
        required: true,
      },
    },
  };

  return (
    <FormModal
      modalProps={{
        title: `Edit ${noteType[0].toUpperCase() + noteType.substring(1)}`,
      }}
      submitText="Submit"
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <FormSchemaFields
        schema={schema}
        onChange={formContext.set}
        formData={formContext.value}
      />
    </FormModal>
  );
}

export default ObservationEditModal;
