import { useQuery } from "react-query";
import clientApi from "../utils/api/client";

export function useStates() {
  return useQuery("clientsGeoData", () => clientApi.geoData.index(), {
    refetchOnWindowFocus: false,
  });
}

export function useCountries() {
  return useQuery("clientsCountryList", () => clientApi.getCountryList.index(), {
    refetchOnWindowFocus: false,
  });
}