import {
  QuestionGroup,
  NewQuestionGroup,
} from "@sw-sw/lib-inspection-templates";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { questionGroupApi } from "../utils/api/questionGroup";

// question groups
export function useQuestionGroups(inspectionTemplateId: number) {
  return useQuery("questionGroups", () =>
    questionGroupApi.index(inspectionTemplateId),
  );
}

// question group
export function useQuestionGroup(
  inspectionTemplateId: number,
  questionGroupId: number,
) {
  return useQuery(["questionGroups", questionGroupId], () =>
    questionGroupApi.show(inspectionTemplateId, questionGroupId),
  );
}

// create question group
export function useCreateQuestionGroup(inspectionTemplateId: number) {
  return useMutation((newQuestionGroup: NewQuestionGroup) => {
    return questionGroupApi.create(inspectionTemplateId, newQuestionGroup);
  });
}

// update question group name
export function useUpdateQuestionGroup(inspectionTemplateId: number) {
  const queryClient = useQueryClient();

  return useMutation(
    (updatedQuestionGroup: QuestionGroup) => {
      if (updatedQuestionGroup.id) {
        return questionGroupApi.update(
          inspectionTemplateId,
          updatedQuestionGroup.id,
          updatedQuestionGroup,
        );
      } else {
        throw new Error("Question Group object must include ID");
      }
    },
    {
      onSuccess: (...args) => {
        queryClient.setQueryData(["questionGroups", args[1].id], args[1]);
      },
    },
  );
}
