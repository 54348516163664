import React from "react";
import { FormSchemaFields } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { Contact } from "../../utils/api/client";

export interface IContactFormProps {
  onChange: (key: string, value: any) => void;
  value: Record<string, any>;
  users: Array<Contact>;
}

export const getInitialData = () => ({
  users: [],
});

const getSchema = (users: Array<Contact>) => {
  const schema = {
    users: {
      label: "Email",
      controlType: UIControlType.customTagSelect,
      validation: {
        required: true,
      },
      isMulti: true,
      noOptionMessage: "No users found",
      placeholder: "Enter at least 3 characters to begin search",
      options: users.map(user => {
        // format label
        let label = `${user.first_name || ""} ${user.last_name || ""}`.trim();

        if (user.email) {
          label += label.length ? ` - ${user.email}` : user.email;
        }

        return { id: user.id, label, name: user.name };
      }),
      valueKey: "id",
      badgeLabelKey: "name",
    },
  };

  return schema;
};

const ContactForm: React.FC<IContactFormProps> = ({
  onChange,
  value,
  users,
}) => {
  return (
    <div className="container">
      <FormSchemaFields
        className="findings-form add-contact-form"
        schema={getSchema(users)}
        formData={value}
        onChange={onChange}
      />
    </div>
  );
};

export default ContactForm;
