import React, { PropsWithChildren } from "react";
import Logo from "../../images/SW-Logo-White.svg";

/** Similar to SidebarLayout, but does not require used to be logged in */
const EmptySidebarLayout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className="layout layout--no-sidebar pure-g">
      <div className="sidebar pure-u-1 pure-u-md-1-4">
        <div className="header pure-u-1-2 pure-u-md-1-1">
          <span className="pointer">
            <img src={Logo} className="logo" alt="logo" />
          </span>
        </div>

        <nav className="pure-u-1-2 pure-u-md-1-1 " />
      </div>

      <div className="content pure-u-1 pure-u-md-3-4">
        <div>{children}</div>
      </div>
    </div>
  );
};

export default EmptySidebarLayout;
