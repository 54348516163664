/**
 *
 * @param {object} dependencies
 */
export const ImageDimensionValidation = (deps = {}) => ({
  async validate(file, { width, height }) {
    if (!file.type.includes("image")) {
      return true;
    }

    const image = await this.readImage(file);

    if (image.naturalWidth < width || image.naturalHeight < height) {
      throw new Error(
        `${file.name} dimensions do not meet the minimum size of ${width}x${height}`,
      );
    }

    return true;
  },

  readImage(file) {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();

      fr.onload = function () {
        const image = new Image();

        image.onload = function () {
          resolve(image);
        };

        image.onerror = function (error) {
          reject(error);
        };

        image.src = fr.result;
      };

      fr.onerror = function (error) {
        reject(error);
      };

      fr.readAsDataURL(file);
    });
  },
});

/**
 *
 * @param {object} dependencies
 */
export const FileSizeValidation = (deps = {}) => ({
  async validate(file, maxFileSize) {
    if (file.size >= maxFileSize) {
      throw new Error(
        `${file.name} is too large. \nMax file size is ${
          maxFileSize / 1000000
        }MB`,
      );
    }

    return true;
  },
});

/**
 *
 * @param {object} dependencies
 */
export const FileTypeValidation = (deps = {}) => ({
  async validate(file, allowedTypes = []) {
    if (allowedTypes.length && !allowedTypes.includes(file.type)) {
      const splitAllowedTypes = allowedTypes.map(type => type.split("/")[1]);

      throw new Error(
        `${file.name} is not an allowed file type. Please add a ${
          splitAllowedTypes.length === 1
            ? splitAllowedTypes.pop()
            : splitAllowedTypes.join(", ").replace(/, ([^,]*)$/, " or $1")
        } file`,
      );
    }

    return true;
  },
});

/**
 *
 * @param {{
 * fileTypeValidation: FileTypeValidation,
 * fileSizeValidation: FileSizeValidation,
 * imageDimensionValidation: ImageDimensionValidation
 * }} dependencies
 */
export const UploadFileValidation = ({
  fileTypeValidation,
  fileSizeValidation,
  imageDimensionValidation,
}) => ({
  async validate(file, { maxFileSize, allowedTypes, imageDimensions }) {
    const errors = [];

    await Promise.all([
      fileSizeValidation.validate(file, maxFileSize).catch(e => errors.push(e)),
      fileTypeValidation
        .validate(file, allowedTypes)
        .catch(e => errors.push(e)),
      imageDimensionValidation
        .validate(file, imageDimensions)
        .catch(e => errors.push(e)),
    ]);

    return errors;
  },
});

/**
 *
 * @param {object} dependencies
 */
export const UploadFilesValidation = ({ uploadFileValidation }) => ({
  async validate(files, { maxFileSize, allowedTypes, imageDimensions }) {
    const errors = await Promise.all(
      files.map(file =>
        uploadFileValidation.validate(file, {
          maxFileSize,
          allowedTypes,
          imageDimensions,
        }),
      ),
    );

    return errors.flat();
  },
});
