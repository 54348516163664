import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// components and helpers
import RolesContext from '../../contexts/RolesContext';
import AppContext from '../../contexts/AppContext';
import ResourceIndex from '../Shared/ResourceIndex/ResourceIndex';
import { getIcon } from '../../utils/positionable';
import LIDetailsForm from './Form/LIDetailsForm';
import { extractInitialFormData, defaultData } from './Form/FormSchema';
import { get } from 'lodash';
import DivisionError from '../Shared/DivisionError/DivisionError';
import { AppDivisionContext } from '../../contexts/AppDivisionContext';
import LICopyForm from './Form/LICopyForm';

function LegendItems() {
  const roleStore = useContext(RolesContext);
  const appDivisionContext = useContext(AppDivisionContext);

  const appStore = useContext(AppContext);

  const user = appStore.get('user');

  if (!appDivisionContext.appDivisionId) {
    return <DivisionError resource='legend items' />;
  }

  return (
    <ResourceIndex
      resourceName='legendItems'
      resourceTableHeaders={[
        ['Name', 'name'],
        ['Map Symbol', 'map_symbol'],
      ]}
      resourceSearchProps={['name', 'type']}
      resourceQueryProps={{ divisionId: appDivisionContext.appDivisionId }}
      enableRead={roleStore.userHasPermission('read', 'Legend Item')}
      enableEdit={roleStore.userHasPermission('update', 'Legend Item')}
      enableSelectAll={user.roleName === 'Super Admin'||user.roleName === 'Admin'}
      enableCallToAction={roleStore.userHasPermission('create', 'Legend Item')}
      searchInputPlaceholder='Search Map Legend'
      callToActionContent='Legend'
      renderRowCell={(legendItem, propName) => {
        if (propName === 'map_symbol') {
          const { src } = getIcon(legendItem.type, legendItem);

          return (
            <img
              src={src}
              className='list-icon'
              alt={`${legendItem.name} map legend icon`}
            />
          );
        }

        return get(legendItem, propName);
      }}
      renderEditor={(
        item,
        handleClose,
        allItems,
        updateById,
        refetch,
        setSelectedItems,
      ) => {
        return (
          <LIDetailsForm
            initialValue={extractInitialFormData(item)}
            onCancel={handleClose}
            onSubmit={(data) => {
              setSelectedItems([]);

              return updateById(item.id, data).then(handleClose).then(refetch);
            }}
            onDelete={() => {
              updateById(item.id, false);
              handleClose();
              setSelectedItems([]);
            }}
            enableDelete={roleStore.userHasPermission('delete', 'Legend Item')}
          />
        );
      }}
      renderReader={(item, handleClose) => {
        return (
          <LIDetailsForm
            initialValue={extractInitialFormData(item)}
            onCancel={handleClose}
            onSubmit={() => {
              handleClose();
            }}
            readOnly
            title='Legend Item'
            submitBtnText={'Close'}
            showSubmit={false}
            cancelText='Close'
            enableDelete={false}
          />
        );
      }}
      renderCallToAction={(
        handleClose,
        pushData,
        refetch,
        setSelectedItems,
        selectedItems,
      ) => {
        if (selectedItems.length > 0) {
          return (
            <LICopyForm
              onSubmit={(data) => {
                pushData(data);
                setSelectedItems([]);
                handleClose();
              }}
              onCancel={handleClose}
              selectedItems={selectedItems}
            />
          );
        } else {
          return (
            <LIDetailsForm
              onCancel={handleClose}
              onSubmit={(data) => {
                pushData(data);
                setSelectedItems([]);
                handleClose();
              }}
              initialValue={defaultData()}
            />
          );
        }
      }}
    />
  );
}

LegendItems.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  permCheck: PropTypes.func.isRequired,
  enableDelete: PropTypes.bool,
};

LegendItems.propTypes = {};

export default LegendItems;
