import React, { Component } from "react";
import PropTypes from "prop-types";

import { Context } from "./Context";

export const OptionsWrapper = props => (
  <div className="featureable-editor-opts">{props.children}</div>
);

/**
 * Control a list of editor options (ie. editor options)
 */
class FeatureableEditorOptions extends Component {
  static contextType = Context;

  static propTypes = {
    // [option name] -> Component
    options: PropTypes.object.isRequired,
    // props to pass to the form component
    extraComponentProps: PropTypes.object,
  };

  handleFormUpdate = (k, v) => {
    this.context.updateForm(k, v);
  };

  render() {
    const { options, extraComponentProps } = this.props;
    const { form } = this.context;

    return (
      <OptionsWrapper>
        <div>
          {Object.keys(options).map(optName => {
            const Component = options[optName];

            if (!Component) {
              return null;
            }

            const props = {
              ...(extraComponentProps || {}),
            };

            if (form.hasOwnProperty(optName)) {
              props.initialValue = form[optName];
            }

            return (
              <Component
                key={optName}
                value={form[optName]}
                onChange={x => this.handleFormUpdate(optName, x)}
                form={form}
                {...props}
              />
            );
          })}
          {/* <pre>{JSON.stringify(this.context.form)}</pre> */}
        </div>
      </OptionsWrapper>
    );
  }
}

export default FeatureableEditorOptions;
