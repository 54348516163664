import React, { useState, useContext, Dispatch } from "react";
import ProjectContext from "../../../contexts/ProjectContext";
import AddContactModal from "../../Contacts/AddContactModal";
import projectApi from "../../../utils/api/project";

export interface IContactAddProps {
  currentContactIds: Array<number>;
  onAdd: Dispatch<any>;
}

const ContactAdd: React.FC<IContactAddProps> = ({
  currentContactIds,
  onAdd,
}) => {
  const context = useContext(ProjectContext);
  const [show, setShow] = useState<boolean>(false);
  const hideModal = () => setShow(false);
  const showModal = () => setShow(true);
  const id = context.project ? context.project.id : null;

  return id ? (
    <React.Fragment>
      <button className="outline small" onClick={showModal}>
        <React.Fragment>
          <i className="fa fa-plus pad-right" /> Add Contact
        </React.Fragment>
      </button>

      <AddContactModal
        show={show}
        hideModal={hideModal}
        currentContactIds={currentContactIds}
        handleAdd={userIds =>
          projectApi.contacts.create(id, userIds).then(onAdd)
        }
      />
    </React.Fragment>
  ) : null;
};

export default ContactAdd;
