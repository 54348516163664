import React, { useContext } from "react";
import { bool, func, number, object } from "prop-types";
import { get } from "lodash";

import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import ProjectContext from "../../../contexts/ProjectContext";

import FindingsForm, { getInitialData } from "./FindingsForm";
import findingApi from "../../../utils/api/finding";
import FormModal from "../../Shared/form/modal/FormModal";
import { toast } from "react-toastify";
import ToastError from "../../Shared/ToastError/ToastError";
import { InspectionContext } from "../../../contexts/InspectionContext";

function AddFindingFormModalUI({ hideModal, onAddFinding, inspectionId }) {
  const formContext = useContext(FormContext);
  const projectContext = useContext(ProjectContext);
  const inspectionContext = useContext(InspectionContext);

  function handleSubmit() {
    const toastId = toast("Creating Finding");

    return findingApi
      .create(inspectionId, formContext.value)
      .then(finding => {
        onAddFinding(finding);
        hideModal();
        toast.update(toastId, {
          render: "Finding Created",
          type: "success",
        });
      })
      .catch(async err => {
        toast.update(toastId, {
          render: (
            <ToastError
              message="Cannot create finding because of error: "
              error={inspectionContext.setSaveError(err)}
            />
          ),
          autoClose: false,
          type: "error",
        });
        hideModal();

        inspectionContext.reloadInspection();
      });
  }

  return (
    <FormModal
      modalProps={{
        title: "Add a Finding",
        submitBtnText: "Save",
        classes: "add-finding-form",
        style: {},
      }}
      onCancel={hideModal}
      onSubmit={handleSubmit}
    >
      <FindingsForm
        onChange={formContext.set}
        value={formContext.value}
        templateName={get(projectContext, "template.name")}
        defaultObservation={get(
          projectContext,
          "project.client.default_finding_observation",
          "",
        )}
        inspectionId={inspectionId}
        templateID={get(projectContext, "template.id")}
        modal={true}
      />
    </FormModal>
  );
}

function AddFindingModal(props) {
  if (!props.show) {
    return null;
  }

  return (
    <FormContextProvider initialValue={getInitialData(props.defaultDate)}>
      <AddFindingFormModalUI
        show={props.show}
        hideModal={props.hideModal}
        onAddFinding={props.onAddFinding}
        inspectionId={props.inspectionId}
      />
    </FormContextProvider>
  );
}

AddFindingModal.propTypes = {
  show: bool.isRequired,
  hideModal: func.isRequired,
  onAddFinding: func.isRequired,
  inspectionId: number.isRequired,
  defaultDate: object.isRequired,
};

export default AddFindingModal;
