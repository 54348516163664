import React from "react";

import FormModal from "../../Shared/form/modal/FormModal";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import { FormSchemaFields } from "@sw-sw/lib-form";

export interface IGroupDetailsFormProps {
  handleClose: () => void;
  handleSubmit: (data: any) => Promise<void>;
  title?: string;
  group?: {
    name: string;
  };
}

const schema = {
  name: {
    label: "Name",
    validation: {
      required: true,
    },
  },
};

const GroupDetailsForm: React.FC<IGroupDetailsFormProps> = ({
  handleClose,
  handleSubmit,
  title,
  group,
}) => {
  return (
    <FormContextProvider>
      <FormContext.Consumer>
        {formContext => {
          return (
            <FormModal
              onCancel={handleClose}
              onSubmit={handleSubmit}
              modalProps={{
                title: title,
              }}
            >
              <FormSchemaFields
                schema={schema}
                onChange={formContext.set}
                formData={formContext.value}
                initialFormData={group ? { name: group.name } : {}}
              />
            </FormModal>
          );
        }}
      </FormContext.Consumer>
    </FormContextProvider>
  );
};

export default GroupDetailsForm;
