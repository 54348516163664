import React, { MouseEventHandler, useContext } from 'react';
import Popper from '../../Shared/Popper/Popper';
import AppContext from '../../../contexts/AppContext';
import userApi from '../../../utils/api/user';

export interface ITenantPopperProps {
  handleClose: MouseEventHandler;
  coordinates: {
    right: number;
    top: number;
  };
  onChange: (division?: number) => void;
}

const TenantPopper: React.FC<ITenantPopperProps> = ({
  handleClose,
  coordinates,
  onChange = null,
}) => {
  const appContext = useContext(AppContext);
  const user = appContext.get('user');

  const handleClick =
    (tenantId: number, tenantName: string) =>
    async (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const data = await userApi.changeTenant(tenantId);

      const startUrl = process.env.REACT_APP_CLIENT_BASE_URL;

      if (startUrl) {
        const url = startUrl.split('//');

        if (url) {
          window.location.href =
            url[0] +
            `//${tenantName}.${url[1]}/identity-auth?token=${data.token}`;
        }
      }
      if (onChange) {
        onChange();
      }
      handleClose(e);
    };

  return (
    <Popper coordinates={coordinates} handleClose={handleClose}>
      <ul>
        {user &&
          user.tenants.length &&
          user.tenants.map(
            (tenant: { id: number; name: string; domain: string }) => {
              return (
                <li
                  className='pointer'
                  onClick={handleClick(tenant.id, tenant.domain)}
                  key={tenant.id}
                  value={tenant.id}
                >
                  {tenant.name}
                </li>
              );
            },
          )}
      </ul>
    </Popper>
  );
};

export default TenantPopper;
