import xhrService from "../api";

export const inspectionTemplateApi = {
  index: () => {
    return xhrService.get("/api/inspections/templates").then(res => res.data);
  },

  indexAll: (searchQuery, divId) => {
    const params = {};
        
        if (searchQuery) {
          params.s = searchQuery;
        }

        if(divId){
          params.divId = divId
        }
        
    return xhrService.get("/api/inspection-templates", {params}).then(res => res.data);
  },

  get: id => {
    return xhrService
      .get(`/api/inspection-templates/${id}`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },

  copy: (tenantId, divisionIds, inspTempIds) => {
    return xhrService
      .post('/api/inspection-templates/copy', {
        tenantId,
        divisionIds,
        inspTempIds,
      })
      .then((_) => _.data);
  },

  create: data =>
    xhrService.post(`/api/inspection-templates`, data).then(res => res.data),

  update: (id, data) =>
    xhrService
      .put(`/api/inspection-templates/${id}`, data)
      .then(res => res.data),

  updateAck: (id, data) =>
    xhrService
      .put(`/api/inspection-template/${id}`, data)
      .then(res => res.data),

  destroy: id => {
    return xhrService
      .delete(`/api/inspection-templates/${id}`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
};

export default inspectionTemplateApi;
