import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** Featureable editor HTML <canvas> node, (editor preview) */
class FeatureableEditorCanvas extends Component {
  static propTypes = {
    // make sure to use this hook to be sure <canvas> is ready
    onMounted: PropTypes.func,
    canvasRef: PropTypes.any.isRequired,
  };

  componentDidMount() {
    if (this.props.onMounted) {
      this.props.onMounted();
    }
  }
  
  render() {
    const { canvasRef, ...props } = this.props;

    return (
      <div className="featureable-editor-preview">
        <div className="featureable-editor-canvas">
          <canvas ref={canvasRef} style={{border: '1px solid #c1c1c1'}}></canvas>
        </div>
        {props.children || null}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <FeatureableEditorCanvas canvasRef={ref} {...props} />);
