import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewQuestionGroupForm from "./Forms/NewQuestionGroupForm";
import TemplateEditorContext from "../../../contexts/TemplateEditorContext";
import { useContext } from "react";

const AddQuestionGroup: React.FC<{}> = () => {
  const templateEditorContext = useContext(TemplateEditorContext);
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleClick = () => {
    setShowForm(true);
  };

  return (
    <React.Fragment>
      <div
        className="add-question-group"
        onClick={handleClick}
        style={{ order: templateEditorContext.getNextQuestionGroupOrder() }}
      >
        <FontAwesomeIcon icon="plus" />
      </div>
      {showForm && (
        <NewQuestionGroupForm
          handleClose={() => setShowForm(false)}
          order={templateEditorContext.getNextQuestionGroupOrder()}
        />
      )}
    </React.Fragment>
  );
};

export default AddQuestionGroup;
