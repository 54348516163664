import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import AuthenticationContext from "../contexts/AuthenticationContext";
import divisionApi from "../utils/api/division";

/**
 * Provide a list of divisions, when logged-in.
 *
 * @important use {AppDivisionContext} to avoid duplicate requests
 */
export function useDivisionQuery() {
  const authStore: any = useContext(AuthenticationContext);
  const [enabled, setEnabled] = useState(authStore.loggedIn);

  const divisionQuery = useQuery("divisions", () => divisionApi.index(), {
    enabled: false,
  });

  useEffect(() => {
    if (enabled) {
      divisionQuery.refetch();
    } else {
      divisionQuery.remove();
    }
  }, [enabled]);

  useEffect(() => {
    if (enabled !== authStore.loggedIn) {
      setEnabled(authStore.loggedIn);
    }
  }, [authStore.loggedIn]);

  return divisionQuery;
}
