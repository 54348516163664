import xhrService from "../xhrService";

export const projectTypesApi = {

    index: () => {
        return xhrService.get(`/api/project-types`).then(response => response.data).catch(e => { console.log(e.message); return false; });
    },

    updateToggle: (projectTypeId: number, is_included: boolean, name: string) => {
        return xhrService.put(`/api/project-types/${projectTypeId}`, { is_included, name }).then(response => response.data).catch(e => { console.log(e.message); return false; });
    },

    create: (body: any) => {
        return xhrService.post(`/api/project-types`, body).then(response => response.data).catch(e => { console.log(e.message); return false; });
    },

    delete: (projectTypeId: number) => {
        return xhrService.delete(`/api/project-types/${projectTypeId}`).then(response => response.data).catch(e => { console.log(e.message); return false; });
    }
}

export default projectTypesApi;
