import { LoadingMessage } from "@sw-sw/lib-ui";
import PropTypes from "prop-types";
import React from "react";
import { FormHelpText } from "@sw-sw/lib-form";
export function Loading({ what, showLoadingText }) {
  return (
    <LoadingMessage
      className="resource-index-loading"
      what={what}
      showLoadingText={showLoadingText}
      renderLoadingText={FormHelpText}
    />
  );
}

Loading.propTypes = {
  showLoadingText: PropTypes.bool.isRequired,
  what: PropTypes.string,
};

Loading.defaultProps = {
  showLoadingText: true,
};

export default Loading;
