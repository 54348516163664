import React from "react";
import PropTypes from "prop-types";
import plural from "pluralize";
/**
 * renders "{selected} of {total}" message
 */
function SelectionStatus({ noun: nounProp, current, total, onReset }) {
  if (current < 1 || total === 0) {
    return null;
  }

  return (
    <div className="selection-status">
      <small className="selection-status-msg">
        selected
        {current === total ? ` ${current} ` : ` ${current} of ${total} `}
        {total > 1 ? plural(nounProp) : nounProp}
      </small>
      {onReset && (
        <small className="selection-undo" onClick={onReset}>
          (clear)
        </small>
      )}
    </div>
  );
}

SelectionStatus.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  // singular name of thing being selected
  noun: PropTypes.string.isRequired,
  onReset: PropTypes.func,
};

SelectionStatus.defaultProps = {
  noun: "thing",
  current: 0,
  total: 0,
};

export default SelectionStatus;
