import React from 'react'

interface IResourceDetailTitleProps {
  title: string;
}

const ResourceDetailTitle:React.FC<IResourceDetailTitleProps> = ({ title }) => {
  return (
    <h2 className="text-truncate">{title}</h2>
  )
}

export default ResourceDetailTitle;