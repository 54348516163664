import React, { useContext } from "react";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import FormModal from "../../Shared/form/modal/FormModal";
import { FormSchemaFields } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";

const AddNotesForm = props => {
  const getSchema = () => {
    return {
      comments: {
        label: "Observations",
        controlType: UIControlType.textarea,
        rows: 4,
      },
    };
  };

  return (
    <FormSchemaFields
      schema={getSchema()}
      formData={props.value}
      onChange={props.onChange}
    />
  );
};

const AddNotesModalUI = props => {
  const formContext = useContext(FormContext);
  const { comments } = formContext.value;

  const handleSubmit = () =>
    props
      .addNotes(props.inspectionId, props.selectedFindings, comments)
      .then(() => {
        props.hideModal();
      });

  return (
    <FormModal
      modalProps={{
        title: "Add Note",
        submitBtnText: "Complete",
      }}
      onCancel={props.hideModal}
      onSubmit={handleSubmit}
    >
      <AddNotesForm
        {...props}
        onChange={formContext.set}
        value={formContext.value}
      />
    </FormModal>
  );
};

const AddNotesModal = props => {
  if (!props.show) {
    return null;
  }

  return (
    <FormContextProvider>
      <AddNotesModalUI {...props} />
    </FormContextProvider>
  );
};

export default AddNotesModal;
