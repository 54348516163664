import { kebabCase } from "lodash";
import React, { FC, useContext } from "react";
import NavTabs, { Tab as NavTab } from "../NavTabs";
import TenantFeatureContext from "../../../contexts/TenantContext";

interface IResourceDetailTabsProps {
  Component?: FC;
  headers: string[];
  onSelect?: (index: number) => void;
  panels?: FC[];
  urlPrefix?: string;
  showTab?: boolean
}

const ResourceDetailTabs: React.FC<IResourceDetailTabsProps> = ({
  Component: TabComponent,
  headers,
  onSelect,
  panels,
  urlPrefix = "/",
  showTab
}) => {
  const featureContext = useContext(TenantFeatureContext)

  if (!TabComponent && !panels) {
    throw new Error('Either "Component" or "panels" is required.');
  }

  const tabs = headers.map<NavTab>((label, index) => {
    var Tab = TabComponent;

    if (!Tab && panels) {
      Tab = panels[index];
    }

    if (!Tab) {
      Tab = () => <p>no tab</p>;
    }

    return {
      Component: Tab,
      label,
      showTab: () => (label === "Legend Details" ? featureContext.tenantHasFeature("Site Map - Editor Updates") : showTab),
      to: `${urlPrefix}${kebabCase(label)}`,
    };
  });
  
  return <NavTabs tabs={tabs} onSelectTab={onSelect} />;
};

export default ResourceDetailTabs;
