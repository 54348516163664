import { fabric } from "fabric";
import IconEditorTool from "./IconEditorTool";

const config = {
  initialState() {
    return {
      shape: null,
    };
  },
  defaultLine: {
    stroke: "#333333",
    strokeWidth: 5,
    opacity: 0.8,
    originX: "center",
    originY: "center",
  },
};

/**
 * Click and drag to draw a single line
 */
class LineTool extends IconEditorTool {
  label = "Line";

  state = config.initialState();

  helpText = "Click and drag to create a line.";

  /**
   * @param {fabric.IEvent} event
   */
  handleMouseDown(event) {
    const { x, y } = this.canvas.getPointer(event.e);

    this.state.shape = new fabric.Line([x, y, x, y], {
      ...config.defaultLine,
    });

    this.canvas.add(this.state.shape);
  }

  /**
   * @param {fabric.IEvent} event
   */
  handleMouseMove(event) {
    if (this.state.shape !== null) {
      const { shape } = this.state;
      const p = this.canvas.getPointer(event.e);

      shape.set({
        x2: p.x,
        y2: p.y,
      });

      this.canvas.renderAll();
    }
  }

  /**
   * @param {fabric.IEvent} event
   */
  handleMouseUp(event) {
    if (this.state.shape !== null) {
      this.canvas.remove(this.state.shape);

      this.canvas.trigger("tool:complete", {
        shape: this.state.shape,
        applyTheme: true,
        autoSelect: true,
      });
    }
  }

  /**
   * When the tool is selected from the toolbar
   */
  handleSelected() {
    this.state = config.initialState();
  }

  /**
   * When tool is deselected
   */
  handleDeselected() {
    this.state = config.initialState();
  }

  //
}

export default LineTool;
