import { CertSignature } from "@sw-sw/lib-certification";
import { FormHelpText } from "@sw-sw/lib-form";
import classnames from "classnames";
import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../../contexts/AppContext";
import ProjectPermissionContext from "../../../contexts/ProjectPermissionContext";

export function SignatureCheckbox({
  lineType = "unspecifiedLineType",
  onSubmit,
  enabled,
  userSignatures = [],
  errors,
  checked = undefined,
  readOnly = undefined,
}: {
  lineType?: string;
  enabled: boolean;
  onSubmit: () => Promise<void>;
  errors: string[];
  userSignatures?: CertSignature[];
  checked?: boolean;
  readOnly?: boolean;
}) {
  const appContext = useContext(AppContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);

  const signatureAuthorized = appContext.get(
    "user.electronicSignatureAuthorization",
  );

  const existingSignature: CertSignature | null = useMemo(() => {
    const sig = userSignatures.filter(s => s.lineType === lineType);

    return sig.length ? sig[0] : null;
  }, [userSignatures, lineType]);
  const canSign = useMemo(() => {
    return (
      enabled &&
      !existingSignature &&
      !projectPermissionContext.readOnly &&
      signatureAuthorized
    );
  }, [
    enabled,
    existingSignature,
    projectPermissionContext.readOnly,
    signatureAuthorized,
  ]);

  const electronicSignatureErrorText = (
    <>
      In order to sign documents electronically, you must consent to electronic
      signatures in your <Link to="/my-profile">user profile</Link>.
    </>
  );

  return (
    <div className="pure-u pure-u-lg-1-3 pure-u-md-1-2 pure-u-1 electronic-signature-wrapper">
      <label className="electronic-signature">
        <input
          type="checkbox"
          name={lineType}
          aria-labelledby={`${lineType}-label`}
          onChange={() => {
            return canSign ? onSubmit() : null;
          }}
          checked={checked || !!existingSignature}
          readOnly={readOnly || !!existingSignature || !canSign}
        />
        <span
          className={classnames("pseudo-checkbox", {
            disabled: !canSign,
          })}
        />
        <div id={`${lineType}-label`} className="label-wrapper">
          <span>Electronic Authorization Signature</span>
          {!canSign && existingSignature ? (
            <span className="text-bold text-italic">
              {existingSignature.userName}
            </span>
          ) : null}
        </div>
      </label>

      {!signatureAuthorized && !existingSignature && !readOnly ? (
        <p className="error signature-error">{electronicSignatureErrorText}</p>
      ) : errors.length && !canSign ? (
        errors.map((message, key) => (
          <FormHelpText key={key} theme="error" content={message} />
        ))
      ) : (
        <>
          <i>
            <FormHelpText
              theme="info"
              content={
                "By checking the electronic authorization signature box, I agree to signing these documents electronically"
              }
            />
          </i>
        </>
      )}
    </div>
  );
}

export default SignatureCheckbox;
