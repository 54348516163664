import { Dispatch, useEffect, useState } from "react";

export type SelectionState = {
  selected: number[];
  setSelected: Dispatch<number[]>;
  all: boolean;
  setAll: Dispatch<boolean>;
};

export function useSelection({ total }: { total: number }): SelectionState {
  const [selected, setSelected] = useState<number[]>([]);
  const [all, setAll] = useState<boolean>(false);

  useEffect(() => {
    if (total > 0 && selected.length > 0 && total === selected.length) {
      setAll(true);
    } else {
      setAll(false);
    }
  }, [selected, total, all]);

  return { selected, setSelected, all, setAll };
}

export default useSelection;
