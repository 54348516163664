import React, { useContext, useEffect, useState } from "react";
import projectApi from "../../../../utils/api/project";
import { ProjectContext } from "../../../../contexts/ProjectContext";
import { FormContextProvider } from "@sw-sw/lib-form";
import Select from "react-select";
import DocumentsLogTable from "./DocumentsLogTable";
import Pagination from "react-js-pagination";
import _ from 'lodash';

const DocumentsLog: React.FC<any> = () => {
  const projectContext = useContext(ProjectContext);
  const projectId = projectContext && projectContext.project && projectContext.project.id
  const [documentsLogData, setDocumentsLogData] = useState<any>()
  const [search, setSearch] = useState('')
  const [filterType, setFilterType] = useState('ALL Documents')
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(450)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const logTabOptions = [
    {value: 1, label: 'ALL Documents'},
    {value: 2, label: 'Uploaded Documents'},
    {value: 3, label: 'Deleted Documents'},
    {value: 4, label: 'Replaced Documents'},
    {value: 5, label: 'Restored Documents'},
  ]

  const onChangeFunc = (data:any) => {
    setFilterType(data.label)
    setPage(1)
  }

  const getDocumentsLog = () => {
    projectApi.documentsLog.getDocumentsLog(projectId, filterType, page, rowsPerPage, search)
    .then(res => {
      setDocumentsLogData(res.data)
      setCount(res.count)
    })
  }

  const onSearchChangeHandler = _.debounce((event: any) => {
    setSearch(event.target.value)
  }, 500);

  useEffect(() => {
    getDocumentsLog()
  },[projectId, filterType, page, rowsPerPage, search])

  return (
    <div className='documents-log'>
      <header>
        <h2 className='documents-log__header-name'>Uploaded / Deleted Log</h2>
        <div className='documents-log__header-tools'>
          <input onChange={onSearchChangeHandler} placeholder="Search" />
          <Select
            closeMenuOnSelect={true}
            defaultValue={logTabOptions[0]}
            options={logTabOptions}
            onChange={(data) => { onChangeFunc(data) }}
            isClearable={false}
            className='documents-log__select'
            classNamePrefix='documents-log'
          />
        </div>
      </header>

      <main>
        <FormContextProvider>
          <DocumentsLogTable 
            projectId={projectId} 
            documentsLogData={documentsLogData}
            getDocumentsLog={getDocumentsLog}
          />
          <div className="documents-log__pagination-wrapper">
            <div>
              <span>Rows Per Page : </span>
              <select className='documents-log__pagination-select'
                onChange={(event) => {
                  setRowsPerPage(Number(event.target.value))
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
            <Pagination
              activePage={page}
              itemsCountPerPage={rowsPerPage}
              totalItemsCount={count}
              pageRangeDisplayed={3}
              onChange={(currentPage) => {
                setPage(currentPage)
              }}
              itemClass="documents-log__pagination-main"
            />
          </div>
        </FormContextProvider>
      </main>
    </div>
  );
};

export default DocumentsLog;
