import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FormHelpText } from "@sw-sw/lib-form";
const maxRetries = 3;

/**
 * Generic error message
 *
 * @todo customize retry action
 */
function Error({ allowRetry, error, onRetry }) {
  const [retries, setRetries] = useState(0);

  return (
    <div className="resource-index-error">
      <FontAwesomeIcon icon="exclamation-circle" color="rgb(187, 28, 28)" />
      <FormHelpText theme="error" content={error} />
      {allowRetry && retries < maxRetries ? (
        <span>
          &nbsp;
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              setRetries(retries + 1);

              if (onRetry) {
                onRetry();
              }

              return false;
            }}
          >
            reload
          </a>
        </span>
      ) : null}
    </div>
  );
}

Error.propTypes = {
  error: PropTypes.string.isRequired,
  allowRetry: PropTypes.bool,
  onRetry: PropTypes.func,
};

export default Error;
