import React, { useContext } from "react";
import PropTypes from "prop-types";
import { findIndex, get, isEmpty } from "lodash";

import { FormSchemaFields } from "@sw-sw/lib-form";

import LineEditor from "../../Featureable/IconEditor/Line";
import IconEditor from "../../Featureable/IconEditor/Icon";
import FeatureableAreaEditor from "../../Featureable/IconEditor/Area";
import { FormContext } from "@sw-sw/lib-form";
import { formField } from "../../Featureable/IconEditor/Context";
import { getUtil } from "../../../utils/positionable";
import { UIControlType } from "@sw-sw/lib-form-control-types";

/**
 * List of available control measure shape editors
 *
 * Each item must have a name and label
 */
const shapeEditors = [
  {
    name: "line",
    label: "Line",
    Component: LineEditor,
  },
  {
    name: "icon",
    label: "Icon",
    Component: IconEditor,
  },
  {
    name: "circle",
    label: "Circle",
    Component: React.forwardRef((props, ref) => (
      <FeatureableAreaEditor areaType="circle" {...props} ref={ref} />
    )),
  },
  {
    name: "polygon",
    label: "Polygon",
    Component: React.forwardRef((props, ref) => (
      <FeatureableAreaEditor areaType="polygon" {...props} ref={ref} />
    )),
  },
];

/**
 * List of item types
 */
const itemTypes = [
  {
    label: "Control Measure",
    value: "control_measure",
  },
  {
    label: "Pollutant",
    value: "pollutant",
  },
];

/** get shape index, by name */
export const getShapeIndex = name => findIndex(shapeEditors, { name });

/** get item type index, by value */
export const getItemTypeIndex = value => findIndex(itemTypes, { value });

const fieldTmpl = {
  controlType: UIControlType.customTagSelect,
  labelKey: "name",
  valueKey: "id",
  required: true,
  isMulti: true,
  openOnFocus: true,
};

export const getSchema = (regulations, isEdit = false) => {
  const metadataFields = {
    name: {
      label: "Name",
      validation: {
        required: true,
        maxLength: 100,
      },
      autoComplete: "off",
    },
  };

  if (regulations) {
    metadataFields.groups = {
      ...fieldTmpl,
      label: "Regulations",
      options: regulations,
      noOptionMessage: "No regulations found",
      placeholder: "Search for regulations",
    };
  }

  const editorFields = {
    type: {
      label: "Type",
      controlType: UIControlType.buttonGroup,
      options: itemTypes,
      validation: {
        required: true,
      },
      style: {
        flex: "0 1 50%",
        marginBottom: 0,
      },
    },
    editorIndex: {
      label: "Shape",
      controlType: UIControlType.buttonGroup,
      options: shapeEditors.map((opt, index) => ({
        value: index,
        label: opt.label,
      })),
      validation: {
        required: true,
      },
      style: {
        flex: "0 0 50%",
        marginBottom: 0,
      },
    },
    featureable_data: {
      controlType: UIControlType.custom,
      renderControl: ({ onChange, value, ...props }) => {
        const formContext = useContext(FormContext);

        if (get(formContext, "value.editorIndex") === undefined) {
          return null;
        }

        const ShapeEditor =
          shapeEditors[get(formContext, "value.editorIndex", 0)].Component;

        if (ShapeEditor) {
          const initialData = get(formContext.value, `${formField}.config`, {});

          return (
            <ShapeEditor
              {...props}
              initialData={isEmpty(initialData) ? null : initialData}
            />
          );
        }

        throw new Error();
      },
    },
  };

  if (isEdit) {
    delete editorFields.type;
  }

  return { ...metadataFields, ...editorFields };
};

export const defaultData = () => ({
  editorIndex: 0,
  type: "control_measure",
  featureable_data: {
    config: {},
  },
});

/** build form data from legend item model */
export const extractInitialFormData = model => {
  const { name, id, type, document_groups: groups } = model;

  const featureableData = getUtil(type).extractFeatureableData(model);

  if (featureableData && featureableData.type) {
    return {
      name,
      id,
      type,
      editorIndex: getShapeIndex(featureableData.type),
      featureable_data: featureableData,
      groups,
    };
  }

  throw new Error("Missing featureable_data or invalid shape type");
};

/**
 * UI for Legend Item form
 */
function LegendItemFormSchema({
  onChange,
  value,
  initialValue,
  projectName,
  schema,
}) {
  return (
    <React.Fragment>
      {projectName && (
        <p style={{ paddingLeft: 0, marginLeft: "-.5rem" }}>
          Project: {projectName}
        </p>
      )}
      <FormSchemaFields
        className="legend-item-form-schema"
        schema={schema}
        initialFormData={initialValue}
        formData={value}
        onChange={onChange}
      />
    </React.Fragment>
  );
}

export const formDataShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  editorIndex: PropTypes.number,
  type: PropTypes.string,
  featureable_data: PropTypes.object,
});

LegendItemFormSchema.propTypes = {
  value: formDataShape.isRequired,
  initialValue: formDataShape,
  /** @important 2 ary*/
  onChange: PropTypes.func.isRequired,
  canEditType: PropTypes.bool,
  projectName: PropTypes.string,
  schema: PropTypes.object.isRequired,
};

LegendItemFormSchema.defaultProps = {
  canEditType: true,
};

export default LegendItemFormSchema;
