import React, { useContext } from "react";
import { Redirect } from "react-router";
import AppDivisionContext from "../../contexts/AppDivisionContext";

export interface IRedirectRouteProps {
  location: {
    pathname: string;
  };
}

export const RedirectRoute: React.FC<IRedirectRouteProps> = routeProps => {
  const appDivisionId = useContext(AppDivisionContext).appDivisionId;

  return (
    <Redirect
      to={`/divisions/${appDivisionId}` + routeProps.location.pathname}
    />
  );
};
