import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

function TinyIconButton({
  className = "",
  iconName,
  iconClassName = "",
  ...props
}) {
  return (
    <button
      className={classnames("icon-only-button tiny outline", className)}
      {...props}
    >
      <i className={classnames(`fa fa-${iconName}`, iconClassName)} />
    </button>
  );
}

TinyIconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};
TinyIconButton.defaultProps = {
  className: "",
  iconClassName: "",
};

export default TinyIconButton;
