import { Modal } from '@sw-sw/lib-ui';
import React, { useContext, useState } from 'react';
import { Context as InteractionContext } from '../InteractionContext';
export default function MapImageModal() {
  const ic = useContext(InteractionContext);
  const [currentImageIndex, setCurrentImageIndex] = useState(ic.modeData.props.index);
  const [showModal, setShowModal] = useState(true);
  const goToNextImage = () => {
    setCurrentImageIndex(
      (prevIndex) =>  prevIndex < ic.modeData.props.images.length ? (prevIndex + 1) % ic.modeData.props.images.length : 0 
    );
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? ic.modeData.props.images.length - 1 : prevIndex - 1,
    );
  };

  return (
    <Modal
      show={showModal}
      hideButtons={true}
      handleClose={() => {
        setShowModal(false);
        ic.setDefaultMode();
      }}
      cancelBtn={true}
      subTitle={ic.modeData.props.images[currentImageIndex].imageName}
    >
      <div className='map-image-container'>
        <img
          src={ic.modeData.props.images[currentImageIndex].imageURL}
          alt={`${currentImageIndex + 1}`}
          style={{ height: '30rem', width: '40rem', marginTop: '5rem' }}
        />
        <div className='navigation-buttons'>
          <div className='previous-button' onClick={goToPreviousImage}>
            Previous
          </div>
          <div className='next-button' onClick={goToNextImage}>
            Next
          </div>
        </div>
      </div>
    </Modal>
  );
}
