import React from "react";

import { pointerMove } from "ol/events/condition";
import SelectFeature from "./SelectFeature";

export const Context = React.createContext();

/**
 * Selection of single feature, via hover
 *
 * @todo Custom overlay style, based on map layers and/or feature geometry
 */
function SelectFeatureOnHover({ children, ...props }) {
  return (
    <SelectFeature condition={pointerMove} {...props}>
      {children}
    </SelectFeature>
  );
}

export default SelectFeatureOnHover;
