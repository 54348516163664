import { FormContext } from "@sw-sw/lib-form";
import {
  Question,
  QuestionGroup,
  ValueType,
} from "@sw-sw/lib-inspection-templates";
import { UserDataContextValue } from "@sw-sw/ui-inspection-templates";
import { get, has } from "lodash";
import { useContext, useMemo } from "react";
import { InspectionContext } from "../contexts/InspectionContext";

export function useTemplateUserData(): Pick<
  UserDataContextValue,
  "answers" | "comments"
> {
  const formContext = useContext(FormContext);
  const inspectionContext = useContext(InspectionContext) as any;
  const groups = inspectionContext.template
    .question_groups as (QuestionGroup & {
    questions: Question[];
  })[];
  const answers = useMemo(() => {
    return groups.reduce<Record<number, ValueType>>(
      (questionAnswers, group) => {
        group.questions.forEach(question => {
          if (
            Object.prototype.hasOwnProperty.call(
              formContext.value,
              `question-${question.id}`,
            )
          ) {
            questionAnswers[question.id!] =
              formContext.value[`question-${question.id}`];
          } else {
            questionAnswers[question.id!] = get(question, "answer", null);
          }
        });

        return questionAnswers;
      },
      {},
    );
  }, [groups, formContext.value]);

  return {
    answers,
    comments: groups.reduce<Record<number, string | null>>((a, group) => {
      group.questions.forEach(question => {
        if (question.id && has(question, "comment")) {
          a[question.id] = get(question, "comment");
        }
      });

      return a;
    }, {}),
  };
}
