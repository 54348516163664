/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  useState,
  Dispatch,
  useEffect,
} from 'react';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { ProjectContext } from '../../../contexts/ProjectContext';
import FindingCard from '../../Inspections/Findings/FindingCard';
import Loading from '../../Shared/ResourceIndex/Loading';
import {
  FormContext,
  FormSchemaFields,
  FormContextProvider,
} from '@sw-sw/lib-form';
import Pagination from "react-js-pagination";
import { correctiveActionsFindingsApi } from "../../../utils/api/correctiveActionsFindingsApi";

const controlField = 'findingFilter';
const statusField = 'statusFilter';

const FindingFilter = (props: {
  handleChange: Dispatch<string>;
  options: { label: string; value: string }[];
}) => {
  const formContext = useContext(FormContext);

  const schema = {
    [controlField]: {
      controlType: UIControlType.select,
      placeholder: 'Finding Types',
      className: 'filter small',
      options: [{ label: 'All Findings', value: 'all' }, ...props.options],
      style: {
        marginRight: '10px',
        marginLeft: '10px',
      }
    },
  };

  return (
    <FormSchemaFields
      className='filter-form'
      formData={formContext.value}
      onChange={(field, val) => {
        formContext.set(field, val);
        props.handleChange(val);
      }}
      schema={schema}
    />
  );
};

const FindingStatusFilter = (props: {
  handleChange: Dispatch<string>;
  options: { label: string; value: string }[];
}) => {
  const formContext = useContext(FormContext);

  const schema = {
    [statusField]: {
      controlType: UIControlType.select,
      placeholder: 'Findings Status',
      className: 'filter small',
      options: [...props.options],
      style: {
        marginRight: '10px',
        marginLeft: '10px',
      }
    },
  };

  return (
    <FormSchemaFields
      className='filter-form'
      formData={formContext.value}
      onChange={(field, val) => {
        formContext.set(field, val);
        props.handleChange(val);
      }}
      schema={schema}
    />
  );
}

export default function FindingListing(_props: {
  showFindings: string;
  inspectionId: number;
  Finding: string;
}) {
  const projectContext = useContext(ProjectContext);

  const [filter, setFilter] = useState<string>('all');
  const [status, setStatus] = useState<string>('all');
  const [page, setPage] = useState<number>(1);
  const [totalFindingModelCount, setTotalFindingModelCount] = useState<number>(10);
  const [correctiveActionsQuery , setCorrectiveActionsQuery] = useState<any>();
  const [findingFilterOptions , setFindingFilterOptions] = useState<Array<{label: string; value: string;}>>();
  const [findingStatusOptions] = useState<Array<{label: string; value: string;}>>([
    {
      label: 'All Findings',
      value: 'all',
    },
    {
      label: 'Open Findings',
      value: 'open',
    },
    {
      label: 'Closed Findings',
      value: 'closed',
    },
  ]);
  const [perPageDataCount, setperPageDataCount] = useState<number>(10);


  const setPageValue = (pageNumber: number) => {
    setPage(pageNumber);
  }

  const fetchFindings = async () => {
    const projectId: number = projectContext.project ? projectContext.project.id : 0;
    const data: any = await correctiveActionsFindingsApi.index(projectId, filter, status, page, perPageDataCount);

    setCorrectiveActionsQuery(data);
  }

  const fetchFindingFilterOptions = async () => {
    const projectId: number = projectContext.project? projectContext.project.id : 0;
    const data: any = await correctiveActionsFindingsApi.getFindingFilterOptions(projectId);

    const options = await data.map((_:{type:string})=>_.type).sort().map((type: any) => {
      return {
        label:
          type === 'CA'
            ? 'Corrective Action'
            : type === 'A'
              ? 'Achievement'
              : type === 'MI'
                ? 'Maintenance Item'
                : type,
        value: type,
      };
    });

    setFindingFilterOptions(options);
  }

  const fetchPageModelTotalCount = async () => {
    const projectId: number = projectContext.project ? projectContext.project.id : 0;
    const data: any = await correctiveActionsFindingsApi.getFindingModelPageCount(projectId, filter, page);

    setTotalFindingModelCount(data.total_model_count);
  }

  const onChangePerPageDataCount = async (dataCount: number) => {
    setperPageDataCount(dataCount)
  }

  useEffect(()=>{
    fetchFindings();
    fetchFindingFilterOptions();
    fetchPageModelTotalCount();
  },[]);

  useEffect(()=>{
    fetchFindings();
  },[page, perPageDataCount]);

  useEffect(()=>{
    fetchFindings();
    fetchPageModelTotalCount();
  },[filter, status]);

  if (!correctiveActionsQuery) {
    return <Loading what='corrective actions' />;
  }

  return (
    <div className='inspection-findings'>
      <div className='right-aligned'>
        <FormContextProvider>
          {(correctiveActionsQuery.length > 0 && findingFilterOptions) && (
            <FindingFilter handleChange={setFilter} options={findingFilterOptions} />
          )}
          <FindingStatusFilter handleChange={setStatus} options={findingStatusOptions} />
        </FormContextProvider>
      </div>
      <section className='pure-g findings-listing'>
        {!correctiveActionsQuery.length ? (
          <p className='pure-u'>No findings</p>
        ) : (
          correctiveActionsQuery.map((correctiveActionFinding: any) => (
            <FindingCard
              key={correctiveActionFinding.id}
              finding={correctiveActionFinding}
            />
          ))
        )}
      </section>
      
      <section>  
        <div className='pagination-container'>
          
          <Pagination
            activePage={page}
            itemsCountPerPage={perPageDataCount}
            totalItemsCount={totalFindingModelCount}
            pageRangeDisplayed={4}
            onChange={(e) => setPageValue(e)}
            itemClass="pagination-item"
          />

          {(totalFindingModelCount > 0) ? <select
            value={perPageDataCount}
            onChange={(e) => onChangePerPageDataCount(parseInt(e.target.value))}
            className='pagesize-item'
          >
            {['10', '25', '50'].map((val, index) => {
              return (
                <option key={index} value={val}>
                  {val}
                </option>
              );
            })}
          </select> : null}
        
        </div>
      </section>
    </div>
  );
}
