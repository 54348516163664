import React from 'react';
import { Grid } from '@mui/material';
import LogContainer from './LogContainer';
import { positionableUtil } from "@sw-sw/common";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Notes from './Notes';
import './index.scss';

type Props = {
    changelogs: Array<any>;
    projectId: number;
}

function ChangeLog(props: Props) {

    const { changelogs, projectUploads, timezone }: any = props.changelogs;

    return (
        <Grid container className='changelog-container'>
            {
                Object.keys(changelogs).map((logkey) => (
                    <>
                        <Grid item xs={12}>
                            <p className='log-label'>{`${positionableUtil.controlMeasure.getLabel(logkey)}`}</p>
                        </Grid>
                        {
                            changelogs[logkey].map((item: any, index: number) => {
                                const legendItem = { shape: item }; // for extracting image need to pass with shape wrapping
                                return (
                                    <Grid item xs={12} key={index} style={{ margin: '15px auto' }}>
                                        <LazyLoadComponent >
                                            <LogContainer legendItem={legendItem} projectId={props.projectId} uploads={projectUploads} timezone={timezone} />
                                        </LazyLoadComponent>
                                    </Grid>
                                )
                            })
                        }
                    </>
                ))
            }

            <Grid item xs={12} style={{ margin: '15px auto' }}>
                <LazyLoadComponent >
                    <Notes projectId={props.projectId} />
                </LazyLoadComponent>
            </Grid>
        </Grid>
    )
}

export default ChangeLog