import { asSchemaDefinition, SchemaFieldsWithContext } from "@sw-sw/lib-form";
import classnames from "classnames";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import userApi from "../../../utils/api/user";
import FormActions from "../../Shared/form/modal/FormActions";
import SuccessModal from "../../Shared/SuccessModal/SuccessModal";

const schema = asSchemaDefinition<{ email: string }>({
  email: {
    label: "Email",
    className: "bottom-outline",
    autoComplete: "username",
    validation: {
      required: true,
      format: "email",
    },
  },
});

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const hideModal = () => {
    setSuccess(false);
  };

  function handleSubmit(email: string) {
    return userApi.forgotPassword(email).then(() => {
      setSuccess(true);
    });
  }

  if (redirect) return <Redirect to="/login" push />;

  return (
    <section className="forgot-password">
      <h3>Forgot Password?</h3>
      <p>We'll send you a recovery link to your email address</p>
      <div className="forgot-password-ui">
        <SchemaFieldsWithContext
          schema={schema}
          className={classnames({ success })}
        >
          <FormActions
            submitText={success ? "Return" : "Send"}
            onSubmit={handleSubmit}
            onCancel={() => {}}
            showCancel={false}
            small={false}
            showSubmit={true}
            showErrorText={true}
            errorMessageText="Invalid form data. Please review errors above."
            cancelText="Cancel"
          >
            <Link to="/" className="no-underline">
              Cancel
            </Link>
          </FormActions>
          <SuccessModal
            classes="reset-password"
            show={success}
            handleClose={hideModal}
            handleSubmit={() => setRedirect(true)}
            title="Success"
            message="Please check your email inbox to reset your account."
            submitBtnText="Go to Login"
          />
        </SchemaFieldsWithContext>
      </div>
    </section>
  );
};

export default ForgotPassword;
