import { Modal } from "@sw-sw/lib-ui";
import React from "react";

export interface IDataPrivacyPolicyModalProps {
  show: boolean;
  handleClose: () => void;
}

const DataPrivacyPolicyModal: React.FC<
  IDataPrivacyPolicyModalProps
> = ({ show, handleClose }) => {
  return (
    <Modal
      title="Privacy Policy - Protection of Personal Data"
      show={show}
      hideButtons
      handleClose={handleClose}
    >
      <div className="DataPrivacyPolicyModal">
        <p>
          <strong>
            Protecting your private information is our priority. By registering with the software you are acknowledging that you have read and consent our <a href="https://sw2.net/privacy-policy/">privacy policy</a>.
          </strong>
        </p>
      </div>
    </Modal>
  );
};

export default DataPrivacyPolicyModal;

