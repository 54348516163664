import React from "react";
import ReactDOM from "react-dom";

// styles
import "../node_modules/purecss/build/pure-min.css";
import "../node_modules/purecss/build/grids-responsive-min.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/weather-icons/css/weather-icons.min.css";
import "react-tabs/style/react-tabs.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-image-lightbox/style.css";
import "./index.scss";
import "ol/ol.css";
import "react-mde/lib/styles/css/react-mde-toolbar.css";
import "react-mde/lib/styles/css/react-mde-editor.css";
import "react-mde/lib/styles/css/react-mde.css";
import "react-mde/lib/styles/css/react-mde-suggestions.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-toastify/dist/ReactToastify.css";
import { env } from "./config";
import { datadogRum } from '@datadog/browser-rum';
// components
import App from "./App";

// fortawesome icons
import "./icons";

if(env.REACT_APP_ENV === "production") {
datadogRum.init({
    applicationId: env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: env.REACT_APP_DATADOG_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'sw2',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    allowedTracingUrls:[env.REACT_APP_API_BASE_URL,`https:\/\/.*\.sw2.net`,(url)=>url.startsWith(`${env.REACT_APP_API_BASE_URL}`)],
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});
}

console.log("Application version:", env.REACT_APP_CI_COMMIT_SHA || "undefined");
console.log("Build Job Id:", env.REACT_APP_CI_JOB_ID || "undefined");

console.log("Public URL:", env.PUBLIC_URL);

datadogRum.startSessionReplayRecording();


ReactDOM.render(<App />, document.getElementById("root"));
