import React from "react";
import { useLocation } from 'react-router-dom'
import {
  arrayOf,
  string,
  shape,
  oneOfType,
  number,
  node,
  func,
  bool,
} from 'prop-types';
import classnames from 'classnames';
import { startCase } from 'lodash';
import Loading from '../ResourceIndex/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const List = (props) => {
  const location = useLocation();

  return (
    // <table className="list-table pure-table ">
    <table
      className={classnames(
        'list-table',
        'pure-table',
        props.rows.length <= 0 ? 'list-table--empty' : null,
      )}
    >
      <thead>
        <tr>
          {props.enableSelection && (
            <th key='check-box' className='select'>
              <input
                type='checkbox'
                checked={props.selectAll}
                onChange={props.onSelectAll}
              />
            </th>
          )}
          
          {props.headers.map(header => {
            const { label, ...headerProps } = header;

            return (
              <th key={label} {...headerProps} className={`${(/divisions\/[0-9]*\/admin/).test(location.pathname) ? "only-for-admin" : ""}`}>
                {label}
              </th>
            );
          })}
          {props.showDetail && props.handleDetail && (
            <th key='detail' className='detail'></th>
          )}
        </tr>
      </thead>

      <tbody>
        {props.loading ? (
          <tr>
            <td
              colSpan={
                props.enableSelection
                  ? props.headers.length + 1
                  : props.headers.length
              }
              className='no-border'
            >
              <Loading />
            </td>
          </tr>
        ) : props.rows.length === 0 ? (
          <tr>
            <td
              colSpan={
                props.enableSelection
                  ? props.headers.length + 1
                  : props.headers.length
              }
              className='no-border'
            >{`No ${startCase(props.pageItems)} found`}</td>
          </tr>
        ) : (
          props.rows.map((row) => {
            const {
              id,
              argument,
              handleClick,
              className,
              cells,
              handleDetail,
              ...rowProps
            } = row;

            return (
              <tr
                key={id}
                onClick={handleClick}
                data-argument={argument}
                className={classnames(
                  className,
                  handleClick ? 'clickable' : null,
                )}
                {...rowProps}
              >
                {props.enableSelection && (
                  <td key={`check ${id}`} className='select'>
                    <input
                      type='checkbox'
                      checked={props.selected.includes(id) ? true : false}
                      value={id}
                      onChange={props.onSelect}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </td>
                )}
                {cells.map((child, i) => {
                  return i === 4 && child === "INVITED" ? (
                    <td key={i} className={`${(/divisions\/[0-9]*\/admin/).test(location.pathname) ? "only-for-admin" : ""}`}>{child} </td>
                  ) : (
                    <td key={i} className={`${(/divisions\/[0-9]*\/admin/).test(location.pathname) ? "only-for-admin" : ""}`}>{child}</td>
                  );
                })}
                {props.showDetail && handleDetail && (
                  <td className='detail'>
                    <FontAwesomeIcon
                      icon={faEye}
                      onClick={() => {
                        handleDetail();
                      }}
                    />
                  </td>
                )}
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

/** @todo rework rows.#.handleclick */
List.propTypes = {
  pageItems: string.isRequired,

  // correlates to <th />
  headers: arrayOf(
    shape({
      label: string.isRequired,
    }),
  ).isRequired,

  // correlates to <tr /> []
  rows: arrayOf(
    shape({
      handleClick: func,
      argument: oneOfType([string, number]), // argument passed to handleClick function
      id: oneOfType([string, number]).isRequired,

      // correlates to <td />
      cells: arrayOf(oneOfType([string, node])),
    }),
  ).isRequired,

  enableSelection: bool,
  selected: arrayOf(number),
  selectAll: bool,
  onSelect: func,
  onSelectAll: func,
  loading: bool,
  showDetail: bool,
  handleDetail: func,
  detailKey: string,
};

List.defaultProps = {
  enableSelection: false,
  selected: [],
  selectAll: false,
  onSelect: null,
  onSelectAll: null,
  loading: false,
  showDetail: false,
  detailKey: null,
};

export default List;
