import useResource from './resource';

export function useUserFormData(divisionId: number) {
  const roles = useResource({
    resource: 'roles',
    disablePagination: true,
    divisionId,
  });
  const divisions = useResource({
    resource: 'divisions',
    disablePagination: true,
    divisionId,
  });

  const tenants = useResource({
    resource: 'tenants',
    disablePagination: true,
    divisionId,
  });

  return {
    roles,
    divisions,
    tenants,
    loading: roles.loading || divisions.loading || tenants.loading,
  };
}
