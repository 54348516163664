import React, { useContext, useState } from 'react';
import ContactPhone from './ContactPhone';

import ConfirmationModal from '../Shared/ConfirmationModal/ConfirmationModal';
import AppContext from '../../contexts/AppContext';
import { RolesContext } from '../../contexts/RolesContext';
import { Contact } from '../../utils/api/client';
import { Address } from '../../utils/api/address';
import ProjectContext from '../../contexts/ProjectContext';

type ContactWithAddress = Contact & {
  address?: Address;
};

export interface IContactsProps {
  id: number;
  onDelete: (
    entityId: number,
    contactId: number,
  ) => Promise<void>;
  contacts: Array<ContactWithAddress>;
  parent?: any;
  label: string;
  readOnly?: Boolean;
}

const Contacts: React.FC<IContactsProps> = ({
  id: entityId,
  onDelete,
  contacts,
  parent,
  label,
  readOnly = false,
}) => {
  const appContext = useContext(AppContext);
  const projectContext = useContext(ProjectContext);
  const permCheck = useContext(RolesContext).userHasPermission;
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [selectedContact, setSelectedContact] = useState<ContactWithAddress>();
  const [errorMsg, setErrorMsg] = useState<string>();

  const handleContactDelete = () => {
    if (selectedContact) {
      onDelete(entityId, selectedContact.id)
        .then(() => {
          setShowDeleteForm(false);
        })
        .catch((err) => {
          setErrorMsg(
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : 'An error has occurred',
          );
        });
    }
  };

  // const { showDeleteForm, selectedContact } = this.state;

  return (
    <div className='contacts pure-g'>
      {contacts.length > 0 ? (
        contacts.map((contact) => {
          return (
            <div
              className='contact-wrapper pure-u-1-1 pure-u-md-1-2 pure-u-lg-1-3'
              key={contact.id}
            >
              <div className='contact'>
                <h3>{contact.name}</h3>

                <div className='contact-email-phone'>
                  <div className='email'>
                    <i className='fa fa-envelope' />
                    <span>
                      <a href={`mailto:${contact.email}`}>{contact.email}</a>
                    </span>
                  </div>

                  {/* if contact has not registered, they will not have an address */}
                  {contact.address && contact.address.phone && (
                    <ContactPhone
                      phone={contact.address.phone}
                      ext={contact.address.phone_ext}
                    />
                  )}
                </div>
                <div className='contact-controls'>
                  {projectContext.project &&
                  projectContext.project.inspector_user_id === contact.id ? (
                    <span className="contact-inspector-text">(Inspector)</span>
                  ) : (
                    !readOnly &&
                    permCheck('update', label) &&
                    appContext.get('user').id !== contact.id && (
                      <button
                        className='icon-only-button tiny outline pad-left'
                        title='remove contact'
                        onClick={() => {
                          setSelectedContact(contact);
                          setShowDeleteForm(true);
                        }}
                      >
                        <i className='fa fa-trash' />
                      </button>
                    )
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className='pure-u-1-1'>No contacts have been added</p>
      )}

      <ConfirmationModal
        show={showDeleteForm}
        handleConfirm={handleContactDelete}
        handleClose={() => setShowDeleteForm(false)}
        title='Remove Contact'
        subTitle={
          errorMsg
            ? errorMsg
            : selectedContact
            ? `Are you sure you want to remove ${
                selectedContact.first_name && selectedContact.last_name
                  ? selectedContact.first_name + ' ' + selectedContact.last_name
                  : selectedContact.email
              } from ${parent}?`
            : ''
        }
        buttonText='Remove contact'
      />
    </div>
  );
};

export default Contacts;
