import React from "react";
import Loading from "../components/Shared/ResourceIndex/Loading";
import ProjectContext from "../contexts/ProjectContext";
import ProjectPermissionContext, {
  ProjectPermissionContextProvider,
} from "../contexts/ProjectPermissionContext";

export function withProjectContext(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <ProjectPermissionContextProvider>
          <ProjectContext.Consumer>
            {projectContext => (
              <ProjectPermissionContext.Consumer>
                {projectPermissionContext =>
                  projectContext && projectPermissionContext ? (
                    <WrappedComponent
                      projectContext={projectContext}
                      projectPermissionContext={projectPermissionContext}
                      {...this.props}
                    />
                  ) : (
                    <Loading />
                  )
                }
              </ProjectPermissionContext.Consumer>
            )}
          </ProjectContext.Consumer>
        </ProjectPermissionContextProvider>
      );
    }
  };
}
