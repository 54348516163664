import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Question } from "@sw-sw/lib-inspection-templates";
import { Button } from "@sw-sw/lib-ui";
import classnames from "classnames";
import React, { createRef, useContext, useState } from "react";
import QuestionGroupContext from "../../../contexts/QuestionGroupContext";
import TemplateEditorContext from "../../../contexts/TemplateEditorContext";
import Popper from "../../Shared/Popper/Popper";
import EditQuestion from "./EditQuestion";
import QuestionDelete from "./Forms/QuestionDelete";

export interface IInspectionQuestionProps {
  question: Question & { questionWidth: string };
  onReorder: () => void;
  first: boolean;
}

const InspectionQuestion: React.FC<IInspectionQuestionProps> = ({
  question,
  onReorder,
  first,
}) => {
  const questionGroupContext = useContext(QuestionGroupContext);
  const templateEditorContext = useContext(TemplateEditorContext);
  const [edit, setEdit] = useState<boolean>(false);
  const [showPopper, setShowPopper] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<any>({
    bottom: null,
    right: null,
    left: null,
  });

  const questionType = templateEditorContext.questionTypes.find(
    qt => qt.id === question.question_type_id,
  ) || { label: "" };

  const targetRef = createRef<any>();

  const handlePopperClick = () => {
    const { right, bottom, left } = targetRef.current.getBoundingClientRect();

    setCoordinates(
      Object.assign(
        { top: bottom + window.scrollY },
        right < 100
          ? { left: left + window.scrollX }
          : { right: window.innerWidth - right },
      ),
    );
    setShowPopper(true);
  };

  const onMoveClick = () => {
    if (question.id && questionGroupContext.moveActive) {
      questionGroupContext.setSelectedQuestionId(question.id);
    }
  };

  const handleReorder = async (order: number) => {
    await questionGroupContext.reorderQuestions(order);
    questionGroupContext.setSelectedQuestionId(null);
    questionGroupContext.setMoveActive(false);
    onReorder();
  };

  return (
    <div
      className="question-container"
      style={{
        flexBasis: `${question.questionWidth}%`,
      }}
    >
      {questionGroupContext.moveActive && first && (
        <Button
          className={
            questionGroupContext.questionGroup.layout_type === "FORM"
              ? "move-question-button-form"
              : "move-question-button-table"
          }
          onClick={() => handleReorder(0)}
        >
          <FontAwesomeIcon
            icon={
              questionGroupContext.questionGroup.layout_type === "FORM"
                ? "grip-lines-vertical"
                : "grip-lines"
            }
          />
        </Button>
      )}
      <div
        className={classnames(
          "question",
          questionGroupContext.moveActive &&
            question.id &&
            questionGroupContext.selectedQuestionId === question.id
            ? "selected-question"
            : "",
        )}
        onClick={onMoveClick}
      >
        <div className="question-header">
          <span>{question.input_label}</span>
          <span className="question-actions">
            <FontAwesomeIcon
              icon="edit"
              fixedWidth
              onClick={() => setEdit(true)}
            />
            <span ref={targetRef}>
              <FontAwesomeIcon
                icon="ellipsis-v"
                fixedWidth
                onClick={handlePopperClick}
              />
            </span>
          </span>
        </div>

        <div className="question-type-text">{questionType.label}</div>
        {edit && question.id && (
          <EditQuestion
            questionId={question.id}
            onCancel={() => setEdit(false)}
          />
        )}
        {showPopper && (
          <Popper
            classes="question-group-menu"
            coordinates={coordinates}
            handleClose={() => setShowPopper(false)}
          >
            <ul>
              <li
                className="pointer"
                onClick={() => {
                  if (question.id) {
                    setShowPopper(false);
                    questionGroupContext.setSelectedQuestionId(question.id);
                    questionGroupContext.setMoveActive(true);
                  } else {
                    throw new Error("Question id is undefined");
                  }
                }}
              >
                Move Question
              </li>
              <li
                className="delete-item pointer"
                onClick={() => setShowDelete(true)}
              >
                Delete Question
              </li>
            </ul>
          </Popper>
        )}
        <QuestionDelete
          questionId={question.id}
          groupId={question.groupId}
          show={showDelete}
          onClose={() => setShowDelete(false)}
        />
      </div>
      {questionGroupContext.moveActive && (
        <Button
          className={
            questionGroupContext.questionGroup.layout_type === "FORM"
              ? "move-question-button-form"
              : "move-question-button-table"
          }
          onClick={() => handleReorder(question.sort_order + 1)}
        >
          <FontAwesomeIcon
            icon={
              questionGroupContext.questionGroup.layout_type === "FORM"
                ? "grip-lines-vertical"
                : "grip-lines"
            }
          />
        </Button>
      )}
    </div>
  );
};

export default InspectionQuestion;
