import React, { PropsWithChildren, useContext } from "react";
import TemplateEditorContext from "../contexts/TemplateEditorContext";
import { useUpdateQuestionConfig } from "../hooks/questionConfig";
import { questionTypeConfigApi } from "../utils/api/questionTypeConfig";
import { useCreateQuestion } from "../hooks/question";
import QuestionGroupContext from "./QuestionGroupContext";
import { Question } from "@sw-sw/lib-inspection-templates";
import { questionApi } from "../utils/api/question";

export type InspectionQuestionContextProps = {
  addQuestion: (question: any) => Promise<any>;
  updateQuestionConfig: (question: any) => Promise<any>;
  updateQuestion: (question: Question & { id: number }) => Promise<any>;
};

const throwError = () => {
  throw new Error("InspectionQuestionProvider is not loaded.");
};

const Context = React.createContext<InspectionQuestionContextProps>({
  addQuestion: throwError,
  updateQuestionConfig: throwError,
  updateQuestion: throwError,
});

export const InspectionQuestionProvider: React.FC<
  PropsWithChildren<{ initialValue: { groupId: number } }>
> = ({ initialValue, children }) => {
  const questionGroupContext = useContext(QuestionGroupContext);
  const templateEditorContext = useContext(TemplateEditorContext);
  const newQuestionQuery = useCreateQuestion(initialValue.groupId);
  const updateQuestionConfigQuery = useUpdateQuestionConfig();

  const updateQuestionConfig = async (question: any) => {
    const configValues: Array<any> = [];
    const configTypeValues: Array<any> = [];
    const  question_type_id = question.question_type_id;

    Object.keys(question).forEach((key: string) => {
      const index = templateEditorContext.questionConfigData.findIndex(
        _ => _.fieldName === key,
      );

      if (key === "question_width" && !question[key]) {
        question[key] = "100";
      }

      if (index >= 0) {
        if(templateEditorContext.questionConfigData[index].dataSource === 'QuestionFeature')
          configValues.push({
            value: templateEditorContext.groupedQuestionTypeConfigData[question_type_id]?.find( (ele: any) => ele.fieldName === templateEditorContext.questionConfigData[index].fieldName) ? question[key] : false,
            questionId: question.id,
            questionFieldId: templateEditorContext.questionConfigData[index].id,
          });
        else
          configValues.push({
            value: question[key],
            questionId: question.id,
            questionFieldId: templateEditorContext.questionConfigData[index].id,
          });
      }
    });

    if (Object.keys(question).includes("options")) {
      const index = templateEditorContext.questionTypeConfigData
        .map(_ => _.questionTypeId)
        .indexOf(parseInt(question.question_type_id));

      configTypeValues.push({
        value: JSON.stringify(question.options),
        questionId: question.id,
        questionTypeFieldId:
          templateEditorContext.questionTypeConfigData[index].id,
      });
    }

    return await updateQuestionConfigQuery
      .mutateAsync(configValues)
      .then(async () => {
        await questionTypeConfigApi.update({
          questionTypeId: question.question_type_id,
          data: configTypeValues,
        });
        await questionGroupContext.refetchQuestionGroup();
      });
  };

  const addQuestion = async (question: any) => {
    await newQuestionQuery
      .mutateAsync(question)
      .then(data => updateQuestionConfig({ ...question, id: data.id }));
  };

  const updateQuestion = async (question: Question & { id: number }) => {
    return questionApi.update(question.id, question).then(() => {
      return updateQuestionConfig(question);
    });
  };

  return (
    <Context.Provider
      value={{ addQuestion, updateQuestionConfig, updateQuestion }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
