import React, { MouseEventHandler, useContext, useState } from "react";
import { Modal } from "@sw-sw/lib-ui";
import DivisionContext from "../../../contexts/DivisionContext";

export interface IDivisionResourceDeleteProps {
  handleClose: MouseEventHandler;
  handleSubmit: MouseEventHandler;
  selected: Array<string> | null;
}

const DivisionResourceDelete: React.FC<IDivisionResourceDeleteProps> = ({
  handleClose,
  handleSubmit,
  selected,
}) => {
  const divisionContext = useContext(DivisionContext);
  const [disabled, setDisabled] = useState(true);

  const customButtons = (
    <div className="buttons">
      <button className="reversed outline" type="button" onClick={handleClose}>
        Cancel
      </button>
      &nbsp;
      <button
        className="warn reversed"
        disabled={disabled}
        onClick={handleSubmit}
      >
        Delete
      </button>
    </div>
  );

  const toggleDisabled = () => {
    setDisabled(!disabled);
  };

  return (
    <React.Fragment>
      <Modal
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        show={true}
        title={`Remove ${
          divisionContext.tabs[divisionContext.selectedTab].header
        } from Division`}
        hideButtons={true}
      >
        <p>
          You are about to remove the following{" "}
          {divisionContext.tabs[divisionContext.selectedTab].header} from{" "}
          {divisionContext.selectedDivision
            ? divisionContext.selectedDivision.name
            : "Division"}
          :
        </p>
        <ul>
          {selected &&
            selected.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
        <p>
          This action could result in users of this division losing access to
          clients, projects, and/or inspections.
        </p>
        <div className="division-removal-confirmation">
          <input
            type="checkbox"
            id="proceed"
            name="proceed"
            onClick={toggleDisabled}
          />
          <label>Yes, I understand</label>
        </div>
        {customButtons}
      </Modal>
    </React.Fragment>
  );
};

export default DivisionResourceDelete;
