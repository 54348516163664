import React, { useContext, useEffect} from "react";//

import { Redirect, useHistory } from "react-router-dom"; //
import AuthenticationContext from "../../contexts/AuthenticationContext";
import { AppDivisionContext } from "../../contexts/AppDivisionContext";
import divisionApi from "../../utils/api/division";


/**
 * Compute the home page route, depending on user auth status
 */
const HomePage = () => {
  const authStore = useContext(AuthenticationContext);
  const history = useHistory();
  const appDivisionContext = useContext(AppDivisionContext);

  if (!authStore.loggedIn) {
    return <Redirect to='/login' push={false} />;
  }

  const fetchDivisionAndRedirect = async () => {
    const division = await divisionApi.index();
    const divisionId = (division.length)? division[0].id : appDivisionContext.getPath("/dashboard");
    history.push(`/divisions/${divisionId}/dashboard`);
  }


  useEffect(()=>{
    fetchDivisionAndRedirect();
  },[]);

  return <></>

  // return <Redirect to={appDivisionContext.getPath("/dashboard")} push={false} />;
};

export default HomePage;
