import xhrService from "../xhrService";

export const inspectionTypeApi = {
  index: () =>
    xhrService
      .get(`/api/inspection-types`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      }),
};

export default inspectionTypeApi;
