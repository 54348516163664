import xhrService from "../xhrService";

const baseUrl = `/api/groups`;

/**
 * Document groups, regulations
 */
export const groupApi = {
  index: (includeUploads = false) => {
    const params = {
      includeUploads,
    };

    return xhrService.get(baseUrl, { params }).then(res => res.data);
  },
  create: data => xhrService.post(`${baseUrl}/add`, data).then(res => res.data),
  destroy: id => xhrService.delete(`${baseUrl}/delete/${id}`).then(() => true),
  update: (id, data) =>
    xhrService.post(`${baseUrl}/update/${id}`, data).then(res => res.data),
};

export default groupApi;
