import React, { useContext, useEffect } from "react";

import ModifyFeatures from "./ModifyFeatures";
import useFeature from "../../hooks/useFeature";
import useMap from "../../useMap";
import { Context as InteractionContext } from "../InteractionContext";

/**
 * Modify single feature from interaction mode data
 *
 * Track click events to deselect feature and leave interaction
 */
function ModifySelectedFeature() {
  const feature = useFeature();
  const map = useMap();
  const interactionContext = useContext(InteractionContext);
  const clickHandler = event => {
    const features = map.getFeaturesAtPixel(event.pixel) || [];

    if (feature && !features.filter(_ => _.ol_uid === feature.ol_uid).length) {
      interactionContext.setDefaultMode();
    }
  };

  useEffect(() => {
    map.on("click", clickHandler);

    return () => {
      map.un("click", clickHandler);
    };
  }, []);

  if (feature) {
    return <ModifyFeatures features={[feature]} />;
  }

  return null;
}

export default ModifySelectedFeature;
