import { useContext } from "react";
import { MapContextType } from "@sw-sw/common";

/**
 * @return {import('ol').PluggableMap}
 */
export function useMap() {
  const c = useContext(MapContextType);

  if (c && c.map) {
    return c.map;
  }

  return null;
}

export default useMap;
