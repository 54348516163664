import { questionConfigValueApi } from "../utils/api/questionConfigValue";
import { useQuery } from "react-query";
import { QuestionConfigValue } from "@sw-sw/lib-inspection-templates";

export function useQuestionConfigValues(questionId: number) {
  return useQuery<Array<QuestionConfigValue>>(
    ["questionConfigValues", questionId],
    () => {
      return questionConfigValueApi.index(questionId);
    },
  );
}
