import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../Shared/form/modal/FormModal';
import { FormContext, FormContextProvider } from '@sw-sw/lib-form';
import { TooltipContextProvider } from '@sw-sw/lib-ui';
import userApi from '../../../utils/api/user';
import AppContext from '../../../contexts/AppContext';
import { AppDivisionContext } from '../../../contexts/AppDivisionContext';
import { useUserFormData } from '../../../hooks/userFormData';
import AddUser from '../Forms/AddUser';
import AddUserMultitenant from '../Forms/AddUserMultitenants';

function getPageContent(
  page,
  optionsData,
  setOptionsData,
  setMultiPageData,
  multiPageData,
  onSubmit,
) {
  return (
    <FormContextProvider key={page}>
      <AddUserMultitenant
        formOpts={optionsData}
        setOptionsData={setOptionsData}
        setMultiPageData={setMultiPageData}
        multiPageData={multiPageData}
        page={page}
        className={`add-user-multitenant-${page}`}
        onSubmit={onSubmit}
      />
    </FormContextProvider>
  );
}

function AddUserModalUI({
  onClose,
  onSubmit,
  setOptionsData,
  optionsData,
  initialValue,
  isEdit = false,
}) {
  const formContext = useContext(FormContext);

  const appStore = useContext(AppContext);

  const user = appStore.get('user');

  const [page, setPage] = useState(0);

  const [multiPageData, setMultiPageData] = useState([initialValue]);

  const handleCreateSubmit = () => {

    const newMultiPageData = [];

    if (formContext.value.tenantIds) {
      formContext.value.tenantIds.forEach((tenant) => {
        newMultiPageData.push({
          roleId: formContext.value.roleId,
          tenantId: tenant.id,
        });
      });

      newMultiPageData[0].divisionIds = formContext.value.allDivisionsIds;
    } else {
      multiPageData[0] = formContext.value;
    }

    const query = userApi.create({
      tags: formContext.value.tags.map((tag) => ({ email: tag.text })),
      payload: formContext.value.tenantIds ? newMultiPageData : multiPageData,
    });

    return query
      .then(onSubmit)
      .then(() => onClose())
      .catch((err) => {
        formContext.setError(err.message);
      });
  };

  const handleEditSubmit = () => {
    const userIdArr = window.location.pathname.split('/');
    const userId = userIdArr[4];

    const newMultiPageData = [];

    if (formContext.value.tenantIds) {
      formContext.value.tenantIds.forEach((tenant) => {
        newMultiPageData.push({
          roleId: formContext.value.roleId,
          tenantId: tenant.id,
        });
      });

      newMultiPageData[0].divisionIds = formContext.value.allDivisionsIds;
    } else {
      multiPageData[0] = formContext.value;
    }

    const query = userApi.addTenant({
      userId: userId,
      payload: formContext.value.tenantIds ? newMultiPageData : multiPageData,
    });

    return query
      .then(onSubmit)
      .then(() => onClose())
      .catch((err) => {
        formContext.setError(err.message);
      });
  };

  const handleDelete = () => {
    const newMultiPageData = [...multiPageData];

    newMultiPageData.splice(page, 1);

    setMultiPageData(newMultiPageData);

    setPage((prev) => prev - 1);
  };

  const handleNextPageSubmit = () => {
    if (page !== 0) {
      const obj = multiPageData[page];

      if (
        !obj.roleId ||
        !obj.tenantId ||
        !obj.divisionIds ||
        !obj.divisionIds.length
      ) {
        formContext.setError('Please fill out all required fields');

        return;
      }
    }

    formContext.validate().then((isValid) => {
      if (isValid) {
        setPage((prev) => prev + 1);
        formContext.setError(null);
      } else {
        formContext.setError('Please fill out all required fields');
      }
    });
  };

  const getActionChildren = () => {
    const children = [];

    if (
      user.roleName === 'Super Admin' &&
      page === multiPageData.length - 1 &&
      !isEdit
    ) {
      children.push(
        <button
          key='Add Tenant'
          onClick={() => handleNextPageSubmit()}
          className='add-tenant button-outline-dark'
        >
          Add Tenant
        </button>,
      );
    }
    if (page >= 1) {
      children.push(
        <button
          key='back'
          onClick={() => setPage((prev) => prev - 1)}
          className='reversed'
        >
          Back
        </button>,
        <button
          key='Remove Tenant'
          onClick={handleDelete}
          className='delete-tenant button-outline-dark'
        >
          Remove Tenant
        </button>,
      );
    }

    return children;
  };

  let modalProps = {};

  modalProps =
    page === multiPageData.length - 1
      ? {
          onSubmit: () => (isEdit ? handleEditSubmit() : handleCreateSubmit()),
          submitText: isEdit ? 'Add' : 'Create',
          showCancel: false,
        }
      : {
          onSubmit: () => handleNextPageSubmit(),
          submitText: 'Next',
          showCancel: false,
        };

  return (
    <TooltipContextProvider>
      <FormModal
        modalProps={{
          title: isEdit ? 'Add Tenant' : 'Add Users',
          classes: 'add-users',
          dismissOnEsc: true,
        }}
        onCancel={onClose}
        actionChildren={getActionChildren()}
        {...modalProps}
      >
        {page > 0 ? (
          getPageContent(
            page,
            optionsData,
            setOptionsData,
            setMultiPageData,
            multiPageData,
          )
        ) : (
          <AddUser
            formOpts={optionsData}
            setOptionsData={setOptionsData}
            page={0}
            isEdit={isEdit}
          />
        )}
      </FormModal>
    </TooltipContextProvider>
  );
}

function AddUserModal({
  onSubmit,
  onClose,
  isEdit = false,
  assignedTenants = [],
}) {
  const appStore = useContext(AppContext);

  const user = appStore.get('user');

  const [initialValue] = useState({
    roleId: '',
    divisionIds: [],
    clients: [],
    tags: [],
    tenantId: '', //user.tenantId,
  });

  const appDivisionContext = useContext(AppDivisionContext);

  const formDataQuery =
    appDivisionContext.appDivisionId &&
    useUserFormData(appDivisionContext.appDivisionId);

  const [loaded, setLoaded] = useState(false);

  // form options for selection of clients/roles/projects
  const [formData, setFormData] = useState({
    roles: formDataQuery.roles.data,
    tenants: formDataQuery.tenants.data,
  });

  useEffect(() => {
    if (!loaded && !formDataQuery.loading) {
      setFormData({
        roles: formDataQuery.roles.data,
        tenants: formDataQuery.tenants.data.filter(
          (c) => !assignedTenants.find((a) => a.id === c.id),
        ),
      });
      setLoaded(true);
    }
  }, [user, formDataQuery]);

  // only render when loaded and initial value is set
  if (!loaded || !initialValue) {
    return null;
  }

  return (
    <FormContextProvider initialValue={initialValue} key={'AddUserModalUI'}>
      <AddUserModalUI
        optionsData={formData}
        initialValue={initialValue}
        setOptionsData={(newData = {}) => {
          setFormData({ ...formData, ...newData });
        }}
        onSubmit={onSubmit}
        onClose={onClose}
        isEdit={isEdit}
        assignedTenants={assignedTenants}
      />
    </FormContextProvider>
  );
}

AddUserModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddUserModal;
