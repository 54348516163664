import React, { PropsWithChildren } from "react";
import { UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";
import Loading from "../components/Shared/ResourceIndex/Loading";
import { useClient, useClientProjects } from "../hooks/client";
import clientApi from "../utils/api/client";
import { Project } from "../utils/api/project";

export type iContextProviderProps = {
  addContacts: any;
  clientQuery: any;
  deleteContact: any;
  updateClient: any;
  client: any;
  clientProjects: Project[];
  contacts: any;
  contactIds: any;
};

const Context = React.createContext<iContextProviderProps>({
  addContacts: {},
  clientQuery: {},
  deleteContact: {},
  updateClient: {},
  client: {},
  clientProjects: [],
  contacts: {},
  contactIds: {},
});

export type Client = {
  id?: number;
  users: Array<{ id: number }>;
};

export const ClientStore: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const params = useParams<{ uuid: string }>();

  //const [clientProjects, setClientProjects] = useState([]);
  const clientQuery: UseQueryResult<Client> = useClient(parseInt(params.uuid));
  const clientProjectQuery = useClientProjects(parseInt(params.uuid));

  if (
    clientProjectQuery.isLoading ||
    clientQuery.isLoading ||
    !clientProjectQuery.data
  ) {
    return <Loading what="client" />;
  }

  const client: Client | undefined = clientQuery.data;

  const contacts = client ? client.users : [];
  const contactIds = contacts.map(_ => _.id);

  const updateClient = () => {
    clientQuery.refetch();
    clientProjectQuery.refetch();
  };

  // add contacts to data store
  const addContacts = () => {
    if (clientQuery && !clientQuery.isLoading) {
      clientQuery.refetch();
    }
  };

  const deleteContact = (id: number, userId: number) => {
    return clientApi.contacts.destroy(id, userId).then(() => {
      if (clientQuery && !clientQuery.isLoading) {
        clientQuery.refetch();
      }
    });
  };

  return (
    <Context.Provider
      value={{
        clientQuery,
        addContacts,
        deleteContact,
        updateClient,
        client,
        clientProjects: clientProjectQuery.data,
        contacts,
        contactIds,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
