/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { faEllipsisV, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { createRef, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useResource from "../../../hooks/resource";
import apiResources from "../../../utils/apiResources";
import List from "../List/List";
import ResourceSearch from "../Resource/Search";
import { Pagination } from "./../Pagination/Pagination";
import ResourceIndexError from "./Error";
import Loading from "./Loading";
import { AppDivisionContext } from "../../../contexts/AppDivisionContext";

/**
 * Index entities for button named resource
 *
 * Render some standard content pieces (and their respective modals)
 * 1. table or cards of entities (read & edit modals)
 * 2. search field
 * 3. cta to create entity (create modals)
 *
 * @todo pagination
 *
 */
const ResourceIndex = ({
  pageNameForStyling, //use this prop to separately target "Resource Index Wrapper" for each page

  resourceName,
  resourceTableHeaders,
  resourceIdKey,
  resourceSearchProps,

  enableClick,
  enableSearch,
  enableRead,
  enableEdit,
  enableOverflow,
  enableSelectAll,
  enableCallToAction,
  enableCallToFilter,
  enablePagination,
  hideCallToActionInMobile,

  renderEditor,
  renderReader,
  renderCallToAction,
  renderCallToFilter,
  renderRowCell,
  renderList: renderListProp,
  renderOverflow,

  searchInputPlaceholder,
  callToActionContent,
  callToFilterContent,
  resourceQueryProps = {},
}) => {
  const {
    pagination,
    search,
    selection,
    client,
    query: apiQuery,
    data,
    setData,
    loading,
  } = useResource({
    resource: resourceName,
    disablePagination: !enablePagination,
    ...resourceQueryProps,
  });
  const { page, setPage, paginationState, setPaginationState } = pagination;
  const { query, setQuery } = search;
  const { selected, setSelected } = selection;
  const { isError, error, refetch } = apiQuery;
  const [dataRows, setDataRows] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [showReader, setShowReader] = useState(false);
  const [showCallToAction, setShowCallToAction] = useState(false);
  const [showCallToFilter, setShowCallToFilter] = useState(false);
  const history = useHistory()
  const [coordinates, setCoordinates] = useState({
    bottom: null,
    right: null,
    left: null,
  });
  const [showPopper, setShowPopper] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const divisionId = useContext(AppDivisionContext).appDivisionId;

  const overflowRef = createRef();

  const onRowClick = (index) => {
    setSelected([index]);

    // order matters below! only read if edit is not enabled
    if (enableEdit) {
      setShowEditor(true);

      return;
    }

    if (enableRead) {
      setShowReader(true);

      return;
    }
  };

  const onSelect = (e) => {
    e.stopPropagation();
    if (selectedItems.includes(parseInt(e.target.value))) {
      const arr = [...selectedItems];
      const index = arr.indexOf(parseInt(e.target.value));

      if (index >= 0) {
        arr.splice(index, 1);
        setSelectedItems(arr);
      }
    } else {
      setSelectedItems([...selectedItems, parseInt(e.target.value)]);
    }
  };

  /** HOC to render resource modal with current data */
  const renderModal = useCallback(
    /**
     * @param {Function} renderFn modal rendering function
     */
    (renderFn, handleClose) => {
      if (!selected.length) {
        throw new Error('Cannot renderModal. Nothing is selected.');
      }

      const entity = data[selected[0]];

      /** Render element */
      return renderFn(
        entity,
        () => {
          setSelected([]);
          handleClose();
        },
        data,
        (id, val) => client.invalidateQueries(resourceName),
        refetch,
        setSelectedItems,
      );
    },
    [data, selected],
  );

  const renderCallToActionModal = useCallback(
    (renderFn, handleClose) => {
      /** Render element */
      return renderFn(
        () => handleClose(),
        refetch,
        data,
        setSelectedItems,
        selectedItems,
      );
    },
    [data, selectedItems],
  );

  const renderCallToFilterModal = useCallback(
    (renderFn, handleClose) => {
      /** Render element */
      return renderFn(
        () => handleClose(),
        refetch,
        data,
        setSelectedItems,
        selectedItems,
      );
    },
    [data, selectedItems],
  );

  const renderList = useCallback(() => {
    return renderListProp ? (
      renderListProp(dataRows, data, enableClick ? onRowClick : undefined)
    ) : (
      <List
        headers={resourceTableHeaders.map((_) => ({ label: _[0] }))}
        rows={dataRows}
        enableSelection={enableSelectAll}
        selected={selectedItems}
        selectAll={selectedItems.length === data.length ? true : false}
        onSelect={onSelect}
        loading={loading}
        onSelectAll={() => {
          if (selectedItems.length === data.length) {
            setSelectedItems([]);
          } else {
            setSelectedItems(data.map((_, $i) => _.id));
          }
        }}
        pageItems={resourceName}
      />
    );
  }, [dataRows, data, enableClick, onRowClick, renderListProp]);

  const handleOverflowClick = () => {
    const { right, bottom, left } = overflowRef.current.getBoundingClientRect();

    setCoordinates(
      Object.assign(
        { top: bottom },
        right < 100 ? { left } : { right: window.innerWidth - right },
      ),
    );
    setShowPopper(true);
  };

  /** Make rows for table layout, from filtered data */
  useEffect(() => {
    setDataRows(
      data.map((row, $d) => ({
        id: get(row, resourceIdKey),
        cells: resourceTableHeaders.map(([_, propName]) =>
          renderRowCell.apply(null, [row, propName]),
        ),
        handleClick: enableClick ? () => onRowClick($d) : undefined,
      })),
    );
  }, [data, renderRowCell]);

  if (isError) {
    return (
      <ResourceIndexError
        error={error.message}
        allowRetry
        onRetry={() => {
          if (enablePagination) {
            setPage(1);
          }

          if (query) {
            setQuery('');
          }

          client.invalidateQueries();
        }}
      />
    );
  }

  const searchProps = {
    query,
    setQuery(input) {
      setPage(1);
      setSelectedItems([]);
      setQuery(input);
    },
    placeholder: searchInputPlaceholder,
  };

  useEffect(() => {
    setSelectedItems([])
  },[divisionId])

  useEffect(() => {
    const params = new URLSearchParams()
    
    if (page) {
     params.append("page", page)
     history.push({search: params.toString()})
    } else {
    params.delete("page")
    }
  
  }, [page,history])

  return (
    <section className={`${pageNameForStyling}-resource-index-wrapper`}>
      <article className="resource-index">
        <header className="resource-index-header">
          {enableSearch && enableRead ? (
            <ResourceSearch
              local={!enablePagination}
              localSearch={
                !enablePagination
                  ? {
                      data,
                      setData,
                      attributes: resourceSearchProps,
                    }
                  : undefined
              }
              {...searchProps}
            />
          ) : null}

          {enableCallToAction && (
            <button
              className={classnames('primary', {
                'mobile-hidden': hideCallToActionInMobile,
              })}
              onClick={() => setShowCallToAction(true)}
              disabled={loading}
            >
              {['legendItems', 'inspectionTemplates'].includes(resourceName) && selectedItems.length
                ? `Copy ${callToActionContent}`
                : ['legendItems', 'inspectionTemplates'].includes(resourceName) && selectedItems.length === 0
                ? `Create ${callToActionContent}`
                : callToActionContent}
            </button>
          )}
          <div>
            {enableCallToFilter && (
              <button
                className='outline overflow'
                onClick={() => setShowCallToFilter(true)}
                disabled={loading}
              >
                <FontAwesomeIcon icon={faFilter} />
                {callToFilterContent}
              </button>
            )}

            {enableOverflow && (
              <button
                className='outline overflow'
                onClick={handleOverflowClick}
                ref={overflowRef}
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </button>
            )}
          </div>
        </header>
        <section className='resource-index-list'>
          {enableSelectAll && selectedItems.length ? (
            !paginationState && selectedItems.length === data.length ? (
              <span className='dri-select-text'>{`Selected all ${data.length} items`}</span>
            ) : (
              <span className='dri-select-text'>
                {`Selected ${selectedItems.length} of ${
                  paginationState ? paginationState.numModels : data.length
                } items`}
              </span>
            )
          ) : (
            enableSelectAll && <span className='dri-select-text'>&nbsp;</span>
          )}

          {loading || !dataRows ? (
            <Loading />
          ) : (
            <React.Fragment>
              {enableRead && renderList()}
              {enablePagination ? (
                <Pagination
                  page={page || 1}
                  setPage={setPage}
                  paginationState={paginationState}
                  setPaginationState={setPaginationState}
                />
              ) : null}
              {!enableRead && <p>Resource listing is not permitted.</p>}
            </React.Fragment>
          )}
        </section>

        {showReader &&
          selected.length &&
          renderModal(renderReader, () => setShowReader(false))}

        {showEditor &&
          selected.length &&
          renderModal(renderEditor, () => setShowEditor(false))}

        {showCallToAction &&
          renderCallToActionModal(renderCallToAction, () =>
            setShowCallToAction(false),
          )}
        
        {showCallToFilter &&
          renderCallToFilterModal(renderCallToFilter, () =>
            setShowCallToFilter(false),
        )}

        {showPopper && renderOverflow(coordinates, () => setShowPopper(false))}
      </article>
    </section>
  );
};

ResourceIndex.propTypes = {
  /** resource specification */

  resourceName: (props, propName) => {
    if (!props[propName]) {
      return new ResourceIndexError('ResourceIndex.resourceName is required');
    }
    if (!apiResources[props[propName]]) {
      return new ResourceIndexError(
        `ResourceIndex.resourceName ${props[propName]} is not supported`,
      );
    }
  },
  // @type {[string, string][]} list of (headerLabel, resourceEntityPropName) tuples
  resourceTableHeaders: (props, propName) => {
    if (!props[propName]) {
      return new ResourceIndexError(
        'ResourceIndex.resourceTableHeaders is required',
      );
    }
    if (!Array.isArray(props[propName])) {
      return new TypeError(
        'ResourceIndex.resourceTableHeaders must be an array',
      );
    }
    if (props[propName].length === 0) {
      return new TypeError(
        'ResourceIndex.resourceTableHeaders must not be empty',
      );
    }

    for (let $p = 0; $p < props[propName].length; $p++) {
      const header = props[propName][$p];

      if (!Array.isArray(header)) {
        return new TypeError(
          'Items inside ResourceIndex.resourceTableHeaders must be arrays',
        );
      }
    }
  },
  // string[] for active search properties in resource entities
  resourceSearchProps: PropTypes.arrayOf(PropTypes.string.isRequired),
  // string for primary key in resource entity
  resourceIdKey: PropTypes.string,

  /** functionality flags */

  // search bar, enableRead is required
  enableSearch: PropTypes.bool,
  // call to action. see prop renderCallToAction
  enableCallToAction: PropTypes.bool,
  // click on button resource item (see enableRead & enableEdit)
  enableCallToFilter: PropTypes.bool,
  // click on button resource filter (see enableRead & enableEdit)
  enableClick: PropTypes.bool,
  // when clicking, show the `renderReader` component
  enableRead: PropTypes.bool,
  // when clicking, show the `renderEditor` component When `enableEdit` is true, `enableRead` is ignored
  enableEdit: PropTypes.bool,
  // pagination for data fetching
  enablePagination: PropTypes.bool,
  // render overflow menu next to callToAction button
  enableOverflow: PropTypes.bool,

  /**
   * Rendering functions
   *
   * unless otherwise noted, parameters have the following types:
   *
   * @param {any} param0 The selected resource
   * @param {() => void)} param1 Function to handle closing
   * @param {any[]} param2 un-filtered data
   * @param {(id: string|number, data: Object|false) => void} param3 function to update or delete data by ID (see {updateOrDeleteById}).
   */
  // shown after clicking row, when editing is enabled
  renderEditor: PropTypes.func,
  // shown after clicking row, when reading is enabled. Ignored, when editing is enabled.
  renderReader: PropTypes.func,
  // shown after overflow button, when overflow enabled
  render: PropTypes.func,

  /**
   * shown after clicking call to action
   *
   * @param {() => void)} param0 Function to handle closing
   * @param {(...data: Object[]) => void} param1 function to push newly added data
   * @param {any[]} param2 un-filtered data
   */
  renderCallToAction: PropTypes.func,

  renderCallToFilter: PropTypes.func,

  /**
   * Optional custom list rendering function. Receives {dataRows} with rendered cells from {renderRowCell}.
   *
   * If unspecified, list is rendered with {shared/List}
   *
   * @param {{id: number|string, cells: Component[], handleClick: Function}} param0 row data, see {setDataRows}
   * @param {{id: number|string, cells: Component[], handleClick: Function}} param0 filtered raw data, see {setFilteredData}
   * @param {(index: nunbmer) => any} param2 click handler for each row. only when {enableClick} is true
   */
  renderList: PropTypes.func,

  /**
   * renders the content of each item
   *
   * @param {any} param0 The resource entity object for the current row
   * @param {string} param1 The name of the property in the entity object
   *
   */
  renderRowCell: PropTypes.func.isRequired,

  /** messaging */
  searchInputPlaceholder: PropTypes.string,
  callToActionContent: PropTypes.string,
  callToFilterContent: PropTypes.string,

  // props for resource hook
  resourceQueryProps: PropTypes.object,
};

ResourceIndex.defaultProps = {
  pageNameForStyling: "",
  resourceIdKey: "id",
  resourceSearchProps: ["id"],
  enableSearch: true,
  enableClick: true,
  enableRead: false,
  enableEdit: false,
  enableCallToAction: true,
  enableCallToFilter: false,
  enablePagination: false,
  enableOverflow: false,
  enableSelectAll: false,
  searchInputPlaceholder: 'Search',
  callToActionContent: 'New',
  callToFilterContent: 'Filter',
  renderEditor: () => null,
  renderReader: () => null,
  renderCallToAction: () => null,
  renderCallToFilter: () => null,
  renderOverflow: () => null,
  renderList: null,
  renderRowCell: (row, propName, selected) => get(row, propName),
};

export default ResourceIndex;
