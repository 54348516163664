import instance from "../xhrService"
import xhrService from "../api";

const baseUrl = "/api/inspection-intervals"

type InspectionIntervalsApi = {
  index: (inspectionIntervalIds?: Array<number>, isIncludeValueTrue?: any) => Promise<Array<any>>;
  fetch: (path?: any) => any;
  post: (path?: any, data?: any) => any;
  update: (path?: any, data?: any) => any;
  delete: (path?: any) => any;
  updateIncludeValue: (inspectionIntervalId?: number, data?: any) => Promise<Array<any>>;
  updateNextInspectionDateForIntervalId: (inspectionIntervalId?: number) => Promise<Array<any>>;
}

export const inspectionIntervalsApi: InspectionIntervalsApi = {
  index: (inspectionIntervalIds, isIncludeValueTrue) => {
    return instance
    .get(
      `${baseUrl}/intervalWithIncludeValueTrue`,
      inspectionIntervalIds
        ? {
          params: {
            inspectionIntervalIds,
            isIncludeValueTrue
          },
        }
        : {},
    )
    .then(res => {
      return res.data.data 
    })
  },
  fetch: (path) => {
    return xhrService.get(`/api${path}`).then(res => res.data);
  },
  post: (path,data) => {
    return xhrService.post(`/api${path}`,data).then(res => res.data);
  },
  update: (path,data) => {
    return xhrService.put(`/api${path}`,data).then(res => res.data);
  },
  delete: (path) => {
    return xhrService.delete(`/api${path}`).then(res => res.data);
  },
  updateIncludeValue: (inspectionIntervalId:any, data:any) => {
    return instance
    .put(
      `${baseUrl}/${inspectionIntervalId}/updateIncludeValue`,
      data
    )
    .then(res => {
      return res.data
    })
  },
  updateNextInspectionDateForIntervalId: (inspectionIntervalId:any) => {
    return instance
    .put(`${baseUrl}/${inspectionIntervalId}/updateNextInspectionDateForIntervalId`)
    .then(res => res.data)
  },
}

export default inspectionIntervalsApi
