import React, { PropsWithChildren, useState, useCallback, useEffect, useContext } from "react";
import { useTenantFeature } from "../hooks/features";
import AppContext from "./AppContext";

const Context = React.createContext<any>({
  tenantHasFeature: (_feature: string) => false,
  refetchTenantFeatures: () => {},
});

export const TenantFeatureContext: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const appStore = useContext(AppContext);
  const [tenantId, setTenantId] = useState<string | null>(null);
  const [enabledFeatures, setEnabledFeatures] = useState<string[]>([]);

  useEffect(() => {
    const user = appStore.get("user");
    
    if (user) {
      setTenantId(user.tenantId);
    }
  }, [appStore]);

  const tenantFeatureQuery = useTenantFeature();

  useEffect(() => {
    if (tenantFeatureQuery.isFetched && tenantFeatureQuery.data) {
      const features = tenantFeatureQuery.data.map((item: any) => item.feature.name);
      setEnabledFeatures(features);
    }
  }, [tenantFeatureQuery.isFetched, tenantFeatureQuery.data]);

  const tenantHasFeature = useCallback(
    (feature: string) => {
      if (!tenantId || !tenantFeatureQuery.isFetched) {
        return false;
      }
      return enabledFeatures.includes(feature);
    },
    [tenantId, enabledFeatures, tenantFeatureQuery.isFetched]
  );

  const refetchTenantFeatures = useCallback(() => {
    tenantFeatureQuery.refetch();
  }, [tenantFeatureQuery]);

  return (
    <Context.Provider
      value={{
        tenantHasFeature,
        refetchTenantFeatures,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
