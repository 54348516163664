import React, { useState, useEffect, useRef, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faCheckCircle, faEdit} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../../components/Shared/ResourceIndex/Loading";
import moment from "moment";

import { FormModal } from "../../../Shared/form";
import { FormContext, FormSchemaFields } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import projectApi from "../../../../utils/api/project";
import ReactTooltip from "react-tooltip";

interface Props{
  dailyPrecipitationQuery: any,
  formattedMinDate: any,
  formattedMaxDate: any,
  projectId: any
}

const DailyPrecipitation = (props: Props) => {
  const formContext = useContext(FormContext);
  const [clientIsAscending, setClientIsAscending] = useState(true)
  const [siteIsAscending, setSiteIsAscending] = useState(true)
  const widgetRef = useRef<any>(0);
  const {dailyPrecipitationQuery, formattedMinDate, formattedMaxDate, projectId} = props
  const [data, setData] = useState([])
  const [isActionModal, setIsActionModal] = useState(false)
  const [selectedSource, setSelectedSource] = useState<string | null>('')
  const [selectedIntervalDetails, setSelectedIntervalDetails] = useState({})

  let rainTotal = 0, snowTotal = 0;

  const sourceOptions = [
    {name: 'Tomorrow.io/NOAA'},
    {name: 'Online Weather Station'},
    {name: 'Other'}
  ]

  function getSchema(detail:any) {
    return {
      [(detail.rain_custom_reading === undefined || detail.rain_custom_reading === null) ? "rain_accumulation" : "rain_custom_reading"]: {
        label: 'Rain Total',
        controlType: UIControlType.text,
        autoComplete: 'organization1',
        validation: {
          required: true,
        },
      },
      [(detail.snow_custom_reading === undefined || detail.snow_custom_reading === null) ? "snow_accumulation" : "snow_custom_reading"]: {
        label: 'Snow Total',
        controlType: UIControlType.text,
        placeholder: '',
        autoComplete: 'address-line1',
        'aria-label': 'Street address line 1 of 2',
        validation: {
          required: true,
        },
      },
      source: {
        label: 'Source',
        controlType: UIControlType.select,
        placeholder: "Source",
        "aria-label": "State",
        autoComplete: "address-level1",
        options: sourceOptions,
        labelKey: 'name',
        valueKey: 'name',
        style: { flex: '1 1 50%' },
        validation: {
          required: true,
        },
      },
      note: {
        label: "Note",
        controlType: UIControlType.text,
        autoComplete: 'code',
        validation: {
          required: false,
        },
      },
    }
  }

  useEffect(() => {
    dailyPrecipitationQuery.data ? setData(dailyPrecipitationQuery && dailyPrecipitationQuery.data) : setData([])
  },[dailyPrecipitationQuery && dailyPrecipitationQuery.data])

  //SORT
  const sortByDateTime = () => {
    const updatedList = data.sort((a:any, b:any) => {
      let A = a.date ? a.date.toString().toLowerCase() : a.justDate.toString().toLowerCase();

      let B = b.date ? b.date.toString().toLowerCase() : b.justDate.toString().toLowerCase();

      if (siteIsAscending === true) {
        setSiteIsAscending(!siteIsAscending);

        return A > B ? -1 : 1; // descending
      } else {
        setSiteIsAscending(!siteIsAscending);

        return A > B ? 1 : -1; //ascending
      }
    });

    setData(updatedList);
  };

  const sortByRainAccData = () => {
    const updatedList = data.sort((a:any, b:any) => {
      let A = a.rain_accumulation;

      let B = b.rain_accumulation;

      if (clientIsAscending === true) {
        setClientIsAscending(!clientIsAscending);

        return A > B ? -1 : 1; // descending
      } else {
        setClientIsAscending(!clientIsAscending);

        return A > B ? 1 : -1; //ascending
      }
    });

    setData(updatedList);
  };

  const sortBySnowAccData = () => {
    const updatedList = data.sort((a:any, b:any) => {
      let A = a.snow_accumulation;

      let B = b.snow_accumulation;

      if (clientIsAscending === true) {
        setClientIsAscending(!clientIsAscending);

        return A > B ? -1 : 1; // descending
      } else {
        setClientIsAscending(!clientIsAscending);

        return A > B ? 1 : -1; //ascending
      }
    });

    setData(updatedList);
  };

  const handleSubmit = async(formData:any) => {
    setSelectedSource(formData.value)

    return await projectApi.updateOneDailyPrecipitationEntry(projectId, formData)
  }

  return (
    <>
      <div className="daily-precipitation" ref={widgetRef}>
        <div>
          <div className="daily-precipitation__body">
            <div className="daily-precipitation__content">
              <section className="daily-precipitation__list-view">
                <div className="daily-precipitation__table-head">
                  <h3 onClick={sortByDateTime}>Date <span><FontAwesomeIcon icon={faSort} /></span></h3>
                  <h3 onClick={sortByRainAccData}>Total Rain <span><FontAwesomeIcon icon={faSort} /></span></h3>
                  <h3 onClick={sortBySnowAccData}>Total Snow <span><FontAwesomeIcon icon={faSort} /></span> </h3>
                  <h3>Source</h3>
                  <h3>Notes</h3>
                  <h3>Action</h3>
                </div>

                {dailyPrecipitationQuery && !dailyPrecipitationQuery.isLoading ?
                  (data && data.map((ele:any, index:any) => {
                    const { justDate, note, rain_accumulation, snow_accumulation, rain_custom_reading, snow_custom_reading } = ele

                    const selectedRainReading = (ele.rain_custom_reading === undefined || ele.rain_custom_reading === null) ? rain_accumulation : rain_custom_reading
                    const selectedSnowReading = (ele.snow_custom_reading === undefined || ele.snow_custom_reading === null) ? snow_accumulation : snow_custom_reading

                    rainTotal = rainTotal + selectedRainReading
                    snowTotal = snowTotal + selectedSnowReading

                    return (
                      <div key={index} className="daily-precipitation__table-body">
                        {
                          ele.date ? 
                          <li>{moment(ele.date.slice(0,-1)).format('MM/DD/YYYY')}</li> :
                          <li>{moment(justDate).format('MM/DD/YYYY')}</li>
                        }
                        <li>{selectedRainReading}</li>
                        <li>{selectedSnowReading}</li>
                        <li>{selectedSource ? selectedSource : ele.source}</li>
                        <ReactTooltip place="top" type="dark" effect="float"/>
                        <li data-tip={`${note}`}>
                          {note.length > 20 ? `${note.substring(0, 20)}...` : note}
                        </li>
                        
                        <li>
                          <span>
                            <FontAwesomeIcon
                              icon={faEdit}
                              color="blue" 
                              onClick={() => {
                                setIsActionModal(true)
                                setSelectedIntervalDetails(ele)
                              }}
                            />
                          </span>
                        </li>
                      </div>
                      );
                    })
                  ) : (
                  <Loading what='data' />
                )}
              </section>
            </div>

            { isActionModal && 
              <FormModal
                modalProps={{
                  title: 'Edit Daily Precipitation',
                  submitBtnText: "Submit",
                }}
                onSubmit={async(formData) => {
                  await handleSubmit(formData)
                  await dailyPrecipitationQuery.refetch()
                  setIsActionModal(false)
                }}
                onCancel={() => setIsActionModal(false)}
              >
                <FormSchemaFields
                  schema={getSchema(selectedIntervalDetails)}
                  onChange={formContext.set}
                  formData={formContext.value}
                  initialFormData={selectedIntervalDetails ? selectedIntervalDetails : {}}
                />
              </FormModal>
            }


            <footer className="daily-precipitation__footer">
              <div className="daily-precipitation__footer-layout">
                <h3>{(formattedMinDate && formattedMaxDate) ? `${formattedMinDate} - ${formattedMaxDate}`: ''}</h3>
                <h3>Rain Total : {rainTotal.toFixed(2)}</h3>
                <h3>Snow Total : {snowTotal.toFixed(2)}</h3>
                <h3><span><FontAwesomeIcon icon={faCheckCircle} /></span> = User Verified Total -</h3>
                <h3>hourly totals not available for date(s)</h3>
              </div>              
            </footer>
          </div>
        </div>
      </div>
    </>
  )
}


export default DailyPrecipitation
