import React from "react";
import { Link } from "react-router-dom";

import Popper from "../../Shared/Popper/Popper";

const UserPopper = ({ handleClose, coordinates }) => {
  return (
    <Popper
      classes="user-profile-menu"
      coordinates={coordinates}
      handleClose={handleClose}
    >
      <ul>
        <Link to="/my-profile">
          <li className="pointer" onClick={handleClose}>
            My Profile
          </li>
        </Link>
        <Link to="/logout">
          <li className="pointer">Log Out</li>
        </Link>
      </ul>
    </Popper>
  );
};

export default UserPopper;
