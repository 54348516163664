import React, { useMemo, useState } from 'react';
import { getDownloadUrl } from '../../../utils/api/upload';
import { AnnotationDisplayModal } from '@sw-sw/lib-ui';

type FindingImageProps = {
  lightbox?: boolean;
  sources: any[];
  imageNumber?: any;
};

export default function FindingImage({
  lightbox,
  sources,
  imageNumber,
}: FindingImageProps) {
  const [showLb, setShowLb] = useState(false);
  const [lbIndex, setLbIndex] = useState(0);

  const thumbnailImageSrc = useMemo(
    () => getDownloadUrl(sources[0].guid),
    [sources],
  );

  const urls = useMemo(
    () => sources.map((src) => getDownloadUrl(src.guid)),
    [sources],
  );

  const handleLeftClick = () => {
    setLbIndex((prev) => (prev - 1 + urls.length) % urls.length);
  };

  const handleRightClick = () => {
    setLbIndex((prev) => (prev + 1) % urls.length);
  };

  return (
    <>
      <div
        className='finding-image-holder pointer'
        title='Click to enlarge image'
        onClick={
          lightbox
            ? (e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowLb(true);
              }
            : undefined
        }
      >
        <img
          src={thumbnailImageSrc}
          alt='thumbnail'
          className='finding-thumbnail'
        />

        {imageNumber > 1 && (
          <span className='image-number-text'>{`(+${
            imageNumber - 1
          } IMGs)`}</span>
        )}
      </div>
      <AnnotationDisplayModal
        key={lbIndex}
        src={urls[lbIndex]}
        onClose={() => setShowLb(false)}
        annotations={sources[lbIndex].annotations}
        show={showLb}
        isMultiple={urls.length > 1}
        onLeft={handleLeftClick}
        onRight={handleRightClick}
      />
    </>
  );
}

export { FindingImage };
