import { mapUtil } from '@sw-sw/common';
import PropTypes from 'prop-types';
import React, { Component, Fragment, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AppDivisionContext from '../../../contexts/AppDivisionContext';
import { ProjectContext } from '../../../contexts/ProjectContext';
import ProjectPermissionContext from '../../../contexts/ProjectPermissionContext';
import uploadApi from '../../../utils/api/upload';
import SiteMapCard from '../../Card/SiteMapCard';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import SuccessModal from '../../Shared/SuccessModal/SuccessModal';
import SiteMapPrint from '../../Inspections/Details/SiteMapPrint';
import PrintSiteMapsModal from '../Header/PrintSiteMapsModal';
import { ProjectPrintProvider } from '../../../contexts/ProjectPrintContext';
import AppContext from '../../../contexts/AppContext';
import _ from 'lodash';
import ResourceSearch from '../../Shared/Resource/Search';
import useResource from '../../../hooks/resource';

const { isSupportedMimeType } = mapUtil;
/**
 * Render cards for each site map in a project
 *
 * @todo optional canEdit
 * @todo generic 'map editor' route, at root of app, instead of route-per-map
 */
class SiteMapsUI extends Component {
  state = {
    selected: null,
    showConfirmation: false,
    showArchiveConfirmation: false,
    showRestoreConfirmation: false,
    showPrintConfirmation: false,
    targetMap: null,
    error: '',
    targetDoc: {},
    archiveView: false,
  };

  static contextType = AppDivisionContext;


  get editorProps() {
    const { selected } = this.state;

    const editorProps = {
      onClose: () => this.deselect(),
    };

    if (selected !== null) {
      Object.assign(editorProps, {
        map: selected,
        title: this.props.projectName,
        subtitle: selected.name,
      });
    }

    return editorProps;
  }
  handleCardClick = (selected, isPublic) => {
    this.setState({ selected }, () => {
      const { history, projectId, inspectionId, isActiveMap } = this.props;
      const ext = isActiveMap ? 'active-map' : 'inspection';

      if (isPublic) {
        history.push(
          this.context.getPublicPath(
            `/projects/${projectId}/map/${selected.GUID}/edit?from=${ext}${this.state.archiveView ? '&archive' : ''}`,
          ),
          {
            inspectionId: inspectionId,
          },
        );
      } else {
        history.push(
          this.context.getPath(
            `/projects/${projectId}/map/${selected.GUID}/edit?from=${ext}${this.state.archiveView ? '&archive' : ''}`,
          ),
          {
            inspectionId: inspectionId,
          },
        );
      }
    });
  };

  deselect = () => {
    this.setState({ selected: null }, () => {
      const { history, match } = this.props;

      history.push(this.context.getPath(match.url));
    });
  };

  handleDelete = () => {
    uploadApi.project
      .destroy(this.props.projectId, this.state.targetDoc.id)
      .then(() => {
        this.props.onDocumentDelete(this.state.targetDoc.GUID);
        this.setState({ showConfirmation: false });
      })
      .catch((err) =>
        this.setState({
          error:
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : 'An error has occurred',
        }),
      );
  };

  handleArchive = () => {
    uploadApi.project
      .archive(this.props.projectId, this.state.targetDoc.id, this.state.archiveView ? "Restore" : "Archive")
      .then(() => {
        if (this.state.archiveView) {
          const ele = this.props.archivesiteMaps.filter((arr) => arr.GUID === this.state.targetDoc.GUID)
          const index = this.props.archivesiteMaps.indexOf(ele[0])

          this.props.archivesiteMaps.splice(index, 1)

          this.props.setSiteMaps([...this.props.siteMaps, ele[0]])
          this.props.setArchiveSiteMaps(this.props.archivesiteMaps)
        } else {
          const ele = this.props.siteMaps.filter((arr) => arr.GUID === this.state.targetDoc.GUID)
          const index = this.props.siteMaps.indexOf(ele[0])

          this.props.siteMaps.splice(index, 1)

          this.props.setArchiveSiteMaps([...this.props.archivesiteMaps, ele[0]])
          this.props.setSiteMaps(this.props.siteMaps)
        }
        this.setState({ showArchiveConfirmation: false, showRestoreConfirmation: false });
      })
      .catch((err) =>
        this.setState({
          error:
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : 'An error has occurred',
        }),
      );
  };


  handleClick = () => {
    this.setState({ archiveView: !this.state.archiveView })
  }


  getImgSrc = _.memoize((doc) => {
    return uploadApi.getDownloadUrl(doc.GUID, 450);
  });

  render() {
    const isPublic = this.props.roleName === 'Public' || this.props.roleName === "Public No Image";



    return (
      <Fragment>
        {this.props.siteMaps && this.props.siteMaps.length ? (
          <div className='project-site-maps pure-g pure-g-with-gutters'>
            <div style={{ display: "flex", gap: "5px" }}>
              <ResourceSearch local={false} {...this.props.searchProps} />
              <button
                className='card-action pure-button button-outline-blue'
                style={{ width: '25%', marginLeft: "auto", minWidth: "130px" }}
                onClick={this.handleClick}
              >
                {this.state.archiveView ? "Active Site Maps" : "Archived Maps"}
              </button>
            </div>
            <div className='map-container'>
              {this.state.archiveView ? this.props.archivesiteMaps.filter((e) => {
                if (this.props.searchValue) {
                  const mapName = e.name.toLowerCase();
                  const searchValue = this.props.searchValue.query.toLowerCase()

                  return mapName.match(searchValue)
                }

                return e
              }).map((doc) => (
                <div className='pure-u-1 pure-u-md-1-3' key={doc.GUID}>
                  <SiteMapCard
                    imgSrc={this.getImgSrc(doc)}
                    title={doc.name}
                    label='Project'
                    handleImgClick={() => this.handleCardClick(doc, isPublic)}
                    handleDelete={() =>
                      this.setState({ showConfirmation: true, targetDoc: doc })
                    }
                    handleArchive={() =>
                      this.setState({ showRestoreConfirmation: true, targetDoc: doc })
                    }
                    handlePrint={() =>
                      this.setState({
                        showPrintConfirmation: true,
                        targetDoc: doc,
                      })
                    }
                    showActions={
                      this.props.projectPermissionContext.readOnly
                        ? false
                        : this.props.showActions
                    }
                    archiveView={true}
                  />
                </div>
              ))
                :
                this.props.siteMaps.filter((e) => {
                  if (this.props.searchValue) {
                    const mapName = e.name.toLowerCase();
                    const searchValue = this.props.searchValue.query.toLowerCase()

                    return mapName.match(searchValue)
                  }

                  return e
                }).map((doc) => (
                  <div className='pure-u-1 pure-u-md-1-3' key={doc.GUID}>
                    <SiteMapCard
                      imgSrc={this.getImgSrc(doc)}
                      title={doc.name}
                      label='Project'
                      handleImgClick={() => this.handleCardClick(doc, isPublic)}
                      handleDelete={() =>
                        this.setState({ showConfirmation: true, targetDoc: doc })
                      }
                      handleArchive={() =>
                        this.setState({ showArchiveConfirmation: true, targetDoc: doc })
                      }
                      handlePrint={() =>
                        this.setState({
                          showPrintConfirmation: true,
                          targetDoc: doc,
                        })
                      }
                      showActions={
                        this.props.projectPermissionContext.readOnly
                          ? false
                          : this.props.showActions
                      }
                    />
                  </div>
                ))}
            </div>
          </div>
        ) : isPublic ? (
          <p>No site maps have been uploaded.</p>
        ) : (
          <p>
            No site maps have been uploaded. Upload a site map{' '}
            <Link to={`/projects/${this.props.projectId}/project-docs`}>
              here
            </Link>
          </p>
        )
        }

        <ConfirmationModal
          show={this.state.showConfirmation}
          handleClose={() => this.setState({ showConfirmation: false })}
          handleConfirm={this.handleDelete}
          title='Are you sure you want to delete this site map?'
          subTitle='This can not be undone.'
          buttonText='Delete Site Map'
        />

        <ConfirmationModal
          show={this.state.showArchiveConfirmation}
          handleClose={() => this.setState({ showArchiveConfirmation: false })}
          handleConfirm={this.handleArchive}
          title='Are you sure you want to archive this site map?'
          subTitle='This will be only visible on the archive site map page'
          buttonText='Archive Site Map'
        />

        <ConfirmationModal
          show={this.state.showRestoreConfirmation}
          handleClose={() => this.setState({ showRestoreConfirmation: false })}
          handleConfirm={this.handleArchive}
          title='Are you sure you want to restore this site map?'
          buttonText='Restore Site Map'
        />

        {
          this.state.showPrintConfirmation && this.props.inspectionId > 0 && (
            <SiteMapPrint
              onClose={() => this.setState({ showPrintConfirmation: false })}
              targetDoc={this.state.targetDoc}
            />
          )
        }

        {
          this.state.showPrintConfirmation && !this.props.inspectionId && (
            <ProjectPrintProvider>
              <PrintSiteMapsModal
                onClose={() => this.setState({ showPrintConfirmation: false })}
                targetDoc={this.state.targetDoc}
              />
            </ProjectPrintProvider>
          )
        }

        <SuccessModal
          show={this.state.error.length > 0}
          handleClose={() => this.setState({ error: '' })}
          handleSubmit={() => this.setState({ error: '' })}
          submitBtnText='OK'
          message={this.state.error}
          title='Error replacing document'
          isAlert
        />
      </Fragment >
    );
  }
}

SiteMapsUI.propTypes = {
  /**
   * Uploads
   */
  siteMaps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      GUID: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  archivesiteMaps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      GUID: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  onDocumentDelete: PropTypes.func,
  onDocumentUpload: PropTypes.func,
  showActions: PropTypes.bool,
  inspectionId: PropTypes.number,
  isActiveMap: PropTypes.bool,
};

SiteMapsUI.defaultProps = {
  inspectionId: 0,
};

export const SiteMaps = ({ ...props }) => {
  const history = useHistory();
  const histUrlString = history.location.pathname;
  const urlReg = RegExp('active');
  const checkRegex = urlReg.test(histUrlString);
  const projectContext = useContext(ProjectContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const docTypes = projectContext.projectDocs.documentTypes.filter(
    (dType) => dType.name === 'Active Site Maps',
  );
  const docs = docTypes.length ? docTypes[0].documents : [];
  const [siteMaps, setSiteMaps] = useState(docs.filter((doc) => isSupportedMimeType(doc.mime_type) && !doc.archived_at))
  const [archivesiteMaps, setArchiveSiteMaps] = useState(docs.filter((doc) => isSupportedMimeType(doc.mime_type) && doc.archived_at))
  const auth = useContext(AppContext).get('user');

  const { search } = useResource({ resource: 'divisionProjects' });
  const { query, setQuery } = search;

  const searchProps = {
    query,
    setQuery(input) {
      setQuery(input);
    },
    placeholder: 'Search Active Site Maps..',
  };

  return (
    <SiteMapsUI
      {...props}
      history={history}
      siteMaps={siteMaps}
      archivesiteMaps={archivesiteMaps}
      projectId={projectContext.project.id}
      projectName={projectContext.project.name}
      projectPermissionContext={projectPermissionContext}
      isActiveMap={checkRegex}
      roleName={auth.roleName}
      searchProps={searchProps}
      searchValue={search}
      setSiteMaps={setSiteMaps}
      setArchiveSiteMaps={setArchiveSiteMaps}
    />
  );
};

export default SiteMaps;
