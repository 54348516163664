import React, { Component } from "react";
import { func, any, string, bool } from "prop-types";
import classnames from "classnames";
import "./_InspectionAdd.scss";

import inspectionApi from "../../utils/api/inspection";
import { ProjectContext } from "../../contexts/ProjectContext";
import { noop } from "lodash";
import ReactTooltip from "react-tooltip";
class InspectionAdd extends Component {
  static contextType = ProjectContext;
  
  static propTypes = {
    disabled: bool,
    reason: string,
    onInspectionAdd: func,
    className: any,
    type: string.isRequired,
  };

  static defaultProps = {
    className: "outline small",
    onInspectionAdd: noop,
  };

  state = {
    busy: false,
  };

  handleSubmit = () => {
    if (this.state.busy) {
      return;
    }

    const { onInspectionAdd, type, inspectionRefetch, teamManagementRefetch } = this.props;

    this.setState({ busy: true });

    return inspectionApi
      .create(this.context.project.id, type)
      .then(result => {
        this.setState({ busy: false });
        onInspectionAdd(result);
        inspectionRefetch();
        teamManagementRefetch();
      })
      .catch(err => {
        this.setState({ busy: false });

        /** @todo global app error handler */
        console.error(err);

        return Promise.reject(err);
      });
  };

  render() {
    const { className, disabled, reason } = this.props;
    const { busy } = this.state;

    return (
      <>
        <button
          className={classnames(className, "new-inspection")}
          onClick={!busy && !disabled ? this.handleSubmit : null}
          ref={ref => (this.buttonRef = ref)}
          data-tip={reason}
          onMouseOver={() => {
            if (reason) {
              ReactTooltip.show(this.buttonRef);
            }
          }}
        >
          New {this.props.type} Inspection
        </button>
        <ReactTooltip />
      </>
    );
  }
}

export default InspectionAdd;
