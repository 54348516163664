import { useContext } from "react";
import { QueryObserverResult, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { AppDivisionContext } from "../contexts/AppDivisionContext";
import divisionApi from "../utils/api/division";
import projectApi, { Project } from "../utils/api/project";

export function useProject(projectId: number): QueryObserverResult<Project> {
  const location = useLocation();

  const isArchive = location.pathname.includes("archive");
  const appDivisionContext = useContext(AppDivisionContext);

  const query = useQuery({
    queryKey: ["project", isArchive, projectId],
    queryFn: () =>
      isArchive
        ? divisionApi.projects.archive.get(
            appDivisionContext.appDivisionId!,
            projectId,
          )
        : projectApi.get(projectId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    refetchOnMount: true,
  });

  return query;
}
