import React from "react";
import { Button } from "@sw-sw/lib-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";

import "./_SelectAll.scss";

export default function SelectAll({
  value,
  label = "Select All Inspections",
  onChange,
}) {
  let icon = faSquare;

  if (value) {
    icon = faCheckSquare;
  }

  return (
    <div className="select-all">
      <Button
        className="outline primary select-all__button small"
        onClick={() => onChange(!value)}
        busy={"false"}
      >
        <FontAwesomeIcon icon={icon} className="select-all__icon" />
        &nbsp;
        <span>{label}</span>
      </Button>
    </div>
  );
}
