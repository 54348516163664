import { QuestionTypeConfigApi } from "@sw-sw/lib-inspection-templates";
import xhrService from "../api";

export const questionTypeConfigApi: QuestionTypeConfigApi = {
  index: () => {
    return xhrService
      .get(`/api/question-types-config`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
  update: arg => {
    return xhrService
      .put(`/api/question-types/${arg.questionTypeId}/config`, arg.data)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
};
