import { NewQuestion } from "@sw-sw/lib-inspection-templates";
import { useMutation, useQuery } from "react-query";
import { questionApi } from "../utils/api/question";

export function useQuestion(questionId: number) {
  return useQuery(["questions", questionId], () => {
    return questionApi.show(questionId);
  });
}

export function useCreateQuestion(groupId: number) {
  return useMutation((newQuestion: NewQuestion) => {
    return questionApi.create(groupId, newQuestion);
  });
}

export function useUpdateQuestion(id: number) {
  return useMutation((data: NewQuestion) => {
    return questionApi.update(id, data);
  });
}
