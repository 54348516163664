import React from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { node, string, shape, number, func } from "prop-types";

/**
 * renders children, at specified coordinates, around a styled container
 */
const Popper = props => {
  const { left, right, top, bottom } = props.coordinates;

  return ReactDOM.createPortal(
    <div
      className={classnames("popper", props.classes)}
      style={{ left, right, top, bottom }}
      onMouseLeave={props.handleClose}
    >
      {props.children}
    </div>,
    document.querySelector("#popper"),
  );
};

Popper.propTypes = {
  children: node.isRequired,
  classes: string,
  coordinates: shape({
    left: number,
    right: number,
    top: number,
    bottom: number,
  }).isRequired,
  handleClose: func.isRequired,
};

export default Popper;
