import React, { forwardRef, MouseEventHandler } from "react";
import { func, number } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

export interface INotificationBellProps {
  handleClick: MouseEventHandler;
  notificationCount: number;
  className?: string;
}

const NotificationBell = forwardRef<HTMLSpanElement, INotificationBellProps>(
  (props, ref) => {
    return (
      <span
        className="notification-bell pad-right pointer"
        onClick={props.handleClick}
        ref={ref}
      >
        <FontAwesomeIcon
          icon={faBell}
          className={classnames(
            { active: props.notificationCount > 0 },
            props.className,
          )}
        />
        {props.notificationCount > 0 && <span>{props.notificationCount}</span>}
      </span>
    );
  },
);

NotificationBell.propTypes = {
  handleClick: func.isRequired,
  notificationCount: number.isRequired,
};

export default NotificationBell;
