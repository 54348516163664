import React, { useEffect, useContext, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { ProjectContext } from '../../contexts/ProjectContext';
import projectApi from '../../utils/api/project';
/**
 * Top-level component to start the next inspection.
 *
 * @todo refactor {projectStore.startRoutineInspection} to not require project and inspections list
 */
function StartInspection({ match }) {
  const projectStore = useContext(ProjectContext);
  const [inspectionId, setInspectionId] = useState(null);


  useEffect(() => {
    // load the project
    projectApi.get(match.params.id).then(async (project) => {
      projectStore.setProject(project);
      projectStore.inspectionsQuery.refetch();
    });
  }, []);

  useEffect(() => {
    // get next inspection
    if (projectStore.inspectionsQuery.isFetched) {
      projectStore.startRoutineInspection().then((id) => {
        setInspectionId(id);
      });
    }
  }, [projectStore.inspectionsQuery.isFetched, projectStore.project]);

  if (inspectionId) {
    return <Redirect push to={`/inspection/${inspectionId}/questionnaire`} />;
  }

  return (
    <>
      {projectStore.project && (
        <div className='error-msg'>
          <span>{projectStore.errorMsg}</span>
          <Link to={`/projects/${projectStore.project.id}/inspections`}>
            <button className='error-button'>Back to Project</button>
          </Link>
        </div>
      )}
    </>
  );
}

export default StartInspection;
