import React, { useContext, useEffect, useState } from "react";
import { AppDivisionContext } from "../../contexts/AppDivisionContext";
import { RolesContext } from "../../contexts/RolesContext";
import { useDivisionUsers } from "../../hooks/users";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import FormModal from "../Shared/form/modal/FormModal";
import Loading from "../Shared/ResourceIndex/Loading";
import ContactForm, { getInitialData } from "./ContactForm";
import { Contact } from "../../utils/api/client";

export interface IAddContactModalProps {
  show: Boolean;
  hideModal: () => void;
  currentContactIds: Array<number>;
  handleAdd: (ids: Array<number>) => void;
}

const AddContactModal: React.FC<IAddContactModalProps> = ({
  show,
  hideModal,
  currentContactIds,
  handleAdd,
}) => {
  const appDivisionId = useContext(AppDivisionContext).appDivisionId;
  const roleStore = useContext(RolesContext);
  const [loaded, setLoaded] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<Array<Contact>>([]);
  const usersQuery = useDivisionUsers(appDivisionId!);

  useEffect(() => {
    if (!loaded) {
      roleStore.fetchRoles().then(() => setLoaded(true));
    }
  }, []);

  useEffect(() => {
    const { isLoading, data } = usersQuery;

    if (!isLoading && data && currentContactIds && currentContactIds.length) {
      setFilteredUsers(
        data.filter(user => {
          return !currentContactIds.includes(user.id);
        }),
      );
    } else {
      setFilteredUsers(data || []);
    }
  }, [usersQuery.isLoading, currentContactIds]);

  if (!loaded || usersQuery.isLoading) {
    return <Loading what="contact form" />;
  }

  if (!show) {
    return null;
  }

  const handleSubmit = (formData: Record<string, any>) => {
    const submitResult: any = handleAdd(
      formData.users.map((_: Contact) => _.id),
    );

    if (submitResult instanceof Promise) {
      return submitResult.then(() => {
        hideModal();
      });
    } else {
      hideModal();

      return Promise.resolve();
    }
  };

  return (
    <FormContextProvider initialValue={getInitialData()}>
      <FormContext.Consumer>
        {formContext => {
          return (
            <FormModal
              modalProps={{
                title: "Add a Contact",
                submitBtnText: "Add",
              }}
              onCancel={hideModal}
              onSubmit={() => handleSubmit(formContext.value)}
            >
              <ContactForm
                onChange={formContext.set}
                value={formContext.value}
                users={filteredUsers}
              />
            </FormModal>
          );
        }}
      </FormContext.Consumer>
    </FormContextProvider>
  );
};

export default AddContactModal;
