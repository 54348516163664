import xhrService from "../api";

export const dashboard = {
  fetch: (path) => {
    return xhrService.get(`/api${path}`).then(res => res.data);
  },
};

export const customDashboard = {
  fetch: (path) => {
    return xhrService.get(`/api${path}`).then(res => res.data);
  },
  fetchWithParams: (path, paramsObject) => {
    return xhrService.get(`/api${path}`, {
      params: paramsObject
    })
    .then(res => res.data);
  },
  post: (path,data) => {
    return xhrService.post(`/api${path}`,data).then(res => res.data);
  },
};

export default dashboard;
