import { TemplateQuestionGroupApi } from "@sw-sw/lib-inspection-templates";
import xhrService from "../api";

export const questionGroupApi: TemplateQuestionGroupApi = {
  index: templateId => {
    return xhrService
      .get(`/api/inspection-templates/${templateId}/question-groups`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },

  show: (inspectionTemplateId, id) => {
    return xhrService
      .get(
        `/api/inspection-templates/${inspectionTemplateId}/question-groups/${id}`,
      )
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },

  create: (inspectionTemplateId, data) => {
    return xhrService
      .post(
        `/api/inspection-templates/${inspectionTemplateId}/question-groups`,
        data,
      )
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },

  update: (inspectionTemplateId, id, data) => {
    return xhrService
      .put(
        `/api/inspection-templates/${inspectionTemplateId}/question-groups/${id}`,
        data,
      )
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },

  delete: (templateId: number, id: number) => {
    return xhrService
      .delete(`/api/inspection-templates/${templateId}/question-groups/${id}`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },

  swap: (
    templateId: number,
    questionGroupId1: number,
    questionGroupId2: number,
  ) => {
    return xhrService
      .put(`/api/inspection-templates/${templateId}/question-groups-swap`, {
        groupId1: questionGroupId1,
        groupId2: questionGroupId2,
      })
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
};
