import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
// import { bool, string, arrayOf } from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IModalProps, Modal } from '@sw-sw/lib-ui';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CheckmarkSVG from '../CheckmarkSVG';

interface ISuccessModalProps extends IModalProps {
  messages?: string[];
  message?: string;
  isAlert?: boolean;
  redirect?: boolean;
}

const SuccessModal: React.FC<ISuccessModalProps> = (props) => {
  const { messages, message, isAlert, redirect, ...rest } = props;
  const history = useHistory();

  return (
    <Modal disableClose {...rest}>
      <section className='success-modal'>
        {isAlert ? (
          <FontAwesomeIcon icon={faExclamationTriangle} className='alert' />
        ) : (
          <CheckmarkSVG />
        )}
        {messages && messages.map((m, index) => <p key={index}>{m}</p>)}
        {message && <p>{message}</p>}
        <br />
        {redirect && (
          <>
            <p>OR</p>
            <button className='primary' onClick={() => history.push('/login')}>
              Redirect To Login
            </button>
          </>
        )}
      </section>
    </Modal>
  );
};

export default SuccessModal;
