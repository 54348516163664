import React, {
  useState,
  MouseEventHandler,
  useContext,
  // useEffect,
} from 'react';
import inspectionTemplateApi from '../../utils/api/inspectionTemplate';
import InspectionTemplateDetailsForm from './Forms/InspectionTemplateDetailsForm';
import AppDivisionContext from '../../contexts/AppDivisionContext';
import { Redirect } from 'react-router-dom';
import { Tenant } from '../../utils/api/tenant';

export interface IInspectionTemplateAddProps {
  handleClose: MouseEventHandler;
  show?: boolean;
  enableRedirect?: boolean;
  tenants?: Tenant[];
}

const InspectionTemplateAdd: React.FC<IInspectionTemplateAddProps> = ({
  handleClose,
  show = true,
  enableRedirect = true,
}) => {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [redirectId, setRedirectId] = useState(0);
 

  const handleSubmit = (formState: any) => {
    return inspectionTemplateApi
      .create(formState)
      .then((data) => {
        if (enableRedirect) {
          setRedirectId(data.id);
        }

        return Promise.resolve();
      })
      .catch(() => console.error('Error creating Inspection Template'));
  };

  if (Boolean(redirectId)) {
    return (
      <Redirect
        to={`/divisions/${appDivisionId}/inspection-templates/${redirectId}`}
        push
      />
    );
  }

  if (show) {
    return (
      <InspectionTemplateDetailsForm
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        title='Add an Inspection Template'
        inspectionTemplate={{ divisionId: appDivisionId }}
      />
    );
  }

  return null;
};

export default InspectionTemplateAdd;
