import xhrService from "../xhrService";


const url = `/api/clients/docTypesList`;
const Projecturl = `/api/clients/projectTab`;


type docTypesApiTypes = {
    get: () => Promise<any>;
}

type projectTabsApiTypes = {
    get: () => Promise<any>;
}


export const docTypesApi: docTypesApiTypes = {
    get: () => {
        return xhrService.get(url)
            .then((res) => res.data)
    }
};

export const projectTabsApi: projectTabsApiTypes = {
    get: () => {
        return xhrService.get(Projecturl)
            .then((res) => res.data)
    }
};