import WebViewerClass, { Core, WebViewerInstance } from "@pdftron/webviewer";
import { MutableRefObject, useContext, useEffect, useState } from "react";
import { useTimeoutFn } from "react-use";
import ProjectContext from "../contexts/ProjectContext";
import { getDownloadUrl } from "../utils/api/upload";
import xfdfApi from "../utils/api/xfdf";
import { AnnotationApi, useAnnotationApi } from "./annotationApi";
import { useEnvConfig } from "./envConfig";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

type PdfEditorProps = {
  upload: { id: number; guid: string; canEdit: boolean; canDownload?: boolean; disableDownload?: boolean };
  ref: MutableRefObject<HTMLDivElement | null>;
  onClose: Function
};

export type EditorStatus = "loading" | "saving" | "loaded" | "saved";

/**
 * @param param0
 * @returns
 */

export function usePdfEditor({ upload, ref,
  onClose
}: PdfEditorProps): {
  status: EditorStatus;
} {
  const projectId = useProjectId();
  const annotationApi = useAnnotationApi(upload.id, projectId);
  const licenseKey = useEnvConfig("WEBVIEWER_KEY");
  const publicUrl = useEnvConfig("PUBLIC_URL");
  const [viewer, setViewer] = useState<null | WebViewerInstance>(null);
  const [annotationsLoaded, setAnnotationsLoaded] = useState(false);
  const [editorStatus, setEditorStatus] = useState<EditorStatus>("loading");
  const settleEditorStatus = useTimeoutFn(() => {
    if (viewer) {
      setEditorStatus("loaded");
    }
  }, 1000);


  useEffect(() => {
    if (ref.current && !viewer) {
      const element = ref.current;
      // console.log("licenseKey", licenseKey);
      WebViewerClass(
        {
          path: `${publicUrl}/libs/Webviewer`,
          licenseKey: licenseKey,
          extension: "pdf",
          isAdminUser: true,
          annotationUser: annotationApi.authorName,
          initialDoc: getDownloadUrl(upload.guid, null, true),
          // fullAPI: true,
          isReadOnly: !upload.canEdit,
        },
        element,
      ).then(viewerInstance => {
        setViewer(viewerInstance);
        const { annotationManager, documentViewer } = viewerInstance.Core;

        if (upload.disableDownload) {
          const { disableElements } = viewerInstance.UI;
          disableElements(['downloadButton', 'printButton'])
        };

        if (upload.canDownload) {
          documentViewer.addEventListener("annotationsLoaded", async () => {
            setAnnotationsLoaded(true);
            const annotation = await annotationApi.index()

            var str: any
            if (annotation && annotation.length > 0) {
              annotation.map((ele) => {
                str += ele.uploadId
              })
            } else {
              str = ""
            }

            const options = {
              filename: uuidv4(),
              xfdfString: str,
              flags: viewerInstance.Core.SaveOptions.LINEARIZED,
              downloadType: 'pdf',
              useDisplayAuthor: true,
            };

            viewerInstance.UI.downloadPdf(options).then(() => {
              toast("Download Completed")
              setTimeout(() => {
                onClose()
              }, 100)
            })
          })
        }
        else {
          annotationManager.addEventListener("fieldChanged", async () => {

            const xfdf = await annotationManager.exportAnnotationCommand()
            const mainStr = xfdf.replace(/\n/g, '')

            setEditorStatus("saving");


            await annotationApi.save({
              isCommand: true,
              key: uuidv4(),
              xfdfValue: mainStr,
              projectId,
            });

            setEditorStatus("saved");
            settleEditorStatus[2]();
          })


          annotationManager.addEventListener(
            "annotationChanged",
            (_annotations, _action, info) => {
              onAnnotationChange(
                viewerInstance,
                async model => {
                  setEditorStatus("saving");

                  await annotationApi.save(model);

                  setEditorStatus("saved");
                  settleEditorStatus[2]();
                },
                info,
                projectId,
              );
            },
          );

          documentViewer.addEventListener("annotationsLoaded", () => {
            setAnnotationsLoaded(true);
          });
        }


      });
    }
  }, []);

  useEffect(() => {
    if (annotationsLoaded && viewer) {
      console.log("before loadRemoteAnnotations");
      loadRemoteAnnotations(annotationApi, viewer).then(() => {
        setEditorStatus("loaded");
      });
    }
  }, [annotationsLoaded, viewer]);

  return { status: editorStatus };
}

export async function loadRemoteAnnotations(
  { data }: AnnotationApi,
  webViewerInstance: WebViewerInstance,
) {
  const annotationCommands = data.filter(d => d.isCommand);

  await Promise.all(
    annotationCommands.map(async row => {
      const annotations =
        await webViewerInstance.Core.annotationManager.importAnnotationCommand(
          row.xfdfValue,
        );
      await webViewerInstance.Core.annotationManager.drawAnnotationsFromList(
        annotations,
      );
    }),
  );
}

async function onAnnotationChange(
  webViewerInstance: WebViewerInstance,
  apiSave: AnnotationApi["save"],
  info: Core.AnnotationManager.AnnotationChangedInfoObject,
  projectId?: number,
) {
  if (!info.imported) {
    console.log("changed", info);

    await saveAnnotationCommands(
      webViewerInstance.Core.annotationManager,
      apiSave,
      projectId,
    );
  }
}

async function saveAnnotationCommands(
  manager: Core.AnnotationManager,
  apiSave: AnnotationApi["save"],
  projectId?: number,
) {
  const xfdfString = await manager.exportAnnotationCommand();
  const parser = new DOMParser();
  const commandData = parser.parseFromString(xfdfString, "application/xml");
  const addedAnnots = commandData.getElementsByTagName("add")[0];
  const modifiedAnnots = commandData.getElementsByTagName("modify")[0];
  const deletedAnnots = commandData.getElementsByTagName("delete")[0];
  const saveRequests: Promise<void>[] = [];

  console.log({ addedAnnots, modifiedAnnots, deletedAnnots });

  // List of added annotations
  addedAnnots.childNodes.forEach(child => {
    if (isElement(child) && hasTitleAttribute(child)) {
      const model = xfdfApi.getCommandModel(child, "add", projectId);

      if (model.key) {
        saveRequests.push(apiSave(model));
      }
    }
  });

  // List of modified annotations
  modifiedAnnots.childNodes.forEach(child => {
    if (isElement(child) && hasTitleAttribute(child)) {
      saveRequests.push(apiSave(xfdfApi.getCommandModel(child, "modify", projectId)));
    }
  });

  // List of deleted annotations
  deletedAnnots.childNodes.forEach(child => {
    if (isElement(child)) {
      saveRequests.push(apiSave(xfdfApi.getCommandModel(child, "delete", projectId)));
    }
  });

  await Promise.all(saveRequests);
}

function isElement(child: ChildNode): child is Element {
  return child.nodeType == Node.ELEMENT_NODE;
}

function hasTitleAttribute(element: Element) {
  return (element.getAttribute("title") || "").length > 0;
}

function useProjectId() {
  const { project } = useContext(ProjectContext);

  if (project && project.id) {
    return project.id;
  }

  return undefined;
}
