import { OptionShape } from '@sw-sw/lib-form';
import { Inspection } from '@sw-sw/lib-inspection-templates';
import { isNumber } from 'lodash';
import moment, { MomentInput } from 'moment';
import pluralize from 'pluralize';
import React, { PropsWithChildren } from 'react';
import { getInspectionTypeLabel } from '../utils';

export type PrintProjectFormData = Record<
  'start_date' | 'end_date' | 'inspectionIds' | 'timeFilter' | 'select_all',
  any
>;

export type InspectionOption = { value: number; label: string };
export type InspectionDateFilter = {
  prevMonths: number | null;
  start: Date | null;
  end: Date | null;
};

export type ProjectPrintContext = {
  getInspectionOptions: (
    allInspections: Inspection[],
    filter: InspectionDateFilter,
  ) => Array<InspectionOption>;
  getInspectionText: (args: {
    filter: InspectionDateFilter;
    inspections: InspectionOption[];
  }) => string;
  getSiteMapText: (args: {
    filter: InspectionDateFilter;
    inspections: InspectionOption[];
  }) => string;
  timeFilterOptions: OptionShape[];
  validateInspectionData: (
    formData: PrintProjectFormData,
  ) => PrintProjectFormData;
};

const throwError = () => {
  throw new Error('ProjectPrintProvider not loaded');
};

const Context = React.createContext<ProjectPrintContext>({
  getInspectionOptions: throwError,
  getInspectionText: throwError,
  timeFilterOptions: [],
  validateInspectionData: throwError,
  getSiteMapText: throwError,
});

export const ProjectPrintProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const extractOptions = (inspection: Inspection) => {
    return {
      value: inspection.id || 0,
      label: getInspectionTypeLabel(inspection),
    };
  };

  const getInspectionOptions = (
    inspections: Inspection[],
    filter: InspectionDateFilter,
  ): InspectionOption[] => {
    if (
      filter.prevMonths &&
      isNumber(filter.prevMonths) &&
      filter.prevMonths > 0
    ) {
      return inspections
        .filter((inspection) =>
          isDateInRange(
            inspection.created_date,
            moment().subtract(filter.prevMonths, 'months').toDate(),
            new Date(),
          ),
        )
        .sort(sortInspections)
        .map(extractOptions);
    }

    if (filter.start || filter.end) {
      return inspections
        .filter((inspection) =>
          isDateInRange(inspection.created_date, filter.start, filter.end),
        )
        .sort(sortInspections)
        .map(extractOptions);
    }

    return inspections.sort(sortInspections).map(extractOptions);
  };

  const sortInspections = (a: Inspection, b: Inspection) => {
    if (!a.created_date || !a.id || !b.created_date || !b.id) {
      throw new Error(
        'id and created date are required properties when sorting inspections.',
      );
    }

    if (a.created_date < b.created_date) {
      return 1;
    } else if (a.created_date > b.created_date) {
      return -1;
    } else {
      if (a.id < b.id) {
        return -1;
      } else {
        return 1;
      }
    }
  };

  const isDateInRange = (
    date: MomentInput,
    start?: MomentInput,
    end?: MomentInput,
  ): boolean => {
    if (start && end) {
      return moment(date).isBetween(start, end, null, '[]');
    }

    if (start && !end) {
      return moment(date).isSameOrAfter(start);
    }

    return moment(date).isSameOrBefore(end);
  };

  const getInspectionText = ({
    filter,
    inspections,
  }: {
    filter: InspectionDateFilter;
    inspections: InspectionOption[];
  }) => {
    const startDate = moment().subtract(filter.prevMonths, 'months');

    return inspections.length
      ? `${inspections.length} ${pluralize(
          'inspection',
          inspections.length,
        )} from ${startDate.format('MM-DD-YYYY')} to ${moment().format(
          'MM-DD-YYYY',
        )}`
      : '0 matching inspections';
  };

  const getSiteMapText = ({
    filter,
    inspections,
  }: {
    filter: InspectionDateFilter;
    inspections: InspectionOption[];
  }) => {
    const startDate = moment().subtract(filter.prevMonths, 'months');

    return inspections.length
      ? `${inspections.length} ${pluralize(
          'Version',
          inspections.length,
        )} from ${startDate.format('MM-DD-YYYY')} to ${moment().format(
          'MM-DD-YYYY',
        )}`
      : '0 matching Maps';
  };

  const timeFilterOptions = [
    {
      label: 'Past 3 months',
      value: 3,
    },
    {
      label: 'Past 6 months',
      value: 6,
    },
    {
      label: 'Past year',
      value: 12,
    },
    {
      label: 'Custom',
      value: 0,
    },
  ];

  const validateInspectionData = (formData: PrintProjectFormData) => {
    let validatedFormData = JSON.parse(JSON.stringify(formData));

    if (validatedFormData.inspectionIds) {
      validatedFormData.inspectionIds = Object.keys(
        formData.inspectionIds,
      ).filter((id) => {
        return formData.inspectionIds[id];
      });
    }

    return validatedFormData;
  };

  return (
    <Context.Provider
      value={{
        getInspectionOptions,
        timeFilterOptions,
        getInspectionText,
        getSiteMapText,
        validateInspectionData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
