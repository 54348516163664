import { camelCase } from "lodash";
import { useContext } from "react";
import { AppDivisionContext } from "../contexts/AppDivisionContext";
import { IApiQueryResource } from "./apiQuery";
import useResource, { ResourceHookProps, ResourceHook } from "./resource";

export function useDivisionResource<
  D extends IApiQueryResource = IApiQueryResource
>({ resource, divisionId, ...props }: ResourceHookProps): ResourceHook<D> {
  const { appDivisionId } = useContext(AppDivisionContext);
  const resourceProps: ResourceHookProps<D> = {
    resource: getDivisionResourceName(resource),
    ...props,
  };

  if (divisionId) {
    resourceProps.divisionId = divisionId;
  } else if (appDivisionId) {
    resourceProps.divisionId = appDivisionId;
  } else {
    throw new Error("Division must be selected in-app or passed as prop");
  }

  return useResource<D>(resourceProps);
}

export const getDivisionResourceName = (resource: string) => {
  return camelCase(`division-${resource}`);
};

export default useDivisionResource;
