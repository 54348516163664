import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuestionDetailForm from "./Forms/QuestionDetailForm";
import InspectionQuestionContext from "../../../contexts/InspectionQuestionContext";
import Loading from "../../Shared/ResourceIndex/Loading";

export interface IAddQuestionProps {
  order: number;
}

const AddQuestion: React.FC<IAddQuestionProps> = ({ order }) => {
  const inspectionQuestionContext = useContext(InspectionQuestionContext);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (newQuestion: any) => {
    setLoading(true);
    try {
      await inspectionQuestionContext.addQuestion(newQuestion);
    } catch (e) {
      console.error(e);
    }
    setShowForm(false);
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <div className="add-question" onClick={() => setShowForm(true)}>
        {loading ? <Loading /> : <FontAwesomeIcon icon="plus" />}
      </div>

      {showForm && (
        <QuestionDetailForm
          onCancel={() => setShowForm(false)}
          onSubmit={handleSubmit}
          title="Add a Question"
          order={order}
        />
      )}
    </React.Fragment>
  );
};

export default AddQuestion;
