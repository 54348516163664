import { Modal } from "@sw-sw/lib-ui";
import React, { useContext, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import { InspectionContext } from "../../../contexts/InspectionContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import inspectionQuestionReopeningLogApi from "../../../utils/api/inspectionQuestionReopeningLog";

export interface IInspectionReopenConfirmationModalProps {
  onClose: () => void;
}

const InspectionReopenConfirmationModal: React.FC<IInspectionReopenConfirmationModalProps> =
  ({ onClose }) => {
    const appContext = useContext(AppContext);
    const inspectionContext = useContext(InspectionContext);
    const projectStore = useContext(ProjectContext) as any;
    const [errorMsg, setErrorMsg] = useState<string>("");

    const onSubmit = async () => {
      return inspectionQuestionReopeningLogApi
        .update(inspectionContext.inspectionId)
        .then(data => {
          if(projectStore.template.name === "Maryland")    
            return inspectionContext.updateInspection({...data.inspection, certification_date: null})
          else return inspectionContext.updateInspection(data.inspection)
        })
        .then(() => {
          inspectionContext.signedComplianceCertQuery.refetch()
          appContext.triggerSuccessNotification();
          onClose();
        })
        .catch(error => setErrorMsg(error));
    };

    return (
      <Modal
        title="Edit Inspection Confirmation"
        subTitle="Are you sure that you want to open this inspection for editing? This will delete all compliance signatures and unlock the questions for editing. This action cannot be undone."
        show={true}
        submitBtnText="Confirm"
        handleClose={onClose}
        handleSubmit={onSubmit}
        cancelBtn={true}
        errorMsg={errorMsg}
      />
    );
  };

export default InspectionReopenConfirmationModal;
