import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Radio input, rendered with button group using pure.css
 */
export class ButtonGroup extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.string,
        iconComponent: PropTypes.func,
        iconProps: PropTypes.object,
        disabled: PropTypes.bool,
        active: PropTypes.bool,
        separatorAfter: PropTypes.bool,
      }),
    ).isRequired,
    activeIndex: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  onChange = (index, event) => {
    const { options, onChange } = this.props;
    const opt = options[index];

    /** @todo this doesn't make sense for all implementations */
    if (opt && (!opt.disabled || opt.active)) {
      onChange(index, event);
    }
  };

  render() {
    const { options, activeIndex } = this.props;

    return (
      <div className="pure-button-group">
        {options.map((option, $e) => {
          const c = classnames("pure-button", {
            "pure-button-active":
              (activeIndex !== null &&
                activeIndex !== undefined &&
                options[activeIndex].label === option.label) ||
              option.active,
            "button-transparent": option.icon || option.iconComponent,
            "button-disabled": !option.active && option.disabled === true,
            "button-separator-after": option.separatorAfter === true,
          });

          return (
            <div
              className={c}
              key={$e}
              onClick={event => this.onChange($e, event)}
              title={option.icon || option.iconComponent ? option.label : ""}
            >
              {option.icon ? (
                <FontAwesomeIcon
                  icon={option.icon}
                  {...(option.iconProps || {})}
                />
              ) : option.iconComponent ? (
                <option.iconComponent title={option.label} />
              ) : (
                <span>{option.label}</span>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default ButtonGroup;
