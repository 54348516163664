import React, { useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";

import { Select } from "ol/interaction";
import { pointerMove, never } from "ol/events/condition";
import { Context as InteractionContext } from "./../InteractionContext";
import useFeature from "../../hooks/useFeature";

export const Context = React.createContext();

/**
 * Reusable selection of map features, configured via props
 *
 * @todo Custom overlay style, based on map layers and/or feature geometry
 */
function SelectFeature(props) {
  const {
    condition,
    hitTolerance,
    selectedFeatures,
    multi,
    filterModeDataFeature,
  } = props;

  const interactionContext = useContext(InteractionContext);
  const feature = useFeature();

  const select = useRef(
    new Select({
      hitTolerance,
      multi,
      condition,
      toggleCondition: never,
    }),
  );

  const contextValue = {
    getSelectedFeature: () => {
      const x = select.current
        .getFeatures()
        .getArray()
        .slice(0, 1);

      return x.length ? x[0] : null;
    },
    // clearSelection: () => {
    //   select.current.getFeatures().clear();
    // },
  };

  useEffect(() => {
    interactionContext.addInteraction(select.current);

    return () => {
      select.current.getFeatures().clear();
      interactionContext.removeInteractions(x => x === select.current);
    };
  }, []);

  /** Add selected features from props */
  useEffect(() => {
    const features = select.current.getFeatures();

    features.clear();

    if (selectedFeatures) {
      const selectedIds = features.getArray().map(_ => _.ol_uid);

      selectedFeatures.forEach(feature => {
        if (selectedIds.indexOf(feature.ol_uid) === -1) {
          features.push(feature);
        } else {
          features.remove(feature);
        }
      });
    } else {
      select.current.getFeatures().clear();
    }
  }, [selectedFeatures]);

  useEffect(() => {
    select.current.filter_ = interactionFeature => {
      // only select tracked/controlled features (avoid selection overlay features)
      if (!interactionFeature.get("positionable_type")) {
        return;
      }

      // optionally ignore the feature in modeData
      if (filterModeDataFeature && feature) {
        return interactionFeature.ol_uid !== feature.ol_uid;
      }

      return true;
    };
  }, [feature, selectedFeatures]);

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
}

SelectFeature.propTypes = {
  multi: PropTypes.bool,
  condition: PropTypes.oneOfType([
    PropTypes.oneOf([never, pointerMove]),
    PropTypes.func,
  ]).isRequired,
  hitTolerance: PropTypes.number.isRequired,
  selectedFeatures: PropTypes.arrayOf(
    PropTypes.shape({
      ol_uid: PropTypes.string.isRequired,
    }).isRequired,
  ),
  children: PropTypes.node,
  /** Ignores the feature set in modeData in InteractionContext */
  filterModeDataFeature: PropTypes.bool,
};

SelectFeature.defaultProps = {
  hitTolerance: 0,
};

export default SelectFeature;
