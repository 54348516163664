import {
  FormContext,
  FormContextProvider,
  FormSchemaFields,
} from "@sw-sw/lib-form";
import React, { EventHandler } from "react";
import FormModal from "../form/modal/FormModal";

export interface IFileRenameModalProps {
  onSubmit: EventHandler<any>;
  onCancel: EventHandler<any>;
  file: {
    guid: any;
    name: string;
  };
  show?: Boolean;
}

const schema = {
  name: {
    label: "Name",
    validation: {
      required: true,
    },
  },
};

const FileRenameModal: React.FC<IFileRenameModalProps> = ({
  onSubmit,
  onCancel,
  file,
  show = false,
}) => {
  return (
    <React.Fragment>
      {show && (
        <FormContextProvider initialValue={{ name: file.name }}>
          <FormContext.Consumer>
            {formContext => (
              <FormModal
                onSubmit={async () => onSubmit(formContext.value.name)}
                onCancel={onCancel}
                modalProps={{
                  title: "Rename Document",
                  subTitle: `Enter a new name for ${file.name}`,
                }}
              >
                <FormSchemaFields
                  schema={schema}
                  onChange={formContext.set}
                  formData={formContext.value}
                  initialFormData={{ name: file.name }}
                />
              </FormModal>
            )}
          </FormContext.Consumer>
        </FormContextProvider>
      )}
    </React.Fragment>
  );
};

export default FileRenameModal;
