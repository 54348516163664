import React, { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { throttle } from "lodash";

/**
 * Update children classnames, when scrolling past it component
 *
 * @todo when window resizes, update yOffset and elHeight (responsive)
 *
 * @param {{elementRef: {current?: HTMLElement}}} param0
 */
function ScrollSpy({ className, children, elementRef, spacer }) {
  const [enabled, setEnabled] = useState(false);

  const yOffset = useRef(0);
  const elHeight = useRef(0);

  const throttledHandler = useCallback(
    throttle(() => setEnabled(window.pageYOffset >= yOffset.current), 200),
    [yOffset],
  );

  useEffect(() => {
    if (elementRef && elementRef.current) {
      const { bottom, height } = elementRef.current.getBoundingClientRect();

      yOffset.current = bottom;
      elHeight.current = height;
    }
  }, [elementRef]);

  useEffect(() => {
    window.addEventListener("scroll", throttledHandler);

    return () => window.removeEventListener("scroll", throttledHandler);
  }, [throttledHandler]);

  return enabled ? (
    <React.Fragment>
      {React.Children.map(children, child => {
        return React.isValidElement(child)
          ? React.cloneElement(child, {
              className: enabled ? className : "",
            })
          : null;
      })}
      {spacer && (
        <div
          className="scrollspy-spacer"
          style={{ height: elHeight.current }}
        />
      )}
    </React.Fragment>
  ) : (
    children
  );
}

ScrollSpy.propTypes = {
  /** A classnaem to apply on the children, when ScrollSpy is `enabled` */
  className: PropTypes.any.isRequired,
  /** A reference to the element to spy on */
  elementRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  /** optional spacer when ScrollSpy is `enabled` */
  spacer: PropTypes.bool,
};

ScrollSpy.defaultProps = {
  className: "scrolled-past",
  spacer: false,
};

/**
 * HOC to place component at bottom of screen, when scrolling past
 *
 * By default, supports an element positioned "in the flow" of the document (via `spacer` prop)
 *
 * @note for accurate height calculation, the element should not have any top/bottom margin
 */
function ScrollFixHOC(Component) {
  return ({ spacer = true, ...props }) => {
    const ref = useRef();

    return (
      <ScrollSpy className="affix-bottom" elementRef={ref} spacer={spacer}>
        <Component ref={ref} {...props} />
      </ScrollSpy>
    );
  };
}

export default ScrollSpy;
export { ScrollSpy, ScrollFixHOC };
