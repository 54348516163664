import React, { useContext, useState } from "react";
import ClientDetailsForm from "./Forms/ClientDetailsForm";
import clientApi, { Client } from "../../utils/api/client";
import { Redirect } from "react-router-dom";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import { useQuery } from "react-query";
import { docTypesApi, projectTabsApi } from "../../utils/api/docTypes";

export interface IClientAddProps {
  onAdd?: (client: Client) => void;
  show?: boolean;
  handleClose: () => void;
  enableRedirect?: boolean;
}

const ClientAdd: React.FC<IClientAddProps> = ({
  onAdd,
  show = true,
  handleClose,
  enableRedirect = true,
}) => {
  const [redirectClientId, setRedirectClientId] = useState<number | null>(null);
  const { getPath } = useContext(AppDivisionContext);
  const [ isNextPage, setIsNextPage ] = useState(false)
  const [ newDocTypes, setNewDocTypes ] = useState([])
  const [toSubmitOrderData, setToSubmitOrderData] = useState<any>({addRows: [], deleteRows: [], remainingDocTypes: []})
  const docTypesQuery = useQuery("docTypesData", () => docTypesApi.get(), {
    refetchOnWindowFocus: false,
  })

  const projectTabQuery = useQuery("ProjectTabList", () => projectTabsApi.get(), {
    refetchOnWindowFocus: false,
  })

  const setInitialTabValue = (projectTabs: any) => {
    delete projectTabs[1]
    
    return projectTabs.map((tabs: any) => {
      return {
        ...tabs,
        isChecked: true
      }
    })

  }

  function handleSubmit(formState: any) {
    return clientApi.create({...formState, newDocTypes: newDocTypes, toSubmitOrderData: toSubmitOrderData}).then(client => {

      if (typeof onAdd === "function") {
        onAdd(client);
      }

      if (enableRedirect === true) {
        setRedirectClientId(client.id);
      }

      return Promise.resolve(client);
    });
  }

  const handleNext = () => {
    setIsNextPage(true)
  }

  const handleBack = () => {
    setIsNextPage(false)
  }

  if (redirectClientId) {
    return <Redirect to={getPath(`/clients/${redirectClientId}`)} push />;
  }

  return (
    <>
      {
        isNextPage ? 
        <ClientDetailsForm
          handleSubmit={async (formData) => {
            await handleSubmit(formData)
          }}
          show={show}
          handleClose={handleClose}
          title="Create New Client"
          docTypes={docTypesQuery.data}
          nextBtn={true}
          isNextPage={isNextPage}
          handleBack={handleBack}
          newDocTypes={newDocTypes}
          setNewDocTypes={setNewDocTypes}
          projectTabs={setInitialTabValue(projectTabQuery.data)}
          toSubmitOrderData={toSubmitOrderData}
          setToSubmitOrderData={setToSubmitOrderData}
        /> :
        <ClientDetailsForm
          handleSubmit={handleSubmit}
          show={show}
          handleClose={handleClose}
          title="Create New Client"
          nextBtn={true}
          isNextPage={isNextPage}
          handleNext={handleNext}
        />
      }
    </>
  );
};

export default ClientAdd;
