import React, { MouseEventHandler, ReactNode } from "react";
import { Modal } from "@sw-sw/lib-ui";

export interface IResourceDetailDeleteProps {
  resource: string;
  handleSubmit: MouseEventHandler;
  confirmationMsg: ReactNode;
  show: boolean;
  errorMsg: string;
  hideModal: MouseEventHandler;
  showModal: MouseEventHandler;
  disableDelete: boolean;
  toggleDisabled: MouseEventHandler;
  icon?: boolean
}

const ResourceDetailDelete: React.FC<IResourceDetailDeleteProps> = ({
  resource,
  handleSubmit,
  confirmationMsg,
  show,
  errorMsg,
  hideModal,
  showModal,
  disableDelete,
  toggleDisabled,
  icon=true
}) => {
  const customButtons = (
    <div className="buttons">
      <button className="reversed outline" type="button" onClick={hideModal}>
        Cancel
      </button>
      &nbsp;
      <button
        className="warn reversed"
        onClick={handleSubmit}
        disabled={disableDelete}
      >
        Delete
      </button>
    </div>
  );

  return (
    <React.Fragment>
      {icon && <button className="icon-only-button outline warn" onClick={showModal}>
        <i className="fa fa-trash" />
      </button>}
      <Modal
        handleSubmit={handleSubmit}
        show={show}
        handleClose={hideModal}
        errorMsg={errorMsg}
        title={`Delete ${resource}`}
        hideButtons={true}
      >
        <h4>Warning: This cannot be undone</h4>
        <p>{confirmationMsg}</p>
        <p>
          <input
            type="checkbox"
            id="proceed"
            name="proceed"
            onClick={toggleDisabled}
          />
          <label htmlFor="proceed">Yes, I understand</label>
        </p>
        {customButtons}
      </Modal>
    </React.Fragment>
  );
};

export default ResourceDetailDelete;
