import instance from "../xhrService";
import { Address, State } from "./address";
import { Project } from "./project";

const baseUrl = "/api/clients";
const geoDataUrl = `${baseUrl}/geoData`;
const getCountryListUrl = `${baseUrl}/getCountryList`;
const getClientsDocTypesUrl = `${baseUrl}/getClientDocTypes`;
const getClientsProjectTabsUrl = `${baseUrl}/getClientProjectTabs`;
const getClientsUrl = (a: number) => `${baseUrl}/${a}`;
const getContactsUrl = (a: number, b?: number) =>
  `${baseUrl}/${a}/contacts${b ? `/${b}` : ""}`;

export type Contact = {
  id: number;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
};

export type Client = {
  id: number;
  name: string;
  default_finding_observation?: string;
  users: Array<any>;
  address: Address;
  precipitation_client_project_flag: boolean;
  custom_tab: boolean,
  tenant_id?: number
};

export type ClientDoc = {
  client_doc_type_id: string;
  client_dt: object;
  id: number;
  is_checked: boolean;
  name: string
}

type ClientApi = {
  index: (clientIds?: Array<number>) => Promise<Array<Client>>;
  show: (clientId: number) => Promise<Client>;
  create: (data: Omit<Client, "id">) => Promise<Client>;
  update: (clientId: number, data: Omit<Client, "id">) => Promise<Client>;
  destroy: (clientId: number) => Promise<any>;
  geoData: {
    index: () => Promise<Array<State>>;
  };
  getCountryList: {
    index: () => Promise<Array<State>>;
  };
  projects: {
    index: (clientId: number) => Promise<Array<Project>>;
    updateProjectPrecipitationDetails: (id: number, data: any) => Promise<Project>;
  };
  contacts: {
    create: (clientId: number, userIds: Array<number>) => Promise<any>;
    destroy: (clientId: number, userId: number) => Promise<any>;
  };
  getClientsDocTypes: {
    index: (clientId: number | null) => Promise<Array<ClientDoc>>;
    forProjects: (clientId: number | null) => Promise<Array<ClientDoc>>;
  };
  addNewClientDocTypes: {
    index: (clientId: number, data: any) => Promise<Array<State>>
  };
  deleteCustomClientDocType: {
    index: (clientId: number, data: any) => Promise<Array<any>>
  };
  editCustomClientDocType: {
    index: (clientId: number, id: any, selectedDocTypeName: any) => Promise<Array<any>>
  };
  reorderClientDocType: {
    index: (actionType: string, clientId: number | null, data: any) => Promise<Array<any>>
  };
  getClientsProjectTab: {
    index: (clientId: number) => Promise<Array<State>>;
  };
};

export const clientApi: ClientApi = {
  /**
   * By default route will return all clients.
   * Pass an array of client ids to retrieve only those clients
   */
  index: clientIds => {
    return instance
      .get(
        baseUrl,
        clientIds
          ? {
            params: {
              clientIds,
            },
          }
          : {},
      )
      .then(res => res.data);
  },
  show: clientId => {
    return instance.get(getClientsUrl(clientId)).then(res => res.data);
  },
  create: data => instance.post(baseUrl, data).then(res => res.data),
  update: (clientId, data) => {
    return instance.put(getClientsUrl(clientId), data).then(res => res.data);
  },
  destroy: clientId => {
    return instance.delete(getClientsUrl(clientId)).then(res => res.data);
  },

  geoData: {
    index: () => instance.get(geoDataUrl).then(res => res.data),
  },

  getCountryList: {
    index: () => instance.get(getCountryListUrl).then(res => res.data),
  },

  projects: {
    index: clientId =>
      instance.get(`/api/clients/${clientId}/projects`).then(res => res.data),

    updateProjectPrecipitationDetails: (id: number, data: any) => {
      return instance.put(`/api/clients/updateProjectPrecipitationDetails/projects/${id}`, data).then(res => res.data);
    }
  },

  contacts: {
    /**
     * @param {number} clientId
     * @param {number[]} userIds
     */
    create: (clientId, userIds) => {
      return instance
        .post(getContactsUrl(clientId), { userIds })
        .then(res => res.data);
    },

    destroy: (clientId, userId) => {
      return instance
        .delete(getContactsUrl(clientId, userId))
        .then(res => res.data);
    },
  },

  getClientsDocTypes: {
    index: (clientId) => instance.get(`${getClientsDocTypesUrl}/${clientId}`).then(res => {
      return res.data
    }),
    forProjects: (clientId) => instance.get(`${getClientsDocTypesUrl}/${clientId}`).then(res => {
      return res.data.length && res.data.filter((ele: any) => ele.is_checked)
    }),
  },

  addNewClientDocTypes: {
    index: (clientId, data) => instance.post(`${baseUrl}/${clientId}/customClientDocType`, data).then(res => {
      return res.data
    }),
  },

  deleteCustomClientDocType: {
    index: (clientId, docTypeId) => instance.delete(`${baseUrl}/${clientId}/customClientDocType/${docTypeId}`).then(res => {      
      return res.data
    }),
  },

  editCustomClientDocType: {
    index: (clientId, docTypeId, selectedDocTypeName) => instance.put(`${baseUrl}/${clientId}/customClientDocType/${docTypeId}`, {updatedName: selectedDocTypeName}).then(res => {            
      return res.data
    }),
  },

  reorderClientDocType: {
    index: (actionType, clientId, data) => instance.put(`${baseUrl}/${clientId}/reorderClientDocType`, { actionType: actionType, data: data }).then(res => {            
      return res.data
    }),
  },
  
  getClientsProjectTab: {
    index: (clientId) => instance.get(`${getClientsProjectTabsUrl}/${clientId}`).then(res => res.data),
  },
};

export default clientApi;
