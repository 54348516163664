import xhrService from "../xhrService";
import { ReopenFindingApi } from "@sw-sw/lib-certification";

export const inspectionFindingReopeningLogApi: ReopenFindingApi = {
  show: (findingId: number) =>
    xhrService
      .get(`/api/findings/${findingId}/open`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      }),
  update: (findingId: number) =>
    xhrService
      .post(`/api/findings/${findingId}/open`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      }),
};

export default inspectionFindingReopeningLogApi;
