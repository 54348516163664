import React from "react";

import { FormSchemaFields } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";

const noteFormSchema = {
  title: {
    label: "Title",
    validation: {
      required: true,
      maxLength: 100,
    },
  },
  content: {
    label: "Notes",
    controlType: UIControlType.textarea,
    validation: {
      required: true,
      maxLength: 500,
    },
  },
};

type noteValue = {
  title?: string;
  content?: string;
};

export interface INoteFormProps {
  onChange: () => void;
  value: noteValue;
  initialValue: noteValue;
}

const NoteForm: React.FC<INoteFormProps> = ({
  onChange,
  value,
  initialValue,
}) => {
  return (
    <FormSchemaFields
      className="commentable-note-form"
      schema={noteFormSchema}
      initialFormData={initialValue}
      formData={value}
      onChange={onChange}
    />
  );
};

export default NoteForm;
