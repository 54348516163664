import IconEditorTool from "./IconEditorTool";

const config = {
  initialState() {
    return {};
  },
};

class SelectTool extends IconEditorTool {
  label = "Select";
  helpText = "Click to select an object";
  state = config.initialState();

  /**
   * When the tool is selected from the toolbar
   */
  handleSelected() {
    this.canvas
      .forEachObject(o => {
        o.selectable = true;
        o.evented = true;
      })
      .renderAll();
  }

  /**
   * When tool is deselected
   */
  handleDeselected() {
    this.canvas.discardActiveObject().renderAll();

    this.canvas
      .forEachObject(o => {
        o.selectable = false;
        o.evented = false;
      })
      .renderAll();
  }

  // update data in canvas selected object
  handleUpdateActiveObject(field, value) {
    const obj = this.canvas.getActiveObject();

    if (obj && obj.get(field) !== value) {
      obj.set(field, value);
      this.canvas.renderAll();
    }
  }

  //
}

export default SelectTool;
