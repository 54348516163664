import React from "react";
import { ColorInput } from "../../../FormInput/Color";
import BaseShapeEditorField from "./BaseField";

export default function Color({
  initialValue,
  value,
  label,
  onChange,
  ...props
}) {
  return (
    <BaseShapeEditorField
      label={label}
      onChange={onChange}
      initialValue={initialValue}
    >
      {() => (
        <ColorInput
          onChange={onChange}
          value={value || initialValue || ""}
          {...props}
        />
      )}
    </BaseShapeEditorField>
  );
}
