import React from "react";
import {
  ProjectPermissionContext,
  ProjectPermissionContextProvider,
} from "../contexts/ProjectPermissionContext";

export function withProjectPermissionContext<T>(
  WrappedComponent: React.ComponentType<T>,
) {
  return (props: T) => (
    <ProjectPermissionContext.Consumer>
      {projectPermissionContext => (
        <WrappedComponent
          projectPermissionContext={projectPermissionContext}
          {...props}
        />
      )}
    </ProjectPermissionContext.Consumer>
  );
}

export function withProjectPermissionProvider<T>(
  Component: React.ComponentType<T>,
) {
  return (props: T) => (
    <ProjectPermissionContextProvider>
      <Component {...props} />
    </ProjectPermissionContextProvider>
  );
}

export default withProjectPermissionContext;
