import React from "react";
import PropTypes from "prop-types";

import StatusColumnButton from "./StatusColumnButton";
import upperCase from "lodash/upperCase";
import capitalize from "lodash/capitalize";
import lowerCase from "lodash/lowerCase";

/**
 * Map of `user.status` ENUM values
 * @see {@sw-sw/backend/models/users.js}
 */
export const USER_STATUSES = {
  invited: "INVITED",
  active: "ACTIVE",
  inactive: "INACTIVE",
};

const renderStatusWithoutAction = (status, className) => {
  return <span className={className}>{status}</span>;
};

/**
 * Render the table cell for user.status
 */
export default function UserStatus({
  user,

  isBusy,

  onInvite,
  onToggleActivation,

  canInvite,
  canToggleActivation,
}) {
  const userStatus = upperCase((user.userTenantRoles && user.userTenantRoles[0].status) || user.status);
  const formattedStatus = capitalize(lowerCase((user.userTenantRoles && user.userTenantRoles[0].status) || user.status));

  const buttonProps = {
    className: "users-index-user-status",
    isBusy,
    statusText: formattedStatus,

    onClick: undefined,
    icon: undefined,
    title: undefined,
  };

  // update button props, depending on status
  switch (userStatus) {
    case USER_STATUSES.invited:
      if (!canInvite) {
        return renderStatusWithoutAction(
          formattedStatus,
          buttonProps.className,
        );
      }

      Object.assign(buttonProps, {
        title: "Re-Invite User",
        icon: "envelope",
        onClick: () => onInvite(),
      });

      break;

    case USER_STATUSES.active:
      if (!canToggleActivation) {
        return renderStatusWithoutAction(
          formattedStatus,
          buttonProps.className,
        );
      }

      Object.assign(buttonProps, {
        title: "Deactivate User",
        icon: "toggle-on",
        onClick: () => onToggleActivation(USER_STATUSES.inactive),
      });

      break;

    case USER_STATUSES.inactive:
      if (!canToggleActivation) {
        return renderStatusWithoutAction(
          formattedStatus,
          buttonProps.className,
        );
      }

      Object.assign(buttonProps, {
        title: "Activate User",
        icon: "toggle-off",
        onClick: () => onToggleActivation(USER_STATUSES.active),
      });

      break;

    default:
      // exit early with default rendering function
      return renderStatusWithoutAction(formattedStatus, buttonProps.className);
  }

  // render action button
  return <StatusColumnButton {...buttonProps} />;
}

UserStatus.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  isBusy: PropTypes.bool,

  // actions
  onInvite: PropTypes.func.isRequired,
  onToggleActivation: PropTypes.func.isRequired,

  // permissions
  canInvite: PropTypes.bool,
  canToggleActivation: PropTypes.bool,
};
