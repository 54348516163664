import React from 'react';
import DashTable from '../utils/DashTable';
import { arrayOf, shape, string, number } from 'prop-types';
import ResourceSearch from '../../Shared/Resource/Search';
import useResource from '../../../hooks/resource';
const RegulatorView = (props) => {
  const projectArray = [];

  props.data.forEach((e) => {
   let dataObj = {
      siteName: e.project.name,
      clientName: e.project.client.name,
      inspectionId: e.project_id
    };
    
    return projectArray.push(dataObj)
  });
  const {search} = useResource({resource: 'divisionProjects'})
  const { query, setQuery } = search;

  const searchProps = {
    query,
    setQuery(input) {
      setQuery(input);
    },
    placeholder: "Search Projects..",
  };

  const searchFunc = (arrList, event) => {
    return arrList.filter(ele => {
      const siteNameStr = ele.siteName.toLowerCase()
      const clientNameStr = ele.clientName.toLowerCase()
      const searchStr = search.query.toLowerCase()

      return siteNameStr.match(searchStr) || clientNameStr.match(searchStr)
    })
  }

  return (
    <section className='inspections-paper'>
      <div className='inspections-paper-header list'>
        <h3>Projects</h3>
        <ResourceSearch
              local={false}
              {...searchProps}
            />
      </div>

      <DashTable
        tableKeys={['siteName', 'clientName']}
        tableData={searchProps.query ? searchFunc(projectArray) : projectArray || []}
        getCtaText={() => 'View Project'}
        getCtaRoute={({ inspectionId }) => `/projects/${inspectionId}/inspections`}
        showPopper={false}
      />
    </section>
  );
};

RegulatorView.propTypes = {
  data: arrayOf(
    shape({
      projectId: number.isRequired,
      inspectionId: number.isRequired,
      siteName: string.isRequired,
      clientName: string.isRequired,
    }),
  ).isRequired,
};

export default RegulatorView;
