import React from 'react'
import moment from 'moment';
import { Typography, Popover } from '@mui/material';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from "lodash";

import Loading from '../../../Shared/ResourceIndex/Loading';
import { ProjectWeatherDataType, ProjectWeatherDataIF } from '../../widgets/Precipitation';

type Props = {
    projectData: ProjectWeatherDataIF;
    currentDate: string;
}

type HourlyDataRepresentPropsType = {
    timezone: string;
    projectWeatherHourlyData: ProjectWeatherDataType[];
    currentDate: string;
}

const applyWeatherFilter = (weatherData: ProjectWeatherDataType[], isPresentDay: boolean, currentDate: string, timezone: string): ProjectWeatherDataType[] => {
    return weatherData.filter(e => {

        const isItemCurrentDate = moment(e.datetime).tz(timezone).format('DD-MM-YYYY') === moment(currentDate).tz(timezone).format('DD-MM-YYYY'); //moment(e.datetime).utc().format('DD-MM-YYYY') === moment(currentDate).utc().format('DD-MM-YYYY')
        const isHourPassed = parseInt(moment().tz(timezone).format("HH")) >= parseInt(moment(e.datetime).tz(timezone).format("HH"));

        if (isItemCurrentDate) {

            if (isPresentDay) {
                return (isHourPassed) ? true : false;
            }

            return true;

        } else {

            return false;

        }

    });
}

const HourlyDataRepresent = (props: HourlyDataRepresentPropsType) => {

    const isPresentDay = moment().tz(props.timezone).format("DD-MM-YYYY") === moment(props.currentDate).tz(props.timezone).format("DD-MM-YYYY");
    const currentDateWeatherValues = _.sortBy(applyWeatherFilter(props.projectWeatherHourlyData, isPresentDay, props.currentDate, props.timezone), ['datetime']);

    return (
        <>
            {currentDateWeatherValues.length ? currentDateWeatherValues.map((weatherItem) => {

                const weather_time = moment(weatherItem.datetime).tz(props.timezone).format("hh:mm a"); // replace tz with .utc()
                const rain_value = (weatherItem.rain_accumulation) ? weatherItem.rain_accumulation + '"' : '-';
                const snow_value = (weatherItem.snow_accumulation) ? weatherItem.snow_accumulation + '"' : '-';

                return (
                    <>
                        <div className='pure-u-1-2 precip-hr-data-contatiner__bodyItems'>
                            {weather_time}
                        </div>
                        <div className='pure-u-1-2 precip-hr-data-contatiner__bodyItems'>
                            {`${rain_value} / ${snow_value}`}
                        </div>
                    </>
                )

            })
                :

                <div className='pure-u-1-1 precip-hr-data-contatiner__bodyItems'>
                    No data to show.
                </div>

            }
        </>
    );

}

export const getTotalAccumulation = (data: ProjectWeatherDataType[]) => {
    let totalRainAccumulation = 0;
    let totalSnowAccumulation = 0;

    data.map((weatherItem: ProjectWeatherDataType) => {
        totalRainAccumulation += (weatherItem.rain_accumulation) ? weatherItem.rain_accumulation : 0;
        totalSnowAccumulation += (weatherItem.snow_accumulation) ? weatherItem.snow_accumulation : 0;
    });

    totalRainAccumulation = parseFloat(totalRainAccumulation.toFixed(2));
    totalSnowAccumulation = parseFloat(totalSnowAccumulation.toFixed(2));

    return { totalRainAccumulation, totalSnowAccumulation };
}

const getRainSnowValueString = (precipitaionData: ProjectWeatherDataType[], currentDate: string, timezone: string) => {

    const isPresentDay = moment().tz(timezone).format("DD-MM-YYYY") === moment(currentDate).tz(timezone).format("DD-MM-YYYY");

    precipitaionData = applyWeatherFilter(precipitaionData, isPresentDay, currentDate, timezone);

    const { totalRainAccumulation, totalSnowAccumulation } = getTotalAccumulation(precipitaionData);
    return `${(totalRainAccumulation) ? totalRainAccumulation + '"' : "-"} / ${(totalSnowAccumulation) ? totalSnowAccumulation + '"' : "-"}`;

}

const index = (props: Props) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'precipitaion-value-popover' : undefined;

    return (
        <>
            <div className='precip-value-wrapper' onClick={handleClick}>
                <Typography component={"span"} className={`cell-elm`}> {getRainSnowValueString(props.projectData.project_weather_data, props.currentDate, props.projectData.timezone)} </Typography>
                <FontAwesomeIcon icon={faAngleDown} size={'sm'} className={'expand-precipitaion-icon'} />
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className='pure-g precip-hr-data-contatiner'>
                    <div className='pure-u-1-2 precip-hr-data-contatiner__header'>Time</div>
                    <div className='pure-u-1-2 precip-hr-data-contatiner__header'>Rain / Snow</div>
                    {(!props.projectData) ? <Loading /> : <HourlyDataRepresent timezone={props.projectData.timezone} projectWeatherHourlyData={props.projectData.project_weather_data} currentDate={props.currentDate} />}
                </div>
            </Popover>
        </>
    )
}

export default index