import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

/**
 * select-all, taken from {Findings}
 *
 * @todo implement this in other places, refactor & remove unused styles
 * @todo refactor to use something from {Shared/Form/Controls} or {Shared/Form/FormGroup}
 */
function SelectAll({ value, onSelectAll }) {
  return (
    <div className="selection-select-all">
      <div className="select-all pointer" onClick={onSelectAll}>
        <input type="checkbox" defaultChecked={value === true} readOnly />
        <div className={value === true ? "checkbox checked" : "checkbox"}>
          {value === true && (
            <FontAwesomeIcon icon={faCheck} className="checkmark" />
          )}
        </div>
        <div className="check-label">Select All</div>
      </div>
    </div>
  );
}

SelectAll.propTypes = {
  // control value
  value: PropTypes.bool.isRequired,
  // checkbox on change handler
  onSelectAll: PropTypes.func.isRequired,
};

export default SelectAll;
