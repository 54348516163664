import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../Shared/ResourceIndex/Loading";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import projectApi from "../../../../utils/api/project";

interface Props{
  projectId: any,
  documentsLogData: any,
  getDocumentsLog: any
}

const DocumentsLogTable = (props: Props) => {
  const widgetRef = useRef<any>(0)
  const {documentsLogData, getDocumentsLog} = props

  const dateDisplayFunc = (ele: any) => {
    return moment(ele.relevant_date ).utc().format('MM/DD/YYYY')
  }

  const statusFunc = (ele: any) => {
    if(ele.deletedAt){
      return 'Deleted'
    }
    if(ele.status === 'Replaced'){
      return(
        <>
          <ReactTooltip place="top" type="dark" effect="float"/>
          <>Replaced<FontAwesomeIcon 
            icon={faExclamationCircle} 
            data-tip={`${ele.upload.name} is replaced with ${ele.project_upload && ele.project_upload.upload && ele.project_upload.upload.name}`}
          /></>
        </>
      )
    }
    if(ele.status === 'Restored'){
      return(
        <>
          <ReactTooltip place="top" type="dark" effect="float"/>
          <>Restored{ele.project_upload ? <FontAwesomeIcon 
            icon={faExclamationCircle} 
            data-tip={`${ele.project_upload && ele.project_upload.upload && ele.project_upload.upload.name} is replaced with ${ele.upload.name}`}
          />: <></>}</>
        </>
      )
    }
    if(ele.status){
      return `${ele.status}`
    }
    else{
      return 'Uploaded'
    }
  }

  const onRestoreClick = async(ele: any) => {
    if(ele.deletedAt || ele.replaced_by){
      await projectApi.documentsLog.restore(ele.project_id, ele.id, ele)
      getDocumentsLog()

      return
    }
    else{
      return
    }
  }

  return (
    <>
      <div className="documents-log-table" ref={widgetRef}>
        <div className="documents-log-table__body">
          <div className="documents-log-table__content">
            <section className="documents-log-table__list-view">
              <div className="documents-log-table__table-head">
                <h3>Date</h3>
                <h3>File Name</h3>
                <h3>Folder Name</h3>
                <h3>Modified By</h3>
                <h3>Status</h3>
                <h3>Action</h3>
              </div>

              {documentsLogData ?
                (documentsLogData.map((ele:any, index:any) => {
                  let fullName = ele.users && `${ele.users.first_name} ${ele.users.last_name}`

                  return (
                    <div key={index} className="documents-log-table__table-body">
                      <li>{dateDisplayFunc(ele)}</li>
                      <ReactTooltip place="top" type="dark" effect="float"/>
                      <li data-tip={`${ele.upload.name}`}>
                        {ele.upload.name}
                      </li>
                      <ReactTooltip place="top" type="dark" effect="float"/>
                      <li data-tip={`${ele.project_doc_type && ele.project_doc_type.dt ? ele.project_doc_type.dt.name : ''}`}>
                        {ele.project_doc_type && ele.project_doc_type.dt && ele.project_doc_type.dt.name}
                      </li>
                      <ReactTooltip place="top" type="dark" effect="float"/>
                      <li data-tip={fullName}>
                        {fullName}
                      </li>
                      <li>{statusFunc(ele)}</li>
                      <li>{(ele.deletedAt || ele.status === 'Replaced') ? <button className={`start-inspection-btn`} onClick={() => onRestoreClick(ele)}>Restore</button> : <></>}</li>
                    </div>
                    )
                  })
                ) : (
                <Loading what='data' />
              )}
            </section>
          </div>
        </div>
      </div>
    </>
  )
}


export default DocumentsLogTable
