import React, { useCallback, useContext, useState, useEffect } from "react";
import iContextProviderProps from "../../../contexts/ClientContext";
import ProjectCard from "./ProjectCard";

export default function ClientProjectsTab(props: { showProjects: string }) {
  const context = useContext(iContextProviderProps);
  const [ clientProjects, setClientProjects ] = useState(context.clientProjects)
  const [ allClientProjects ] = useState(context.clientProjects)
  const [ search, setSearch] = useState('')

  const { showProjects } = props;

  const onAllSelect = useCallback(() => {
    context.clientProjects.map(_ => _.id);
  }, [context.clientProjects]);

  const sortByDate = (order: "ASC" | "DESC") => {
    const updatedList = [...allClientProjects].sort((a, b) => {

    let A = new Date(a.createdAt).getTime()

    let B = new Date(b.createdAt).getTime()
      
    if(order === "ASC")
      return A > B ? 1 : -1 // ascending

    return A > B ? -1 : 1 // descending
    })

    setClientProjects(updatedList)
  }

  const sortByName = (order: "ASC" | "DESC") => {
    const updatedList = [...allClientProjects].sort((a, b) => {

      let A = a.name.toString().toLowerCase()

      let B = b.name.toString().toLowerCase()
        
      if(order === "ASC")
        return A > B ? 1 : -1 // ascending

      return A > B ? -1 : 1 // descending
    })

    setClientProjects(updatedList)
  }

  //SEARCH
  useEffect(() => {
    const updatedList = [...allClientProjects].filter((el) => {
      return el.name.toLowerCase().includes(search.toLowerCase())
    })

    setClientProjects(updatedList)
  }, [search])

  //SORT
  const sortByHandler = (event: any) => {
    switch(Number(event.target.value)){
      case 1 : sortByDate("DESC"); break;
      case 2 : sortByDate("ASC"); break;
      case 3 : sortByName("ASC"); break;
      case 4 : sortByName("DESC"); break;
      default: setClientProjects(context.clientProjects)
    }
  }

  return (
    <div className="client-projects-tab projects">
      <div className="client-projects-tab__bars">
        <input onChange={(event) => setSearch(event.target.value)} placeholder="Search" type="text" />

        <select onChange={(event) => sortByHandler(event)}>
          <option value="-10" hidden>Sort By</option>
          <option value='1'>Date Created (Newest to Oldest)</option>
          <option value='2'>Date Created (Oldest to Newest)</option>
          <option value='3'>Project Name (A-Z)</option>
          <option value='4'>Project Name (Z-A)</option>
        </select>
      </div>
      <div className="flex-row project-list">
        {showProjects && (
          <div className="post-storm-controls">
            <div className="select-all" onClick={onAllSelect}>
              <div className="check-holder"></div>
            </div>
          </div>
        )}
        {!context.clientProjects || context.clientProjects.length === 0 ? (
          <p>No projects found</p>
        ) : null}
        {clientProjects.map(project => {
          return <ProjectCard project={project} custom_tab={context.client.custom_tab} />;
        })}
      </div>
    </div>
  );
}
