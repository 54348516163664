import React, { MouseEventHandler, useState } from "react";
import { Redirect } from "react-router-dom";
import divisionApi from "../../utils/api/division";
import { AppDivisionContext } from "../../contexts/AppDivisionContext";

import DivisionDetailsForm from "./Forms/DivisionDetailsForm";
import { useContext } from "react";

export interface IDivisionProps {
  handleClose: MouseEventHandler;
  show?: boolean;
  onAdd?: Function;
  enableRedirect?: boolean;
}

const DivisionAdd: React.FC<IDivisionProps> = ({
  onAdd = undefined,
  show = true,
  handleClose,
  enableRedirect = true,
}) => {
  const { refetchDivisions } = useContext(AppDivisionContext);
  const [redirectId, setRedirectId] = useState(0);

  const handleSubmit = (formState: any) => {
    return divisionApi
      .create(formState)
      .then(data => {
        if (typeof onAdd === "function") {
          onAdd(data);
        }

        if (enableRedirect === true) {
          setRedirectId(data.id);
        }
        refetchDivisions();

        return Promise.resolve();
      })
      .catch(() => console.error("Error creating division"));
  };

  if (Boolean(redirectId)) {
    return <Redirect to={`/divisions/${redirectId}`} push />;
  }

  if (show) {
    return (
      <DivisionDetailsForm
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        title="Add a Division"
      />
    );
  }

  return null;
};

export default DivisionAdd;
