import React, { useState, useEffect, useContext } from "react";
import dashboardApi from "../utils/api/dashboard";
import { AppDivisionContext } from "./AppDivisionContext"
import AppContext from "./AppContext";


const Context = React.createContext([]);

export const DashboardStore = props => {
  const { getPath, appDivisionId } = useContext(AppDivisionContext);
  const appContext = useContext(AppContext);

  const initialData = {
    data: [],
    name: "",
  };
  const [data, setData] = useState(initialData);

  const loadInitialData = () => {
    appContext.loadData(() =>
      dashboardApi
        .fetch(getPath('/dashboard'))
        .then(inspections => {
          setData(inspections);
        })
        .catch(() => {
          setData(initialData);
        }));
  };

  useEffect(() => {
    loadInitialData()
  }, [appDivisionId])

  const handleReassign = (resp, activeView) => {
    const { old_inspector, new_inspector } = resp;
    const oldId = parseInt(old_inspector, 10);
    const newId = parseInt(new_inspector, 10);

    if (!activeView) {
      const oldInspectorData = {
        ...data.data.inspectors.find(element => {
          return element.id === oldId;
        }),
      };

      const newInspectorData = data.data.inspectors.find(
        element => element.id === newId,
      );
      const filteredInspections = oldInspectorData.inspections.filter(
        inspection => inspection.inspectionId !== resp.newInspection.inspectionId,
      );

      oldInspectorData.inspections = filteredInspections;

      newInspectorData.inspections = [
        ...newInspectorData.inspections,
        resp.newInspection,
      ];

      const filterArr = [oldId, newId];

      const filteredData = [
        ...data.data.inspectors.filter(
          inspector => !filterArr.includes(inspector.id),
        ),
      ];

      setData({
        name: data.name,
        data: {
          inspectors: [...filteredData, oldInspectorData, newInspectorData].sort(
            (a, b) => (a.name > b.name ? 1 : -1),
          ),
        },
      });
    } else {
      const actualData = Object.assign({}, data);

      const oldData = {
        ...data.data[activeView.key].find(element => {
          return element.id === oldId;
        }),
      };

      const newData = data.data[activeView.key].find(
        element => element.id === newId,
      );

      const filteredInspections = oldData.inspections.filter(
        inspection => inspection.inspectionId !== resp.newInspection.inspectionId,
      );

      oldData.inspections = filteredInspections;

      newData.inspections = [
        ...newData.inspections,
        resp.newInspection,
      ];

      const filterArr = [oldId, newId];

      const filteredData = [
        ...data.data[activeView.key].filter(
          inspector => !filterArr.includes(inspector.id),
        ),
      ];


      actualData.data[activeView.key] = [...filteredData, oldData, newData].sort(
        (a, b) => (a.name > b.name ? 1 : -1),
      )

      setData({
        name: data.name,
        data: actualData.data,
      });
    }
  };

  return (
    <Context.Provider
      value={{
        data,
        updateData: data => setData(data),
        handleReassign,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default Context;
