import React, { useContext, useEffect, useState } from "react";
import { ProjectProvider } from "../../contexts/ProjectContext";
import { QuestionCommentProvider } from "../../contexts/QuestionCommentContext";
import PublicURLNavbar from "../PublicUrl/Component/PublicURLNavbar";
import { useQuery } from "react-query";
import { useRouteMatch } from "react-router";
import { InspectionContext } from "../../contexts/InspectionContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import { ProjectPermissionContext, ProjectPermissionContextProvider } from "../../contexts/ProjectPermissionContext";
import {
  QuestionTypeContext,
} from "../../contexts/QuestionTypeContext";
import { useQuestionTypes } from "../../hooks/questionTypes";
import inspectionApi from "../../utils/api/inspection";
import projectApi from "../../utils/api/project";
import { InspectionStore } from '../../contexts/InspectionContext';
import { LoadingMessage } from "@sw-sw/lib-ui";
import { DivisionProvider } from "../../contexts/DivisionContext";



const NoLayoutWarpper = ({ children }) => {
  return (
    <DivisionProvider>
      <ProjectProvider>
        <InspectionStore>
          <QuestionCommentProvider>
            <ProjectPermissionContextProvider>
              <NoLayout children={children} />
            </ProjectPermissionContextProvider>
          </QuestionCommentProvider>
        </InspectionStore>
      </ProjectProvider>
    </DivisionProvider>
  )

}


const NoLayout = ({ children }) => {
  const inspectionContext = useContext(InspectionContext);
  const projectContext = useContext(ProjectContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const questionTypeContext = useContext(QuestionTypeContext);
  const match = useRouteMatch();
  const projectId = Number(match.params.id);
  const inspectionId = Number(match.params.inspectionId);
  const questionTypesQuery = useQuestionTypes();
  const [loading, setLoading] = useState(true);

  const Query = inspectionId ? useQuery(
    ["inspection", inspectionId],
    async () => {
      const { inspection, ...data } = await inspectionApi.get(inspectionId);
      const project = await projectApi.get(inspection.project_id);

      return {
        ...data,
        inspection: {
          ...inspection,
          project,
        },
      };
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    },
  ) : useQuery(
    ["project", projectId],
    async () => {
      const project = await projectApi.get(projectId);

      return {
        inspection: {
          project,
        },
      };
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    },
  );

  useEffect(() => {
    const { data, isFetched } = Query;

    if (isFetched && data) {
      if (inspectionId) {
        inspectionContext.updateInspection(data.inspection);
        inspectionContext.setFindings(data.inspection.findings);
        inspectionContext.setTemplate(data.template);
        inspectionContext.setInspectionLoaded(true);
      }
      projectContext.setProject(data.inspection.project);

      projectPermissionContext.setReadOnly(true);
    }
  }, [Query.isFetched]);

  useEffect(() => {
    if (questionTypesQuery.isFetched && questionTypesQuery.data) {
      questionTypeContext.setTypes(questionTypesQuery.data);
    }
  }, [questionTypesQuery.isFetched]);

  useEffect(() => {
    const { inspection } = inspectionContext;
    const { project } = projectContext;

    inspectionId ? setLoading(!(inspection.id && project && questionTypesQuery.isFetched)) : setLoading(!(project && questionTypesQuery.isFetched));
  }, [projectContext, inspectionContext, questionTypesQuery.isFetched]);

  useEffect(() => {
    return () => {
      projectContext.clear();
      inspectionContext.resetInspection();
      inspectionContext.setTemplate(null);
      Query.remove();
    };
  }, []);


  return (
    loading ? (
      <LoadingMessage what="Inspection Details" showLoadingText />
    ) : (
      <>
        <PublicURLNavbar />
        <div className="PublicURl__wrapper">
          <div className="PublicURl">{children}</div>
        </div>
      </>
    )

  )
};

export default NoLayoutWarpper;
