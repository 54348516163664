import React, { useContext } from "react";
import QuestionGroupContext from "../../../contexts/QuestionGroupContext";
import TemplateEditorContext from "../../../contexts/TemplateEditorContext";
import Popper from "../../Shared/Popper/Popper";

export interface IQuestionGroupPopperProps {
  groupName?: string;
  coordinates: any;
  handleClose: () => void;
  showDeleteForm: () => void;
}

const QuestionGroupPopper: React.FC<IQuestionGroupPopperProps> = ({
  groupName = "",
  coordinates,
  handleClose,
  showDeleteForm,
}) => {
  const questionGroupContext = useContext(QuestionGroupContext);
  const templateEditorContext = useContext(TemplateEditorContext);

  return (
    <Popper
      classes="question-group-menu"
      coordinates={coordinates}
      handleClose={handleClose}
    >
      <ul>
        {templateEditorContext.mappedAndSortedQuestionGroups[0].id !==
          questionGroupContext.questionGroup.id && (
          <li
            className="pointer"
            onClick={() => {
              if (questionGroupContext.questionGroup.id) {
                templateEditorContext.swapQuestionGroups(
                  questionGroupContext.questionGroup.id,
                  "up",
                );
                handleClose();
                templateEditorContext.removeQuestionGroups();
                templateEditorContext.refetchQuestionGroups();
              }
            }}
          >
            Move Question Group Up
          </li>
        )}

        {templateEditorContext.mappedAndSortedQuestionGroups[
          templateEditorContext.mappedAndSortedQuestionGroups.length - 1
        ].id !== questionGroupContext.questionGroup.id && (
          <li
            className="pointer"
            onClick={() => {
              if (questionGroupContext.questionGroup.id) {
                templateEditorContext.swapQuestionGroups(
                  questionGroupContext.questionGroup.id,
                  "down",
                );
                handleClose();
                templateEditorContext.removeQuestionGroups();
                templateEditorContext.refetchQuestionGroups();
              }
            }}
          >
            Move Question Group Down
          </li>
        )}
        <li className="delete-item pointer" onClick={showDeleteForm}>
          Delete {groupName} Question Group
        </li>
      </ul>
    </Popper>
  );
};

export default QuestionGroupPopper;
