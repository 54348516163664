import React from "react";

import DashTable from "../utils/DashTable";
import { arrayOf, shape, string, number } from "prop-types";

const ContractorView = props => {
 
  return (
    <section className="inspections-paper">
      <div className="inspections-paper-header list">
        <h3>Open Findings</h3>
      </div>

      <DashTable
        tableKeys={["siteName", "clientName", "overdueItems"]}
        tableData={props.data || []}
        getCtaText={() => "Complete Findings"}
        getCtaRoute={({ inspectionId }) =>
          `/inspection/${inspectionId}/findings`
        }
      />
    </section>
  );
};

ContractorView.propTypes = {
  data: arrayOf(
    shape({
      projectId: number.isRequired,
      inspectionId: number.isRequired,
      siteName: string.isRequired,
      clientName: string.isRequired,
      overdueItems: number.isRequired,
    }),
  ).isRequired,
};

export default ContractorView;
