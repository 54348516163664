import React from "react";

export interface IDataPlaceholder {
  width?: number;
}

const DataPlaceholder: React.FC<IDataPlaceholder> = ({ width = 5 }) => {
  return (
    <span style={{ width: `${width}rem` }} className="data-placeholder"></span>
  );
};

export default DataPlaceholder;
