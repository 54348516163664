import { useContext } from "react";
import { isNumber, has } from "lodash";

import { Context as InteractionContext } from "../Interaction/InteractionContext";
import { Context as DataSourceContext } from "../DataSourceContext";

/**
 * Provide the source model for the active positionable type
 */
function usePositionableSourceModel() {
  const ic = useContext(InteractionContext);
  const dataSource = useContext(DataSourceContext);

  if (ic && ic.positionableType && ic.positionableSourceModel !== null) {
    return dataSource.get(ic.positionableType, ic.positionableSourceModel);
  }

  /**
   * Fallback to interaction modeData
   *
   * @todo may need to guard by interaction mode
   */
  if (
    ic &&
    ic.positionableType &&
    has(ic, "modeData.sourceModelIndex") &&
    isNumber(ic.modeData.sourceModelIndex)
  ) {
    return dataSource.get(ic.positionableType, ic.modeData.sourceModelIndex);
  }

  return null;
}

export default usePositionableSourceModel;
