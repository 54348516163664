import React from "react";
import FeatureDetails from "./FeatureDetails";
import SelectFeatureOnHover from "../Select/SelectFeatureOnHover";

export const FeatureDetailsReadOnly: React.FC<any> = props => (
  <SelectFeatureOnHover>
    <FeatureDetails {...props} readOnly/>
  </SelectFeatureOnHover>
);

export default FeatureDetailsReadOnly;
