import {
  FormContextProvider,
  SchemaFieldsWithContextUI,
} from "@sw-sw/lib-form";
import React, { MouseEventHandler } from "react";
import { FormModal, SubmitHandlerFn } from "../../Shared/form";

interface Division {
  name: string;
}

export interface IDivisionDetailsFormUIProps {
  handleSubmit: SubmitHandlerFn;
  handleClose: MouseEventHandler;
  title?: string;
  division?: Partial<Division>;
}

const schema = {
  name: {
    label: "Name",
    validation: {
      required: true,
    },
  },
};

const DivisionDetailsForm: React.FC<IDivisionDetailsFormUIProps> = ({
  handleSubmit,
  handleClose,
  title = "Add/Modify a Division",
  division = { name: "" },
}) => {
  return (
    <FormContextProvider>
      <FormModal
        onCancel={handleClose}
        onSubmit={handleSubmit}
        modalProps={{
          title: title,
        }}
      >
        <SchemaFieldsWithContextUI schema={schema} initialFormData={division} />
      </FormModal>
    </FormContextProvider>
  );
};

export default DivisionDetailsForm;
