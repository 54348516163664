import React from 'react';
import FormModal from "../../Shared/form/modal/FormModal";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import { FormSchemaFields } from "@sw-sw/lib-form";
import { UIControlType } from '@sw-sw/lib-form-control-types';
import moment from 'moment';


type Props = {
    handleClose: () => void;
    handleSubmit: (data: any) => Promise<void>;
    handleDelete: (data: any) => Promise<void>;
    title?: string;
    modalInspection: {
        id: number;
        project_name?: string;
        created_date?: string;
        type?: string;
        certification_notes?: string;
    },
    inspectionTypes: any;
    project_name: string | null;
}

const ExternalProjectsInspectionSubmitModal = (props: Props) => {

    const getSchema = () => {
        return {
            project_name: {
                label: "Name",
                disabled: true,
                controlType: UIControlType.text,
                validation: {
                    required: true
                }
            },
            created_date: {
                label: "Date Of Inspection",
                controlType: UIControlType.date,
                dateFormat: "MM-dd-yyyy",
                maxDate: moment().milliseconds(0).seconds(0).minutes(0).hour(0).toDate(),
                validation: {
                    required: true
                }
            },
            type: {
                label: "Reason For Inspection",
                controlType: UIControlType.select,
                options: props.inspectionTypes,
                valueKey: "type",
                labelKey: "type",
                validation: {
                    required: true
                }
            },
            certification_notes: {
                label: "Additional Notes",
                controlType: UIControlType.textarea,
                validation: {
                    maxLength: 200,
                }
            }
        };
    }

    const getInitialFormData = () => {
        return {
            project_name: props.project_name,
            created_date: props.modalInspection.created_date,
            type: props.modalInspection.type,
            certification_notes: props.modalInspection.certification_notes
        };
    }
    

    return (
        <FormContextProvider>
            <FormContext.Consumer>
                {formContext => {
                    return (
                        <FormModal
                            onCancel={props.handleClose}
                            onSubmit={props.handleSubmit}
                            modalProps={{
                                title: props.title,
                            }}
                            onDelete={props.handleDelete}
                            showDelete={true}
                        >
                            <FormSchemaFields
                                schema={getSchema()}
                                onChange={formContext.set}
                                formData={formContext.value}
                                initialFormData={getInitialFormData()}
                            />
                        </FormModal>
                    );
                }}
            </FormContext.Consumer>
        </FormContextProvider>
    );
}

export default ExternalProjectsInspectionSubmitModal