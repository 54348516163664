import React from "react";
import { Link } from "react-router-dom";

import "./_Button.scss";

export function InspectionReportButton({ text, to }: ButtonProps) {
  return (
    <Link className="button" to={to}>
      {text}
    </Link>
  );
}

interface ButtonProps {
  text: string;
  to: string;
}
