import { FormSchema } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import React, { MouseEventHandler, useContext, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import ProjectContext from "../../../contexts/ProjectContext";
import ProjectPrintContext, {
  InspectionDateFilter,
} from "../../../contexts/ProjectPrintContext";
import LoadingModal from "../../Shared/Loading/LoadingModal";
import PrintModal from "../../Shared/Print/PrintModal";
import {
  getInspectionFilterSchema,
  InspectionSchemaOptions,
} from "./PrintInspectionsModal";

export interface IPrintSwmpModal {
  onClose: MouseEventHandler<Element>;
}

function getProjectDoc(doc: any[]) {
  const options: { label: any; value: string; }[] = []
  const newDoc: string[] = []

  doc = doc.filter(e => e.name !== 'Fixed Details' && e.name !== "Fixed Details Project")
  const Doc = doc.map(ele => ele.name)

  Doc.map((newdoc) => {
    doc.map((ele) => {
      if (ele.name === newdoc) {
        newDoc.push(ele)
      }
    })
  })

  doc.map((newdoc) => {
    if (!Doc.includes(newdoc.name)) {
      newDoc.push(newdoc)
    }
  })

  newDoc.map((docName: any) => {

    if (docName.name !== "Active Site Maps") {
      switch (docName.name) {
        case "SWP Narrative/Maps":
          options.push({
            label: "SWP Narrative/Maps",
            value: "narrative"
          })
          break;

        case "Permits":
          options.push({
            label: "Permits",
            value: "permits"
          })
          break;
        case "Regulations":
          options.push({
            label: "Regulations",
            value: "regulations"
          })
          break;
        case "Construction Docs/Civil Drawings":
          options.push({
            label: "Construction Civil Docs",
            value: "constructionDocs"
          })
          break;
        case "Soil Reports":
          options.push({
            label: "Soil Reports",
            value: "soilReports"
          })
          break;
        case "Delegation Letters":
          options.push({
            label: "Delegation Letters",
            value: "delegationLetters"
          })
          break;
        case "Endangered Species":
          options.push({
            label: "Endangered Species",
            value: "endangeredSpecies"
          })
          break;
        case "Historical Properties":
          options.push({
            label: "Historical Properties",
            value: "historicalProperties"
          })
          break;
        case "Qualifications/Certifications":
          options.push({
            label: "Qualifications / Certifications",
            value: "qualifications"
          })
          break;
        case "Local Stormwater Regulations":
          options.push({
            label: "Local Stormwater Regulations",
            value: "localRegulations"
          })
          break;
        case "MISC":
          options.push({
            label: "MISC",
            value: "misc"
          })
          break;

        default:
          options.push({
            label: docName.name,
            value: docName.name
          })
      }
    }
  })

  return options
}

const getPrintSchema = ({
  showInspectionSelection,
  doc,
  ...schemaOptions
}: InspectionSchemaOptions & {
  showInspectionSelection: boolean;
  doc: any
}) => {

  const schema: FormSchema = {
    appendix: {
      controlType: UIControlType.checkboxGroup,
      label: "Project Documents",
      options: getProjectDoc(doc),
      valueKey: "value",
      labelKey: "label",
      showSelectAll: true
    },
    projectDocs: {
      controlType: UIControlType.plainText,
      label: "Appendix"
    },
    bmpDetails: {
      controlType: UIControlType.checkbox,
      label: "BMP Details",
    },
    inspections: {
      controlType: UIControlType.checkbox,
      label: "Inspections",
    },

    ...getInspectionFilterSchema(schemaOptions),
  };

  if (!showInspectionSelection) {
    delete schema.timeFilter;
    delete schema.start_date;
    delete schema.end_date;
    delete schema.select_all;
    delete schema.inspectionIds;
    delete schema.inspectionText;
  }

  return schema;
};

const PrintSwmpModal: React.FC<IPrintSwmpModal> = ({ onClose }) => {
  const projectPrintContext = useContext(ProjectPrintContext);
  const projectContext = useContext(ProjectContext);
  const router = useRouteMatch<{ id?: string }>()

  const initialData = {
    bmpDetails: false,
    inspections: false,
    timeFilter: 3,
  };

  useEffect(() => {
    projectContext.inspectionsQuery.refetch();
    projectContext.loadDocs(Number(router.params.id))
  }, []);

  if (!projectContext.project || projectContext.inspectionsQuery.isFetching || !projectContext.projectDocs) {
    return <LoadingModal show />;
  }

  return (
    <PrintModal
      jobName={"swmp"}
      jobData={{
        projectId: projectContext.project.id,
      }}
      statusUpdateKey={projectContext.project.id}
      onClose={onClose}
      formSchema={(formData: Record<string, any>) => {
        const filter: InspectionDateFilter = {
          start: formData.start_date,
          end: formData.end_date,
          prevMonths: formData.timeFilter,
        };
        const inspections = projectPrintContext.getInspectionOptions(
          projectContext.inspections,
          filter,
        );

        return getPrintSchema({
          inspections,
          filter,
          filterOptions: projectPrintContext.timeFilterOptions,
          showInspectionSelection: formData.inspections,
          inspectionTextLabel: projectPrintContext.getInspectionText({
            inspections,
            filter,
          }),
          doc: projectContext.projectDocs.documentTypes
        });
      }}
      initialFormData={initialData}
      modalTitle="Print Project Documents"
      validateData={projectPrintContext.validateInspectionData}
    />
  );
};

export default PrintSwmpModal;
