import React, { MouseEventHandler } from "react";
import {
  FormContextProvider,
  FormSchema,
  SchemaFieldsWithContextUI,
} from "@sw-sw/lib-form";
import { FormModal } from "../../../Shared/form";
import TemplateEditorContext from "../../../../contexts/TemplateEditorContext";
import { useContext } from "react";
import { UIControlType } from "@sw-sw/lib-form-control-types";

export interface INewQuestionGroupFormProps {
  handleClose: MouseEventHandler;
  title?: string;
  order: number;
}

const schema: FormSchema = {
  name: {
    label: "Name",
    validation: {
      required: true,
    },
  },
  layout_type: {
    controlType: UIControlType.radio,
    label: "Layout Type",
    validation: {
      required: true,
    },
    options: [
      { value: "FORM", label: "Form Layout" },
      { value: "TABLE", label: "Table Layout" },
    ],
  },
};

const NewQuestionGroupForm: React.FC<INewQuestionGroupFormProps> = ({
  handleClose,
  title = "Add a Question Group",
  order,
}) => {
  const templateEditorContext = useContext(TemplateEditorContext);

  const getInitialValues = () => {
    return {
      name: "",
      layout_type: "FORM",
      order: order,
    };
  };

  return (
    <FormContextProvider>
      <FormModal
        onCancel={handleClose}
        onSubmit={(e, newQuestionGroup) => {
          return templateEditorContext
            .addNewQuestionGroup(e, newQuestionGroup)
            .then(() => handleClose(e));
        }}
        modalProps={{ title: title }}
      >
        <SchemaFieldsWithContextUI
          schema={schema}
          initialFormData={getInitialValues()}
        />
      </FormModal>
    </FormContextProvider>
  );
};

export default NewQuestionGroupForm;
