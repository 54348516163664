import React, { MouseEventHandler, useContext } from "react";
import DivisionResourceForm from "./Forms/DivisionResourceForm";
import DivisionContext from "../../../contexts/DivisionContext";
import AppContext from "../../../contexts/AppContext";
import { useQueryClient } from "react-query";

export interface IDivisionResourceAddProps {
  handleClose: MouseEventHandler;
  show?: boolean;
}

const DivisionResourceAdd: React.FC<IDivisionResourceAddProps> = ({
  handleClose,
  show = true,
}) => {
  const divisionContext = useContext(DivisionContext);
  const appContext = useContext(AppContext);
  const client = useQueryClient();
  const { tabs, selectedTab } = divisionContext;
  const resourceApi = tabs[selectedTab].divisionApi;

  const handleSubmit = async (formState: any) => {
    if (divisionContext.selectedDivision) {
      return resourceApi
        .create(divisionContext.selectedDivision.id, formState)
        .then((data: Array<{ userId: number }>) => {
          client.invalidateQueries();

          if (data[0].userId && data[0].userId === appContext.get("user.id")) {
            appContext.set("user.divisions", [
              ...appContext.get("user.divisions"),
              divisionContext.selectedDivision,
            ]);
          }

          return appContext.get<Array<{ id: number; name: string }>>(
            "user.divisions",
          );
        })
        .then(divisions => {
          appContext.set("user.divisions", divisions);
          handleClose(formState);
        });
    } else {
      console.error("No Division in context");
    }
  };

  if (show) {
    return (
      <DivisionResourceForm
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        title={`Add ${tabs[selectedTab].header}`}
      />
    );
  }

  return null;
};

export default DivisionResourceAdd;
