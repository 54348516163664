import React from "react";

import Select from "../../Fields/Select";

export const options = [
  {
    text: "Arial",
  },
  {
    text: "Times New Roman",
  },
].map(opt => ({
  ...opt,
  value: opt.text,
}));

const FontFamily = props => (
  <Select label="Font" options={options} {...props} />
);

export default FontFamily;
