import React from 'react';
import styles from './ToggleSmall.module.scss';

export default function ToggleSmall(props: any) {
  return (
    <>
      <label className={styles.switch} style={props.style}>
        <input
          type='checkbox'
          checked={props.isChecked}
          onChange={props.handleChange}
          id={props.id}
          disabled={props.disabled}
        />
        <span className={`${styles.slider} ${styles.round}`}></span>
      </label>
    </>
  );
}
