import { FormHelpText } from "@sw-sw/lib-form";
import { values } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";

/** @todo extract into generic "tags" input */
const AddUserInput = props => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(null);

  const { value, onChange, className, onBlur, name, id, placeholder } = props;
  const keyCodes = {
    comma: 188,
    enter: 13,
    space: 32,
  };
  const handleDelete = i => onChange(value.filter((_, index) => index !== i));
  const handleAddition = tag => {
    setInputValue("");
    if (
      tag.text.trim().match(/[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/)
    ) {
      onChange([...value, { ...tag, text: tag.text.trim() }]);
      setError(null);
    } else {
      setError("Please enter a valid email address");
    }
  };

  return (
    <div className="add-inspector-modal">
      <ReactTags
        name={name}
        id={id}
        tags={value.map(val => ({
          text: val.text,
          id: val.text,
          className: "input-tag",
        }))}
        placeholder={placeholder || ""}
        delimiters={values(keyCodes)}
        allowDeleteFromEmptyInput={false}
        classNames={{
          tagInputField: className,
        }}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        allowDragDrop={false}
        allowUnique={true}
        // track raw input value
        handleInputChange={val => {
          setInputValue(val);
        }}
        // try to add raw value on blur
        handleInputBlur={() => {
          if (inputValue) {
            handleAddition({ text: inputValue });
            setInputValue(null);
          } else {
            onBlur({
              target: { value },
            });
          }
        }}
      />
      {!error && (
        <FormHelpText content="Add an email address by pressing the enter, comma, or space key." />
      )}
      {error && <FormHelpText theme="error" content={error} />}
    </div>
  );
};

AddUserInput.propTypes = {
  value: PropTypes.array,
};

AddUserInput.defaultProps = {
  value: [],
};

export default AddUserInput;
