import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { parseISO } from "date-fns";

import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import AppDivisionContext from "../../../contexts/AppDivisionContext";
import { submitHandler } from "../../Shared/form/modal/FormActions";
import { FormSchemaFields } from "@sw-sw/lib-form";
import validator from "../../../utils/FormValidator";
import projectApi from "../../../utils/api/project";
import SuccessNotification from "../../Shared/SuccessNotification/SuccessNotification";
import Loading from "../../Shared/ResourceIndex/Loading";
import { ActionButtonsWithScrollFix } from "../../Shared/ActionButtons";
import { isNaN, toNumber } from "lodash";
import { UIControlType } from "@sw-sw/lib-form-control-types";

// Map to take in question type and return an object with correct form values to handle it
// Used to render correct form fields based on API return

const mapTypeToFormData = {
  number: { inputMode: "numeric", parse: validator.parseDecimal },
  text: { controlType: UIControlType.text },
  date: { controlType: UIControlType.date },
  datetime: { controlType: UIControlType.date },
  radio_group: { controlType: UIControlType.radio },
};

function extractInitialData(formData) {
  const initialData = {};

  for (let i = 0; i < formData.length; i++) {
    const projectId = formData[i].projectId;

    for (let j = 0; j < formData[i].questions.length; j++) {
      const { answer, id, type } = formData[i].questions[j];
      const parsedAnswer =
        ["date", "datetime"].includes(type[0]) && answer !== ""
          ? parseISO(answer)
          : answer;

      initialData[`${projectId}-${id}`] = parsedAnswer;
    }
  }

  return initialData;
}

function PostStormFormUI(props) {
  const formContext = useContext(FormContext);
  const [isSaving, setIsSaving] = useState(false);

  function handleSubmit(data) {
    const answersByProject = {};
    const questions = Object.keys(data);

    setIsSaving(true);

    for (let i = 0; i < questions.length; i++) {
      const answer = data[questions[i]];
      const [projectId, questionId] = questions[i].split("-");

      if (answersByProject[projectId]) {
        answersByProject[projectId][questionId] = answer;
      } else {
        answersByProject[projectId] = {};
        answersByProject[projectId][questionId] = answer;
      }
    }
    const payLoad = Object.keys(answersByProject).map(key => ({
      project_id: key,
      ...answersByProject[key],
    }));

    return projectApi.bulkStorm.create(payLoad).then(resp => {
      setIsSaving(false);
      props.setShowSuccess(true);
    });
  }

  function getQuestionSchema(question) {
    let sch = {
      label: question.label,
      style: { flex: "1 1 25%", alignSelf: "flex-end" },
      ...mapTypeToFormData[question.type],
      validation: {
        required: true,
      },
    };

    if (question.description) {
      sch.description = question.description;
    }

    return sch;
  }

  function getSchema(questions, projectId) {
    const sch = {};

    for (let i = 0; i < questions.length; i++) {
      sch[`${projectId}-${questions[i].id}`] = getQuestionSchema(questions[i]);
    }

    return sch;
  }

  function renderProject(project) {
    const { clientName, projectName, projectId, questions } = project;

    if (questions.length === 0) {
      return null;
    }

    return (
      <div className="post-storm-project" key={projectId}>
        <div className="project-info">
          <h4 className="text-truncate">{projectName}</h4>
          <h5>{clientName}</h5>
        </div>
        <div className="form-wrapper">
          <FormSchemaFields
            key={`form-${project.projectId}`}
            formData={formContext.value}
            onChange={formContext.set}
            schema={getSchema(questions, projectId)}
          />
        </div>
      </div>
    );
  }

  return (
    <section className="post-storm">
      <header className="project-header">
        <h2>Post Storm Events</h2>
        <ActionButtonsWithScrollFix
          primary="Save"
          onClick={e => submitHandler(formContext, e, handleSubmit)}
          busy={isSaving}
        />
      </header>
      <div className="bulk-storm-form">
        {props.data.map(project => renderProject(project)).filter(obj => !!obj)}
      </div>
    </section>
  );
}

function PostStormForm(props) {
  const [formData, setFormData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { getPath } = useContext(AppDivisionContext);

  useEffect(() => {
    const loadData = async (projectIds = []) => {
      const result = await projectApi.bulkStorm.get(projectIds);

      if (result.length === 0) {
        props.history.push(getPath("/projects"));
      } else {
        setFormData(result);
        setLoaded(true);
      }
    };

    if (!props.location.search) {
      props.history.push(getPath("/projects"));
    } else {
      const params = new URLSearchParams(props.location.search);

      if (!params.get("pid")) {
        props.history.push(getPath("/projects"));
      } else {
        const projectIds = params
          .get("pid")
          .split(" ")
          .map(id => toNumber(id))
          .filter(id => !isNaN(id));

        projectIds.length === 0
          ? props.history.push(getPath("/projects"))
          : loadData(projectIds);
      }
    }
  }, []);

  if (!loaded) return <Loading what="post storm events" />;

  if (showSuccess) {
    setTimeout(() => {
      props.history.push(getPath("/projects"));
    }, 1500);
  }

  return (
    <FormContextProvider initialValue={extractInitialData(formData)}>
      <SuccessNotification show={showSuccess} />
      <PostStormFormUI data={formData} setShowSuccess={setShowSuccess} />
    </FormContextProvider>
  );
}

PostStormForm.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      clientName: PropTypes.string.isRequired,
      projectName: PropTypes.string.isRequired,
      projectId: PropTypes.number.isRequired,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          type: PropTypes.oneOf(Object.keys(mapTypeToFormData)).isRequired,
          label: PropTypes.string.isRequired,
          description: PropTypes.string,
          answer: PropTypes.string,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default withRouter(PostStormForm);
