import React, { useEffect } from "react";
import "./_Gradient.scss";

export default function Gradient({ children }: any) {
  useEffect(() => {}, []);

  return (
    <div className="gradient">
      <div className="gradient__content">{children}</div>
    </div>
  );
}
