import React, { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import useResource from "../hooks/resource";
import notificationApi from "../utils/api/notifications";
import appContext from "./AppContext";

const Context = React.createContext();

export const NotificationStore = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const client = useQueryClient();
  const appStore = useContext(appContext);
  const userId = appStore.get("user.id");
  const [notificationSettings, setNotificationSettings] = useState(
    appStore.get("user.notificationSettings"),
  );
  const [frequencySettings, setFrequencySettings] = useState(
    appStore.get("user.deliverySettings"),
  );
  // const [notifications, setNotifications] = useState([]);

  const notificationQuery = useResource({
    resource: "notifications",
    /** @todo enable pagination here */
    disablePagination: true,
    queryDefaults: {
      retryDelay: 0,
      enabled: false,
    },
  });
  const notifications = notificationQuery.data;
  const setNotifications = data => client.setQueryData("notifications", data);

  const updateTypeSettings = (typeId, isEnabled, defaultMessage) => {
    if (!loading) {
      setLoading(true);

      notificationApi
        .updateTypeSettings(userId, typeId, isEnabled, defaultMessage)
        .then(data => {
          appStore.set("user.notificationSettings", data);
          setNotificationSettings(data);
          setLoading(false);
        });
    }
  };

  const updateFrequencySettings = frequency => {
    if (loading) {
      setLoading(true);

      notificationApi.updateFrequencySettings(frequency).then(data => {
        setFrequencySettings(data);
        setLoading(false);
      });
    }
  };

  const markNotificationAsRead = (id = null) => {
    return (id
      ? notificationApi.read(id)
      : notificationApi.readAll()
    ).then(data => setNotifications(data));
  };

  useEffect(() => {
    if (loading && !notificationQuery.loading) {
      setLoading(false);
    }
  }, [notificationQuery]);

  useEffect(() => {
    if (loading) {
      notificationQuery.query.refetch();
    }

    return () => {
      notificationQuery.query.remove();
    };
  }, []);

  return (
    <Context.Provider
      value={{
        notificationSettings,
        frequencySettings,
        updateTypeSettings,
        updateFrequencySettings,
        notifications,
        markNotificationAsRead,
        loading,
        loaded: !loading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
