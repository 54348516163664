import React from "react";
import classnames from "classnames";

export interface IFindingCountProps {
  count: number;
  isComplete: boolean;
  isCA?: boolean;
  children: string;
}

const FindingCount: React.FC<IFindingCountProps> = ({
  count = 0,
  isComplete,
  isCA = false,
  children,
}) => {
  return (
    <div
      title={
        !isComplete
          ? `There are incomplete ${children.toLowerCase()}`
          : `All ${children.toLowerCase()} are completed`
      }
    >
      <span>{children}</span>
      <span
        className={classnames("icon-only-button tiny outline", {
          inCompleteCA: !isComplete && isCA,
          inCompleteMI: !isComplete && !isCA,
        })}
      >
        {count}
      </span>
    </div>
  );
};

export default FindingCount;
