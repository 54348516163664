import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames';
import { uniqueId } from 'lodash';

/**
 * A controlled form input
 *
 * @note Requires "this.props.children" to be a function
 * 
 * Generates a unique input id
 * Sets initial value, if available
 * Renders container, label, and children
 */
export class BaseShapeEditorField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    cast: PropTypes.func,
  }

  id = null;

  UNSAFE_componentWillMount() {
    this.id = uniqueId('field_');
  }

  render() {
    const { className, label } = this.props;
    const fieldClass = classnames('shape-editor-field', {
      [`shape-editor-field-${className}`]: className && className.length,
    });

    return (
      <div className={fieldClass}>
        <label htmlFor={this.id}>{label}</label>
        { this.props.children({ inputId: this.id }) }
      </div>
    );
  }
}


export default BaseShapeEditorField;
