import { fabric } from "fabric";
import DraggableShapeTool from "./DraggableShape";

/**
 * rectangle/square tool
 */
class CircleTool extends DraggableShapeTool {
  makeShape(options) {
    return new fabric.Circle(options);
  }

  getDefaultShapeConfig() {
    return {
      selectable: false,
      radius: 0.1,
      stroke: "#333333",
      strokeWidth: 2,
      fill: "#cccccc",
      opacity: 0.8,
      strokeDashArray: [0, 0],
      startAngle: 20,
      endAngle: 45,
      strokeUniform: true,
    };
  }

  calculateDimensions(x, y, left, top) {
    return { radius: Math.max(0.1, (x - left) / 2) };
  }
}

export default CircleTool;
