import React from "react";

import Select from "../../Fields/Select";
import { shapeUtil } from "@sw-sw/common";

/** @todo factory-pattern to make modifyable instances of {fabric.Path} */
export const arrowTypes = shapeUtil.arrowTypes.map((x, $x) => ({
  ...x,
  index: $x,
}));

/**
 * Field to set SVG stroke dash array
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray
 *
 * @todo support more than one value
 */
class ArrowType extends React.Component {
  render() {
    // const {...rest} = this.props;

    return (
      <Select
        label="Arrow Style"
        options={arrowTypes}
        textKey="name"
        valueKey="index"
        {...this.props}
      />
    );
  }
}

export default ArrowType;
