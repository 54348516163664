import React, { useState } from "react";
import { useParams } from "react-router-dom";
import IframeResizer from "iframe-resizer-react";
import { env } from "../../config";

import Loading from "../Shared/ResourceIndex/Loading";

const { REACT_APP_CMS_BASE_URL } = env;

const ProjectDetail = () => {
  const params = useParams<{ slug: string }>();
  const postName = params.slug;
  const [isLoading, setIsLoading] = useState(true);

  const stopLoading = () => setIsLoading(false);

  if (!REACT_APP_CMS_BASE_URL) {
    console.error("CMS URL not found");

    return null;
  }

  return (
    <section className="wp-site-blocks">
      <main className="wp-container wp-block-group">
        {isLoading ? <Loading /> : null}
        <IframeResizer
          id="target"
          name="target"
          heightCalculationMethod="max"
          inPageLinks
          log
          frameBorder={"no"}
          src={`${REACT_APP_CMS_BASE_URL}/${postName}`}
          style={{
            width: "1px",
            minWidth: "100%",
            height: isLoading ? 0 : "auto",
          }}
          onLoad={stopLoading}
        ></IframeResizer>
      </main>
    </section>
  );
};

export default ProjectDetail;
