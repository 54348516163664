import React, { useEffect, useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import AuthenticationContext from '../../contexts/AuthenticationContext';
import userApi from '../../utils/api/user';

// export interface IRedirectRouteProps {
//   location: {
//     pathname: string;
//   };
// }

const IdentityAuth: React.FC = () => {
  const location = useLocation();
  const authStore = useContext(AuthenticationContext);

  //get token from url
  const urlSearchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const token = urlSearchParams.get('token');

    userApi.saveToken(token);
    authStore.identityAuth(token);
  }, [urlSearchParams.get('token')]);

  if (authStore.loggedIn) {
    return <Redirect to={urlSearchParams.get('to') || '/'} />;
  } else {
    return <p>... redirecting</p>;
  }
};

export default IdentityAuth;
