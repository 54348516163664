import React, { useContext } from "react";
import ClientContext from "../../../contexts/ClientContext";
import Contacts from "../../Contacts/Contacts";

export const ClientContactsTab = () => {
  const clientContext = useContext(ClientContext);

  return (
    <Contacts
      contacts={clientContext.contacts}
      onDelete={clientContext.deleteContact}
      id={clientContext.client.id}
      parent={clientContext.client.name}
      label={"Client"}
    />
  );
};
