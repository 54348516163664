import { mapUtil } from "@sw-sw/common";
import { LoadingMessage } from "@sw-sw/lib-ui";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InspectionContext from "../../../contexts/InspectionContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import uploadApi from "../../../utils/api/upload";
import { SiteMaps } from "../../Projects/Details/SiteMaps";

/**
 * Annotate findings on project site maps
 */
export const InspectionMap: React.FC = () => {
  const [maps, setMaps] = useState<{ upload: any }[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const inspectionContext = useContext(InspectionContext);
  const projectContext = useContext(ProjectContext) as any;

  useEffect(() => {
    /** @todo optimize these api calls. Use react-query */

    projectContext.loadDocs(projectContext.project.id);

    uploadApi
      .docTypes("Active Site Maps", projectContext.project.id)
      .then<{ upload: any }[] | null>(docType => {
        if (docType.dt) {
          return uploadApi.project.index(
            projectContext.project.id,
            docType.dt[0].id, // ProjectDocTypeModel['id']
          );
        }

        return null;
      })
      .then(siteMaps => {
        if (siteMaps) {
          setMaps(
            siteMaps.filter(siteMap =>
              mapUtil.isSupportedMimeType(siteMap.upload.mime_type),
            ),
          );
        } else {
          setMaps([]);
        }
      });
  }, []);

  useEffect(() => {
    if (projectContext.projectDocs && Array.isArray(maps)) {
      setLoading(false);
    }
  }, [projectContext.projectDocs, maps]);

  if (loading) {
    return <LoadingMessage what="active site maps" showLoadingText />;
  }

  if (maps && maps.length === 0) {
    return (
      <p>
        No site maps have been uploaded. Please upload a site{" "}
        <Link to={`/projects/${projectContext.project.id}/project-docs`}>
          here
        </Link>
      </p>
    );
  }

  return (
    <section className="inspection-map">
      <SiteMaps
        siteMaps={(maps || []).map(map => map.upload)}
        id={projectContext.project.id}
        projectName={projectContext.project.projectName}
        showActions={false}
        inspectionId={inspectionContext.inspection.id}
      />
    </section>
  );
};

export default InspectionMap;
