import React from 'react'
import { isUndefined, isNull } from 'lodash';

import BaseShapeEditorField from './BaseField';


export default function Range({label, onChange, initialValue, value, cast, ...props}) {
  return (
    <BaseShapeEditorField label={label} initialValue={initialValue} cast={cast}>
      {({inputId}) => (
        <input
          id={inputId}
          type="range"
          onChange={e => onChange(cast ? cast.call(null, e.target.value) : e.target.value) }
          value={isUndefined(value) || isNull(value) ? initialValue : value}
          {...props}
        />
      )}
    </BaseShapeEditorField>
  );
}
