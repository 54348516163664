import { difference, uniq, uniqBy } from "lodash";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Client } from "../utils/api/client";
import divisionApi from "../utils/api/division";
import { AppDivisionContext } from "./AppDivisionContext";

export type IProjectListProps = {
  clients: Array<any>;
  setClients: Dispatch<SetStateAction<any>>;
  addClients: (clientIds: Array<number>) => void;
};

const throwError = () => {
  throw new Error("ProjectListProvider not loaded");
};

const Context = React.createContext<IProjectListProps>({
  clients: [],
  setClients: throwError,
  addClients: throwError,
});

export const ProjectListProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const appDivisionContext = useContext(AppDivisionContext);
  const [clients, setClients] = useState<Client[]>([]);

  const addClients = async (clientIds: Array<number>) => {
    try {
      const ids = uniq(
        difference(
          clientIds,
          clients.map(client => client.id),
        ),
      );

      if (ids.length) {
        const data = appDivisionContext.appDivisionId
          ? await divisionApi.clients.index(
              appDivisionContext.appDivisionId,
              undefined,
              undefined,
              ids,
            )
          : [];

        setClients(uniqBy([...clients, ...data], c => c.id));
      }
    } catch (err) {
      throw new Error("Failed to fetch clients");
    }
  };

  return (
    <Context.Provider
      value={{
        clients,
        setClients,
        addClients,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
