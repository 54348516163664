import React, { PropsWithChildren, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { History } from "history";

export type SearchState = {
  query: string;
  setQuery: (input: string) => void;
};

const SearchContext = React.createContext<SearchState>({
  query: "",
  setQuery() {
    throw new Error("SearchProvider not loaded");
  },
});

type SearchHookProps = {
  initialValue?: string;
};

export function useSearch({ initialValue }: SearchHookProps): SearchState {
  const [query, setQuery] = useState<string>(initialValue || "");
  const history = useHistory();

  useEffect(() => updateLocation(query, history), [query]);

  return {
    query,
    setQuery,
  };
}

export const SearchContextProvider: React.FC<
  PropsWithChildren<SearchHookProps>
> = ({ children, ...props }) => {
  const value = useSearch(props);

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export default SearchContext;

function updateLocation(query: string, history: History) {
  history.replace({
    search: query.length ? `search=${query}` : "",
  });
}
