// import moment from "moment";

// import { dateFormat } from ".";

/**
 * @param {{ id: number, created_at: string, author: { name: string }, note: { content: string }}} param0
 */
export function commentableToString({
  // created_at,
  author,
  note,
}: ICommentable) {
  return ` ${author.name}: ${note.content}`;
}

export interface ICommentable {
  id: number;
  created_at?: string;
  createdAt?: string;
  author: {
    name: string;
    id: number;
  };
  note: {
    content: string;
  };
  commentable_id?: number;
  userId?: number;
}
