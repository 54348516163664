import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Paper,
    Typography,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    InputLabel,
    MenuItem,
    OutlinedInput,
    useTheme,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { regionApi } from "../../utils/api/region";
import { AppDivisionContext } from "../../contexts/AppDivisionContext";
import { ActionButtons } from "../Shared/ActionButtons";

export const RegionComponent = () => {
    const theme = useTheme();
    const [collapse, setCollapse] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [region, setRegion] = useState<any[]>([]);
    const [newRegion, setNewRegion] = useState<any>("");
    const [divisionSelected, setDivisionSelected] = useState<any[]>([]);
    const divisions = useContext(AppDivisionContext);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [division, setDivision] = useState<any[]>([]);
    const [editRegionId, setEditRegionId] = useState<number | null>(null);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const reset = () => {
        setNewRegion("");
        setErrorText("");
        setDivisionSelected([])
        setError(false)
    }

    const handleOpen = () => {
        setOpenAddModal(true);
    };

    const handleClose = () => {
        setOpenAddModal(false);
        reset()
    };

    const handleEditOpen = (region: any) => {
        setEditRegionId(region.id);
        setNewRegion(region.name);
        setDivisionSelected(region.divisions.map((div: any) => div.id));
        setOpenEditModal(true);
    };

    const handleEditClose = () => {
        setOpenEditModal(false);
        setEditRegionId(null);
        reset()
    };

    const handleSave = () => {
        if (newRegion.trim() === "") {
            setError(true);
            setErrorText("Region name cannot be empty.");
            return;
        }

        regionApi
            .create({ name: newRegion, division_ids: divisionSelected })
            .then(() => {
                regionApi
                    .index()
                    .then((res: any) => {
                        if (res.length) {
                            setRegion(res);
                            setOpenAddModal(false);
                            reset()
                        }
                    })
                    .catch((err) => {
                        console.log("error caught", err);
                    });
            })
            .catch((err) => {
                setError(true);
                setErrorText(err.message);
            });
    };

    const handleEditSave = () => {
        if (editRegionId === null) return;

        regionApi
            .update(editRegionId, {
                name: newRegion,
                divisionIds: divisionSelected,
            })
            .then(() => {
                regionApi
                    .index()
                    .then((res: any) => {
                        if (res.length) {
                            setRegion(res);
                            setOpenEditModal(false);
                            reset()
                        }
                    })
                    .catch((err) => {
                        console.log("error caught", err);
                    });
            })
            .catch((err) => {
                setError(true);
                setErrorText(err.message);
            });
    };

    const handleDelete = () => {
        if (editRegionId === null) return;
        regionApi.
            delete(editRegionId).then(() => {
                regionApi
                    .index()
                    .then((res: any) => {
                        setRegion(res);
                        setOpenEditModal(false);
                        reset()
                    })
                    .catch((err) => {
                        console.log("error caught", err);
                    });
            })
    };

    const handleChange = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;
        setDivisionSelected(typeof value === "string" ? value.split(",") : value);
    };

    useEffect(() => {
        if (divisions) {
            setDivision(divisions.divisions.map((ele: any) => ({ name: ele.name, id: ele.id })));
        }
    }, [divisions]);

    useEffect(() => {
        regionApi.index().then((res: any) => {
            if (res.length) {
                setRegion(res);
            }
        });
    }, []);

    function getStyles(name: string, personName: string[], theme: any) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    return (
        <Box sx={{ width: "100%" }}>
            <div
                className="settings-main-tenant-text"
                style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                onClick={() => setCollapse(!collapse)}
            >
                <h3>Region(s)</h3>
                <i
                    className={collapse ? "fa fa-chevron-down" : "fa fa-chevron-up"}
                    aria-hidden="true"
                />
            </div>
            <hr></hr>
            <br />

            <div className={collapse ? "administration-hidden" : "administration-visible"}>
                <Box>
                    {region.map((region: any) => (
                        <Paper key={region.id} sx={{ mb: 2, padding: 2 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="subtitle1">{region.name}</Typography>
                                <IconButton color="primary" onClick={() => handleEditOpen(region)}>
                                    <EditIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="body2" color="textSecondary">
                                {region.divisions.map((ele: any) => ele.name).join(", ")}
                            </Typography>
                        </Paper>
                    ))}
                </Box>

                <ActionButtons
                    className="inspection-intervals__create-new-button"
                    primary='+ Create New Region'
                    onClick={handleOpen}
                />

                <Dialog open={openAddModal} onClose={handleClose} fullWidth>
                    <DialogTitle>Add New Region</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="normal"
                            label="Region Name"
                            fullWidth
                            value={newRegion}
                            onChange={(e) => {
                                setNewRegion(e.target.value);
                            }}
                            error={error}
                            helperText={error ? errorText : ""}
                        />
                        <InputLabel id="demo-multiple-name-label">Divisions</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            fullWidth
                            value={divisionSelected}
                            onChange={handleChange}
                            input={<OutlinedInput label="Name" />}
                            MenuProps={MenuProps}
                        >
                            {division.map((div: any) => (
                                <MenuItem
                                    key={div.id}
                                    value={div.id}
                                    style={getStyles(div.name, divisionSelected, theme)}
                                >
                                    {div.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSave} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Edit Dialog */}
                <Dialog open={openEditModal} onClose={handleEditClose} fullWidth>
                    <DialogTitle>Edit Region</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="normal"
                            label="Region Name"
                            fullWidth
                            value={newRegion}
                            onChange={(e) => {
                                setNewRegion(e.target.value);
                            }}
                            error={error}
                            helperText={error ? errorText : ""}
                        />
                        <InputLabel id="edit-multiple-name-label">Divisions</InputLabel>
                        <Select
                            labelId="edit-multiple-name-label"
                            id="edit-multiple-name"
                            multiple
                            fullWidth
                            value={divisionSelected}
                            onChange={handleChange}
                            input={<OutlinedInput label="Name" />}
                            MenuProps={MenuProps}
                        >
                            {division.map((div: any) => (
                                <MenuItem
                                    key={div.id}
                                    value={div.id}
                                    style={getStyles(div.name, divisionSelected, theme)}
                                >
                                    {div.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDelete} color="warning">
                            Delete
                        </Button>
                        <Button onClick={handleEditClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleEditSave} color="primary">
                            Save Changes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <br /><br /><br />
        </Box>
    );
};

export default RegionComponent;
