import { asSchemaDefinition, SchemaFieldsWithContext } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import AppContext from "../../../contexts/AppContext";
import { AppDivisionContext } from "../../../contexts/AppDivisionContext";
import AuthenticationContext from "../../../contexts/AuthenticationContext";
import userApi from "../../../utils/api/user";
import FormActions from "../../Shared/form/modal/FormActions";
import SuccessModal from "../../Shared/SuccessModal/SuccessModal";

interface MatchParams {
  uuid: string;
}

interface ResetPasswordProps extends RouteComponentProps<MatchParams> {}

const schema = asSchemaDefinition({
  password: {
    controlType: UIControlType.password,
    label: "New Password",
    className: "bottom-outline",
    validation: {
      required: true,
    },
  },
});

const ResetPassword: React.FC<ResetPasswordProps> = ({ match }) => {
  const authStore = useContext(AuthenticationContext);
  const appStore = useContext(AppContext);
  const appDivisionContext = useContext(AppDivisionContext)

  const [showSuccess, setShowSuccess] = useState(false);
  const [redirectToLogout, setRedirectToLogout] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const [redirectToForgot, setRedirectToForgot] = useState(false);

  const { uuid }: { uuid?: string } = match.params;

  const successRedirect = async () => {
    await appStore.reloadInitialData();
    setRedirectToDashboard(true);
  };

  const hideModal = () => {
    setShowSuccess(false);
  };

  const handleSubmit = async (formData: { password: string }) => {
    await authStore.resetPassword(uuid, formData, () => {});
    setShowSuccess(true);
    setTimeout(successRedirect, 5000);

    return;
  };

  const handleCancel = () => {
    setRedirectToLogout(true);
  };

  useEffect(() => {
    if (uuid) {
      userApi
        .validate(uuid)
        .then(validUser => {
          if (!validUser) {
            setRedirectToLogout(true);
          }
        })
        .catch(() => {
          setRedirectToForgot(true);
        });
    } else {
      setRedirectToLogout(true);
    }
  }, []);

  if (redirectToDashboard === true) {
    return <Redirect to={appDivisionContext.getPath("/dashboard")} />;
  }
  if (redirectToForgot === true) {
    return <Redirect to={"/forgot"} />;
  }
  if (redirectToLogout === true) {
    return <Redirect to={"/logout"} />;
  }

  return (
    <>
      <SchemaFieldsWithContext className="reset-password-form" schema={schema}>
        <FormActions
          submitText="Submit"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          showCancel={false}
          small={false}
          showSubmit={true}
          showErrorText={true}
          errorMessageText="Invalid form data. Please review errors above."
          cancelText="Cancel"
        />
        <SuccessModal
          classes="reset-password"
          show={showSuccess}
          handleClose={hideModal}
          handleSubmit={successRedirect}
          title="Password Reset"
          message="Your password has been reset"
          submitBtnText="Go to Dashboard"
        />
      </SchemaFieldsWithContext>
    </>
  );
};

export default ResetPassword;
