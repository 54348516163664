import { camelCase } from "lodash";

import { positionableUtil } from "@sw-sw/common";
import { positionableDataApis } from "./api";

/**
 * {utils/positionable} provides positionableUtil and exports commonly used functions
 */

const cachedApiNames = {}; // { [positionableType: string]: string }

export const getApiPrefix = positionableType => {
  if (!cachedApiNames[positionableType]) {
    cachedApiNames[positionableType] = camelCase(positionableType);
  }

  return cachedApiNames[positionableType];
};

export const getUtil = positionableType => {
  if (positionableUtil[getApiPrefix(positionableType)]) {
    return positionableUtil[getApiPrefix(positionableType)];
  }

  throw new Error(
    `Positionable Type (${positionableType}) does not have {positionableUtil} implementation (${getApiPrefix(
      positionableType,
    )})`,
  );
};

/**
 * Get the api (XHR) for the specified positionable type
 */
export const getApi = positionableType => {
  const apiName = `${getApiPrefix(positionableType)}Api`;

  if (positionableDataApis[apiName]) {
    return positionableDataApis[apiName];
  }

  throw new Error(
    `Positionable Type (${positionableType}) does not have {api} implementation (${apiName})`,
  );
};

export const getIcon = (positionableType, sourceModel) =>
  getUtil(positionableType).getIcon(sourceModel);

export const getName = (positionableType, sourceModel) =>
  getUtil(positionableType).getName(sourceModel);
