import { useEffect, useState } from "react";
import uploadApi from "../../utils/api/upload";

interface IDocType {
  id: string;
  name: string;
}

export function useDocType(
  projectId: number,
  name: string,
  onError: (err: string) => void,
): IDocType | null {
  const [state, setState] = useState<null | IDocType>(null);

  useEffect(() => {
    uploadApi
      .docTypes(name, projectId)
      .then(docType => setState(docType.dt[0].id))
      .catch(err => {
        console.error("useProjectSiteMapsDocType", err);
        onError("Cannot get doc type: " + name);
      });
  }, []);

  return state;
}
