import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import InspectionContext from '../../../contexts/InspectionContext';
import PrintModal from '../../Shared/Print/PrintModal';

export interface ISiteMapPrintProps {
  onClose: () => void;
  targetDoc?: any;
}

export const getMapOptionsSchema = () => ({
  map_options: {
    label: 'Map Options',
    controlType: UIControlType.plainText,
    style: { marginBottom: 0 },
  },
  include_map_changelog: {
    label: 'Include Changelog',
    controlType: UIControlType.checkbox,
    style: {},
  },
});

/**
 * @param {{[k:string]: any}} formData
 */
const getPrintSiteMapSchema = () => {
  const schema: {
    [key: string]: any;
  } = {
    ...getMapOptionsSchema(),
  };

  return schema;
};

const SiteMapPrint: React.FC<ISiteMapPrintProps> = ({ onClose, targetDoc }) => {
  const context = useContext(InspectionContext);

  return (
    <PrintModal
      jobName='sitemap'
      jobData={{
        inspectionId: context.inspectionId,
        targetDocId: targetDoc ? targetDoc.id : null,
      }}
      statusUpdateKey={context.inspectionId}
      onClose={onClose}
      formSchema={getPrintSiteMapSchema}
      initialFormData={{
        include_map_changelog: false,
      }}
      modalTitle='Print Site Map'
      validateData={null}
    />
  );
};

SiteMapPrint.propTypes = {
  onClose: PropTypes.func.isRequired,
  targetDoc: PropTypes.object,
};

export default SiteMapPrint;
