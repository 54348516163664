import { env } from "../../config";

type ManualApiParams = {
  s: string;
  p: number;
};

const { REACT_APP_CMS_BASE_URL } = env;

export const manualApi = {
  index: async (
    pageNumber: number = 0,
    searchQuery: string = "",
  ): Promise<Array<Record<string, any>>> => {
    const params: Partial<ManualApiParams> = {};

    if (searchQuery) {
      params.s = searchQuery;
    }

    if (pageNumber) {
      params.p = pageNumber;
    }

    if (!REACT_APP_CMS_BASE_URL) {
      console.error("CMS URL not found");

      return [];
    }

    try {
      const response = await fetch(
        `${REACT_APP_CMS_BASE_URL}/wp-json/wp/v2/posts`,
      );
      const content = await response.json();

      return content;
    } catch (error) {
      console.error(error);

      return [];
    }
  },
};

export default manualApi;
