import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { getIcon, getName, getUtil } from "../../../utils/positionable";
import AddIcon from "../../Shared/Icons/Add";
import CaretIcon from "../../Shared/Icons/Caret";
import SourceModelMenuUI from "../Interaction/Pointer/ContextMenu/SourceModelMenuUI";
import DocumentListener from "../../Shared/DocumentListener";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zipWithIndex } from "../../../utils";
import TenantFeatureContext from "../../../contexts/TenantContext";

const canEdit = (positionableType, sourceModel) => {
  return getUtil(positionableType).canEditSourceModel(sourceModel);
};

/**
 * A collapsible list of positionable source models
 *
 * @todo collapsible
 */
function PositionableList({
  items,
  title,
  type,
  isActive,
  toggleActive,
  activeItemIndex,
  toggleActiveItem,
  setInteractionMode,
  permCheck,
  label,
  actions,
  enableSearch,
  mode,
  readOnly: readOnlyProp,
  dropMenu
}) {
  const className = [
    "positionable-list",
    "pure-menu",
    {
      "positionable-list--open": isActive,
    },
  ];
  const titleClassName = [
    "positionable-list-title",
    "pure-menu-item",
    {
      "pure-menu-active": isActive,
    },
  ];

  const [showSourceModelMenu, setShowSourceModelMenu] = useState(false);
  const [menuProps, setMenuProps] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const [visibleItems, setVisibleItems] = useState(zipWithIndex(items));
  const readOnly = readOnlyProp || mode === "readOnly";
  const featureContext = useContext(TenantFeatureContext)

  useEffect(() => {
    if (searchVal) {
      setVisibleItems(
        zipWithIndex(items).filter(item => {
          return getName(type, item)
            .toLowerCase()
            .includes((searchVal || "").toLowerCase());
        }),
      );
    } else {
      setVisibleItems(zipWithIndex(items));
    }
  }, [searchVal]);

  useEffect(() => {
    setSearchVal("");
    setVisibleItems(zipWithIndex(items));
  }, [items]);

  if (["Control Measures", "Pollutants", "Notes"].includes(title) && !featureContext.tenantHasFeature("Site Map - Editor Updates")) {
    return null
  }
  if (["Findings"].includes(title) && !featureContext.tenantHasFeature("Site Map - Finding Locations")) {
    return null
  }

  return (
    <div className={classnames(className)}>
      <h3 className={classnames(titleClassName)}>
        {/* @todo onClick handler in link */}
        <span className="pure-menu-link">
          <span className="positionable-list-group-expand positionable-list-group-action">
            <CaretIcon
              rotate={isActive ? 0 : -90}
              onClick={e => {
                e.preventDefault();
                toggleActive();
                dropMenu(isActive ? false : true)
              }}
            />
          </span>

          <span
            className="positionable-list-group-title"
            onClick={e => {
              e.preventDefault();
              toggleActive();
              dropMenu(isActive ? false : true)
            }}
          >
            {title}
          </span>
          {permCheck(actions.create, label) && !readOnly && (
            <span
              className="positionable-list-group-add positionable-list-group-action"
              onClick={e => {
                e.preventDefault();

                toggleActive(true);
                setInteractionMode("createSourceModel");
                dropMenu(isActive ? false : true)
              }}
            >
              <AddIcon />
            </span>
          )}
        </span>
      </h3>

      {enableSearch && isActive && items.length > 0 && (
        <div className="positionable-list-search">
          <input
            className="form-control"
            value={searchVal}
            onChange={e => setSearchVal(e.target.value)}
            placeholder={`Search ${title}`}
          />
          {searchVal && (
            <FontAwesomeIcon icon="times" onClick={() => setSearchVal("")} />
          )}
        </div>
      )}

      <ul className="positionable-list-list pure-menu-list">
        {permCheck(actions.read, label) &&
          visibleItems.map(positionable => {
            const index = positionable.index;
            const itemClassName = [
              "positionable-list-item",
              "pure-menu-item",
              {
                "pure-menu-selected pure-menu-active":
                  index === activeItemIndex,
              },
            ];
            const img = getIcon(type, positionable);
            const name = getName(type, positionable);

            return (
              <li
                className={classnames(itemClassName)}
                key={positionable.id}
                onClick={e => {
                  e.preventDefault();
                  if (permCheck("all", "Map Drawing") && !readOnly) {
                    toggleActiveItem(index);
                  }
                }}
              >
                <div className="pure-menu-link pointer">
                  <span>
                    {img && <img src={img.src} alt={name} />}
                    <span>{name}</span>
                  </span>

                  {canEdit(type, positionable) &&
                    permCheck(actions.update, label) &&
                    !readOnly && (
                      <i
                        className="positionable-list-item-menu-toggle fa fa-ellipsis-v"
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();

                          const targetBox = e.target.getBoundingClientRect();

                          setMenuProps({
                            left: targetBox.left,
                            top: targetBox.top,
                            onEdit: () => {
                              toggleActiveItem(index);
                              setInteractionMode("editSourceModel", {
                                sourceModelIndex: index,
                              });
                            },
                            onDelete: () => {
                              toggleActiveItem(index);
                              setInteractionMode("deleteSourceModel", {
                                sourceModelIndex: index,
                              });
                            },
                          });
                          setShowSourceModelMenu(true);
                        }}
                      />
                    )}
                </div>
              </li>
            );
          })}

        {visibleItems.length === 0 && (
          <li className="pure-menu-item pure-menu-disabled">
            <span className="pure-menu-link">No available items</span>
          </li>
        )}
      </ul>

      {showSourceModelMenu && menuProps && !readOnly && (
        <div className="positionable-list-menu">
          <DocumentListener
            eventName="click"
            handler={() => {
              setShowSourceModelMenu(false);
              setMenuProps(null);
            }}
          />
          <SourceModelMenuUI {...menuProps} />
        </div>
      )}
    </div>
  );
}

PositionableList.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  /** positionable source models */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      // ... others (specific to source model's schema)
    }).isRequired,
  ).isRequired,

  isActive: PropTypes.bool.isRequired,
  toggleActive: PropTypes.func.isRequired,

  activeItemIndex: PropTypes.number,
  toggleActiveItem: PropTypes.func.isRequired,

  setInteractionMode: PropTypes.func.isRequired,
  permCheck: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    create: PropTypes.string,
    read: PropTypes.string,
  }),
  enableSearch: PropTypes.bool.isRequired,

  mode: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

PositionableList.defaultProps = {
  isActive: false,
  enableSearch: false,
  readOnly: false,
  actions: {
    read: "read",
    create: "create",
    update: "update",
  },
};

export default PositionableList;
