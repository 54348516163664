import React, { useContext, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import userApi from '../utils/api/user';
import AppContext from './AppContext';

export const AuthenticationContext = React.createContext();

/**
 * UserStore
 *
 * Manage user authentication only. (not authorization)
 *
 * @important don't manage roles & permissions here
 */
export function AuthenticationStore(props) {
  const client = useQueryClient();
  const appStore = useContext(AppContext);
  const [loggedIn, setLoggedIn] = useState(appStore.has('user'));

  useEffect(() => {
    setLoggedIn(appStore.has('user'));
  }, [appStore]);

  return (
    <AuthenticationContext.Provider
      value={{
        loggedIn,
        verifyUser: async (email, password) => {
          const tenants = await userApi.verifyUser(email, password);

          if (tenants) {
            return tenants;
          }
          throw new Error('Invalid response data');
        },
        login: async (email, password, tenant) => {
          const token = await userApi.login(email, password, tenant);

          if (token) {
            await appStore.reloadInitialData();

            return token;
          }

          throw new Error('Invalid response data');
        },
        publiclogin: async (email, password, tenant) => {
          const success = await userApi.publiclogin(email, password, tenant);

          if (success) {
            await appStore.reloadInitialData();

            return true;
          }

          throw new Error('Invalid response data');
        },
        publicLoginWithProject: async (email, password, projectId) => {
          const success = await userApi.publicLoginWithProject(
            email,
            password,
            projectId,
          );

          if (success) {
            await appStore.reloadInitialData();

            return true;
          }

          throw new Error('Invalid response data');
        },
        
        logout: () => {
          userApi.logout();
          client.removeQueries();
          appStore.set('user', null);
        },
        resetPassword: (token, form) => userApi.resetPassword(token, form),
        register: (token, form) => userApi.register(token, form),
        getTenantByName: (tenant) => userApi.getTenantByName(tenant),
        identityAuth: async (token) => {
          if (token) {
            await appStore.reloadInitialData();
          }
        },
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationContext;
