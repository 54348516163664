import { SchemaFieldsWithContextUI } from "@sw-sw/lib-form";
import React, { MouseEventHandler, useContext } from "react";
import DivisionContext from "../../../../contexts/DivisionContext";
import useDivisionResource from "../../../../hooks/divisionResource";
import useResource from "../../../../hooks/resource";
import { FormModalWithContext, SubmitHandlerFn } from "../../../Shared/form";
import Loading from "../../../Shared/ResourceIndex/Loading";

export interface IDivisionResourceFormProps {
  handleSubmit: SubmitHandlerFn;
  handleClose: MouseEventHandler;
  title?: string;
}

export const DivisionResourceForm: React.FC<IDivisionResourceFormProps> = ({
  handleClose,
  handleSubmit,
  title = "Add Resource",
}) => {
  const divisionContext = useContext(DivisionContext);
  const { tabs, selectedTab, selectedDivision } = divisionContext;

  if (!selectedDivision) {
    throw new Error("selectedDivision is required");
  }

  const { formSchema } = tabs[selectedTab];
  const schema = {
    ...formSchema,
  };
  const resourceOptions = {
    disablePagination: true,
    resource: selectedTab,
  };
  const allResources = useResource(resourceOptions);
  const divisionResources = useDivisionResource({
    divisionId: selectedDivision.id,
    ...resourceOptions,
  });
  const divisionModelIds = divisionResources.data.map(_ => _.id);

  if (
    schema[selectedTab] &&
    !allResources.loading &&
    !divisionResources.loading
  ) {
    schema[selectedTab].options = allResources.data.filter(
      ({ id }) => !divisionModelIds.includes(id),
    );
  }

  if (allResources.loading || divisionResources.loading) {
    return <Loading />;
  }

  return (
    <FormModalWithContext
      onCancel={handleClose}
      onSubmit={handleSubmit}
      modalProps={{
        title: title,
      }}
    >
      <SchemaFieldsWithContextUI schema={schema} />
    </FormModalWithContext>
  );
};

export default DivisionResourceForm;
