import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import DivisionAdd from "./DivisionAdd";
import RolesContext from "../../contexts/RolesContext";
import ResourceIndex from "../Shared/ResourceIndex/ResourceIndex";
import { DivisionContext } from "../../contexts/DivisionContext";

export interface IDivisionsProps {}

const Divisions: React.FC<IDivisionsProps> = () => {
  const roleStore = useContext(RolesContext);
  const divisionContext = useContext(DivisionContext);

  useEffect(() => {
    divisionContext.setDisableSwitcher(true);

    return () => divisionContext.setDisableSwitcher(false);
  }, []);

  return (
    <ResourceIndex
      resourceName="divisions"
      resourceTableHeaders={[
        ["Name", "name"],
        ["Created At", "createdAt"],
      ]}
      resourceSearchProps={["name"]}
      enableRead={roleStore.userHasPermission("read", "Divisions")}
      enableClick={
        roleStore.userHasPermission("update", "Divisions") ? true : false
      }
      enablePagination={false}
      enableCallToAction={roleStore.userHasPermission("create", "Divisions")}
      callToActionContent="Add Division"
      renderReader={division => (
        <Redirect push to={`/divisions/${division.id}`} />
      )}
      renderCallToAction={(handleClose: any) => (
        <DivisionAdd handleClose={handleClose} />
      )}
      hideCallToActionInMobile={false}
    />
  );
};

export default Divisions;
