import { useState, useEffect } from "react";
import { ICommentable } from "../utils/notes";

export function useModalControls() {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [selected, setSelected] = useState<ICommentable | null>(null);

  useEffect(() => {
    if (!selected) {
      if (showEdit) {
        setShowEdit(false);
      }

      if (showDelete) {
        setShowDelete(false);
      }
    }
  }, [selected, showEdit, showDelete]);

  return {
    showEdit,
    setShowEdit,
    showDelete,
    setShowDelete,
    selected,
    setSelected,
  };
}
