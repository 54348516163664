import { LoadingMessage, Modal } from "@sw-sw/lib-ui";
import { Inspection } from "@sw-sw/lib-domain";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import inspectionFindingReopeningLogApi from "../../../utils/api/inspectionFindingReopeningLog";
import { ProjectContext } from "../../../contexts/ProjectContext";

export interface IFindingReopenconfirmationModalProps {
  onClose: () => void;
  findingId: number;
  onSuccess: () => void;
}

const FindingReopenConfirmationModal: React.FC<IFindingReopenconfirmationModalProps> =
  ({ onClose, findingId, onSuccess }) => {
    const appContext = useContext(AppContext);
    const projectContext = useContext(ProjectContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [inspections, setInspections] = useState<Inspection[]>([]);
    const [errorMsg, setErrorMsg] = useState<string>("");

    const onSubmit = async () => {
      return inspectionFindingReopeningLogApi
        .update(findingId)
        .then(() => {
          onSuccess();
          appContext.triggerSuccessNotification();
          onClose();
        })
        .catch(error => setErrorMsg(error));
    };

    const getInspectionList = () => {
      const listItems: Array<JSX.Element> = [];

      projectContext.inspections.forEach((inspection, i) => {
        if (inspections.map(insp => insp.id).includes(inspection.id)) {
          listItems.push(
            <li>{`# ${projectContext.inspections.length - i} | ${
              projectContext.inspections[i].created_date
            } | ${projectContext.inspections[i].type}`}</li>,
          );
        }
      });

      return <ul>{listItems}</ul>;
    };

    useEffect(() => {
      inspectionFindingReopeningLogApi.show(findingId).then(data => {
        setInspections(data.inspections);
        setLoading(false);
      });
    }, []);

    return (
      <Modal
        title="Edit Finding Confirmation"
        subTitle="Are you sure that you want to open this finding for editing? The following inspections will have their compliance signatures removed and their inspection questions unlocked. This action cannot be undone."
        show={true}
        submitBtnText="Confirm"
        handleSubmit={onSubmit}
        disableSubmit={loading}
        handleClose={onClose}
        cancelBtn={true}
        errorMsg={errorMsg}
      >
        {loading && (
          <div className="center">
            <LoadingMessage what="Finding data" />
          </div>
        )}
        {!loading && getInspectionList()}
      </Modal>
    );
  };

export default FindingReopenConfirmationModal;
