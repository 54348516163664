import React, { useEffect, useContext, useState } from "react";
import { number, shape, func, arrayOf, string } from "prop-types";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import RolesContext from "../../../contexts/RolesContext";
import { FormSchemaFields } from "@sw-sw/lib-form";
import FormActions from "../../Shared/form/modal/FormActions";
import commentableApi from "../../../utils/api/commentable";
import ProjectPermissionContext from "../../../contexts/ProjectPermissionContext";
import NotesFormControl from "../Notes/NotesFormControl";
import { get } from "lodash";

/**
 * Get form schema with optional templates. (used for CDOT)
 */
function getSchema(templates = null) {
  const hasTemplates = templates && templates.length;

  return {
    newNote: {
      label: "Add note",
      controlType: hasTemplates
        ? UIControlType.textareaTmpl
        : UIControlType.textarea,
      templates: hasTemplates ? templates : undefined,
      "aria-labelledby": "comments-label",
      validation: { required: true },
    },
  };
}

export const getCertificationNotesSchema = (
  canCreate = false,
  canRead = false,
  canEdit = false,
  canDelete = false,
) => {
  const schema = {};

  if (canRead) {
    /** display-only "field" for previous comments */
    schema.notes = {
      controlType: UIControlType.custom,
      renderControl: controlProps => (
        <NotesFormControl
          {...controlProps}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      ),
    };
  }

  return schema;
};

function NotesFormUI({
  handleSuccess,
  initialNotes,
  inspectionId,
  templates,
  canAddNote,
}) {
  const formStore = useContext(FormContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const permCheck = useContext(RolesContext).userHasPermission;

  useEffect(() => {
    formStore.set("notes", initialNotes);
  }, [initialNotes]);

  function handleSubmit() {
    return commentableApi
      .create("inspections", inspectionId, {
        note: { content: formStore.value.newNote },
      })
      .then(newNote => {
        handleSuccess();
        formStore.set("notes", [...get(formStore.value, "notes", []), newNote]);

        // reset form
        formStore.set("newNote", "");
        formStore.setBusy(false);
      });
  }

  return (
    <div className="additional-notes pure-g pure-u">
      <h3 id="comments-label">
        Additional Site Notes & Information (Optional)
      </h3>
      <div>
        <FormSchemaFields
          schema={{
            ...getCertificationNotesSchema(
              projectPermissionContext.readOnly
                ? false
                : permCheck("update", "Inspection Notes"),
              permCheck("read", "Inspection Notes"),
              projectPermissionContext.readOnly
                ? false
                : permCheck("update", "Inspection Notes"),
              projectPermissionContext.readOnly
                ? false
                : permCheck("delete", "Inspection Notes"),
            ),
          }}
          formData={formStore.value}
          onChange={formStore.set}
        />
      </div>
      {canAddNote && (
        <>
          {" "}
          <FormSchemaFields
            formData={formStore.value}
            onChange={formStore.set}
            schema={getSchema(templates)}
          />
          <FormActions
            showCancel={false}
            submitText="Save Note"
            onSubmit={handleSubmit}
            showErrorText={false}
          />
        </>
      )}
    </div>
  );
}

/**
 * Inspection notes and observations
 */
function NotesForm(props) {
  const [initialNotes, setInitialNotes] = useState([]);
  const userHasPermission = useContext(RolesContext).userHasPermission;
  const projectPermissionContext = useContext(ProjectPermissionContext);

  useEffect(() => {
    commentableApi.index("inspections", props.inspectionId).then(notes => {
      setInitialNotes(notes);
    });
  }, []);

  return (
    <FormContextProvider>
      <NotesFormUI
        initialNotes={initialNotes}
        canAddNote={
          projectPermissionContext.readOnly
            ? false
            : userHasPermission("create", "Inspection Notes")
        }
        {...props}
      />
    </FormContextProvider>
  );
}

export const commentShape = shape({
  createdAt: string,
  note: shape({
    content: string,
  }),
  author: shape({
    name: string,
  }),
});

const sharedProps = {
  inspectionId: number.isRequired,
  handleSuccess: func.isRequired,
  templates: arrayOf(
    shape({
      label: string.isRequired,
      content: string.isRequired,
    }).isRequired,
  ),
};

NotesForm.propTypes = {
  ...sharedProps,
};
NotesFormUI.propTypes = {
  initialNotes: arrayOf(commentShape).isRequired,
  ...sharedProps,
};

export default NotesForm;
