import { Context as InteractionContext } from "../Interaction/InteractionContext";
import { useContext } from "react";

/**
 * get "current" map feature (interaction context modedata)
 *
 * @return {import('ol').Feature}
 */
function useFeature() {
  const context = useContext(InteractionContext);

  if (context && context.modeData && context.modeData.feature) {
    return context.modeData.feature;
  }

  return null;
}

export default useFeature;
