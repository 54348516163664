import React from "react";

import { LoadingIcon } from "@sw-sw/lib-ui";
import { Modal } from "@sw-sw/lib-ui";

export function MapLoadingSpinner() {
  return (
    <Modal show hideButtons isSmall disableClose>
      <div className="map-loader">
        <LoadingIcon />
        <p>Loading Map Editor...</p>
      </div>
    </Modal>
  );
}
