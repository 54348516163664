import React, { useContext } from "react";

import { Context as InteractionContext } from "./../InteractionContext";
import { Context as DataSourceContext } from "../../DataSourceContext";
import { Context as PositionableInstanceDataContext } from "../../Positionable/InstanceDataContext";
import { Modal } from "@sw-sw/lib-ui";
import usePositionableSourceModel from "../../hooks/usePositionableSourceModel";
import DeleteFindingModal from "../../../Inspections/Findings/DeleteFindingModal";

function DeleteSourceModel() {
  const sourceModel = usePositionableSourceModel();

  const interactionContext = useContext(InteractionContext);
  const dataContext = useContext(PositionableInstanceDataContext);
  const dataSource = useContext(DataSourceContext);

  const forms = {
    findings: () => (
      <DeleteFindingModal
        show={true}
        handleClose={() => interactionContext.setDefaultMode()}
        onDelete={() => Promise.resolve()}
        findingId={sourceModel.id}
        inspectionId={sourceModel.InspectionFindingModel ? 
          sourceModel.InspectionFindingModel.inspection_id : 
          sourceModel.initial_inspection_id
        }
      />
    ),
  };

  const renderForm = () => {
    if (forms[interactionContext.positionableType]) {
      const Form = forms[interactionContext.positionableType];

      return <Form />;
    } else {
      return (
        <Modal
          show={true}
          handleClose={() => interactionContext.setDefaultMode()}
          handleSubmit={() =>
            dataContext
              .deleteSourceModel(
                interactionContext.positionableType,
                sourceModel,
                {
                  note: "hey",
                },
              )
              .then(() => interactionContext.setDefaultMode())
          }
          classes="delete-source-model-modal"
          submitBtnText="Delete"
          hideHeader
          isSmall
          disableClose
          cancelBtn
          dismissOnEsc
          dismissOnBgClick
        >
          <p>{`Are you sure you want to delete this ${dataSource
            .getDataTypeLabel(interactionContext.positionableType, false)
            .toLowerCase()}?`}</p>
        </Modal>
      );
    }
  };

  if (!sourceModel) {
    return null;
  }

  return renderForm();
}

DeleteSourceModel.propTypes = {};

export default DeleteSourceModel;
