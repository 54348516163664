import React, { useContext } from "react";
import classnames from "classnames";
import InspectionQuestion from "./InspectionQuestion";
import AddQuestion from "./AddQuestion";
import { Question } from "@sw-sw/lib-inspection-templates";
import QuestionGroupContext from "../../../contexts/QuestionGroupContext";
import { InspectionQuestionProvider } from "../../../contexts/InspectionQuestionContext";
import Loading from "../../Shared/ResourceIndex/Loading";
export interface IInspectionQuestionsProps {
  questions: Array<Question & { questionWidth: string }>;
  onReorder: () => void;
}

const InspectionQuestions: React.FC<IInspectionQuestionsProps> = ({
  questions,
  onReorder,
}) => {
  const questionGroupContext = useContext(QuestionGroupContext);
  const newQuestionOrder =
    questions.length > 0
      ? Math.max(...questions.map(q => q.sort_order)) + 1
      : 0;

  if (!questionGroupContext.questionGroup.id) {
    return <Loading />;
  }

  return (
    <InspectionQuestionProvider
      initialValue={{ groupId: questionGroupContext.questionGroup.id }}
    >
      <div
        className={classnames(
          questionGroupContext.questionGroup.layout_type === "FORM"
            ? "form-questions"
            : "table-questions",
          "editor-questions",
        )}
      >
        {questions
          .sort((a, b) => (a.sort_order < b.sort_order ? -1 : 1))
          .map((question, index) => {
            return (
              <InspectionQuestion
                first={index === 0 ? true : false}
                key={question.id}
                question={question}
                onReorder={onReorder}
              />
            );
          })}
        {
          questionGroupContext.questionGroup.name === "Condition Group" || (
            <AddQuestion order={newQuestionOrder} />
          )
        }
      </div>
    </InspectionQuestionProvider>
  );
};

export default InspectionQuestions;
