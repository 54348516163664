import { FormContextProvider, FormSchemaProps } from "@sw-sw/lib-form";
import React, { PropsWithChildren } from "react";
import FormModal, { IFormModalProps } from "./FormModal";

export interface IFormModalWithContextProps
  extends IFormModalProps,
    Pick<FormSchemaProps, "initialFormData"> {}

/**
 * Controller for modal and form actions. Receives custom form UI via children
 * @param {} props
 */
export const FormModalWithContext: React.FC<
  PropsWithChildren<IFormModalWithContextProps>
> = ({ initialFormData, ...props }) => {
  return (
    <FormContextProvider initialValue={initialFormData}>
      <FormModal {...props} />
    </FormContextProvider>
  );
};

export default FormModalWithContext;
