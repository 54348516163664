import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Simple listener on global "document" variable
 */
function DocumentListener({ eventName, filter, handler }) {
  const onEvent = useCallback(
    e => {
      if (filter && !filter(e)) {
        return;
      }

      return handler(e);
    },
    [filter, handler],
  );

  useEffect(() => {
    document[`on${eventName}`] = onEvent;

    return () => {
      document[`on${eventName}`] = null;
    };
  }, [eventName, onEvent]);
  
return <div />;
}

DocumentListener.propTypes = {
  eventName: PropTypes.string.isRequired,
  filter: PropTypes.func,
  handler: PropTypes.func.isRequired,
};

export default DocumentListener;
