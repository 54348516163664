import React from "react";

interface IToastErrorProps {
  message?: string;
  error: string;
}

const ToastError: React.FC<IToastErrorProps> = ({
  message = "Error: ",
  error,
}) => {
  return (
    <>
      <div className="center">{message}</div>
      <div className="error center">{error}</div>
    </>
  );
};

export default ToastError;
