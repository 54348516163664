import { QuestionType, QuestionFeature } from "@sw-sw/lib-inspection-templates";
import { isNull, sortBy } from "lodash";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import AppContext from "../../../contexts/AppContext";
import { InspectionContext } from "../../../contexts/InspectionContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import ProjectPermissionContext from "../../../contexts/ProjectPermissionContext";
import { QuestionCommentContext } from "../../../contexts/QuestionCommentContext";
import RolesContext from "../../../contexts/RolesContext";
import { inspectionQuestionApi } from "../../../utils/api/inspectionQuestion";
import { getFindingLabel } from "../../../utils/findings";
import Loading from "../../Shared/ResourceIndex/Loading";
import { DynamicTemplateQuestions } from "../Questions/DynamicTemplateQuestions";
import { LegacyTemplateQuestions } from "../Questions/LegacyTemplateQuestions";
import ToastError from "../../Shared/ToastError/ToastError";
import { certificationApi } from "../../../utils/api/certification";

export type InspectionApiErrorType = {
  response: { data: { message: string } };
};

export const InspectionQuestions = () => {
  const permCheck = useContext(RolesContext).userHasPermission;
  const appContext = useContext(AppContext);
  const projectStore = useContext(ProjectContext) as any;
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const inspectionStore = useContext(InspectionContext);
  const questionCommentContext = useContext(QuestionCommentContext);
  const inspection = inspectionStore.inspection;
  const inspectionDate = moment.utc(inspection.created_date);

  const history = useHistory();

  const inspectionQuestionQuery = useQuery({
    queryFn: () => inspectionQuestionApi.show(inspectionStore.inspection.id),
    queryKey: ["inspectionQuestions", inspectionStore.inspection.id],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const signedComplianceCertQuery:any = useQuery({
    queryFn: () => certificationApi.getAllSignedCompliancesWithCount(inspectionStore.inspection.id),
    queryKey: ["allSignedCompliancesWithCount", inspectionStore.inspection.id],
  });
  
  useEffect(() => {
    if (inspectionQuestionQuery.isFetched) {
      // set inspection type feature question
      inspectionQuestionQuery.data.template.question_groups.forEach(
        (questionGroup: {
          questions: Array<{
            answer: string;
            features: Array<QuestionFeature>;
          }>;
        }) => {
          const templateArr = ["Lennar (Colorado)", "Colorado"]
          questionGroup.questions.forEach(question => {
            if (question.features.includes(QuestionFeature.isInspectionType) && !templateArr.includes(inspectionQuestionQuery.data.template.name)) {
              question.answer = inspection.type;
            }
          });
        },
      );
 
      inspectionStore.setTemplate(inspectionQuestionQuery.data.template);
    }
  }, [inspectionQuestionQuery.data]);

  if (
    !projectStore.project ||
    !inspectionQuestionQuery.data ||
    !signedComplianceCertQuery.data ||
    !inspectionStore.template
  ) {
    return <Loading what="inspection" />;
  }

  if (!inspectionStore.template) {
    throw new Error("inspectionStore.template must be present");
  }

  const checkInspectionReadonly = async() => {
    if(inspectionStore.inspection.certification_date){
      inspectionStore.setIsInspectionReadOnly(true)
    }
  }

  /**
   * Dynamic (user-generated) Template or "system" template
   */
  return inspectionStore.template.isUserTemplate ? (
    <DynamicTemplateQuestions />
  ) : (
    <LegacyTemplateQuestions
      onCommentSave={questionCommentContext.onSave}
      onCommentDelete={questionCommentContext.onDelete}
      handleSubmit={async (formData: Record<string, any>, source: string = 'save') => {
        // formData = {savingSource: source, ...formData};
        const toastId = toast("Saving inspection");
        const response = await inspectionStore
          .onSave(formData)
          .catch(async (err: InspectionApiErrorType) => {
            toast.update(toastId, {
              render: (
                <ToastError
                  message="Cannot save inspection because of error: "
                  error={inspectionStore.setSaveError(err)}
                />
              ),
              autoClose: false,
              type: "error",
            });

            await inspectionStore.reloadInspection();
            inspectionStore.resetTemplate();
          });

        if (response) {
          inspectionStore.setInspTempAnswerSaveResponse({ ...response.templateUnanswered })
          inspectionStore.setTemplate({
            ...inspectionStore.template,
            question_groups: response.template.question_groups,
          });

          inspectionQuestionQuery.refetch();

          let hasTemplateUnanswered = false;

          if (response.templateUnanswered && response.templateUnanswered.status != "complete" && response.templateUnanswered.unansweredAndRequired && response.templateUnanswered.unansweredAndRequired.length > 0) {
            hasTemplateUnanswered = true;
            toast.update(toastId, {
              render: "Inspection is saved! But need to answer all required questions marked in red to continue!",
              type: "warning",
            });
          } else {
            toast.update(toastId, {
              render: "Inspection saved!",
              type: "success",
            });
          }

          if (source === "saveAndContinue" && !hasTemplateUnanswered) {
            history.push(`/inspection/${response.inspection.id}/findings`);
          }

        }
      }}
      inspTempAnswerSaveResponse={inspectionStore.inspTempAnswerSaveResponse}
      checkInspectionReadonly={checkInspectionReadonly}
      inspection={inspection}
      questionTypes={Object.keys(
        inspectionQuestionQuery.data.questionTypes,
      ).reduce<QuestionType[]>((questionTypes, qid) => {
        questionTypes.push(
          inspectionQuestionQuery.data.questionTypes[Number(qid)],
        );

        return questionTypes;
      }, [])}
      template={inspectionStore.template}
      isReadOnly={
        projectPermissionContext.readOnly ||
        (!isNull(inspection.certification_date) &&
          (projectStore.project.inspection_compliance_required
            ? signedComplianceCertQuery.data.count > 0
            : true))
      }
      isInspectionReadOnly={inspectionStore.isInspectionReadOnly}
      findings={sortBy(
        inspectionStore.findings
          .filter(
            (finding: any) =>
              finding.type === "CA" &&
              (!finding.date_completed ||
                (finding.date_completed &&
                  moment
                    .utc(finding.date_completed)
                    .isSameOrAfter(inspectionDate, "day"))),
          )
          .map((finding: any) => ({
            value: finding.number,
            label: getFindingLabel(finding),
            badgeLabel: `#${finding.number}`,
          })),
        "label",
      )}
      permCheck={permCheck}
      currentUserName={
        !!appContext.get("user.first_name") ? appContext.get("user.name") : ""
      }
    />
  );
};

export default InspectionQuestions;
