import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingIcon, SmallButton } from "@sw-sw/lib-ui";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

/**
 * An action button for the 'status' column of the Users index
 *
 */
export default function StatusColumnButton({
  onClick,
  isBusy,
  icon,
  statusText,
  className,
  ...btnProps
}) {
  const localOnClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      if (onClick) {
        return onClick(e);
      }

      return false;
    },
    [onClick],
  );

  return (
    <span className={className}>
      <span>{statusText}</span>
      <SmallButton onClick={localOnClick} {...btnProps}>
        {isBusy ? (
          <LoadingIcon />
        ) : (
          <FontAwesomeIcon icon={icon} className="table-icon" />
        )}
      </SmallButton>
    </span>
  );
}

StatusColumnButton.propTypes = {
  statusText: PropTypes.string.isRequired,
};
