import xhrService from '../xhrService';

const isLoggedIn = () => {
  const token = localStorage.getItem("token") || sessionStorage.getItem("publictoken");

  return token && token.length && token.length > 0;
};

const saveToken = (token) =>
  localStorage.setItem('token', JSON.stringify(token));

const savePublicToken = token => sessionStorage.setItem("publictoken", JSON.stringify(token));

export const userApi = {
  index: (context = null, pageNumber = null, searchQuery = null) => {
    const params = {};

    if (context) {
      params.context = context;
    }

    if (searchQuery) {
      params.s = searchQuery;
    }

    if (pageNumber) {
      params.p = pageNumber;
    }

    return xhrService.get('/api/users').then((res) => res.data);
  },
  tenantInfo: () => {
    return xhrService
    .get(`/api/users/tenantInfo`,)
    .then((res) => res.data);
  },
  tenantUpdate: (data) => 
    xhrService
    .post('/api/users/tenantUpdate', data)
    .then(res => res.data)
    .catch(({response}) => {
      throw new Error(response.data.message);
    }),
  create: (data) =>
    xhrService
      .post('/api/users', data)
      .then((res) => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      }),
  get: (id, includeFormData = false) => {
    const params = {};

    if (includeFormData) {
      params.context = 'form';
    }

    return xhrService
      .get(`/api/users/${id}`, { params })
      .then((res) => res.data);
  },
  getUserByTenant: (userId, tenantId) => {
    return xhrService
      .get(`/api/users/${userId}/tenant/${tenantId}`)
      .then((res) => res.data);
  },
  getProfile: (id) => {
    return xhrService.get(`/api/users/profile/${id}`).then(res => res.data);
  },

  getCreateOpts: (data) => {
    //(roleId, clientIds) => {
    return xhrService
      .get(`/api/users/groups/creation`, { params: data })
      .then((res) => res.data);
  },
  isLoggedIn,
  login: (email, password, tenant) =>
    xhrService
      .post('/api/login', { email, password, tenant })
      .then(({ data }) => {
        if (data.token) {
          //saveToken(data.token);

          return data.token;
        }

        // don't leak token throughout application
        return true;
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return Promise.reject(error.response.data.message);
        }

        return Promise.reject(null);
      }),
  verifyUser: (email, password) =>
    xhrService
      .post('/api/verifyUser', { email, password })
      .then(({ data }) => {
        if (data) {
          return data;
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return Promise.reject(error.response.data.message);
        }

        return Promise.reject(null);
      }),
  getTenantByName: (tenant) =>
    xhrService
      .get(`/api/getTenantByName/${tenant}`)
      .then((res) => res.data)
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return Promise.reject(error.response.data.message);
        }

        return Promise.reject(null);
      }),
  changeTenant: (tenant) =>
    xhrService
      .post('/api/tenants/changeTenant', { tenantId: tenant })
      .then((res) => res.data)
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return Promise.reject(error.response.data.message);
        }

        return Promise.reject(null);
      }),
  redirectTenant: (tenant) =>
    xhrService
      .post('/api/tenants/redirectTenant', { tenantId: tenant })
      .then((res) => res.data)
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return Promise.reject(error.response.data.message);
        }

        return Promise.reject(null);
      }),

  publiclogin: (email, password, tenant) =>
    xhrService
      .post('/api/login', { email, password, tenant })
      .then(({ data }) => {
        if (data.token) {
          savePublicToken(data.token);
        }

        // don't leak token throughout application
        return true;
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return Promise.reject(error.response.data.message);
        }

        return Promise.reject(null);
      }),
  publicLoginWithProject: (email, password, projectId) =>
    xhrService
      .post('/api/login', { email, password, projectId })
      .then(({ data }) => {
        if (data.token) {
          savePublicToken(data.token);
        }

        return true;
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return Promise.reject(error.response.data.message);
        }

        return Promise.reject(null);
      }),
  saveToken,
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("divisionId");
    localStorage.removeItem("Qr Access");
    localStorage.removeItem("publictoken");
    localStorage.removeItem("navigationPanel")
  },
  resetPassword: (token, form) => {
    return xhrService
      .post(`/api/resetPassword`, { token, ...form })
      .then(({ data }) => {
        if (data.token) {
          saveToken(data.token);
        }

        return data;
      });
  },
  forgotPassword: (email) => {
    return xhrService
      .post('/api/forgotPassword', email)
      .then((res) => res.data);
  },
  register: (token, form) => {
    return xhrService
      .post(`/api/register`, {
        token,
        ...form,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.token) {
          saveToken(data.token);
        }

        return data;
      });
  },

  /** Get the profile of logged in user */
  profile: () => {
    if (isLoggedIn()) {
      return xhrService
        .get('/api/users/profile')
        .then((res) => res.data)
        .catch(() => {
          // invalid or deprecated token
          localStorage.removeItem('token');

          return Promise.reject(null);
        });
    }

    return Promise.reject('User is not logged in');
  },
  update: (id, data) =>
    xhrService.post(`/api/users/update/${id}`, data).then((res) => res.data),
  addTenant: (data) =>
    xhrService
      .post(`/api/users/${data.userId}/addTenant`, data)
      .then((res) => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      }),

  updateProfile: (data) =>
    xhrService.post(`/api/users/profile`, data).then((res) => res.data),

  /**
   * Validate a user
   */
  validate: (token) =>
    xhrService.post(`/api/user/validate`, { token }).then((res) => res.data),

  /**
   * On confirm, resend user invitation
   */
  invite: (id) =>
    xhrService.post(`/api/users/${id}/invite`).then((res) => res.data),

  toggleActivation: (id) =>
    xhrService.put(`/api/users/${id}/status`).then((res) => res.data),

  updateElectronicSignatureAuthorization: (id, action) =>
    xhrService
      .post(`/api/users/${id}/signature_authorization/${action}`)
      .then(res => res.data),

  getProjectList: (id) =>
    xhrService
      .get(`/api/users/${id}/projectList`)
      .then((res) => res.data),
  
  getProjectListTenantWise: (id, tenantId) =>
    xhrService
      .get(`/api/users/${id}/projectListTenantWise/${tenantId}`)
      .then((res) => res.data),  
};

export default userApi;
