import React, { useState } from "react";

const Context = React.createContext({});

export const RegulationStore = props => {
  const [regulation, setRegulation] = useState({});

  return (
    <Context.Provider
      value={{
        regulation,
        updateRegulation: setRegulation,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default Context;
