function getObservationContent(when) {
  return `Corrective Action item is to be addressed ${when}, after inspection, per scheduled control measure maintenance.`;
}

export const defaultFindingObservationTemplates = [
  {
    label: "To be completed (next day)",
    content: getObservationContent("next business day"),
  },
  {
    label: "To be completed (2 or more days)",
    content: getObservationContent("in 2 business days"),
  },
];
