import {
  FormContext,
  FormContextProvider,
  FormSchema,
  SchemaFieldsWithContextUI,
} from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { LayoutTypes, Question, QuestionGroup } from "@sw-sw/lib-inspection-templates";
import { Button } from "@sw-sw/lib-ui";
import React, { useContext } from "react";
import TemplateEditorContext from "../../../../contexts/TemplateEditorContext";
import { useUpdateQuestionGroup } from "../../../../hooks/questionGroups";

export interface IEditQuestionGroupFormProps {
  initialValue?: { name?: string; layout_type?: LayoutTypes };
  questionGroup: QuestionGroup & {
    order: number;
    questions: Array<Question & { questionWidth: string }>;
  };
  onCancel: () => void;
  onSubmit: () => void;
}

const EditQuestionGroupForm: React.FC<IEditQuestionGroupFormProps> = ({
  initialValue,
  questionGroup,
  onCancel,
  onSubmit,
}) => {
  const templateEditorContext = useContext(TemplateEditorContext);
  const updateMutation = useUpdateQuestionGroup(
    templateEditorContext.templateId,
  );

  const handleSubmit = (name: string, layout_type: LayoutTypes) => {
    if ( layout_type === LayoutTypes.FORM){
      questionGroup.questions?.forEach(question => {
        if (question.features) {
          question.features = question.features.filter(
            feature => feature !== "comment",
          );
        }
      });
    }
    updateMutation.mutate({
      ...questionGroup,
      name: name,
      layout_type: layout_type,
      description: questionGroup.description || "",
      table_headers: questionGroup.table_headers || [],
    });
    onSubmit();
  };

  const schema: FormSchema = {
    name: {
      validation: { required: true },
      className: "question-group-name-input",
    },
    layout_type: {
      controlType: UIControlType.select,
      options: [
        { value: "FORM", label: "Form Layout" },
        { value: "TABLE", label: "Table Layout" },
      ],
      labelKey: "label",
      valueKey: "value",
      validation: {
        required: true,
      },
      style: {
        width: "fit-content",
      },
    },
  };

  return (
    <FormContextProvider>
      <FormContext.Consumer>
        {formContext => (
          <div className="question-group-name-form">
            <SchemaFieldsWithContextUI
              className="question-group-name-form-inputs"
              schema={schema}
              initialFormData={initialValue}
            />
            <div className="question-group-name-form-controls">
              <Button onClick={onCancel}>Cancel</Button>
              <Button
                primary
                onClick={() =>
                  handleSubmit(
                    formContext.value.name,
                    formContext.value.layout_type,
                  )
                }
              >
                Submit
              </Button>{" "}
            </div>
          </div>
        )}
      </FormContext.Consumer>
    </FormContextProvider>
  );
};

export default EditQuestionGroupForm;
