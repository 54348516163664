import wsUtil from "../ws";

const ns = "convert";

const convertApi = {
  init: () => wsUtil.connect(ns),
  deinit: () => wsUtil.disconnect(ns),

  /**
   * Begin the job and subscribe to status updates
   *
   * @param {Function} onStatusUpdate Callback to handle status updates
   */
  run: (uploadId, jtModelName, jtAtts, onStatusUpdate) => {
    wsUtil.send(ns, `convert:pdf:png`, {
      uploadId,
      jtModelName,
      jtAtts,
    });

    wsUtil.on(ns, `status:convert:pdf:png:${uploadId}`, payload => {
      onStatusUpdate(payload);
    });
  },
};

export default convertApi;
