import React, { Component } from "react";
import PropTypes from "prop-types";
import { fabric } from "fabric";
import { forOwn } from "lodash";

import { Context } from "../Context";
import Canvas from "../Canvas";
import { setAlpha, getAlpha } from "../../../../utils/color";
import { shapeUtil, mapUtil } from "@sw-sw/common";

export const EDITOR_SIZE = mapUtil.ICON_SIZE;
export const EDITOR_PADDING = 10;
export const editorContentSize = EDITOR_SIZE - EDITOR_PADDING * 2;

/**
 * Manage featureable editor preview canvas
 */
class AreaEditorPreview extends Component {
  static contextType = Context;

  static propTypes = {
    areaType: PropTypes.string.isRequired,
  };

  htmlCanvas = React.createRef();
  /** @type {fabric.Canvas} */
  canvas = null;
  /** @type {fabric.Object} */
  shape = null;
  /** @type {fabric.Path} */
  arrow = null;

  componentWillUnmount() {
    this.canvas.off("featureable-form:updated", data => this.updateShape(data));
  }

  init = () => {
    this.canvas = this.context.initCanvas(this.htmlCanvas.current, [
      EDITOR_SIZE,
      EDITOR_SIZE,
    ]);

    /** @todo either circle or polygon, depending on prop */
    if (this.props.areaType === "circle") {
      this.shape = new fabric.Circle({
        radius: editorContentSize / 2,
        selectable: false,
        hoverCursor: "auto",
        originX: "center",
        originY: "center",
      });
    } else {
      this.shape = new fabric.Polyline(
        [
          { x: 37.337, y: 38.375 },
          { x: 39.337, y: 116.375 },
          { x: 122.337, y: 115.375 },
          { x: 112.337, y: 8.375 },
          // remove this last point for an "open" polygon
          { x: 37.337, y: 38.375 },
        ],
        {
          selectable: false,
          hoverCursor: "auto",
          originX: "center",
          originY: "center",
        },
      );
    }

    this.canvas.add(this.shape);
    this.shape.center();

    this.canvas.renderAll();

    this.canvas.on("featureable-form:updated", this.updateShape);

    forOwn(this.context.form, (value, field) => {
      this.updateShape({ value, field });
    });
  };

  updateShape = ({ field, value }) => {
    switch (field) {
      case "fillType":
        switch (value) {
          case "lines":
          case "hatch":
            this.shape.fill = null;
            this.updateShapePatternFill();
            break;
          case "solid":
            this.updateShape({
              field: "fill",
              value: this.context.form.fill,
            });
            break;
          default:
            break;
        }
        break;

      case "fillOpacity":
        switch (this.context.form.fillType) {
          case "lines":
          case "hatch":
            this.updateShapePatternFill();
            break;
          default:
            this.shape.set("fill", setAlpha(this.shape.get("fill"), value));
            break;
        }
        break;

      case "opacity":
        this.shape.set("stroke", setAlpha(this.shape.get("stroke"), value));
        break;

      case "fill":
        switch (this.context.form.fillType) {
          case "lines":
          case "hatch":
            this.updateShapePatternFill();
            break;
          default:
            this.shape.set(
              "fill",
              setAlpha(value, getAlpha(this.shape.get("fill"))),
            );
            break;
        }
        break;

      case "stroke":
        this.shape.set(
          "stroke",
          setAlpha(value, getAlpha(this.shape.get("stroke"))),
        );

        break;
      case "lineStrokeWidth":
      case "lineSeparation":
      case "lineDirection":
        if (
          this.context.form.fillType === "lines" ||
          this.context.form.fillType === "hatch"
        ) {
          this.updateShapePatternFill();
        }
        break;

      default:
        this.shape.set(field, value);
        break;
    }

    this.canvas.renderAll();
  };

  updateShapePatternFill() {
    const pattern = this.getFillPattern();

    this.shape.setPatternFill(pattern, () => {
      this.shape.canvas.renderAll();
    });
  }

  getFillPattern = () => {
    const { form } = this.context;

    return shapeUtil.getLinePattern(
      setAlpha(form.fill, form.fillOpacity),
      form.lineStrokeWidth,
      form.lineSeparation,
      form.lineDirection,
      form.fillType === "hatch",
    );
  };

  render() {
    return (
      <div className="area-editor-preview">
        <Canvas ref={this.htmlCanvas} onMounted={() => this.init()} />
      </div>
    );
  }
}

export default AreaEditorPreview;
