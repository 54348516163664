import { FormSchema, SchemaFieldsWithContextUI } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import React, { useContext } from "react";
import { SignatureEditorContext } from "../../../../contexts/SignatureEditorContext";
import FormModal from "../../../Shared/form/modal/FormModal";
import MarkdownEditor from "../MarkdownEditor";
import { SignatureEditorFormProps } from "./SignatureEditorForm";

type AttestationFormData = {
  attestationStatement: string;
};

const attestationSchema: FormSchema = {
  attestationStatement: {
    label: "Attestation Statement",
    controlType: UIControlType.custom,
    renderControl: () => <MarkdownEditor formField="attestationStatement" />,
    validation: {
      required: true,
    },
  },
};

const AttestationForm: React.FC<SignatureEditorFormProps> = ({
  value,
  onSubmit: onSubmitProp,
}) => {
  const signatureEditorContext = useContext(SignatureEditorContext);

  if (!signatureEditorContext.formState) {
    console.trace(signatureEditorContext);

    throw new Error("signatureEditorContext.formState must be defined");
  }

  const getInitialFormData = () => {
    const data: AttestationFormData = { attestationStatement: "" };

    try {
      if (signatureEditorContext.formState) {
        data.attestationStatement =
          value.attestations[signatureEditorContext.formState.groupType][
            signatureEditorContext.formState.resourceIndex
          ].content;
      }
    } catch (e) {
      console.log("Form data object structure is not correct");
    }

    return data;
  };

  const onSubmit = async (formData: AttestationFormData) => {
    const data: typeof value = JSON.parse(JSON.stringify(value));

    if (signatureEditorContext.formState) {
      if (
        !value.attestations[signatureEditorContext.formState.groupType][
          signatureEditorContext.formState.resourceIndex
        ]
      ) {
        /** @todo fix this type */
        data.attestations[signatureEditorContext.formState.groupType].push({
          content: formData.attestationStatement,
          signatureType: signatureEditorContext.formState.groupType,
        } as any);
      } else {
        data.attestations[signatureEditorContext.formState.groupType][
          signatureEditorContext.formState.resourceIndex
        ].content = formData.attestationStatement;
      }
    }

    onSubmitProp({ ...data });

    signatureEditorContext.setModal();
  };

  return (
    <FormModal
      onCancel={() => signatureEditorContext.setModal()}
      modalProps={{
        title: `${signatureEditorContext.formState.formType} Attestation`,
      }}
      onSubmit={onSubmit}
    >
      <SchemaFieldsWithContextUI
        schema={attestationSchema}
        initialFormData={getInitialFormData()}
      />
    </FormModal>
  );
};

export default AttestationForm;
