import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AppContext from '../../contexts/AppContext';
import AppDivisionContext from '../../contexts/AppDivisionContext';
import { ProjectContext } from '../../contexts/ProjectContext';
import { ProjectListProvider } from '../../contexts/ProjectListContext';
import RolesContext from '../../contexts/RolesContext';
import { getDivisionResourceName } from '../../hooks/divisionResource';
import projectApi from '../../utils/api/project';
import DivisionError from '../Shared/DivisionError/DivisionError';
import Popper from '../Shared/Popper/Popper';
import ResourceIndex from '../Shared/ResourceIndex/ResourceIndex';
import ProjectForm from './Forms/ProjectForm';
import ProjectList from './ProjectList';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Modal } from '@sw-sw/lib-ui';
import rolesApi from '../../utils/api/roles';

function ProjectAdd({ handleClose }) {
  const [redirectId, setRedirectId] = useState(null);
  const [customTab, setCustomTab] = useState(false);
  const appStore = useContext(AppContext);
  const projectContext = useContext(ProjectContext);
  const { getPath } = useContext(AppDivisionContext);

  const handleSubmit = useCallback(
    (data) => {
      return projectApi.create(data).then((project) => {
        appStore.triggerSuccessNotification();

        setRedirectId(project.id);
        setCustomTab(project.client.custom_tab);

        return Promise.resolve(project);
      });
    },
    [handleClose],
  );

  useEffect(() => {
    projectContext.clear();
  }, []);

  if (redirectId) {
    if (customTab) {
      return (
        <Redirect to={getPath(`/projects/${redirectId}/project-docs`)} push />
      );
    }

    return (
      <Redirect to={getPath(`/projects/${redirectId}/inspections`)} push />
    );
  }

  return (
    <ProjectForm
      handleClose={handleClose}
      submitBtnText={'Create'}
      handleSubmit={handleSubmit}
      hideStartDate={false}
    />
  );
}

function ProjectFilter({ handleClose }) {
  const projectContext = useContext(ProjectContext);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [projectType, setProjectType] = useState([]);
  const [primaryContact, setPrimaryContact] = useState([]);
  const [projectTypeOptions, setProjectTypeOptions] = useState([]);
  const [primaryContactOptions, setPrimaryContactOptions] = useState([]);
  const [error, setError] = useState(false);
  const animatedComponents = makeAnimated();

  const handleSubmit = () => {
    if (projectType.length === 0 && primaryContact.length === 0) {
      setError(true);

      return Promise.resolve();
    }

    projectContext.setFilterProjectType(projectType);
    projectContext.setFilterProjectPrimaryContact(primaryContact);

    handleClose();

    return Promise.resolve();
  };

  const handleClear = () => {
    setProjectType([]);
    setPrimaryContact([]);
    projectContext.setFilterProjectType([]);
    projectContext.setFilterProjectPrimaryContact([]);
    setError(false);
    handleClose();
  };

  useEffect(() => {      
    projectApi.getProjectTypes().then((data) => {
      setProjectTypeOptions(
        data.map((type) => {
          return { value: type.id, label: type.name };
        }),
      );
    });
    rolesApi.primaryContactDropdown(appDivisionId).then((data) => {
      setPrimaryContactOptions(
        data.data.map((contact) => {
          return { value: contact.id, label: contact.name };
        }),
      );
    });
    setProjectType(projectContext.filterProjectType);
    setPrimaryContact(projectContext.filterProjectPrimaryContact);
  }, []);

  return (
    <Modal
      title='Filter Projects'
      handleClose={handleClose}
      dismissOnBgClick={true}
      handleSubmit={handleSubmit}
      customButtonText='Clear'
      handleCustom={handleClear}
      show={true}
    >
      <label>Project Type</label>
      <Select
        closeMenuOnSelect={false}
        isMulti
        components={animatedComponents}
        placeholder='Project Type'
        onChange={(selected) => setProjectType(selected)}
        options={projectTypeOptions}
        value={projectType}
        isClearable={true}
        className='project-select-dropdown'
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
      <label>Primary Contact</label>
      <Select
        closeMenuOnSelect={false}
        isMulti
        components={animatedComponents}
        className='project-select-dropdown'
        placeholder='Primary Contact'
        noOptionsMessage={() => 'No User Assigned as Primary Contact in Projects'}
        onChange={(selected) => setPrimaryContact(selected)}
        options={primaryContactOptions}
        value={primaryContact}
        isClearable={true}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />

      {error && (
        <p className='precipitation-error-msg-wrapper'>
          Please select at least one Project Type or Primary Contact
        </p>
      )}
    </Modal>
  );
}

function Projects() {
  const [viewArchive, setviewArchive] = useState(false);
  const roleStore = useContext(RolesContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const projectContext = useContext(ProjectContext);
  const [showBulkStorm, setShowBulkStorm] = useState(false);

  const renderList = useCallback(
    (_, projects, onClick) => (
      <ProjectList
        isArchived={viewArchive}
        projects={projects}
        handleClick={(id, index) => onClick(index)}
        showBulkStorm={showBulkStorm}
        handleCancel={() => setShowBulkStorm(false)}
      />
    ),
    [showBulkStorm, viewArchive],
  );

  if (!appDivisionContext.appDivisionId) {
    return <DivisionError resource='projects' />;
  }

  const resourceName = !viewArchive ? 'projects' : 'projectArchive';

  return (
    <ProjectListProvider>
      <ResourceIndex
        pageNameForStyling={'project'}
        resourceName={getDivisionResourceName(resourceName)}
        resourceQueryProps={{ divisionId: appDivisionContext.appDivisionId, projectTypes: projectContext.filterProjectType, primaryContacts: projectContext.filterProjectPrimaryContact }}
        resourceTableHeaders={[['ID', 'id']]}
        enableRead={roleStore.userHasPermission('read', 'Project')}
        enableEdit={false}
        enableCallToAction={
          !viewArchive && roleStore.userHasPermission('create', 'Project')
        }
        enableOverflow={true}
        callToActionContent='Create New Project'
        enablePagination
        enableCallToFilter = {!viewArchive}
        searchInputPlaceholder='Search Projects'
        renderReader={(project) => {
          if (viewArchive) {
            return (
              <Redirect
                to={{
                  pathname: appDivisionContext.getPath(
                    `/projects/archive/${project.id}`,
                  ),
                  state: {
                    project: project,
                  },
                }}
                push
              />
            );
          } else {
            return project.client.custom_tab ? (
              <Redirect
                to={{
                  pathname: appDivisionContext.getPath(
                    `/projects/${project.id}/project-docs`,
                  ),
                  state: {
                    project: project,
                  },
                }}
                push
              />
            ) : (
              <Redirect
                to={{
                  pathname: appDivisionContext.getPath(
                    `/projects/${project.id}/inspections`,
                  ),
                  state: {
                    project: project,
                  },
                }}
                push
              />
            );
          }
        }}
        renderCallToAction={(handleClose) => {
          return <ProjectAdd handleClose={handleClose} />;
        }}
        renderCallToFilter={(handleClose) => {
          return <ProjectFilter handleClose={handleClose} />;
        }}
        renderList={renderList}
        renderOverflow={(coordinates, handleClose) => (
          <React.Fragment>
            <Popper coordinates={coordinates} handleClose={handleClose}>
              <ul>
                {!viewArchive && (
                  <li>
                    <button
                      onClick={() => {
                        setShowBulkStorm(true);
                        handleClose();
                      }}
                      className='button-transparent'
                    >
                      Create Multiple Post-Storm Inspections
                    </button>
                  </li>
                )}
                {roleStore.userHasPermission('read', 'Project') && (
                  <li>
                    <button
                      onClick={() => {
                        setviewArchive(!viewArchive);
                        handleClose();
                      }}
                      className='button-transparent'
                    >
                      {viewArchive
                        ? 'View All Projects'
                        : 'View Archived Projects'}
                    </button>
                  </li>
                )}
              </ul>
            </Popper>
          </React.Fragment>
        )}
      />
    </ProjectListProvider>
  );
}

export default Projects;
