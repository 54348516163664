import React from "react";
import ButtonGroup from "../../Fields/ButtonGroup";

export const strokeWidths = [
  {
    label: "Small",
    value: 2,
  },
  {
    label: "Medium",
    value: 4,
  },
  {
    label: "Large",
    value: 6,
  },
];

/**
 */
class StrokeWidth extends React.Component {
  render() {
    const { onChange, value, initialValue, ...rest } = this.props;

    return (
      <ButtonGroup
        onChange={i => onChange(strokeWidths[i].value)}
        options={strokeWidths}
        value={value || initialValue || ""}
        {...rest}
      />
    );
  }
}

export default StrokeWidth;
