import { merge, pickBy, set } from 'lodash';
import moment from 'moment';
/**
 * Returns {object}, without the specified keys
 * @param {{}} object
 * @param {string[]} keys
 */
export const withoutKeys = (object, keys) =>
  pickBy(object, (val, key) => !keys.includes(key));

/**
 * Deeply merge a key-val pair into an object
 */
export const mergeKeyVal = (obj, key, data) => ({
  ...merge(obj, set({}, key, data)),
});

export const dateFormat = 'MM-DD-YYYY';
export const inputDateFormat = 'MM/D/YYYY';

export function zipWithIndex(array) {
  return array.map((val, index) => ({ ...val, index }));
}

/**
 * Used to display inspection "type" as a label
 * @param {Inspection} arg0
 */

export const getInspectionTypeLabel = (
  { type, created_date, siteName, message },
  fromWidget = false,
) => {
  const currentDate = moment.utc(created_date).format(dateFormat);

  
  let labelString = '';

  if (fromWidget === true) {
    labelString = `${siteName} | ${currentDate} | ${type}`;
  } else {
    labelString = `${currentDate} | ${type} ${message ? `- ${message}` : ''}`;
  }

  return labelString;
};
