import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import ConfirmationModal from "../Shared/ConfirmationModal/ConfirmationModal";

export const MapLoadingError: React.FC<{
  content: string;
  projectId: number;
}> = ({ content, projectId }) => {
  const [redirectId, setRedirectId] = useState<null | number>(null);
  const { getPath } = useContext(AppDivisionContext);

  if (redirectId) {
    return <Redirect to={getPath(`/projects/${redirectId}`)} push />;
  }

  return (
    <ConfirmationModal
      title="Loading Error"
      subTitle={content}
      show
      hideCancel
      buttonText="Back to project"
      handleConfirm={() => setRedirectId(projectId)}
    />
  );
};
