import { kebabCase } from "lodash";
import React, { FC, useContext } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { NavTab } from "react-router-tabs";
import RolesContext from "../../contexts/RolesContext";

export type Tab<Props = {}> = {
  Component: FC<Props>;
  /** @deprecated */
  getProps?: (...args: any[]) => Props;
  label: string;
  /** permission check */
  showTab?: (permCheck: (action: string, location: any) => boolean) => boolean | undefined;
  /**
   * Path with leading slash. If missing, `label` is used.
   */
  to?: string;
};

type NavTabsProps<Args = any[]> = {
  /** @deprecated */
  getPropFuncArgs?: () => Args;
  tabs: Tab[];
  /** @deprecated */
  url?: string;
  /** side-effect after selecting tab */
  onSelectTab?: (index: number) => void;
  redirectToFirstTab?: boolean;
};

const NavTabs = ({
  getPropFuncArgs = () => [],
  tabs,
  url: urlProp,
  onSelectTab = () => { },
  redirectToFirstTab = true,
}: NavTabsProps) => {
  const match = useRouteMatch();
  const permCheck = useContext(RolesContext).userHasPermission;
  const url = urlProp || match.url;
  const location = useLocation();

  const getUrl = (tabIndex: number) => {
    return `${url}${tabs[tabIndex].to || "/" + kebabCase(tabs[tabIndex].label)
      }`;
  };

  if (redirectToFirstTab && tabs && tabs.length > 0 && location.pathname === url) {
    if (tabs[0].showTab && tabs[0].showTab(permCheck)) {
      return <Redirect to={getUrl(0)} />;
    }

    return <Redirect to={getUrl(1)} />;
  }

  return (
    <section className="react-tabs">
      <nav className="tabs react-tabs__tab-list">
        {tabs.map(({ label, showTab = () => true }, i) =>
          showTab(permCheck) ? (
            <NavTab
              replace={false}
              key={i}
              to={getUrl(i)}
              className="react-tabs__tab"
              activeClassName="react-tabs__tab--selected"
              onClick={() => onSelectTab(i)}
            >
              {label}
            </NavTab>
          ) : (
            <React.Fragment key={i} />
          ),
        )}
      </nav>

      <Switch>
        {tabs.map(({ getProps = () => { }, showTab = () => true, ...tab }, i) => (
          showTab(permCheck) &&
          <Route
            key={i}
            path={getUrl(i)}
            render={() => <tab.Component {...getProps(...getPropFuncArgs())} />}
          />
        ))}
      </Switch>
    </section>
  );
};

export default NavTabs;
