import { NewConfigValue } from "@sw-sw/lib-inspection-templates";
import { useMutation } from "react-query";
import { questionConfigApi } from "../utils/api/questionConfig";

export function useUpdateQuestionConfig() {
  return useMutation(
    (data: Array<NewConfigValue & { questionFieldId: number }>) => {
      return questionConfigApi.update(data);
    },
  );
}
