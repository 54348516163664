import { Button } from "@sw-sw/lib-ui";
import pl from "pluralize";
import React, { useContext } from "react";
import {
  SignatureEditorContext,
  SignatureEditorFormType,
} from "../../../../contexts/SignatureEditorContext";
import { SignatureEditorButtonProps } from "./SignatureEditorButton";

const formTypeIconProps: Record<
  SignatureEditorFormType,
  { className: string }
> = {
  [SignatureEditorFormType.EDIT]: { className: "fa fa-pencil" },
  [SignatureEditorFormType.ADD]: { className: "fa fa-plus" },
};

export const EditButton: React.FC<SignatureEditorButtonProps> = ({
  formType = SignatureEditorFormType.EDIT,
  groupType,
  resourceType,
  resourceIndex,
}) => {
  const signatureEditorContext = useContext(SignatureEditorContext);

  return (
    <Button
      outline
      small
      onClick={() => {
        signatureEditorContext.setFormState({
          groupType,
          resourceIndex,
          formType,
        });
        signatureEditorContext.setModal(resourceType);
      }}
      title={`${formType} ${pl(resourceType, 1)}`}
      className="signature-editor-btn"
    >
      <i {...formTypeIconProps[formType]} />
    </Button>
  );
};

export default EditButton;
