import React, { useState, PropsWithChildren, useContext } from "react";
import RolesContext from "./RolesContext";

export type ProjectPermissionState = {
  readOnly: boolean;
  setReadOnly: (input: boolean) => void;
  getDocumentProps: (docType: string) => {
    canEdit: boolean;
    canDelete: boolean;
    canDrag: boolean;
    canReplace: boolean;
    canRename: boolean;
    canUpload: boolean;
  };
};

export const ProjectPermissionContext =
  React.createContext<ProjectPermissionState>({
    readOnly: false,
    setReadOnly() {
      throw new Error("ProjectPermissionProvider not loaded");
    },
    getDocumentProps() {
      throw new Error("ProjectPermissionProvider not loaded");
    },
  });

type ProjectPermissionHookProps = {
  initialValue?: boolean;
};

export function useProjectPermission({
  initialValue,
}: ProjectPermissionHookProps): ProjectPermissionState {
  const isPublic = window.location.pathname.startsWith("/public")
  const [readOnly, setReadOnly] = useState<boolean>(initialValue || isPublic || false);
  const { userHasPermission } = useContext(RolesContext);

  const getDocumentProps = (docType: string) => {
    return {
      canEdit: readOnly ? false : true,
      canDelete: readOnly ? false : true,
      canDrag: readOnly ? false : userHasPermission("update", "Project Documents"),
      canReplace: readOnly || docType === "Active Site Maps" ? false : true,
      canRename: readOnly || docType === "Regulations" ? false : true,
      canUpload: readOnly ? false : true,
    };
  };

  return {
    readOnly,
    setReadOnly,
    getDocumentProps,
  };
}

export const ProjectPermissionContextProvider: React.FC<
  PropsWithChildren<ProjectPermissionHookProps>
> = ({ children, ...props }) => {
  const value = useProjectPermission(props);

  return (
    <ProjectPermissionContext.Provider value={value}>
      {children}
    </ProjectPermissionContext.Provider>
  );
};

export default ProjectPermissionContext;
