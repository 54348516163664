import { QuestionTypeApi } from "@sw-sw/lib-inspection-templates";
import xhrService from "../api";

export const questionTypeApi: QuestionTypeApi = {
  index: () => {
    return xhrService
      .get(`/api/question-types`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
};
