import React from "react";

/**
 * Static text for Compliance certification
 *
 * @todo get this from backend
 */
function ComplianceCertificationInstructions() {
  return (
    <p className="compliance-cert-help-text">
      Must be signed by a corporate officer (for corporations), general partner
      or proprietor (for partnership or sole proprietorship), principle,
      executive officer, or ranking elected official (for municipality, state,
      federal or other public agency) or their duly authorized representative.
    </p>
  );
}

export default ComplianceCertificationInstructions;
