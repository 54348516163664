import { Inspection } from '@sw-sw/lib-inspection-templates';
import xhrService from '../xhrService';
const baseUrl = '/api/projects';

type ProjectInspection = Omit<Inspection, 'id'> & {
  id: number;
};

export const projectSiteMapInspection = {
  index: (id: number, siteMapId: number): Promise<ProjectInspection[]> => {
    return xhrService
      .get(`${baseUrl}/${id}/sitemaps/${siteMapId}/inspections`)
      .then((response) => response.data);
  },
  show: (id: number): Promise<ProjectInspection[]> => {
    return xhrService
      .get(`${baseUrl}/${id}/sitemaps/inspections`)
      .then((response) => response.data);
  },
};

export default projectSiteMapInspection;
