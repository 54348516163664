import xhrService from "../xhrService";
import { ReopenInspectionApi } from "@sw-sw/lib-certification";

export const inspectionQuestionReopeningLogApi: ReopenInspectionApi = {
  update: (inspectionId: number) =>
    xhrService
      .post(`/api/inspections/${inspectionId}/open`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      }),
};

export default inspectionQuestionReopeningLogApi;
