import { IModalProps, Modal } from "@sw-sw/lib-ui";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import FormActions, { IFormActionsProps } from "./FormActions";

export interface IFormModalProps extends IFormActionsProps {
  modalProps: Omit<
    IModalProps,
    "hideButtons" | "handleClose" | "show" | "errorMsg"
  >;
  actionChildren?: React.ReactNode;
  nextBtn?: boolean;
  isNextPage?: boolean;
  handleNext?: React.MouseEventHandler;
  handleBack?: React.MouseEventHandler;
}
/**
 * Controller for modal and form actions. Receives custom form UI via children
 * @param {} props
 */
export const FormModal: React.FC<PropsWithChildren<IFormModalProps>> = ({
  children,
  actionChildren,
  modalProps,
  ...actionProps
}) => {
  return (
    <Modal
      // defaults (can be overridden by props)
      handleClose={actionProps.onCancel}
      dismissOnBgClick={false}
      dismissOnEsc={true}
      isSmall={true}
      // user-supplied props
      {...modalProps}
      // no overriding these defaults via props
      hideButtons={true}
      show={true}
      classes={classNames("form-modal", modalProps.classes)}
    >
      {children}
      <FormActions {...actionProps} children={actionChildren} />
    </Modal>
  );
};

export default FormModal;
