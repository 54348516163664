import React, { InputHTMLAttributes } from "react";
import classnames from "classnames";
import { SearchState } from "../../../contexts/SearchContext";

export type SearchInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "value"
> &
  SearchState;

export const SearchInput: React.FC<SearchInputProps> = ({
  placeholder = "Search",
  className,
  setQuery,
  query,
  ...inputProps
}) => {
  return (
    <input
      {...inputProps}
      value={query || ""}
      type="search"
      className={classnames("search-control", className)}
      placeholder={placeholder}
      onChange={e => setQuery(e.target.value)}
    />
  );
};

export default SearchInput;
