import React, { MouseEventHandler } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

export interface ICheckBoxProps {
  handleClick: MouseEventHandler<HTMLDivElement>;
  isChecked: boolean;
}

const CheckBox: React.FC<ICheckBoxProps> = ({ handleClick, isChecked }) => {
  return (
    <div className={classnames("insp-check-holder")} onClick={handleClick}>
      <input type="checkbox" defaultChecked={isChecked} readOnly />
      <span className={classnames("checkbox", { checked: isChecked })}>
        {isChecked && <FontAwesomeIcon icon={faCheck} className="checkmark" />}
      </span>
    </div>
  );
};

export default CheckBox;
