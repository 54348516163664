import React, { PropsWithChildren } from "react";
import Logo from "../../images/SW-Logo-White.svg";

/**
 * Narrow & centered
 *
 * Used for login and recovery
 */
const NarrowLayout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className="container App">
      <div className="layout--narrow">
        <img src={Logo} className="app-logo" alt="logo" />

        {children}
      </div>
    </div>
  );
};

export default NarrowLayout;
