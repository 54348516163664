import React from "react";
import { SignatureEditorProvider } from "../contexts/SignatureEditorContext";

export function withSignatureEditorProvider(
  WrappedComponent: React.FC<any>,
  templateId: number,
): typeof WrappedComponent {
  return (props: any) => (
    <SignatureEditorProvider templateId={templateId}>
      <WrappedComponent {...props} />
    </SignatureEditorProvider>
  );
}

export default withSignatureEditorProvider;
