import xhrService from "../xhrService";
const baseUrl = "/api/divisions";


export const inspectionsCertificationDue = {
  index: (queryParams: any, divisionId: number) => {
    if (!divisionId) { return false };
    return xhrService
      .get(`${baseUrl}/${divisionId}/getCertDueList`, {
        params: {
          ...queryParams
        }
      })
      .then(response => response.data);
  },
};

export default inspectionsCertificationDue;
