import wsUtil from "../ws";
import xhrService from "./../xhrService";

export type XfdfModel = {
  id: number;
  key: string | null;
  uploadId: number;
  xfdfValue: string;
  createdByUserId: number;
  isCommand: boolean;
  projectId?: number;
};

export type SaveXfdfModel = Omit<XfdfModel, "createdByUserId" | "id">;

export type XfdfApiActions = "add" | "modify" | "delete";

const baseUrl = "/api/xfdf";
const socketNamespace = "xfdf";

export const xfdfApi = {
  serializer: new XMLSerializer(),
  xfdfPrefix: `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields />`,
  xfdfSuffix: `</xfdf>`,

  async index(uploadId: number, projectId?: number): Promise<XfdfModel[]> {
    let url = `${baseUrl}/${uploadId}`;

    if (projectId) {
      url = `${url}?projectId=${projectId}`;
    }

    return xhrService.get(url).then(res => res.data);
  },

  save(payload: SaveXfdfModel): Promise<void> {
    return wsUtil.send(socketNamespace, "message", payload);
  },

  initSocket: async (onError?: Function) => {
    const socket = await wsUtil.connect(socketNamespace);

    if (onError && socket) {
      socket.on("error", onError);
    }

    return socket;
  },
  stopSocket: () => wsUtil.disconnect(socketNamespace),

  /**
   * Get model to represent xfdf annotation command.
   *
   * @returns XML string
   */
  getCommandModel(
    element: Element,
    action: XfdfApiActions,
    projectId?: number,
  ): Pick<XfdfModel, "key" | "xfdfValue" | "isCommand" | "projectId"> {
    let xfdfFragment = xfdfApi.serializer.serializeToString(element);

    if (action === "add") {
      xfdfFragment = `<add>${xfdfFragment}</add><modify /><delete />`;
    } else if (action === "modify") {
      xfdfFragment = `<add /><modify>${xfdfFragment}</modify><delete />`;
    } else if (action === "delete") {
      xfdfFragment = `<add /><modify /><delete>${xfdfFragment}</delete>`;
    } else {
      throw new Error("Unknown action: " + action);
    }

    return {
      isCommand: true,
      key: element.getAttribute("name") || null,
      xfdfValue: [xfdfApi.xfdfPrefix, xfdfFragment, xfdfApi.xfdfSuffix].join(
        "",
      ),
      projectId,
    };
  },
};

export default xfdfApi;
