import { useEffect, useState } from "react";
import uploadApi from "../../utils/api/upload";

interface IInspectionUpload {
  id: number;
}
export function useInspectionUploads(
  projectId: number,
  inspectionId: number | null,
  onError: (error: string) => void,
): IInspectionUpload[] | null {
  const [state, setState] = useState<IInspectionUpload[] | null>(null);

  useEffect(() => {
    if (inspectionId) {
      uploadApi.projectInspection
        .index(projectId, inspectionId)
        .then(data => setState(data))
        .catch(err => {
          console.error("useInspectionSiteMaps", err);
          onError("Cannot get inspection site maps");
        });
    }
  }, [inspectionId]);

  return state;
}
