import React from "react";

export const Context = React.createContext();

/**
 * Context to track & manage whether interaction is happening or not
 *
 * @deprecated maybe
 */
function InteractionTrackerContext(props) {
  // const [state, setState] = useState(false);

  /** @important not reacting to avoid re-rendering interaction controllers */
  let state = false;
  const setState = val => (state = val);

  return (
    <Context.Provider
      value={{
        begin: () => setState(true),
        end: () => setState(false),
        is: () => state,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export default InteractionTrackerContext;
