import {
  FormContext,
  FormContextProvider,
  FormSchemaFields,
  UploadControlContextProvider,
} from "@sw-sw/lib-form";
import {maxBy} from "lodash"
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";
import { InspectionContext } from "../../../contexts/InspectionContext";
import { RolesContext } from "../../../contexts/RolesContext";
import { dateFormat } from "../../../utils";
import FormModal from "../../Shared/form/modal/FormModal";
import {
  defaultCompletionTemplateContent,
  getFindingCommentsSchema,
} from "./FindingsForm";
import uploadApi from "../../../utils/api/upload";
import { UIControlType } from "@sw-sw/lib-form-control-types";

/**
 * Schema for completing a single finding
 *
 * @todo mini imageUpload
 * @todo maybe inline some fields
 *
 * @param {Array<{id: number}>} findings guaranteed to include all findings from value.finding_ids
 */
const getCompleteFindingSchema = (findings, permCheck) => {
  const minValidationDate = maxBy(findings.map((finding)=>new Date(finding.date_initiated)))
  const schema = {
    finding_ids: {
      label: `Selected Findings`,
      controlType: UIControlType.custom,
      renderControl: ({ value }) => (
        <ul className="form-control">
          {value.map(id => {
            const { number: num, date_initiated: date } = findings.find(
              f => f.id === id,
            );

            return (
              <li key={id}>
                #{num} on {moment.utc(date).format(dateFormat)}
              </li>
            );
          })}
        </ul>
      ),
    },
    date_completed: {
      label: "Date Completed",
      controlType: UIControlType.date,
      validation: {
        minDate : minValidationDate,
        maxDate: new Date(),
        required: true,
      },
    },

    // comment creation only
    ...getFindingCommentsSchema(permCheck("create", "finding_comments"), false),
  };

  if (permCheck("create", "Finding Images")) {
    findings.forEach(
      f =>
        (schema[`images_${f.id}`] = {
          label: `#${f.number} on ${moment
            .utc(f.date_initiated)
            .format(dateFormat)}`,
          controlType: UIControlType.imageUpload,
        }),
    );
  }

  return schema;
};

const CompleteFindingsModalUI = ({ findings, onSubmit, onClose }) => {
  const formContext = useContext(FormContext);
  const roleContext = useContext(RolesContext);

  return (
      <FormModal
        modalProps={{
          title: "Complete Findings",
          isExtraWide: true,
          classes : "complete-findings"
        }}
        submitText="Complete"
        onCancel={onClose}
        onSubmit={onSubmit}
      >
        <UploadControlContextProvider
          allowAnnotation={roleContext.userHasPermission("all", "Annotations")}
          allowDelete
          allowCreate
          getUrl={uploadApi.getDownloadUrl}
        >
          <section className="complete-findings-form">
            <FormSchemaFields
              schema={getCompleteFindingSchema(
                findings,
                roleContext.userHasPermission,
              )}
              onChange={formContext.set}
              formData={formContext.value}
            />
          </section>
        </UploadControlContextProvider>
      </FormModal>
  );
};

CompleteFindingsModalUI.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  findings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      number: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
};

const CompleteFindingsModal = ({ findings: findingIds, onSubmit, onClose }) => {
  const inspectionContext = useContext(InspectionContext);

  const findings = inspectionContext.findings.filter(({ id }) =>
    findingIds.includes(id),
  );

  /** @todo initial value */
  const initialValue = useRef({
    finding_ids: findings.map(_ => _.id),
    comments: defaultCompletionTemplateContent,
    date_completed: new Date(),
  });

  return (
    <FormContextProvider initialValue={initialValue.current}>
      <CompleteFindingsModalUI
        findings={findings}
        onClose={onClose}
        onSubmit={formData =>
          inspectionContext.completeFindings(formData).then(onSubmit)
        }
      />
    </FormContextProvider>
  );
};

CompleteFindingsModal.propTypes = {
  // list of ids
  findings: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompleteFindingsModal;
