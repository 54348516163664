import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import convertApi from "../../../utils/api/convert";
import CheckmarkSVG from "../CheckmarkSVG";
import { LoadingIcon } from "@sw-sw/lib-ui";
import { FormHelpText } from "@sw-sw/lib-form";
const getStatusUI = status => {
  switch (status) {
    case "error":
      return <FontAwesomeIcon icon="times" color="rgb(187, 28, 28)" />;
    case "done":
      return <CheckmarkSVG />;
    case "queued":
      return status;
    default:
      return <LoadingIcon />;
  }
};

/** Run jobs when loaded and connected */
const ConvertJobRunner = ({
  doc,
  connected,
  onCompletePage,
  onCompleteQueue,
}) => {
  const { id, name, jtModelName, jtAtts } = doc;

  const [busy, setBusy] = useState(false);
  const [status, setStatus] = useState(null);
  const [err, setErr] = useState(null);
  const [total, setTotal] = useState(null);
  const [complete, setComplete] = useState(null);

  // run job
  useEffect(() => {
    if (!busy && connected) {
      setBusy(true);

      convertApi.run(id, jtModelName, jtAtts, data => {
        if (data.status === "progress") {
          setStatus("converting");
          setTotal(data.total);
          setComplete(data.completed);
        }

        if (data.status === "error") {
          setErr(data.message);
        }

        setStatus(data.status || null);

        if (data.status === "done") {
          /** @todo split into done-page and done-queue events */
          data.results.forEach(_ => onCompletePage(..._));
          onCompleteQueue(doc);
        }
      });
    }
  }, [connected]);

  return (
    <div className="convert-job">
      <span className="convert-job-doc">{name}</span>

      {status && (
        <span>
          {err && <FormHelpText content={err} theme="error" />}
          {total !== null && !err && (
            <span className="convert-job-progress">
              {complete} / {total}
            </span>
          )}

          <span className="convert-job-status">{getStatusUI(status)}</span>
        </span>
      )}
    </div>
  );
};

ConvertJobRunner.propTypes = {
  connected: PropTypes.bool.isRequired,
  doc: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    jtModelName: PropTypes.string.isRequired,
    jtAtts: PropTypes.object.isRequired,
  }).isRequired,
  onCompletePage: PropTypes.func.isRequired,
  onCompleteQueue: PropTypes.func.isRequired,
};

export default ConvertJobRunner;
