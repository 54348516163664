/**
 * A generic tool for icon editor
 */
class IconEditorTool {
  /** @type {fabric.Canvas} */
  canvas = null;

  theme = {};

  // user instructions for using the tool
  helpText = "";

  constructor(canvas) {
    this.canvas = canvas;
  }

  handleSelected() {}
  handleDeselected() {}

  handleMouseDown(/** @type {fabric.IEvent} */ event) {}
  handleMouseUp(/** @type {fabric.IEvent} */ event) {}
  handleMouseMove(/** @type {fabric.IEvent} */ event) {}

  // respond to data changes, only useful when updating the selected object
  handleUpdateActiveObject(
    field,
    value,
    /** @type {fabric.Object} */ activeObject,
  ) {}

  // store theme state
  updateTheme(field, value) {
    this.theme[field] = value;

    return this;
  }

  applyTheme(/** @type {fabric.Object} */ shape) {
    Object.keys(this.theme).forEach(field => {
      shape.set(field, this.theme[field]);
    });

    return shape;
  }
}

export default IconEditorTool;
