import React, { useContext } from "react";
import NotificationSettingsContext from "../../contexts/NotificationsContext";
import classnames from "classnames";
import { capitalize } from "lodash";

const EmailSettings = props => {
  const settingsContext = useContext(NotificationSettingsContext);
  const { frequencySettings, updateFrequencySettings } = settingsContext;
  const { frequency, availableFrequencies } = frequencySettings;

  const renderCheck = (option, i) => {
    return (
      <label
        className="radio-holder"
        key={i}
        onClick={() => updateFrequencySettings(option)}
        htmlFor={`custom-radio-${i}`}
      >
        <input
          type="checkbox"
          defaultChecked={option === frequency}
          id={`custom-radio-${i}`}
        />
        <span
          className={classnames("radio", { checked: option === frequency })}
        >
          {option === frequency && <div className="indicator" />}
        </span>
        <span className="radio-label">{capitalize(option)}</span>
      </label>
    );
  };

  return (
    <div className="frequency-settings">
      <h3>Notification Summary Email Frequency</h3>
      <form className="radio-list">
        {availableFrequencies &&
          availableFrequencies.map((frequency, index) =>
            renderCheck(frequency, index),
          )}
      </form>
    </div>
  );
};

export default EmailSettings;
