import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";

// helpers
import uploadApi from "../../utils/api/upload";
import SuccessModal from "../Shared/SuccessModal/SuccessModal";
import {
  UploadFileValidation,
  UploadFilesValidation,
  FileSizeValidation,
  FileTypeValidation,
  ImageDimensionValidation,
} from "../../utils/file";
import { LoadingIcon } from "@sw-sw/lib-ui";

const uploadFileValidation = UploadFileValidation({
  fileSizeValidation: FileSizeValidation(),
  fileTypeValidation: FileTypeValidation(),
  imageDimensionValidation: ImageDimensionValidation(),
});
const uploadFilesValidation = UploadFilesValidation({
  uploadFileValidation,
});

/**
 * UI for upload dnd input
 */
function UploadInput({
  maxFileSize = 125000000,
  allowedTypes,
  onUpload,
  className,
  showHelpText,
  imageDimensions,
}) {
  const [showError, setShowError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function onDrop(files) {
    setIsLoading(true);

    const errors = await uploadFilesValidation.validate(files, {
      maxFileSize,
      allowedTypes,
      imageDimensions,
    });

    if (errors.length) {
      setErrorMessages(errors.map(error => error.message));
      setShowError(true);
      setIsLoading(false);

      return;
    }

    await processFiles(files);
    setIsLoading(false);
  }

  async function processFiles(files) {
    try {
      return await Promise.all(files.map(processFile));
    } catch (ex) {
      setErrorMessages([ex]);
      setShowError(true);
    }
  }

  async function processFile(file) {
    let response = await uploadApi.create(file);

    onUpload(response);
  }

  return (
    <>
      <Dropzone onDrop={onDrop} multiple>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={classNames("upload-control dropzone", className, {
                "upload-control--active": isDragActive,
                "upload-control--loading": isLoading,
              })}
            >
              <input {...getInputProps()} />
              {isLoading ? (
                <LoadingIcon className={"upload-control__icon"} />
              ) : (
                <i className="fa fa-cloud-upload upload-control__icon" />
              )}
              {showHelpText && !isLoading ? (
                <span>Drag and drop files here to upload.</span>
              ) : null}

              {showHelpText && isLoading ? <span>Uploading files</span> : null}
            </div>
          );
        }}
      </Dropzone>

      <SuccessModal
        show={showError}
        handleClose={() => setShowError(false)}
        handleSubmit={() => setShowError(false)}
        submitBtnText="OK"
        messages={errorMessages}
        title="Error"
        isAlert
      />
    </>
  );
}

UploadInput.propTypes = {
  onUpload: PropTypes.func,
  allowedTypes: PropTypes.arrayOf(PropTypes.string), // array of mime_types
  showHelpText: PropTypes.bool,
  imageDimensions: PropTypes.object,
};

export default UploadInput;
