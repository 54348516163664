import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppDivisionContext from '../../contexts/AppDivisionContext';
import { getDivisionResourceName } from '../../hooks/divisionResource';
import { ProjectListProvider } from '../../contexts/ProjectListContext';
import DivisionError from '../Shared/DivisionError/DivisionError';
import ResourceIndex from '../Shared/ResourceIndex/ResourceIndex';
import RolesContext from '../../contexts/RolesContext';
import TenantFeatureContext from '../../contexts/TenantContext';

type Report = {
  id: number;
  name: string;
};

const Reports = () => {
  const roleStore = useContext(RolesContext);

  const appDivisionContext = useContext(AppDivisionContext);
  const tenantFeature = useContext(TenantFeatureContext)
  const navigation = useHistory();
  const [search, setSearch] = useState('');

  const handleClick = useCallback(
    (report: Report) => () => {
      if (report && report.name) {
        navigation.push(
          `${appDivisionContext.getPath(`/reports/${report.name}`)}`,
        );
      }

      return null;
    },
    [],
  );

  const renderList = useCallback(
    (_, reports: Report[]) => (
      <div className='projects'>
        <div className='flex-row project-list'>
          {reports == null || reports.length === 0 ? (
            <p>No reports found</p>
          ) : (
            reports
              .filter((e) =>{ 
                return e.name.toLowerCase().match(search.toLowerCase()) && tenantFeature.tenantHasFeature(e.name)
              }).length === 0 ? (
                <p>No reports found</p>
              ) : reports
              .filter((e) =>{ 
                return e.name.toLowerCase().match(search.toLowerCase()) && tenantFeature.tenantHasFeature(e.name)
              })
              .map((report) => {
                return (
                  
                  <div className='project' key={report.id}>
                    <div className='project-main' onClick={handleClick(report)}>
                      <h3>{report.name}</h3>
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
    ),
    [search, tenantFeature],
  );

  if (!appDivisionContext.appDivisionId) {
    return <DivisionError resource='Reports' />;
  }

  return (
    <ProjectListProvider>
      <div className='report-search-container'>
        <input
          className='search-control'
          type='search'
          placeholder='Search'
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <ResourceIndex
        pageNameForStyling={'Report'}
        resourceName={getDivisionResourceName('Reports')}
        resourceQueryProps={{ divisionId: appDivisionContext.appDivisionId }}
        resourceTableHeaders={[['ID', 'id']]}
        enableRead={roleStore.userHasPermission('all', 'Reports')}
        enableEdit={false}
        enableCallToAction={false}
        enableOverflow={false}
        hideCallToActionInMobile
        enablePagination={false}
        searchInputPlaceholder='Search Reports'
        renderList={renderList}
        enableSearch={false}
      />
    </ProjectListProvider>
  );
};

export default Reports;
