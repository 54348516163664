import { Button } from "@sw-sw/lib-ui";
import pl from "pluralize";
import { default as React } from "react";
import { SignatureEditorButtonProps } from "./SignatureEditorButton";

export type ReorderActionPayload = SignatureEditorButtonProps & {
  targetIndex: number;
};

export type ReorderButtonsProps = SignatureEditorButtonProps & {
  onReorder: (payload: ReorderActionPayload) => void;
  resourceSize: number;
};

/** @todo style */
const ReorderButtons: React.FC<ReorderButtonsProps> = ({
  groupType,
  resourceType,
  resourceIndex,
  resourceSize,
  onReorder,
}) => {
  return (
    <>
      {resourceIndex > 0 ? (
        <Button
          outline
          small
          onClick={() =>
            onReorder({
              groupType,
              resourceType,
              resourceIndex,
              targetIndex: resourceIndex - 1,
            })
          }
          title={`Reorder ${pl(resourceType, 1)}`}
          className="signature-editor-btn signature-editor-reorder-btn"
        >
          <i className="fa fa-arrow-circle-up" />
        </Button>
      ) : null}

      {resourceIndex < resourceSize - 1 ? (
        <Button
          outline
          small
          onClick={() =>
            onReorder({
              groupType,
              resourceType,
              resourceIndex,
              targetIndex: resourceIndex + 1,
            })
          }
          title={`Reorder ${pl(resourceType, 1)}`}
          className="signature-editor-btn signature-editor-reorder-btn"
        >
          <i className="fa fa-arrow-circle-down" />
        </Button>
      ) : null}
    </>
  );
};

export default ReorderButtons;
