import { InspectionTemplate } from '@sw-sw/lib-inspection-templates';
import { uniq } from 'lodash';
import moment from 'moment';
import xhrService from '../xhrService';

const baseUrl = `/api/divisions`;

interface Division {
  id: number;
  name: string;
  createdAt?: Date;
}

export interface DivisionUser {
  userId: number;
  divisionId: number;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
}

export type IndexApi<D = any[]> = (
  divisionId: number,
  pageNumber?: number,
  searchQuery?: string,
  resourceIds?: number[],
  paginated?: boolean,
) => Promise<D>;
export type DeleteApi = (id: number, data: number[]) => Promise<void>;

export type CreateApi<A = any, B = any> = (id: number, data: A) => Promise<B>;

export type ArchiveApi = (divisionId: number, id: number) => Promise<any>;

type DivisionApi = {
  index: (search?: string) => Promise<Division[]>;
  create: (data: any) => Promise<Division>;
  get: (id: number) => Promise<Division>;
  destroy: (id: number) => Promise<any>;
  update: (id: number, data: any) => Promise<Division>;
  users: {
    // add search and pagination that can be found in userApi#index
    index: IndexApi;
    create: CreateApi<{ users: { id: number }[] }>;
    delete: DeleteApi;
  };
  clients: {
    index: IndexApi;
    create: CreateApi<{ clients: { id: number }[] }>;
    delete: DeleteApi;
    getClientsForMultipleDivisions: (divisionIds: number[]) => Promise<any>;
  };
  regulations: {
    index: IndexApi;
    create: CreateApi<{ regulations: { id: number }[] }>;
    delete: DeleteApi;
  };
  inspectionTemplates: {
    index: IndexApi<InspectionTemplate[]>;
    create: CreateApi<{ inspectionTemplates: { id: number }[] }>;
    delete: DeleteApi;
  };
  projects: {
    index: (
      divisionId: number,
      pageNumber?: number,
      searchQuery?: string,
      projectTypes?: any,
      primaryContacts?: any,
    ) => Promise<any>;
    archive: {
      index: IndexApi;
      get: ArchiveApi;
      archive: ArchiveApi;
      restore: ArchiveApi;
    };
  };
  inspectionIntervals: {
    index: IndexApi;
    fetchWithDefaultTrue: IndexApi;
    create: CreateApi<{ inspectionIntervals: { id: number }[] }>;
    delete: DeleteApi;
  };
  reports: {
    index: IndexApi;
  };
};

const divisionApi: DivisionApi = {
  index: (search?: string) => {
    const params: { s?: string } = {};

    if (search) {
      params.s = search;
    }

    return xhrService.get(baseUrl, { params }).then((res) => {
      return res.data.map((division: Division) => {
        return {
          ...division,
          createdAt: moment(division.createdAt).format('MM-DD-YYYY'),
        };
      });
    });
  },
  create: (data) => xhrService.post(`${baseUrl}`, data).then((res) => res.data),
  get: (id) =>
    xhrService
      .get(`${baseUrl}/${id}`)
      .then((res) => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      }),
  destroy: (id) =>
    xhrService
      .delete(`${baseUrl}/${id}`)
      .then(() => true)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      }),
  update: (id, data) =>
    xhrService.put(`${baseUrl}/${id}`, data).then((res) => res.data),
  users: {
    index: (id, pageNumber?: number, searchQuery?: string) => {
      const params: { s?: string; p?: number } = {};

      if (searchQuery) {
        params.s = searchQuery;
      }

      if (pageNumber) {
        params.p = pageNumber;
      }

      return xhrService
        .get(`${baseUrl}/${id}/users`, { params })
        .then(handlePaginatedResponse.bind(null, 'user'));
    },
    create: (id, data) => {
      const userIds = data.users.map((user: { id: number }) => user.id);

      return xhrService
        .post(`${baseUrl}/${id}/users`, { userIds: userIds })
        .then((res) => res.data);
    },
    delete: (id, data) => {
      return xhrService
        .delete(`${baseUrl}/${id}/users`, { data: { userIds: data } })
        .then((res) => res.data);
    },
  },
  clients: {
    index: (
      id,
      pageNumber?: number,
      searchQuery?: string,
      resourceIds?: number[],
      paginated?: boolean,
    ) => {
      const params: { s?: string; p?: number; clientIds?: number[], paginated?: boolean } = {};

      if (searchQuery) {
        params.s = searchQuery;
      }

      if (pageNumber) {
        params.p = pageNumber;
      }

      if (resourceIds) {
        params.clientIds = uniq(resourceIds);
      }

      if (paginated) {
        params.paginated = paginated;
      }

      return xhrService
        .get(`${baseUrl}/${id}/clients`, { params })
        .then(handlePaginatedResponse.bind(null, 'client'));
    },
    create: (id, data) => {
      const clientIds = data.clients.map((client: { id: number }) => client.id);

      return xhrService
        .post(`${baseUrl}/${id}/clients`, { clientIds: clientIds })
        .then((res) => res.data);
    },
    delete: (id, data) => {
      return xhrService
        .delete(`${baseUrl}/${id}/clients`, { data: { clientIds: data } })
        .then((res) => res.data);
    },
    getClientsForMultipleDivisions: (divisionIds: number[]) => {
      return xhrService
        .get(`${baseUrl}clients/clients`, { params: { divisionIds } })
        .then((res) => res.data);
    },
  },
  regulations: {
    index: (id, pageNumber?: number, searchQuery?: string) => {
      const params: { s?: string; p?: number } = {};

      if (searchQuery) {
        params.s = searchQuery;
      }

      if (pageNumber) {
        params.p = pageNumber;
      }

      return xhrService
        .get(`${baseUrl}/${id}/regulations`, { params })
        .then(handlePaginatedResponse.bind(null, 'documentGroup'));
    },
    create: (id, data) => {
      const regulationIds = data.regulations.map(
        (reg: { id: number }) => reg.id,
      );

      return xhrService
        .post(`${baseUrl}/${id}/regulations`, { regulationIds: regulationIds })
        .then((res) => res.data);
    },
    delete: (id, data) => {
      return xhrService
        .delete(`${baseUrl}/${id}/regulations`, {
          data: { regulationIds: data },
        })
        .then((res) => res.data);
    },
  },
  inspectionTemplates: {
    index: (id) => {
      return xhrService
        .get(`${baseUrl}/${id}/inspectionTemplates`)
        .then((res) => res.data.map((dit: { inspectionTemplate: any }) => dit));
    },
    create: (id, data) => {
      const inspectionTemplateIds = data.inspectionTemplates.map(
        (inspTemp: { id: number }) => inspTemp.id,
      );

      return xhrService
        .post(`${baseUrl}/${id}/inspectionTemplates`, {
          inspectionTemplateIds: inspectionTemplateIds,
        })
        .then((res) => res.data);
    },
    delete: (id, data) => {
      return xhrService
        .delete(`${baseUrl}/${id}/inspectionTemplates`, {
          data: { inspectionTemplateIds: data },
        })
        .then((res) => res.data);
    },
  },
  projects: {
    index: (
      id,
      pageNumber?: number,
      searchQuery?: string,
      projectTypes?: any,
      primaryContacts?: any,
    ) => {
      const params: {
        s?: string;
        p?: number;
        projectTypes?: number[];
        primaryContacts?: number[];
      } = {};

      if (searchQuery) {
        params.s = searchQuery;
      }

      if (pageNumber) {
        params.p = pageNumber;
      }

      if (projectTypes) {
        params.projectTypes = projectTypes.map((pt: { value: number }) => pt.value);
      }

      if (primaryContacts) {
        params.primaryContacts = primaryContacts.map((pc: { value: number }) => pc.value);
      }

      return xhrService
        .get(`${baseUrl}/${id}/projects`, { params })
        .then(handlePaginatedResponse.bind(null, 'project'));
    },
    archive: {
      index: (divisionId, pageNumber?: number, searchQuery?: string) => {
        const params: { s?: string; p?: number,projectTypes?: number[], primaryContacts?: number[] } = {};

        if (searchQuery) {
          params.s = searchQuery;
        }

        if (pageNumber) {
          params.p = pageNumber;
        }

        return xhrService
          .get(`${baseUrl}/${divisionId}/projects/archive`, { params })
          .then(handlePaginatedResponse.bind(null, 'project'));
      },
      get: (divisionId: number, id: number) => {
        return xhrService
          .get(`${baseUrl}/${divisionId}/projects/archive/${id}`)
          .then((res: { data: any }) => res.data);
      },
      archive: (divisionId: number, id: number) => {
        return xhrService
          .post(`/api/divisions/${divisionId}/projects/archive/${id}`)
          .then((res) => res.data);
      },
      restore: (divisionId: number, id: number) => {
        return xhrService
          .delete(`/api/divisions/${divisionId}/projects/archive/${id}`)
          .then((res) => res.data);
      },
    },
  },
  inspectionIntervals: {
    index: (id?: number, pageNumber?: number, searchQuery?: string) => {
      const params: { s?: string; p?: number } = {};

      if (searchQuery) {
        params.s = searchQuery;
      }

      if (pageNumber) {
        params.p = pageNumber;
      }

      return xhrService
        .get(`${baseUrl}/${id}/inspection-intervals`, { params })
        .then((res) => {
          res.data.pageData = res.data.pageData.filter(
            (ele: any) => ele.inspectionInterval.default === false,
          );

          return res;
        })
        .then(handlePaginatedResponse.bind(null, 'inspectionInterval'));
    },
    fetchWithDefaultTrue: (
      id?: number,
      pageNumber?: number,
      searchQuery?: string,
    ) => {
      const params: { s?: string; p?: number } = {};

      if (searchQuery) {
        params.s = searchQuery;
      }

      if (pageNumber) {
        params.p = pageNumber;
      }

      return xhrService
        .get(`${baseUrl}/${id}/inspection-intervals`, { params })
        .then(handlePaginatedResponse.bind(null, 'inspectionInterval'));
    },
    create: (id, data) => {
      const inspectionIntervalIds = data.inspectionIntervals.map(
        (inspectionInterval: { id: number }) => inspectionInterval.id,
      );

      return xhrService
        .post(`${baseUrl}/${id}/inspection-intervals`, {
          inspectionIntervalIds: inspectionIntervalIds,
        })
        .then((res) => res.data);
    },
    delete: (id, inspectionIntervalIds) => {
      return xhrService
        .delete(`${baseUrl}/${id}/inspection-intervals`, {
          data: { inspectionIntervalIds: inspectionIntervalIds },
        })
        .then((res) => res.data);
    },
  },
  reports: {
    index: (id: number, pageNumber?: number, searchQuery?: string) => {
      const params: { s?: string; p?: number } = {};

      if (searchQuery) {
        params.s = searchQuery;
      }

      if (pageNumber) {
        params.p = pageNumber;
      }

      return xhrService
        .get(`${baseUrl}/${id}/reports`, { params })
        .then((res) => res.data);
    },
  },
};

export default divisionApi;

function handlePaginatedResponse(key: string, res: { data: any }) {
  if (Array.isArray(res.data)) {
    return res.data.map((dc: any) => dc[key]);
  }

  if (res.data.pageData) {
    return {
      ...res.data,
      pageData: res.data.pageData.map((dc: any) => dc[key]),
    };
  }

  return res.data;
}
