import React, { Component } from "react";
import PropTypes from "prop-types";
import { toNumber } from "lodash";

import FeatureableEditorOptions from "../Options";
import Color from "../Fields/Color";
import Range from "../Fields/Range";
import Text from "../Fields/Text";

import { Context } from "./Context";
import StrokeWidth from "../Line/Fields/StrokeWidth";
import FontFamily from "./Fields/FontFamily";

/**
 * map of object attribute to form field component
 *
 */
const shapeOptions = {
  fill: ({ currentTool, selectedObject, ...props }) =>
    currentTool === "line" ||
    (currentTool === "select" &&
      (selectedObject || {}).type === "line") ? null : (
      <Color label="Fill Color" {...props} />
    ),
  stroke: ({ currentTool, selectedObject, ...props }) => (
    <Color label="Stroke Color" {...props} />
  ),
  strokeWidth: ({ currentTool, selectedObject, ...props }) => (
    <StrokeWidth label="Thickness" {...props} />
  ),
  opacity: ({ currentTool, selectedObject, ...props }) => (
    <Range
      label="Opacity"
      step="0.05"
      min="0.05"
      max="1"
      cast={toNumber}
      {...props}
    />
  ),
};

const textOptions = {
  text: ({ currentTool, selectedObject, ...props }) => {
    return <Text label="Text" {...props} maxLength="10" />;
  },
  fontFamily: ({ currentTool, selectedObject, ...props }) => (
    <FontFamily {...props} />
  ),
  fill: ({ currentTool, selectedObject, ...props }) => (
    <Color label="Color" {...props} />
  ),
  opacity: ({ currentTool, selectedObject, ...props }) => (
    <Range
      label="Opacity"
      step="0.05"
      min="0.05"
      max="1"
      cast={toNumber}
      {...props}
    />
  ),
};

/** Map of object type -> options */
const objectTypeOptions = {
  default: shapeOptions,
  text: textOptions,
};

// HOC when selecting, and no object is selected, hide the component
function provideInitialValueHOC(Component, optName) {
  class ProvideValueFromSelectedObject extends React.Component {
    static propTypes = {
      currentTool: PropTypes.string.isRequired,
      selectedObject: PropTypes.any,
      value: PropTypes.any,
    };

    static displayName = `ProvideValueFromSelectedObject(${Component.displayName ||
      Component.name ||
      "Component"})`;

    render() {
      const props = { ...this.props };

      // attempt to load field value from selected object
      if (
        !props.value &&
        props.currentTool === "select" &&
        props.selectedObject
      ) {
        props.initialValue = props.selectedObject.get(optName);
      }

      return <Component {...props} />;
    }
  }

  return ProvideValueFromSelectedObject;
}

/**
 * Apply the HOC to {shapeOptions} and {textOptions}
 */

[shapeOptions, textOptions].forEach(opts => {
  Object.keys(opts).forEach(optName => {
    const Component = opts[optName];

    opts[optName] = provideInitialValueHOC(Component, optName);
  });
});

/**
 * Configure either the selected object or a tool setting
 */
class IconEditorSelectionOptions extends Component {
  static contextType = Context;

  getOptions = selectedObject => {
    return objectTypeOptions[selectedObject.type] || objectTypeOptions.default;
  };

  render() {
    const { currentTool, selectedObject } = this.context;

    if (currentTool !== "select" || !selectedObject) {
      return null;
    }

    return (
      <div className="shape-editor-selection-options">
        <FeatureableEditorOptions
          options={this.getOptions(selectedObject)}
          extraComponentProps={{ currentTool, selectedObject }}
        />
      </div>
    );
  }
}

export default IconEditorSelectionOptions;
