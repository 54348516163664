import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import ReactDOM from "react-dom";
import { LoadingIcon, Modal } from "@sw-sw/lib-ui";

/**
 * Attach a loading modal to cover the parent element
 */
const LoadingModal = ({ show, portalQuerySelector }) => {
  const querySelectorRef = useRef(portalQuerySelector);

  useEffect(() => {
    if (portalQuerySelector !== querySelectorRef.current) {
      querySelectorRef.current = portalQuerySelector;
    }
  }, [portalQuerySelector, querySelectorRef]);

  const LoadingElement = show ? (
    <Modal
      disableClose
      isSmall
      cancelBtn={false}
      hideButtons
      className="loading-modal"
      show={show}
      title="Loading"
    >
      <LoadingIcon />
    </Modal>
  ) : null;
  const portalElement = document.querySelector(querySelectorRef.current);

  return portalElement
    ? ReactDOM.createPortal(LoadingElement, portalElement)
    : null;
};

LoadingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  portalQuerySelector: PropTypes.string.isRequired,
};

LoadingModal.defaultProps = {
  portalQuerySelector: "#loading",
};

export default LoadingModal;
