import {
  FormContextProvider,
  SchemaFieldsWithContextUI,
} from '@sw-sw/lib-form';
import React, { MouseEventHandler } from 'react';
import { FormModal, SubmitHandlerFn } from '../../Shared/form';
// import { UIControlType } from '@sw-sw/lib-form-control-types';

/**
 * @deprecated see @sw-sw/lib-inspection-templates
 */
interface InspectionTemplate {
  name: string;
  divisionId?: number | null;
}

export interface IInspectionTemplateFormProps {
  handleSubmit: SubmitHandlerFn;
  handleClose: MouseEventHandler;
  title?: string;
  inspectionTemplate?: Partial<InspectionTemplate>;
  errorMsg?: boolean;
}

export const getSchema = () => {
  const fields: any = {
    name: {
      label: 'Name',
      validation: {
        required: true,
      },
    },
  };

  return fields;
};

const InspectionTemplateDetailsForm: React.FC<IInspectionTemplateFormProps> = ({
  handleSubmit,
  handleClose,
  title = 'Add/Modify an Inspection Template',
  inspectionTemplate = { name: '', tenants: [], divsionId: 0 },
  errorMsg,
}) => {
  const schema = getSchema();

  return (
    <FormContextProvider>
      <FormModal
        onCancel={handleClose}
        onSubmit={handleSubmit}
        modalProps={{
          title: title,
        }}
      >
        <SchemaFieldsWithContextUI
          schema={schema}
          initialFormData={inspectionTemplate}
        />
        {errorMsg ? (
          <h5 className='error-messages finding-error'>
            This finding type name already exist. Please try another.
          </h5>
        ) : (
          ''
        )}
      </FormModal>
    </FormContextProvider>
  );
};

export default InspectionTemplateDetailsForm;
