import React, { useState, useEffect } from "react";

import weatherApi from "../utils/api/weather";

const Context = React.createContext({});

export const WeatherStore = props => {
  const [loaded, setLoadedStatus] = useState(false);
  // generic error catch
  const [error, setErrorStatus] = useState(false);
  // expected array with 3 items
  const [weather, setWeather] = useState([]);
  // expected shape for geo-location data
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    denied: false,
  });

  useEffect(() => {
    if (!loaded) {
      const data = weatherApi.index();

      if (data.name === "Error") {
        weatherApi.store([]);
        setWeather([]);

        setLoadedStatus(true);
      } else {
        weatherApi.store(data);
        setWeather(data);

        setLoadedStatus(true);
      }

      if (data.length < 3) {
        fetchWeatherData();
      }
    }
  }, [loaded]);

  function fetchWeatherData() {
    const options = {
      enableHighAccuracy: true, // if performance issues, set this to false
      timeout: Infinity,
      maximumAge: 0, // don't cache position since users will be mobile
    };

    function success(pos) {
      // only set loaded to true on success
      setLoadedStatus(true);

      const { latitude, longitude } = pos.coords;

      setLocation({ latitude, longitude, denied: false });

      weatherApi.fetch(latitude, longitude).then(data => {
        const hasError = data.name === "Error";

        setErrorStatus(hasError);
        setWeather(hasError ? [] : data);
      }).catch((err) => {
        setErrorStatus(true);
        setWeather([]);
      });
    }

    function error(err) {
      weatherApi.clear();

      // user rejected permission
      if (err.code === 1) {
        setLocation({
          latitude: location.latitude,
          longitude: location.longitude,
          denied: true,
        });
      } else {
        setErrorStatus(true);
      }
    }

    return navigator.geolocation.getCurrentPosition(success, error, options);
  }

  return (
    <Context.Provider
      value={{
        weather,
        location,
        loaded,
        error,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default Context;
