import React from "react";

function CaretIcon({ title, rotate, ...props }) {
  return (
    <svg
      width="14px"
      height="9px"
      viewBox="0 0 14 9"
      version="1.1"
      style={{
        transform: `rotate(${rotate}deg)`,
      }}
      {...props}
    >
      <title>{title}</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-10.000000, -107.000000)" fill="#7D92AC">
          <polygon
            transform="translate(17.000000, 111.400000) rotate(-270.000000) translate(-17.000000, -111.400000) "
            points="21 111.4 13 117.8 13 105"
          />
        </g>
      </g>
    </svg>
  );
}

CaretIcon.defaultProps = {
  title: "",
  rotate: 0,
};
export default CaretIcon;
