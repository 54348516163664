import React from 'react'          
import { isUndefined } from 'lodash';

import BaseShapeEditorField from './BaseField';

/**
 * {options} must be array of objects
 */
export default function Select({options, label, value, onChange, cast, initialValue, ...props}) {
  const { valueKey, textKey, ...rest } = props;

  return (
    <BaseShapeEditorField label={label} onChange={onChange} initialValue={initialValue}>
      {({inputId}) => (
        <select
          id={inputId}
          onChange={e => onChange(cast ? cast.call(null, e.target.value) : e.target.value) }
          value={isUndefined(value) ? initialValue : (value || '')}
          {...rest}
        >
          {options.map((l, $l) => <option value={l[valueKey || 'value']} key={$l}>
            {l[textKey || 'text']}
          </option>)}
        </select>
      )}
    </BaseShapeEditorField>
  );
}
