import React from "react";

export interface IContactPhoneProps {
  phone: string;
  ext?: string;
}

const ContactPhone: React.FC<IContactPhoneProps> = ({ phone, ext }) => {
  return (
    <div className="phone">
      {ext ? (
        <span>
          <i className="fa fa-phone" />
          <a href={`tel:${phone},${ext}`}>
            {phone}
            <span className="ext">&nbsp;ext:{ext}</span>
          </a>
        </span>
      ) : (
        <span>
          <i className="fa fa-phone" />
          <span>
            <a href={`tel:${phone}`}>{phone}</a>
          </span>
        </span>
      )}
    </div>
  );
};

export default ContactPhone;
