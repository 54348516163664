import { forIn } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import useDivisionResource from "../../../hooks/divisionResource";
import legendItemApi from "../../../utils/api/legendItem";
import ConfirmationModal from "../../Shared/ConfirmationModal/ConfirmationModal";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import FormModal from "../../Shared/form/modal/FormModal";
import LegendItemFormSchema, { formDataShape, getSchema } from "./FormSchema";
import { useLocation } from "react-router-dom";
import { AppDivisionContext } from "../../../contexts/AppDivisionContext";


const handleSubmit = (formData, callback, readOnly = false,appDivisionId) => {
  if (readOnly === true) {
    // exit early and don't run callback
    return;
  }

  // set api method and build method arguments
  const method = formData.id ? 'updateGlobal' : 'createGlobal';
  const reqData = {
    ...formData,
    groups: formData.groups && formData.groups.map((group) => group.id),
    divisionId:appDivisionId ,
  };

  return legendItemApi[method](reqData).then(callback);
};

const handleDelete = (id, onDelete) => {
  return legendItemApi.destroy(id).then(() => {
    if (onDelete) {
      onDelete();
    }
  });
};

function LIDetailsFormUI({
  onCancel,
  onSubmit,
  title,
  cancelText,
  showSubmit,
  schema,
  readOnly,
  enableDelete,
  onDelete,
}) {
  const formContext = useContext(FormContext);
  const [showDelete, setShowDelete] = useState(false);
  const [value, setValue] = useState(null);
  const { appDivisionId } = useContext(AppDivisionContext);


  useEffect(() => {
    setValue(formContext.value);
  }, [formContext.value]);

  return (
    <FormModal
      modalProps={{
        title,
        isSmall: false,
      }}
      onCancel={onCancel}
      onSubmit={formData => {
        return handleSubmit(formData, onSubmit, readOnly, appDivisionId);
      }}
      showSubmit={showSubmit}
      cancelText={cancelText}
      actionChildren={
        enableDelete === true ? (
          <button
            onClick={() => setShowDelete(true)}
            className='delete-user button-outline-dark'
          >
            Delete
          </button>
        ) : undefined
      }
    >
      <div className='legend-item-form'>
        <LegendItemFormSchema
          onChange={formContext.set}
          value={value}
          schema={schema}
        />
      </div>
      {showDelete && (
        <ConfirmationModal
          title='Delete Legend Item'
          subTitle={
            formContext.value &&
            `The legend item "${formContext.value.name}" will be deleted and removed from all site maps.`
          }
          show={true}
          handleClose={() => setShowDelete(false)}
          handleConfirm={() => handleDelete(formContext.value.id, onDelete)}
          buttonText='Delete'
        />
      )}
    </FormModal>
  );
}

export default function LIDetailsForm({ initialValue, readOnly, ...props }) {
  const location = useLocation();
  
  if (location.search) {
    const params = new URLSearchParams(location.search);
    
    params.delete('search');
    location.search = params.toString();
  }
  const regulations = useDivisionResource({
    resource: 'regulations',
    disablePagination: true,
  });

  const schema = getSchema(regulations.data, initialValue && initialValue.id);

  if (readOnly === true) {
    forIn(schema, (val) => {
      val.disabled = true;
    });
  }

  return (
    <FormContextProvider initialValue={initialValue}>
      <LIDetailsFormUI
        title={
          initialValue && initialValue.id
            ? 'Edit Legend Item'
            : 'Add Legend Item'
        }
        schema={schema}
        readOnly={readOnly}
        {...props}
      />
    </FormContextProvider>
  );
}

LIDetailsForm.propTypes = {
  initialValue: formDataShape,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  showSubmit: PropTypes.bool,
  enableDelete: PropTypes.bool,
  onDelete: PropTypes.func,
};

LIDetailsForm.defaultProps = {
  readOnly: false,
  showSubmit: true,
  enableDelete: false,
};
