import React, { useState, useEffect, useContext } from 'react';
import projectTypesApi from '../../utils/api/projectTypes';
import AppContext from '../../contexts/AppContext';
import RolesContext from '../../contexts/RolesContext';
import ToggleSmall from '../Shared/Toggle/ToggleSmall';
import { ActionButtons } from '../Shared/ActionButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@mui/material';
import { FormModalWithContext } from '../Shared/form';

interface ProjectType {
  projectType: {
    id: any;
    name: string;
    is_static: boolean;
  };
  is_included: boolean;
}

export const ProjectTypes = () => {
  const [collapse, setCollapse] = useState(false);
  const [projectTypes, setProjectTypes] = useState<ProjectType[]>([]);
  const [isEdit, setIsEdit] = useState<ProjectType | undefined>();
  const [open, setOpen] = useState(false);
  const [newProjectTypeName, setNewProjectTypeName] = useState('');
  const [nameError, setNameError] = useState(false);
  const appContext = useContext(AppContext);
  const permCheck = useContext(RolesContext).userHasPermission;
  const userTenantId =
    appContext &&
    appContext.state &&
    appContext.state.user &&
    appContext.state.user.tenantId;

  const loadInitialData = () => {
    projectTypesApi.index().then((res) => {
      setProjectTypes(res);
    });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setNewProjectTypeName('');
    setNameError(false);
    setIsEdit(undefined);
  };

  const handleToggle = (
    id: number,
    currentInclude: boolean,
    projectTypeName: string,
  ) => {
    projectTypesApi
      .updateToggle(id, currentInclude, projectTypeName)
      .then((res) => {
        const updatedProjectTypes = projectTypes.map((ele) => {
          if (ele.projectType.id === res.project_type_id) {
            return {
              projectType: {
                id: res.project_type_id,
                name: ele.projectType.name,
                is_static: ele.projectType.is_static,
              },
              is_included: res.is_included,
            };
          }

          return ele;
        });

        setProjectTypes(updatedProjectTypes);
      });
  };

  const handleSave = (): Promise<void> => {
    if (newProjectTypeName.trim() === '') {
      setNameError(true);

      return Promise.resolve();
    }
    projectTypesApi
      .create({ name: newProjectTypeName, tenantId: userTenantId })
      .then((res) => {
        const updatedProjectTypes = [
          ...projectTypes,
          {
            is_included: res.mapping.is_included,
            projectType: {
              id: res.projectType.id,
              name: res.projectType.name,
              is_static: false,
            },
          },
        ];

        setProjectTypes(updatedProjectTypes);
        handleClose();
      });

    return Promise.resolve();
  };

  const handleEdit = (): Promise<void> => {
    if (newProjectTypeName.trim() === '') {
      setNameError(true);

      return Promise.resolve();
    }

    projectTypesApi
      .updateToggle(
        isEdit?.projectType.id,
        isEdit?.is_included || false,
        newProjectTypeName,
      )
      .then((res) => {
        const updatedProjectTypes = projectTypes.map((ele) => {
          if (ele.projectType.id === res.project_type_id) {
            return {
              projectType: {
                id: res.project_type_id,
                name: newProjectTypeName,
                is_static: ele.projectType.is_static,
              },
              is_included: res.is_included,
            };
          }

          return ele;
        });

        setProjectTypes(updatedProjectTypes);
        handleClose();
      });

    return Promise.resolve();
  };

  const handleDelete = (): Promise<void> => {
    if (isEdit) {
      projectTypesApi.delete(isEdit.projectType.id).then((res) => {
        if (!res) {
          return;
        }
        const updatedProjectTypes = projectTypes.filter(
          (ele) => ele.projectType.id !== isEdit?.projectType.id,
        );

        setProjectTypes(updatedProjectTypes);
        handleClose();
      });
    }

    return Promise.resolve();
  };

  return (
    <div className='settings-tenant-main-container'>
      <div
        className='settings-main-tenant-text'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => setCollapse(!collapse)}
      >
        <h3>Project Types</h3>
        <i
          className={collapse ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
          aria-hidden='true'
        />
      </div>
      <hr></hr>
      <br />
      <div
        className={
          collapse ? 'administration-hidden' : 'administration-visible'
        }
      >
        <div className='project-type-table-body'>
          <h3>Name</h3>
          <h3>Include</h3>
        </div>
        {projectTypes.map((projectType) => (
          <div className='project-type-table-body'>
            <h3>{projectType.projectType.name}</h3>
            <h3 className=''>
              <ToggleSmall
                isChecked={projectType.is_included}
                handleChange={() =>
                  handleToggle(
                    projectType.projectType.id,
                    !projectType.is_included,
                    projectType.projectType.name,
                  )
                }
                disabled={!permCheck('all', 'Project Type')}
              />
              {projectType.projectType.is_static ? null : (
                <span
                  onClick={() => {
                    setIsEdit(projectType);
                    setNewProjectTypeName(projectType.projectType.name);
                    setOpen(true);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <FontAwesomeIcon icon={faEdit} title='Edit Interval' />
                </span>
              )}
            </h3>
          </div>
        ))}
        <ActionButtons
          className='project-type-create-new-button'
          primary='Create New Project Type +'
          onClick={() => setOpen(true)}
        />
        <br />
        <br />
        <br />
      </div>
      {open && (
        <FormModalWithContext
          modalProps={{
            title: `${isEdit ? 'Edit Project Type' : 'Add Project Type'}`,
            submitBtnText: 'Submit',
          }}
          onSubmit={() => (isEdit ? handleEdit() : handleSave())}
          onCancel={handleClose}
          showDelete={isEdit ? true : false}
          onDelete={handleDelete}
        >
          <TextField
            autoFocus
            fullWidth
            label='Project Type Name'
            value={newProjectTypeName}
            onChange={(e) => {
              setNewProjectTypeName(e.target.value);
              setNameError(false);
            }}
            style={{ marginBottom: '20px' }}
            error={nameError}
            helperText={nameError ? 'Name cannot be empty' : ''}
          />
        </FormModalWithContext>
      )}
    </div>
  );
};
