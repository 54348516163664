import { QuestionApi } from "@sw-sw/lib-inspection-templates";
import xhrService from "../api";

export const questionApi: QuestionApi = {
  show: id => {
    return xhrService
      .get(`/api/questions/${id}`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
  create: (groupId, newQuestion) => {
    ///////////////////
    console.log(groupId);
    ///////////////////

    return xhrService
      .post(`/api/questions`, newQuestion)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },

  update: (id, data) => {
    return xhrService
      .put(`/api/questions/${id}`, data)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },

  delete: id => {
    return xhrService
      .delete(`/api/questions/${id}`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
};
