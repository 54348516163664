import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import AppContext from "../../contexts/AppContext";
import userApi from "../../utils/api/user";
import ElectronicSignatureConsentModal from "../Shared/ElectronicSignatureConsentModal";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const ElectronicSignatureControl: React.FC<{}> = () => {
  const appContext = useContext(AppContext);
  const [isSigned, setIsSigned] = useState<boolean>(
    appContext.get("user.electronicSignatureAuthorization"),
  );
  const [showConsentModal, setShowConsentModal] = useState<boolean>(false);

  const handleClick = async () => {
    const userId = appContext.get("user.id");
    const action = isSigned ? "disagree" : "agree";

    const resp = await userApi.updateElectronicSignatureAuthorization(
      userId,
      action,
    );

    setIsSigned(resp.electronicSignatureAuthorization);
    appContext.set(
      "user.electronicSignatureAuthorization",
      resp.electronicSignatureAuthorization,
    );
  };

  return (
    <div className="electronic-signature-control">
      <h3>Electronic Signature Control</h3>
      <div className="signature-form">
        <input type="checkbox" checked={isSigned} onChange={handleClick} />
        <p>
          I agree to the use of electronic signatures.{" "}
          <FontAwesomeIcon
            className="modal-icon"
            icon={faQuestionCircle}
            onClick={() => setShowConsentModal(true)}
          />
        </p>
      </div>
      {showConsentModal && (
        <ElectronicSignatureConsentModal
          show={showConsentModal}
          handleClose={() => setShowConsentModal(false)}
        />
      )}
    </div>
  );
};

export default ElectronicSignatureControl;
