import { get, pick, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppDivisionContext } from '../../../contexts/AppDivisionContext';
import { useUserFormData } from '../../../hooks/userFormData';
import divisionApi from '../../../utils/api/division';
import userApi from '../../../utils/api/user';
import { FormContext, FormContextProvider } from '@sw-sw/lib-form';
import FormModal from '../../Shared/form/modal/FormModal';
import Form1 from '../Forms/UserStepOne';
import Form2 from '../Forms/UserStepTwo';
import { TooltipContextProvider } from '@sw-sw/lib-ui';

/**
 * Extract initial form data to edit a user
 *
 * @param {{id: number}} user The selected user
 * @param {{id:number}[]} clients "master" list of clients, for the application user, _not_ the selcted user.
 *
 * whitelist "clients" ensures form data is scoped, according to the application user's permissions
 */
const extractInitialValue = (user) => {
  const keys = [
    'id',
    'first_name',
    'last_name',
    'email',
    'address.phone',
    'address.phone_ext',
    'position',
    'roleId',
    'roleName',
    'ccrNumber',
    'peNumber',
  ];

  const mapAsOpts = (list) => list.map((x) => pick(x, ['id', 'name']));

  return {
    ...pick(user, keys),

    /**
     * @important this is needed to initialize current selections
     */
    clients: mapAsOpts(get(user, 'clients', [])),
    projects: mapAsOpts(get(user, 'projects', [])),
    regulations: mapAsOpts(get(user, 'document_groups', [])),
    templates: mapAsOpts(get(user, 'templates', [])),
    divisionIds: mapAsOpts(get(user, 'divisions', [])),
    managerUserId:
      user.managers && Array.isArray(user.managers) && user.managers.length
        ? user.managers[0].id
        : null,
  };
};

/**
 * UI Controller for two-step user form
 *
 * @todo abstract this into a generic multi-step form controller
 */
function EditUserModalUI({
  onClose,
  onSubmit,
  isProfile,
  isEdit,
  // handleDelete,
  setFormData,
  formData,
  showEditRole,
  isMyProfile
}) {
  const formContext = useContext(FormContext);
  const [page, setPage] = useState(0);
  // const [formOpts, setFormOpts] = useState(formData);

  /** submit page 0 */
  const handlePageSubmit = useCallback(async () => {
    const clientIds = await (formContext.value.divisionIds.length
      ? Promise.all(
          formContext.value.divisionIds.map(async (division) => {
            const clients = await divisionApi.clients.index(division.id);
            const ids = clients.map((client) => client.id);

            return [...ids];
          }),
        )
      : Promise.resolve([]));

    const divisionIds = formContext.value.divisionIds
      ? formContext.value.divisionIds.map((_) => _.id)
      : [];

    return userApi
      .getCreateOpts({
        divisionIds: divisionIds,
        roleId: formContext.value.roleId,
        clientIds: clientIds,
        tenantId: formContext.value.tenantId,
        
      })
      .then((data) => {
        if (formContext.value.clients) {
          formContext.set(
            'clients',
            formContext.value.clients.filter((c) =>
              get(data, 'clients')
                .map((_) => _.id)
                .includes(c.id),
            ),
          );
        }
        // combine server options with current user data
        // in case the user is associated with other data
        setFormData({
          ...data,
          clients: data.clients.length
            ? uniqBy(
                [
                  ...get(data, 'clients', []),
                  ...get(formContext, 'value.clients', []),
                ],
                'id',
              )
            : [],
        });

        formContext.setBusy(false);

        setPage(1);
      });
  }, [formContext]);

  /** submit page 1 (last page) */
  const handleCreateSubmit = useCallback(() => {
    const query = isEdit
      ? isProfile
        ? userApi.updateProfile(formContext.value)
        : userApi.update(formContext.value.id, {
            ...formContext.value,
            divisionIds: formContext.value.divisionIds
              ? formContext.value.divisionIds.map((_) => _.id)
              : [],
          })
      : userApi.create({
          ...formContext.value,
          tags: formContext.value.tags.map((tag) => ({ email: tag.text })),
          divisionIds: formContext.value.divisionIds
            ? formContext.value.divisionIds.map((_) => _.id)
            : [],
        });

    return query.then(onSubmit).then(() => onClose());
  }, [formContext]);

  const getActionChildren = () => {
    const children = [];

    // not allowed to delete oneself
    if (!isProfile && !isEdit) {
      children.push(
        <button key='Add Tenant' className='delete-user button-outline-dark'>
          Add Tenant
        </button>,
      );
    }

    if (page === 1) {
      children.push(
        <button key='back' onClick={() => setPage(0)} className='reversed'>
          Back
        </button>,
      );
    }

    return children;
  };

  let modalProps = {};

  if (isMyProfile) {
    modalProps = {
      onSubmit: () => handleCreateSubmit(),
      submitText: 'Save',
      showCancel: true,
    };
  } else {
    modalProps = page
      ? {
          onSubmit: () => handleCreateSubmit(),
          submitText: isEdit ? 'Save' : 'Create',
          showCancel: false,
        }
      : {
          onSubmit: () => handlePageSubmit(),
          submitText: 'Next',
          cancelText: 'Cancel',
        };
  }

  return (
    <TooltipContextProvider>
      <FormModal
        modalProps={{
          title: isEdit ? 'Edit User' : 'Add Users',
          classes: isEdit ? 'edit-user' : 'add-users',
          dismissOnEsc: true,
        }}
        onCancel={onClose}
        actionChildren={getActionChildren()}
        // breadCrumbData={{ pages: 2, currentPage: page }}
        {...modalProps}
      >
        {page ? (
          <Form2 formOpts={formData} isEdit={isEdit} />
        ) : (
          <Form1
            formOpts={formData}
            showEditRole={showEditRole}
            isEdit={isEdit}
            isProfile={isProfile}
            isMyProfile={isMyProfile}
          />
        )}
      </FormModal>
    </TooltipContextProvider>
  );
}

/**
 * Data controller for two-step user edit form
 *
 * @note implementation supports creation and editing
 */
function EditUserModal({
  userId,
  roleId,
  name,
  onDelete,
  onSubmit,
  onClose,
  isProfile,
  isEdit,
  isMyProfile = false,
}) {
  // const appStore = useContext(AppContext);
  const appDivisionContext = useContext(AppDivisionContext);

  const formDataQuery =
    appDivisionContext.appDivisionId &&
    useUserFormData(appDivisionContext.appDivisionId);

  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(false);

  // form options for selection of clients/roles/projects
  const [formData, setFormData] = useState({
    roles: formDataQuery.roles.data,
    divisions: formDataQuery.divisions.data,
  });

  const [initialValue, setInitialValue] = useState(
    isEdit
      ? null
      : {
          tags: [],
          roleId: '',
          projects: [],
        },
  );

  let showEditRole = true;

  if (isEdit) {
    // allow role edit, when user's role is visible to this user
    showEditRole =
      roleId && formData.roles
        ? formData.roles.some((role) => role.id === roleId)
        : false;
  }

  /**
   * @todo use hook
   */
  useEffect(() => {
    if (isEdit && isMyProfile) {
      userApi.getProfile(userId).then((u) => {
        setUser(u);
        setInitialValue(extractInitialValue(u));
      });
    } else if (isEdit) {
      userApi.get(userId).then((u) => {
        setUser(u);
        setInitialValue(extractInitialValue(u));
      });
    }
  }, []);

  useEffect(() => {
    if (!loaded && !formDataQuery.loading) {
      setFormData({
        roles: formDataQuery.roles.data,
        divisions: formDataQuery.divisions.data,
      });
      setLoaded(true);
    }
  }, [user, formDataQuery]);

  // only render when loaded and initial value is set
  if (!loaded || !initialValue) {
    return null;
  }

  return (
    <FormContextProvider initialValue={initialValue}>
      <EditUserModalUI
        showEditRole={showEditRole}
        formData={formData}
        setFormData={(newData = {}) => setFormData({ ...formData, ...newData })}
        onSubmit={onSubmit}
        onClose={onClose}
        isProfile={isProfile}
        isEdit={isEdit}
        isMyProfile={isMyProfile}
      />
    </FormContextProvider>
  );
}

export const formDataShape = {
  id: PropTypes.number,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  address: PropTypes.shape({
    phone: PropTypes.string,
    phone_ext: PropTypes.string,
  }),
  position: PropTypes.string,
};

EditUserModalUI.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isProfile: PropTypes.bool,
  isEdit: PropTypes.bool,
  showEditRole: PropTypes.bool,

  // method to append form options data {(newData: {}) => void}
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

EditUserModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,

  isProfile: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,

  userId: PropTypes.number,
  roleId: PropTypes.number,
  name: PropTypes.string.isRequired,
};

EditUserModal.defaultProps = {
  isProfile: false,
  isEdit: true,
  name: '',
};

export default EditUserModal;
