import { useContext, useEffect, useState } from "react";
import { mapUtil } from "@sw-sw/common";
import uploadApi from "../../utils/api/upload";
import AppContext from "../../contexts/AppContext";

interface ISiteMap {
  id: number;
}

export function useSiteMaps(
  projectId: number,
  projectDocTypeId: number | null,
  onError: (error: string) => void,
): ISiteMap[] | null {
  const [state, setState] = useState<ISiteMap[] | null>(null);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (projectDocTypeId) {
      appContext.loadData(
        () =>
          uploadApi.project
            .index(projectId, projectDocTypeId)
            .then(maps =>
              maps.filter((m: any) =>
                mapUtil.isSupportedMimeType(m.upload.mime_type),
              ),
            )
            .then(data => setState(data))
            .catch(err => {
              console.error("useProjectSiteMaps", err);
              onError("Cannot get project site maps");
            }),
        "Site Maps",
      );
    }
  }, [projectDocTypeId]);

  return state;
}
