import React, { useEffect, useState } from 'react';
import { InspectionStore } from '../../contexts/InspectionContext';
import ProjectContext from '../../contexts/ProjectContext';
import projectApi from '../../utils/api/project';
import { useRouteMatch } from 'react-router-dom';
import inspectionTemplateApi from '../../utils/api/inspectionTemplate';
/**
 *
 */

const FullScreenLayout = ({ children }) => {
  const match = useRouteMatch();
  const [projectDocs, setProjectDocs] = useState();
  const [templateName, setTemplateName] = useState();

  useEffect(async () => {
    const data = await projectApi.getDocs(match.params.projectId);

    setProjectDocs(data);

    const getProject = await projectApi.get(match.params.projectId);

    const getName =
      getProject && (await inspectionTemplateApi.get(getProject.inspection_template_id));

    setTemplateName(getName);
  }, []);


  
  return (
    <ProjectContext.Provider
      value={{
        projectDocs,
        setProjectDocs,
        template: templateName && templateName.name,
      }}
    >
      <InspectionStore>
        <div className='layout layout--fullscreen'>{children}</div>
      </InspectionStore>
    </ProjectContext.Provider>
  );
};

export default FullScreenLayout;