import React from "react";
import { CompactPicker } from "react-color";

export interface IColorInputProps {
  onChange: (color: string) => void;
  value: string;
}
/**
 * Color input with input mask. Value as hex.
 * @see react-text-mask
 */

export const ColorInput: React.FC<IColorInputProps> = ({
  onChange,
  value,
  ...rest
}) => {
  return (
    <CompactPicker
      color={value}
      onChange={(color: { hex: string }) => onChange(color.hex)}
      {...rest}
    />
  );
};

// /**
//  * Wraps input around field
//  * @see informed
//  */
// const ColorField = asField(({fieldState, fieldApi, ...props}) => {
//   const {
//     onBlur, onChange,
//     initialValue, forwardedRef,
//     field,
//     ...rest
//   } = props;

//   const onBlurFn = e => {
//     fieldApi.setTouched();

//     if (onBlur) {
//       onBlur(e);
//     }
//   };

//   const onChangeFn = e => {
//     fieldApi.setValue(e.target.value);

//     if (onChange) {
//       onChange(e);
//     }
//   };

//   return (<ColorInput {...rest} value={fieldState.value || ''} onBlur={onBlurFn} onChange={onChangeFn} />);
// });

// export default ColorField;
