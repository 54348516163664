import { useQuery } from "react-query";
import clientApi from "../utils/api/client";
import divisionApi from "../utils/api/division";

/**
 * @deprecated not used
 */
export function useClients() {
  return useQuery("clients", () => clientApi.index());
}

export function useDivisionClients(divisionId: number) {
  return useQuery(
    ["divisionClients", divisionId],
    () => divisionApi.clients.index(divisionId),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useClient(clientId: number) {
  return useQuery(["clients", clientId], () => clientApi.show(clientId), {
    refetchOnWindowFocus: false,
  });
}

export function useClientProjects(clientId: number) {
  return useQuery(["clientsProjects", clientId], () =>
    clientApi.projects.index(clientId),
  );
}
