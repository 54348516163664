import { TemplateQuestionsApi } from "@sw-sw/lib-inspection-templates";
import xhrService from "../api";

export const templateQuestionConfigApi: TemplateQuestionsApi = {
  index: (templateId: number) =>
    xhrService
      .get(`/api/inspection-templates/${templateId}/questions/config`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      }),
};
