import { fabric } from "fabric";
import DraggableShapeTool from "./DraggableShape";

/**
 * rectangle/square tool
 */
class EllipseTool extends DraggableShapeTool {
  makeShape(options) {
    return new fabric.Ellipse(options);
  }

  getDefaultShapeConfig() {
    return {
      selectable: false,
      radius: 0.1,
      stroke: "#333333",
      strokeWidth: 2,
      fill: "#cccccc",
      opacity: 0.8,
      strokeDashArray: [0, 0],
      startAngle: 20,
      endAngle: 45,
      strokeUniform: true,
    };
  }

  calculateDimensions(x, y, left, top) {
    return {
      rx: Math.max(0.1, (x - left) / 2),
      ry: Math.max(0.1, (y - top) / 2),
    };
  }
}

export default EllipseTool;
