import React, { useContext, useEffect } from 'react';
import DashWidgets from './DashWidgets';
import ContractorView from './Views/ContractorView';

import dashboardContext, { DashboardStore } from '../../contexts/DashContext';
import { WeatherStore } from '../../contexts/WeatherContext';
import RolesContext from '../../contexts/RolesContext';
import { CustomDashboardLite } from '../CustomDashboard';
import RegulatorView from './Views/RegulatorView';
import AppContext from '../../contexts/AppContext';

const viewControl = {
  'BMP Contractor': ContractorView,
  Regulator: RegulatorView,
  'COA Regulator': RegulatorView,
  'Billing Admin': RegulatorView,
  'Engineering Admin': RegulatorView,
  'Regulator - With Photos': RegulatorView,
  'Certifying Regulator': RegulatorView,
};

const DashboardUI = (props) => {
  const rolesStore = useContext(RolesContext);
  const { name, data } = useContext(dashboardContext).data;
  const { handleReassign } = useContext(dashboardContext);
  const ActiveView = viewControl[name] || <React.Fragment />;
  const appContext = useContext(AppContext);
  const { panel, setPanel } = appContext;
  const setter = () => {
    if (panel === 'open') {
      setPanel('close');
    }
  };

  useEffect(() => {
    setTimeout(setter, 500);
  }, []);

  return (
    <WeatherStore>
      {rolesStore.userHasPermission('read', 'Dashboard') && (
        <section className='dashboard-main'>
          <DashWidgets />
          {typeof ActiveView === 'function' && data && (
            <ActiveView data={data} handleReassign={handleReassign} />
          )}
        </section>
      )}
    </WeatherStore>
  );
};

const Dashboard = (props) => {
  const {
    user: {
      userTenantRoles: [{ role }],
    },
  } = props;

  if (!role) return null;

  const roleName = [
    'BMP Contractor',
    'Regulator',
    'COA Regulator',
    'Billing Admin',
    'Engineering Admin',
    'Regulator - With Photos',
    'Certifying Regulator',
  ];

  if (roleName.includes(role.name)) {
    return (
      <DashboardStore>
        <DashboardUI {...props} />
      </DashboardStore>
    );
  }

  return (
    <WeatherStore>
      <section className='dashboard-main'>
        <DashWidgets />
        <CustomDashboardLite />
      </section>
    </WeatherStore>
  );
};

export default Dashboard;
