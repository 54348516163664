import {
  defaultTemplateRenderer,
  RenderContextValue,
} from "@sw-sw/ui-inspection-templates";
import React, { useContext } from "react";
import { InspectionContext } from "../../../../contexts/InspectionContext";

export const TemplateInput: RenderContextValue["Input"] = props => {
  const inspectionContext = useContext(InspectionContext);

  return (
    <defaultTemplateRenderer.Input
      {...props}
      readOnly={inspectionContext ? inspectionContext.inspection.compliance_date || inspectionContext.isInspectionReadOnly : true}
      inputControl={{ className: "form-control" }}
    />
  );
};
