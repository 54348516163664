import { FormContextProvider } from "@sw-sw/lib-form";
import { RenderContextProvider } from "@sw-sw/ui-inspection-templates";
import React from "react";
import { DynamicBaseTemplate as Template } from "./DynamicTemplate/BaseTemplate";
import { TemplateInput } from "./DynamicTemplate/TemplateInput";
import { TemplateQuestion } from "./DynamicTemplate/TemplateQuestion";

/**
 * User-defined inspection template.
 */
export function DynamicTemplateQuestions() {
  return (
    <FormContextProvider>
      <RenderContextProvider Question={TemplateQuestion} Input={TemplateInput}>
        <Template />
      </RenderContextProvider>
    </FormContextProvider>
  );
}
