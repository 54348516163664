import xhrService from "../xhrService";
import { ProjectFolderFormDataType } from "../../components/Projects/Details/ProjectSitePhotos";
const baseUrl = "/api/divisions";

export const projectSitePhotos = {

    indexFolder: ({ projectId, divisionId }: { projectId: number; divisionId: number; }) => {
        if (!divisionId || !projectId) { return false };
        return xhrService.get(`${baseUrl}/${divisionId}/projects/${projectId}/sitephotos/index`).then(response => response.data).catch(e => { console.log(e.message); return false; });
    },

    createFolder: ({ projectId, divisionId, formData, userId }: { projectId: number; divisionId: number; formData: ProjectFolderFormDataType; userId: number }) => {
        if (!divisionId || !projectId) { return false };
        return xhrService.post(`${baseUrl}/${divisionId}/projects/${projectId}/sitephotos/create`, {
            userId,
            formData
        }).then(response => response.data).catch(e => { console.log(e.response.data); return e.response.data; });
    },

    updateFolder: ({ projectId, divisionId, formData, userId }: { projectId: number; divisionId: number; formData: ProjectFolderFormDataType; userId: number }) => {
        if (!divisionId || !projectId) { return false };
        return xhrService.post(`${baseUrl}/${divisionId}/projects/${projectId}/sitephotos/update`, {
            userId,
            formData
        }).then(response => response.data).catch(e => { console.log(e.message); return false; });
    },

    deleteFolder: ({ projectId, divisionId, folderId }: { projectId: number; divisionId: number; folderId: number; }) => {
        if (!divisionId || !projectId) { return false };
        return xhrService.post(`${baseUrl}/${divisionId}/projects/${projectId}/sitephotos/delete`, { folderId }).then(response => response.data).catch(e => { console.log(e.message); return false; });
    },

    uploadFiles: ({ projectId, divisionId, formData }: { projectId: number; divisionId: number; formData: any; }) => {
        if (!divisionId || !projectId) { return false };
        return xhrService.post(`${baseUrl}/${divisionId}/projects/${projectId}/sitephotos/files/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => response.data).catch(e => { console.log(e.message); return false; });
    },

    getFiles: ({ projectId, divisionId, folderId }: { projectId: number; divisionId: number; folderId: number; }) => {
        if (!divisionId || !projectId) { return false };
        return xhrService.get(`${baseUrl}/${divisionId}/projects/${projectId}/sitephotos/${folderId}/files/list`).then(response => response.data).catch(e => { console.log(e.message); return false; });
    },

    deleteFile: ({ projectId, divisionId, folderId, fileId }: { projectId: number; divisionId: number; folderId: number; fileId: number; }) => {
        if (!divisionId || !projectId) { return false };
        return xhrService.post(`${baseUrl}/${divisionId}/projects/${projectId}/sitephotos/${folderId}/files/delete`, { fileId }).then(response => response.data).catch(e => { console.log(e.message); return false; });
    },

    updateFile: ({ projectId, divisionId, folderId, fileId, formData }: { projectId: number; divisionId: number; folderId: number; fileId: number; formData: any; }) => {
        if (!divisionId || !projectId) { return false };
        return xhrService.post(`${baseUrl}/${divisionId}/projects/${projectId}/sitephotos/${folderId}/files/${fileId}/update`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => response.data).catch(e => { console.log(e.message); return false; });
    },

};

export default projectSitePhotos;
