import React from 'react';

import Select from '../../Fields/Select';

/** @todo factory-pattern to make modifyable instances of {fabric.Path} */
export const fillTypes = [{
  name: 'Solid',
  value: 'solid',
}, {
  name: 'Parallel Lines',
  value: 'lines',
}, {
  name: 'Crossing Lines',
  value: 'hatch',
}];

/**
 * @todo support more than one value
 */
class FillType extends React.Component {
  render() {
    return (
      <Select label="Fill Type" options={fillTypes} textKey="name" {...this.props} />
    );
  }
}

export default FillType;
