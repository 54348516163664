import React from "react";
import PropTypes from "prop-types";
import { FormSchemaFields } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
// import { defaultCustomEmailTemplates } from "../../utils/customEmailMessage";

const schema = {
  // customMessage: {
  //   label: "Message",
  //   controlType: "textareaTmpl",
  //   templates: defaultCustomEmailTemplates,
  // }
  customMessage: {
    label: "Message",
    controlType: UIControlType.textarea,
  },
};

const CustomMessageForm = ({ onChange, value }) => {
  return (
    <>
      <FormSchemaFields schema={schema} formData={value} onChange={onChange} />
    </>
  );
};

CustomMessageForm.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CustomMessageForm;
