import instance from '../xhrService';

export type Finding = {
  id: number;
  type: string;
  date_completed: Date | null;
  date_initiated: Date | null;
};

  type UpdateFindingTypes = {
    inspection_template_id?: number;
    name?: string;
    is_enabled?: boolean;
    is_required_for_cert?: boolean;
    color?: any;
  }
  type CreateFindingTypes = Required<Pick<UpdateFindingTypes, "inspection_template_id" | "name">>
  /**
   * Findings by inspection
   */
  const url = () => `/api/finding-types/`;
  
  
  export const findingTypesApi = {
    index: (id: number) =>
      instance
        .get(`/api/finding-types/by_template_id/${id}`)
        .then(response => response.data),
  
    create: (
        data: CreateFindingTypes
    ) => {
      return instance
        .post(url(), {is_required_for_cert:false, is_static_type:false, is_enabled:false, ...data})
        .then(response => response.data)
    },
  
    destroy: (id: number) =>
      instance
        .delete(`/api/finding-types/${id}`)
        .then(response => response.data),
  
    update: (data: any ) => {      
      return instance      
        .put(url(), data)
         .catch((e) => {
            throw e
          });
      },

    fetchAll: () =>
      instance
        .get(`${url()}fetch_all`)
        .then(response => response.data),
  };
  
  
  export default findingTypesApi;
  
