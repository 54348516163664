import React from "react";

export interface IDivisionErrorProps {
  resource: string;
}
const DivisionError: React.FC<IDivisionErrorProps> = ({ resource }) => {
  return <h3>A division must be selected to view {resource}.</h3>;
};

export default DivisionError;
