import xhrService from '../api';

export interface Division {
  id: number;
  name: string;
  createdAt?: Date;
}
export const regionApi = {
  index: () => {
    return xhrService
      .get(`/api/regions`)
      .then((res) => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
  create: (body: {}) => {
    return xhrService
      .post('/api/regions', body)
      .then((res) => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
  update: (regionId: number, body: {}) => {
    return xhrService
      .put(`/api/regions/${regionId}`, body)
      .then((res) => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
  delete: (regionId: number) => {
    return xhrService
      .delete(`/api/regions/${regionId}`)
      .then((res) => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
  divisions: {
    index(regionId: number): Promise<Division[]> {
      return xhrService
        .get(`/api/regions/${regionId}/divisions`)
        .then((res) => res.data);
    },
  },
};
