import React, { useContext, useEffect, useState } from "react";
import { string, bool, number, shape, func, arrayOf, array } from "prop-types";

import FormModal from "../../Shared/form/modal/FormModal";
import { defaultFindingObservationTemplates } from "../../../utils/clients";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import { FormSchemaFields } from "@sw-sw/lib-form";
import validator from "../../../utils/FormValidator";
import Loading from "../../Shared/ResourceIndex/Loading";
import { useStates } from "../../../hooks/address";
import { useCountries } from "../../../hooks/address";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { env } from '../../../config'
import NewClientDocTypeFolder from "./NewClientDocTypeFolder";
import clientApi from "../../../utils/api/client";
import ConfirmationModal from "../../Shared/ConfirmationModal/ConfirmationModal";
import { Modal } from "@sw-sw/lib-ui";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TenantFeatureContext  from "../../../contexts/TenantContext";
/**
 * naming convention: variable name "states" refers to -- 
 * 1. states of US (for US region)
 * 2. countries of UK (for UK region)
 */

const deleteCustomDocType = async(docTypeId, setDeleteDocTypeError, setShowDeleteModal, docTypesRefetch) => {
  try{
    let clientId = window.location.pathname.split('/')[4]

    const deleteResponse = await clientApi.deleteCustomClientDocType.index(clientId, docTypeId)

    if(deleteResponse.hasOwnProperty('Error')){
      setDeleteDocTypeError(deleteResponse.Error)
    }
    else{
      setShowDeleteModal(false)
      docTypesRefetch.refetch()
    }
  }
  catch(error){
    console.log(error)
  }
}

const editCustomDocType = async (
  docTypeId,
  setDeleteDocTypeError,
  setShowEditModal,
  docTypesRefetch,
  selectedDocTypeName,
) => {
  try {
    let clientId = window.location.pathname.split("/")[4];

    await clientApi.editCustomClientDocType.index(
      clientId,
      docTypeId,
      selectedDocTypeName,
    );
    docTypesRefetch.refetch();
  } catch (error) {
    console.log(error);
  }
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * naming convention: variable name "states" refers to -- 
 * 1. states of US (for US region)
 * 2. countries of UK (for UK region) 
 */
function getSchema(states,tenantPrecipitationTracking) {

  const halfWidthStyle = { flex: "1 1 50%", alignSelf: "flex-end" };

  return {
    name: {
      label: 'Client Name',
      autoComplete: 'organization',
      validation: {
        required: true,
      },
    },
    code: {
      label: "Code",
      autoComplete: 'code',
    },
    phone: {
      label: "Phone",
      autoComplete: "tel",
      mask: env.REACT_APP_REGION === "UK" ? "phoneUK" : "phone",
      validation: {
        required: env.REACT_APP_REGION === "UK" ? false : true,
        format: env.REACT_APP_REGION === "UK" ? "phoneUK" : "phone",
      },
    },
    street1: {
      label: 'Address',
      placeholder: 'Street',
      autoComplete: 'address-line1',
      'aria-label': 'Street address line 1 of 2',
      validation: {
        required: true,
      },
    },
    street2: {
      placeholder: 'Street Line 2',
      'aria-label': 'Street address line 2 of 2',
      autoComplete: 'address-line2',
    },
    city: {
      placeholder: env.REACT_APP_REGION === "UK" ? "Town/City" : "City",
      "aria-label": "City",
      autoComplete: "address-level2",
      validation: {
        required: true,
      },
    },
    state: {
      controlType: UIControlType.select,
      placeholder: env.REACT_APP_REGION === "UK" ? "Country" : "State",
      "aria-label": "State",
      autoComplete: "address-level1",
      options: states,
      labelKey: 'name',
      valueKey: 'id',
      style: { flex: '1 1 50%' },
      validation: {
        required: true,
      },
    },
    zip: {
      placeholder: env.REACT_APP_REGION === "UK" ? "Postcode" : "Zip",
      "aria-label": "zip code",
      className: "zip",
      autoComplete: "postal-code",
      maxLength: 5,
      style: { flex: '1 1 50%' },
      validation: {
        required: true,
      },
      inputMode: 'numeric',
      parse: validator.parseNumber,
    },
    default_finding_observation: {
      label: 'Default Finding Observation',
      controlType: UIControlType.textareaTmpl,
      templates: defaultFindingObservationTemplates,
    },
    disable_images: {
      label: "Disable Images",
      controlType: UIControlType.checkbox,
      placeholder: "Disable Images",
      "aria-label": "Disable Images",
      style: halfWidthStyle,
    },
    precipitation_client_project_flag: {
      label: "Precipitation",
      controlType: UIControlType.toggleButton,
      checked: false,
      toggleSize: 'sm',
      style: halfWidthStyle,
      disabled: !tenantPrecipitationTracking?true:false,
    }
  };
}

function getSchemaNext(docTypes, projectTabs, document_date, setShowDeleteModal, setSelectedDocTypeId, setShowEditModal, setSelectedDocTypeName, setShowReorderModal, tempReorderDocTypes ) {
  const handleDeleteIconClick = async (event, docTypeId) => {
    event.preventDefault()
    setShowDeleteModal(true)
    setSelectedDocTypeId(docTypeId)
  }

  const handleEditCustomDocType = async (event, docTypeId) => {
    const docType = docTypes.filter((ele) => ele.id === docTypeId);

    event.preventDefault();
    setShowEditModal(true);
    setSelectedDocTypeId(docTypeId);

    if (docType.length && docType[0].client_dt && docType[0].client_dt.name) {
      setSelectedDocTypeName(docType[0].client_dt.name);
    }
  };

  const handleOnReorderButtonClick = () => {
    setShowReorderModal(true);
  };

  const filteredDocTypes = docTypes.filter(e => e.name !== 'Fixed Details' && e.name !== "Fixed Details Project")
  const finalDocTypes = tempReorderDocTypes.length ? tempReorderDocTypes.filter(e => e.name !== undefined) : filteredDocTypes
  
  return {
    projectTabs: {
      label: "Project Tabs",
      controlType: UIControlType.checkboxGroup,
      options: projectTabs,
      valueKey: "id",
      labelKey: "name",
    },
    document_date: {
      label: 'Document Date',
      controlType: UIControlType.checkboxGroup,
      options: [document_date],
      valueKey: 'id',
      labelKey: 'name'
    },
    docTypes: {
      label: "Document Types",
      controlType: UIControlType.checkboxGroup,
      options: finalDocTypes,
      valueKey: "id",
      labelKey: "name",
      deleteCustomDocType: handleDeleteIconClick,
      editCustomDocType: handleEditCustomDocType,
      reorderEnabled: true,
      onReorderButtonClick: handleOnReorderButtonClick,
    }
  };
}

function getInitialData(client) {
  const { address = {} } = client;

  return {
    name: client.name,
    code: client.code,
    street1: address.street_1,
    street2: address.street_2,
    city: address.city,
    state: env.REACT_APP_REGION === "UK" ? address.countryObj && address.countryObj.id : address.state && address.state.id,
    zip: address.zip,
    phone: address.phone,
    default_finding_observation: client.default_finding_observation,
    disable_images: client.disable_images,
    precipitation_client_project_flag: client.precipitation_client_project_flag,
  };
}
function ClientDetailsFormUI({ states, docTypes, projectTabs, document_date, docTypesRefetch, toSubmitOrderData, setToSubmitOrderData, ...props }) {

  const formContext = useContext(FormContext);
  const {tenantHasFeature} = useContext(TenantFeatureContext)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedDocTypeId, setSelectedDocTypeId] = useState(false)
  const [deleteDocTypeError, setDeleteDocTypeError] = useState('')
  const [selectedDocTypeName, setSelectedDocTypeName] = useState('')
  const [showReorderModal, setShowReorderModal] = useState(false)
  const [tempReorderDocTypes, setTempReorderDocTypes] = useState([])

  let clientId = window.location.pathname.split('/')[4];

  useEffect(() => {
    let filteredDT = 
    // these two doc types exists in db, but needs to be displayed in very few places, in the frontend. So, remove manually where not required.
    docTypes && docTypes.filter(e => e.name !== 'Fixed Details' && e.name !== "Fixed Details Project")

    setTempReorderDocTypes([...(filteredDT || ''), ...(props.newDocTypes || '')])
  }, [docTypes, props.newDocTypes]);

  const handleOnDragEnd = (result) => {
    let deleteRows = [], addRows = [], remainingDocTypes = []

    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const reordered = reorder(tempReorderDocTypes, result.source.index, result.destination.index);

    const newDataWithUpdatedOrder = reordered.map((ele, index) => {
      ele.client_doc_type_id && deleteRows.push(ele.client_doc_type_id)

      ele.id && addRows.push({
        client_id: clientId,
        doc_type_id: ele.id,
        is_checked: ele.is_checked,
        order: index
      })

      !ele.id && remainingDocTypes.push({
        name: ele,
        order: index
      })

      return ele.name ? { ...ele, order: index } : { name: ele, order: index }
    });

    setTempReorderDocTypes(newDataWithUpdatedOrder)
    setToSubmitOrderData({deleteRows: deleteRows, addRows: addRows, remainingDocTypes: remainingDocTypes})
  };

  return (
    <>
      <FormModal
        className='add-client-form'
        onCancel={props.handleClose}
        onSubmit={props.handleSubmit}
        modalProps={{
          title: props.title,
        }}
        nextBtn={props.nextBtn}
        isNextPage={props.isNextPage}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
      >
        {props.isNextPage ?
          <>
            {/* Add Tab Permission Code here */}
            <FormSchemaFields
              schema={getSchemaNext(docTypes, projectTabs, document_date, setShowDeleteModal, setSelectedDocTypeId, setShowEditModal, setSelectedDocTypeName, setShowReorderModal, tempReorderDocTypes )}
              onChange={formContext.set}
              formData={formContext.value}
              initialFormData={getInitialData(props.client, docTypes, projectTabs)}
            />

            <NewClientDocTypeFolder {...props} />
          </> :
          <>
            <FormSchemaFields
              schema={getSchema(
                states,
                tenantHasFeature('Precipitation Tracking'),
              )}
              onChange={formContext.set}
              formData={formContext.value}
              initialFormData={getInitialData(props.client)}
            />

            {!tenantHasFeature('Precipitation Tracking') && (
              <section className='container precipitation-error-msg-wrapper'>
                <p>
                  “Precipitation tracking is not included in this accounts
                  license agreement. To turn on please contact you company's
                  administrator.”
                </p>
              </section>
            )}
          </>
        }
      </FormModal>
      {showReorderModal && (
        <Modal
          title='Organize Document Types'
          show={true}
          handleClose={() => {
            setShowReorderModal(false)
          }}
          handleSubmit={async() => {
            setShowReorderModal(false)
          }}
          submitBtnText='Save'
          className="client-docTypes-reorder-modal"
        >
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='clientDocTypes'>
              {(provided) => (
                <div 
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {tempReorderDocTypes.length &&
                    tempReorderDocTypes
                    .map((ele, index) => {
                      return (
                        <Draggable
                          key={`${index}`}
                          draggableId={`${index}`}
                          index={index}
                        >
                          {(provide) => (
                            <div
                              ref={provide.innerRef}
                              {...provide.draggableProps}
                              {...provide.dragHandleProps}
                              style={{ ...provide.draggableProps.style,
                                left: "auto !important",
                                top: "auto !important",
                                marginBottom: "5px"

                              }}
                            >
                              <span
                                className="client-docTypes-reorder-modal__draggable-element"
                              >
                                <FontAwesomeIcon icon="grip-lines" size="sm" color="#385980" />
                                <span className="client-docTypes-reorder-modal__draggable-name">
                                  {ele.name ? ele.name : ele}
                                </span>
                              </span>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Modal>
      )}

      {showEditModal && (
        <Modal
          title='Edit Name'
          show={true}
          handleClose={() => {
            setShowEditModal(false);
          }}
          handleSubmit={() => {
            editCustomDocType(
              selectedDocTypeId,
              setDeleteDocTypeError,
              setShowDeleteModal,
              docTypesRefetch,
              selectedDocTypeName,
            );
            setShowEditModal(false);
          }}
          submitBtnText='Edit'
        >
          <input
            type='text'
            value={selectedDocTypeName}
            onChange={(event) => setSelectedDocTypeName(event.target.value)}
          />
        </Modal>
      )}

      {showDeleteModal && (
        <ConfirmationModal
          title="Delete Folder"
          subTitle={
            deleteDocTypeError ? `${deleteDocTypeError}` :
              `The selected Folder will be deleted and removed from all Projects associated with this client. Are you sure you want to proceed?`
          }
          show={true}
          handleClose={() => {
            setShowDeleteModal(false)
            setDeleteDocTypeError("")
          }}
          handleConfirm={() => deleteCustomDocType(selectedDocTypeId, setDeleteDocTypeError, setShowDeleteModal, docTypesRefetch)}
          buttonText="Delete"
        />
      )}
    </>
  );
}

function ClientDetailsForm(props) {
  const statesQuery = env.REACT_APP_REGION === "UK" ? useCountries() : useStates();

  const defaultChecked = {};

  props.docTypes && props.docTypes.length && props.docTypes.filter(doc => {
    if (doc.hasOwnProperty('is_checked')) {
      return doc.is_checked
    }

    return true
  }).forEach(doc => {
    defaultChecked[doc.id] = true;
  });

  if (!props.show) return null;

  if (statesQuery.isLoading) return <Loading />;

  const filterTabData = () => {
    let projectTabData = {}

    props.projectTabs && props.projectTabs.forEach((tab) => {
      projectTabData[tab.id] = tab.isChecked

    })

    return projectTabData
  }
  
  const getDocumentDate=()=>{
    const data={name: 'Show Created/Updated date', id: '1', '1': props.document_date}
    const defaultdata={name: 'Show Created/Updated date', id: '1', '1': true}
    
    return props.document_date === undefined ? defaultdata : data;
  }

  return (
    <FormContextProvider
      initialValue={{
        docTypes: defaultChecked,
        projectTabs: filterTabData(),
        document_date:getDocumentDate()
      }}
    >
      <ClientDetailsFormUI states={statesQuery.data} {...props} document_date={getDocumentDate()} docTypes={props.docTypes} projectTablis={props.projectTabs}  docTypesRefetch={props.docTypesRefetch} />
    </FormContextProvider>
  );
}

const clientShape = shape({
  name: string,
  address: shape({
    phone: string,
    street_1: string,
    street_2: string,
    city: string,
    zip: string,
    state: shape({ id: number }),
  }),
});

ClientDetailsForm.propTypes = {
  // initial data
  client: clientShape,
  // modal props
  handleSubmit: func.isRequired,
  handleClose: func.isRequired,
  show: bool.isRequired,
  title: string.isRequired,
  docTypes: arrayOf(shape({ id: number, name: string })),
  projectTabs: arrayOf(shape({ id: number, name: string })),
  nextBtn: bool,
  isNextPage: bool,
  handleNext: MouseEvent,
  handleBack: MouseEvent,
  newDocTypes: array,
  setNewDocTypes: func,
  toSubmitOrderData: Array,
  setToSubmitOrderData: func
};

ClientDetailsForm.defaultProps = {
  client: {},
};

export default ClientDetailsForm;
