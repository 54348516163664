import { useQuery } from "react-query";
import { InspectionTemplate } from "@sw-sw/lib-inspection-templates";
import inspectionTemplateApi from "../utils/api/inspectionTemplate";

// export interface IInspectionTemplate {
//   id: number;
//   name: string;
//   isUserTemplate: boolean;
// }

export function useInspectionTemplate(inspectionTemplateId: number) {
  return useQuery<InspectionTemplate>(
    ["inspectionTemplates", inspectionTemplateId],
    () => inspectionTemplateApi.get(inspectionTemplateId),
    { refetchOnWindowFocus: false },
  );
}
