import {
  FormContext,
  FormSchemaFields,
  UploadControlContextProvider,
} from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import { AppDivisionContext } from '../../../contexts/AppDivisionContext';
import { ProjectContext } from "../../../contexts/ProjectContext";
import ProjectPermissionContext from "../../../contexts/ProjectPermissionContext";
import RolesContext from "../../../contexts/RolesContext";
import legendItemApi from "../../../utils/api/legendItem";
import uploadApi from "../../../utils/api/upload";
import { parseYMD } from "../../../utils/date";
import Loading from "../../Shared/ResourceIndex/Loading";
import ObservationsFormControl from "./Observations/ObservationsFormControl";
import { questionConfigValueApi } from "../../../utils/api/questionConfigValue";
import { inspectionQuestionApi } from "../../../utils/api/inspectionQuestion";
import clientApi from "../../../utils/api/client";
import projectApi from "../../../utils/api/project";
import findingTypesApi from "../../../utils/api/findingTypes";
import inspectionApi from "../../../utils/api/inspection";
import findingApi from "../../../utils/api/finding";
import { Button } from "@sw-sw/lib-ui";
import { toast } from "react-toastify";

const findingObservationTemplates = [
  {
    type: 'CA',
    label: 'Corrective Action Notice',
    content:
      'Corrective Action Item is to be addressed next business day after the inspection, per scheduled control measure maintenance.',
  },
  {
    label: 'Completed as recommended',
    content: 'Completed as recommended',
  },
];

const ApprovalStatus = ({ status, name }) => {
  const getStatusStyle = (status) => {
    switch (status) {
      case 'Pending':
        return { color: 'orange', fontWeight: 'bold' };
      case 'Approved':
      case 'Not Required':
      case 'ApprovedNoName':
        return { color: '#8dc058', fontWeight: 'bold' };
      default:
        return {};
    }
  };

  const getStatusText = (status, name) => {
    switch (status) {
      case 'Pending':
        return 'Pending Approval';
      case 'Approved':
        return `Approved By ${name}`;
      case 'ApprovedNoName':
        return `Approved`;
      case 'Not Required':
        return 'Not Required';
      default:
        return '';
    }
  };

  return (
    <span style={getStatusStyle(status)}>
      {getStatusText(status, name)}
    </span>
  );
};

const updateSignature = async (formData, formContext) => {
  await findingApi.addSignature(formData.actionId)
    .then(async () => {
      toast("Signature Updated")
      formContext.set("isSigned", true)
    })
}

const deleteNotes = async (formData, formContext) => {
  await findingApi.deleteNotes(formContext.value.id, formData.actionId)
    .then(async () => {
      toast("Action Plan removed")
      formContext.set("actionPlans", false)
      formContext.set("signeeName", null)
      formContext.set("actionObservation", null)
      formContext.set("actionId", null)
    })

}

const removeSignature = async (formData, formContext) => {
  await findingApi.removeSignature(formData.actionId)
    .then(async () => {
      toast("Signature Removed")
      formContext.set("isSigned", false)
    })
}

export const defaultCompletionTemplateContent =
  findingObservationTemplates[1].content;

export const getFindingCommentsSchema = (
  canCreate = false,
  canRead = false,
  canEdit = false,
  canDelete = false,
  readOnly = false,
) => {
  const schema = {
    /** field to add comments */
    comments: {
      label: 'Observations',
      controlType: UIControlType.textareaTmpl,
      'aria-label': 'Observations',
      templates: findingObservationTemplates,
      disabled: !canCreate,
      style: { marginBottom: '0.25rem' },
    },
  };

  if (canRead) {
    /** display-only "field" for previous comments */
    schema.observations = {
      controlType: UIControlType.custom,
      renderControl: (controlProps) => (
        <ObservationsFormControl
          {...controlProps}
          canEdit={canEdit}
          canDelete={canDelete}
          readOnly={readOnly}
        />
      ),
    };
  }

  return schema;
};

export const getActionModalSchema = (
  formData,
  displayStyle,
  template,
  permCheck
) => {
  if (template.findings_action) {
    const schema = {
      actionPlans: {
        label: 'Add Interim Action Plan',
        controlType: UIControlType.checkbox,
        style: displayStyle,
      }
    };

    if (formData.actionPlans) {
      schema.actionObservation = {
        label: 'Action Plan',
        controlType: UIControlType.textarea,
        style: { marginBottom: '0.25rem' },
        disabled: !permCheck('create', 'Action Plan')
      }
    }

    return schema;
  }

  return null
};

export const getActionFullScreenSchema = (
  formData, template, formContext, displayStyle, permCheck
) => {
  const status = () => {
    if (formData.isSigned) {
      if (formData.signeeName) {
        return (
          <div>
            <ApprovalStatus status="Approved" name={formData.signeeName} />
          </div>
        )
      }

      return (
        <div>
          <ApprovalStatus status="ApprovedNoName" />
        </div>
      )
    }

    return (
      <div>
        <ApprovalStatus status="Pending" />
      </div>
    )
  }

  if (formData.actionPlans) {
    const schema = {
      actionObservation: {
        label: 'Action Plan',
        controlType: UIControlType.textarea,
        style: { marginBottom: '0.25rem', width: '100%' },
        disabled: formData.isSigned || !permCheck('create', 'Action Plan')
      }
    };

    if (formData.actionId) {
      schema.actionFooter = {
        controlType: UIControlType.custom,
        renderControl: (controlProps) => (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
            {status()}
            {
              !formData.isSigned &&
              <div>
                <Button outline={true} small={true} disabled={!permCheck("update", "Action Plan")} onClick={() => updateSignature(formData, formContext)} style={{ marginRight: '10px' }}>
                  Approval
                </Button>
                {permCheck('create', 'Action Plan') && (
                  <Button
                    outline={true}
                    style={{ color: 'red', borderColor: 'red' }}
                    small={true}
                    onClick={() => deleteNotes(formData, formContext)}
                  >
                    Remove Action Plan
                  </Button>
                )}
              </div>
            }
            {
              formData.isSigned && !formData.date_completed && permCheck("update", "Action Plan") &&
              <div>
                <Button outline={true} small={true} onClick={() => removeSignature(formData, formContext)} style={{ marginRight: '10px' }}>
                  Clear Approval
                </Button>
              </div>
            }
          </div>
        )
      }
    }

    return schema;
  }
  if (template.findings_action && permCheck('create', 'Action Plan')) {
    const schema = {
      actionPlans: {
        label: 'Add Interim Action Plan',
        controlType: UIControlType.checkbox,
        style: displayStyle,
      }
    };

    return schema;
  }

  return null
};

export const extractFindingData = (model, inspectionId) => {
  const {
    id,
    commentables,
    date_completed,
    date_initiated,
    finding_uploads = [],
    location,
    type,
    condition,
    control_measure,
    legend_item_id,
  } = model;

  const images = finding_uploads
    .filter((findingUpload) => !findingUpload.is_completed)
    .map(toImageUpload);
  const completedImages = finding_uploads
    .filter((findingUpload) => findingUpload.is_completed)
    .map(toImageUpload);

  return {
    id,
    inspectionId,
    images: images,
    completedImages: completedImages,
    observations: orderBy(commentables || [], 'created_at', 'desc'),
    comments: '',
    type: type ? type : 'CA',
    location: location ? location : 'See site inspection map for location',
    date_initiated: date_initiated
      ? parseYMD(date_initiated.substr(0, 10))
      : null,
    date_completed: date_completed
      ? parseYMD(date_completed.substr(0, 10))
      : null,
    condition,
    control_measure,
    legend_item_id: legend_item_id,
    actionObservation: model.action_note ? model.action_note.notes : null,
    actionPlans: model.finding_action,
    actionId: model.action_note_id,
    isSigned: model.action_note ? model.action_note.is_signed : false,
    signeeName: model.action_note && model.action_note.commentableSignature ? model.action_note.commentableSignature.signee_name : null
  };
};

export const toImageUpload = ({ id, upload, annotations }) => {
  return {
    id,
    guid: upload.GUID,
    type: upload.mime_type,
    name: upload.name,
    rotation: upload.rotation ? upload.rotation : 0,
    annotations: annotations ? annotations : [],
  };
};

export const getInitialData = (defaultDateInitiated, type) => ({
  type: type,
  location: "See site inspection map for location",
  date_initiated: defaultDateInitiated,
  date_completed: null,
  images: [],
  observations: [],
  comments: '',
});

/**
 *
 * @param {boolean} commentOnly
 * @param {string} userRole User's Role.Name
 * @param {function} permCheck
 * @param {{condition_options: Array, name: string}} template
 * @param {boolean} readOnly disables inputs
 */
function getSchema(
  templateID,
  commentOnly,
  userRole,
  permCheck,
  template,
  readOnly,
  disableFieldRelatedToDateCompleted,
  formData,
  enableEditComments,
  legendItems,
  archived,
  projectStartDate,
  conditionOptions,
  disableImage,
  inspectionId,
  modal,
  formContext,
) {
  const templateConditionOptions =
    conditionOptions && conditionOptions.length
      ? conditionOptions
      : template.condition_options;

  const displayStyle = { display: commentOnly ? "none" : null };
  const [findingTypes, setFindingTypes] = useState([])
  const handleName = (name) => {

    if (name === "A") {
      return "Achievement"
    }
    else if (name === "MI") {
      return "Maintenance Item"
    }
    else if (name === "CA") {
      return "Corrective Action"
    }
    else {
      return name
    }
  }

  const canUpdate = !permCheck("update", "Findings")
  const restrictedForRoles =
    ["BMP Contractor", "Client User"].indexOf(userRole) > -1;

  React.useEffect(() => {
    (async () => {
      if (!templateID) {
        const { inspection } = await inspectionApi.get(inspectionId)

        templateID = inspection.template_id
      }
      const data = await findingTypesApi.index(templateID)
      const findingName = data.filter((ele) => ele.details.is_enabled)
        .map((ele) => ({ value: ele.name, label: handleName(ele.name) }))

      setFindingTypes(findingName)
    })();
  }, []);

  const schema = {
    type: {
      label: "Finding Type",
      controlType: UIControlType.select,
      validation: { required: true },
      options: findingTypes,
      style: displayStyle,
      disabled: readOnly || canUpdate || restrictedForRoles,
      className: "finding-mobile",
    },
    location: {
      label: 'Location',
      controlType: UIControlType.text,
      validation: {
        required: true,
      },
      style: displayStyle,
      disabled: readOnly || canUpdate || restrictedForRoles,
    },
    legend_item_id: {
      label: 'Legend Item',
      controlType: UIControlType.select,
      options:
        legendItems.length > 0
          ? legendItems.map(legItem => {
            return {
              label: legItem.name,
              value: legItem.id,
            };
          })
          : [],
      style: displayStyle,
      disabled: readOnly,
    },
    date_initiated: {
      label: 'Date Initiated',
      controlType: UIControlType.date,
      validation: {
        required: true,
        minDate: moment(projectStartDate).toDate(),
        maxDate: new Date(),
      },
      style: { flex: "1 1 50%", ...displayStyle },
      disabled: readOnly || canUpdate || restrictedForRoles,
    },
    date_completed: {
      label: 'Date Completed',
      controlType: UIControlType.date,
      style: { flex: '1 1 50%', ...displayStyle },
      validation: {
        maxDate: new Date(),
        minDate: formData.date_initiated ? formData.date_initiated : undefined,
      },
      disabled: formData.actionPlans
        ? (formData.isSigned ? disableFieldRelatedToDateCompleted || canUpdate : true)
        : (disableFieldRelatedToDateCompleted || canUpdate),
    },

    ...getFindingCommentsSchema(
      archived || disableFieldRelatedToDateCompleted ? false : permCheck("create", "finding_comments"),
      permCheck("read", "finding_comments"),
      archived
        ? false
        : enableEditComments
          ? permCheck("update", "finding_comments")
          : false,
      archived
        ? false
        : enableEditComments
          ? permCheck("delete", "finding_comments")
          : false,
      readOnly,
    ),

    control_measure: {
      label: "Control Measure",
      style: { flex: "1 1 50%", ...displayStyle },
      disabled: readOnly || canUpdate,
      validation: { required: true },
    },
    condition: {
      label: 'Condition',
      controlType: UIControlType.select,
      options: templateConditionOptions.map(option => ({
        label: option,
        value: option,
      })),
      style: { flex: "1 1 50%", ...displayStyle },
      disabled: readOnly || canUpdate,
      validation: { required: true },
    },
    images: {
      label:
        formData.completedImages && formData.completedImages.length
          ? 'Initial Images'
          : '',
      controlType: UIControlType.imageUpload,
      style: displayStyle,
      disabled: readOnly,
    },
    ...(modal
      ? getActionModalSchema(formData, displayStyle, template, permCheck)
      : getActionFullScreenSchema(formData, template, formContext, displayStyle, permCheck)
    ),
  };

  if (formData.completedImages && formData.completedImages.length) {
    schema.completedImages = {
      label: 'Completion Images',
      controlType: UIControlType.imageUpload,
      style: displayStyle,
    };
  }

  if (!['Delaware', 'CDOT 1176 06/2024', 'CDOT 1177 - 5/2024'].includes(template.name)) {
    delete schema.legend_item_id;
  }

  /** @todo refactor to use schema from server */

  if (
    !(["CDOT", "CDOT-1177"].includes(template.name) || (conditionOptions && conditionOptions.length))
  ) {
    delete schema.condition;
    delete schema.control_measure;
  }

  if (!permCheck("read", "Finding Images") || disableImage) {
    delete schema.images;
    delete schema.completedImages;
  }

  if (!permCheck('all', 'Download')) {
    delete schema.images;
    delete schema.completedImages;
  }

  if (!permCheck('all', 'Download')) {
    delete schema.images;
    delete schema.completedImages;
  }

  return schema;
}

/**
 * UI to create a note. Does not save data
 */

function FindingsForm({
  commentOnly,
  templateName,
  templateID,
  readOnly,
  disableFieldRelatedToDateCompleted,
  defaultObservation,
  enableEditComments,
  inspectionId,
  modal,
}) {
  const appStore = useContext(AppContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const user = appStore.get("user");
  const permCheck = useContext(RolesContext).userHasPermission;
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const projectContext = useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const [legendItems, setLegendItems] = useState([]);
  const formContext = useContext(FormContext);
  const [questionId, setQuestionId] = useState(null);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [disableImage, setDisableImage] = useState(user.roleName.startsWith("Public"));
  const [findingAction, setFindingAction] = useState(false)
  const [project, setProject] = useState((projectContext || {}).project);
  const {
    observations,
    commentables,
    date_initiated: dateInitiated,
    date_completed: dateCompleted,
    actionPlans,
    actionObservation,
  } = formContext.value;

  useEffect(() => {
    (async () => {
      if (project) {
        if (project.client_id) {
          const c = await clientApi.show(project.client_id);

          if (c && c.disable_images) {
            setDisableImage(c.disable_images);
          }
        }
      } else {
        const url = window.location.href;
        const regex = /projects\/(\d+)\/map/;
        const m = regex.exec(url);

        if (m !== null && m.length > 1) {
          const projectId = m[1];
          const projectDetail = await projectApi.get(projectId)

          setProject(projectDetail);
        }
      }
    })();
  }, [project]);

  useEffect(() => {
    (async () => {
      if (inspectionId) {
        const res = await inspectionQuestionApi.show(inspectionId);

        setFindingAction(res.template.findings_action)
        if (res && res.template && res.template.question_groups) {
          outer: for (const group of res.template.question_groups) {
            if (group.name === "Condition Group") {
              for (const question of group.questions) {
                if (question.input_label === "Condition") {
                  setQuestionId(question.id);
                  break outer;
                }
              }
            }
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (questionId) {
        const questionConfigValues = await questionConfigValueApi.index(
          questionId,
        );

        if (questionConfigValues) {
          for (const questionConfigValue of questionConfigValues) {
            if (
              "questionTypeFieldId" in questionConfigValue &&
              typeof questionConfigValue.value === "string"
            ) {
              setConditionOptions(JSON.parse(questionConfigValue.value));
              break;
            }
          }
        }
      }
    })();
  }, [questionId]);

  useEffect(() => {
    if (['Delaware', 'CDOT 1176 06/2024', 'CDOT 1177 - 5/2024'].includes(templateName)) {

      project && legendItemApi.indexByProject(project.id, appDivisionContext.appDivisionId).then(data => {
        setLegendItems(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [project]);

  useEffect(() => {
    if (
      defaultObservation &&
      observations &&
      !observations.length &&
      !commentables
    ) {
      formContext.set('comments', defaultObservation);
    }
  }, [defaultObservation]);

  //populate Observations with "Completed as recommended" when Date Completed is filled
  useEffect(() => {
    if (formContext.value.date_completed && !formContext.value.comments)
      formContext.set("comments", findingObservationTemplates[1].content)

  }, [formContext.value.date_completed]);

  useEffect(() => {
    if (formContext.value.actionPlans) {
      formContext.set("actionPlans", actionPlans)
      formContext.set("actionObservation", actionObservation)
    } else {
      formContext.set("actionPlans", actionPlans)
      formContext.set("actionObservation", '')
    }

  }, [formContext.value.actionPlans]);

  useEffect(() => {
    if (dateInitiated && dateCompleted && dateInitiated > dateCompleted) {
      formContext.set('date_completed', null);
    }
  }, [dateInitiated, dateCompleted]);

  // wait to gather roles and templates
  if (!appStore || loading) return <Loading />;

  return (
    <UploadControlContextProvider
      {...{
        allowDelete: readOnly ? false : permCheck('delete', 'Finding Images'),
        allowAnnotation: readOnly ? false : permCheck('all', 'Annotations'),
        allowCreate: readOnly ? false : true,
        allowRotate: readOnly ? false : true,
        getUrl: uploadApi.getDownloadUrl,
      }}
    >
      <FormSchemaFields
        className='findings-form'
        schema={getSchema(
          templateID,
          commentOnly,
          appStore.get('user.roleName'),
          permCheck,
          {
            name: templateName,
            condition_options: appStore.get(
              'constants.findingConditionOptions',
            ),
            findings_action: findingAction
          },
          readOnly,
          disableFieldRelatedToDateCompleted,
          formContext.value,
          enableEditComments,
          legendItems,
          projectPermissionContext.readOnly,
          project ? project.start_date : null,
          conditionOptions,
          disableImage,
          inspectionId,
          modal,
          formContext,
        )}
        formData={formContext.value}
        onChange={formContext.set}
      />
    </UploadControlContextProvider>
  );
}

FindingsForm.propTypes = {
  commentOnly: PropTypes.bool, // only display "observations" and "comments"
  readOnly: PropTypes.bool,
  templateName: PropTypes.string.isRequired,
  /**
   * Used to set the default _initial_ observation.
   */
  defaultObservation: PropTypes.string,

  /**
   * Determine if the edit and delete actions are available for comments/notes
   *
   * When true, the schema function will check user permission/role,
   * before rendering the edit & delete controls.
   *
   * When false, the edit and delete controls will never be rendered
   */
  enableEditComments: PropTypes.bool,
  inspectionId: PropTypes.number.isRequired,
};

FindingsForm.defaultProps = {
  initialValue: {},
  commentOnly: false,
  readOnly: false,
  templateName: 'Unknown',
  defaultObservation: '',
  enableEditComments: true,
};

export default FindingsForm;
