import {
  ISignatureEditorData,
  SignatureGroupType,
} from "@sw-sw/lib-certification";
import { LoadingMessage } from "@sw-sw/lib-ui";
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import Error from "../components/Shared/ResourceIndex/Error";
import { signatureEditorApi } from "../utils/api/signatureEditor";

export enum SignatureEditorMode {
  LOADING = "LOADING",
  READ = "READ",
  EDIT = "EDIT",
}

/**
 * Describes the resources that can be managed in the signature editor.
 *
 * @see ISignatureEditorData Values in this enum must match keys in the data type.
 */
export enum SignatureResourceType {
  ATTESTATION = "attestations",
  LINE = "lines",
}

export enum SignatureEditorFormType {
  EDIT = "Edit",
  ADD = "Add",
}

type FormState = {
  formType: SignatureEditorFormType;
  groupType: SignatureGroupType;
  resourceIndex: number;
};

export type SignatureEditorContextValue = {
  data: ISignatureEditorData;
  refetch: () => void;
  modal: null | SignatureResourceType;
  setModal: (modal?: SignatureResourceType) => void;
  formState: null | FormState;
  setFormState: (data?: FormState) => void;
  onUpdate: (data: ISignatureEditorData) => Promise<ISignatureEditorData>;
};

export const SignatureEditorContext =
  createContext<SignatureEditorContextValue>(null as any);

export const SignatureEditorProvider: React.FC<
  PropsWithChildren<{ templateId: number }>
> = ({ templateId, ...props }) => {
  const query = useQuery({
    queryFn: () => signatureEditorApi.index(templateId),
    queryKey: ["signatureEditor", "index", templateId],
    refetchOnWindowFocus: false,
  });
  const [modal, setModal] = useState<null | SignatureResourceType>(null);
  const [formState, setFormState] = useState<null | FormState>(null);

  useEffect(() => {
    return () => {
      query.remove();
    };
  }, []);

  if (query.isLoading) {
    return <LoadingMessage what="template signature configuration" />;
  }

  if (query.data === undefined) {
    return (
      <Error error="missing or invalid template signature configuration" />
    );
  }

  return (
    <SignatureEditorContext.Provider
      value={{
        data: query.data,
        refetch: query.refetch,
        modal: modal,
        setModal: modal => setModal(modal || null),
        formState,
        setFormState: state => setFormState(state || null),
        onUpdate: signatureEditorApi.update.bind(null, templateId),
      }}
      {...props}
    />
  );
};
