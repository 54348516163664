import React, { useContext, useState, useEffect } from "react";
import { PrivateRoute } from "./";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import { useLocation, useHistory } from "react-router-dom";

interface DivisionRouteProps {
  Component: any;
  Layout: any;
}
export const DivisionRoute: React.FC<DivisionRouteProps> = routeProps => {
  const location = useLocation();
  const history = useHistory();
  const [checking, setChecking] = useState<Boolean>(false);
  const appDivisionContext = useContext(AppDivisionContext);

  const indexRegex = /\/divisions\/(\d+)\/([\S]*)/;

  const indexPath = location.pathname.match(indexRegex);

  useEffect(() => {
    setChecking(true);

    if (!appDivisionContext.loading) {
      if (indexPath) {
        const division_id = indexPath[1];

        if (!appDivisionContext.validDivision(parseInt(division_id))) {
          history.push(appDivisionContext.getPath(`/${indexPath[2]}`));
        }
      } else {
        history.push(appDivisionContext.getPath("/dashboard"));
      }
    }

    setChecking(false);
  }, [location.pathname, appDivisionContext.loading]);

  return <PrivateRoute checking={checking} {...routeProps} />;
};
