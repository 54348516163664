/**
 * @typedef {import('ol').Feature} Feature
 *
 * @typedef  {{ id: number, [k: string]: any }} SourceModel
 * @typedef {{ positionableType: string, _unsaved: Boolean } & SourceModel} SourceModelUpdate
 * @typedef {{ feature: Feature, sourceModelId: number, targetId: number, targetType: string, positionableType: string, inspectionId: number }} InstanceModel
 *
 * @typedef {{ [tmpId: number]: SourceModelUpdate }} SourceModelPendingUpdates
 * @typedef {{ [featureOlUid: string]: InstanceModel }} InstanceModelPendingUpdates
 *
 * @typedef {{ source: SourceModelPendingUpdates, instance: InstanceModelPendingUpdates }} PendingUpdates
 */

import React, { useState, useReducer, useContext, useEffect } from 'react';
import { flatten } from 'lodash';

import { mapUtil, MapContextType } from '@sw-sw/common';
import positionableApi from '../../../utils/api/positionable';
import { getApi } from '../../../utils/positionable';
import { Context as DataSourceContext } from './../DataSourceContext';
import { getTargetKey } from './DataSource';

const Context = React.createContext();

const defaultPendingUpdateState = () => ({ instance: {}, source: {} });
const saveAPIBody = (dataSource, pendingUpdates) => {
  const featureIds = Object.keys(pendingUpdates);
  const updatedFeatures = [];

  /**
   * group data by (target type, target id, inspection id)
   *
   * @typedef {{ [tType_tId_iId: string]: Object[] }} GroupedUpdates
   * @type {GroupedUpdates}
   */
  const groupedData = featureIds.reduce((groups, id) => {
    const {
      feature,
      positionableType,
      targetType,
      targetId,
      startInspectionId,
      endInspectionId,
    } = pendingUpdates[id];

    const xy = mapUtil.getFeatureXY(feature);
    const key = [targetId, targetType, startInspectionId].join('--');

    const { positionableContext } =
      dataSource.getDataTypeArguments(positionableType);

    if (!groups[key]) {
      groups[key] = [];
    }
    updatedFeatures.push(feature);

    groups[key].push({
      position_x: xy[0],
      position_y: xy[1],
      id: feature.get('positionables_id'),
      positionable_id: feature.get('positionable_source_id'),
      target_id: targetId,
      start_inspection_id: startInspectionId,
      end_inspection_id: endInspectionId,
      target_type: targetType,
      positionable_type: positionableContext
        ? positionableContext
        : positionableType,
      _context: positionableContext ? positionableType : undefined,
      positionable_config: JSON.stringify(
        feature.get('positionable_config') || {},
      ),
    });

    return groups;
  }, {});

  return { groupedData, featureIds };
};
const pendingUpdateReducer = (state, action) => {
  switch (action.type) {
    case 'updateInstance':
      return {
        ...state,
        instance: {
          ...state.instance,
          ...action.payload, // : Feature
        },
      };
    case 'updateSource':
      return {
        ...state,
        source: {
          ...state.source,
          ...action.payload, // : Feature
        },
      };
    case 'removeItem':
      const { [action.payload.itemKey]: _, ...newGroupState } =
        state[action.payload.groupKey];

      return {
        ...state,
        [action.payload.groupKey]: newGroupState,
      };
    case 'clear':
      return defaultPendingUpdateState();
    default:
      throw new Error();
  }
};

/**
 * Save pending changes via xhr
 *
 * @async
 * @param {InstanceModelPendingUpdates} pendingUpdates
 */
// const apiSave = (dataSource, pendingUpdates) => {
//   const { featureIds, groupedData } = saveAPIBody(dataSource, pendingUpdates)

//   return { groupedData, featureIds }
// }

/**
 * Save pending changes via xhr
 *
 * @async
 * @param {InstanceModelPendingUpdates} pendingUpdates
 */
const apiSave = (dataSource, pendingUpdates) => {
  const { featureIds, groupedData } = saveAPIBody(dataSource, pendingUpdates);

  /** xhr for each group */
  const p = Object.keys(groupedData).map((target) => {
    const [targetId, targetType] = target.split('--');

    return positionableApi
      .bulkUpdate(targetType, targetId, groupedData[target])
      .then(flatten);
  });

  return Promise.all(p)
    .then((data) => {
      // remove updated features from map
      featureIds.forEach((id) => {
        const { feature } = pendingUpdates[id];

        /** remove unsaved features from map */
        if (typeof feature.get('onSaveCallback') === 'function') {
          feature.get('onSaveCallback').call(null);
        }
      });

      return flatten(data);
    })
    .then((models) =>
      models.map((model) => ({
        ...model,
        positionable_type: model._context || model.positionable_type,
      })),
    );
};

/**
 * Save a single source model, via api
 *
 * @todo create vs update, depending on 'id' key
 */
const apiSaveSourceModel = (data, positionableType, methodArguments) => {
  if (data.id) {
    if (positionableType === 'commentables')
      return getApi(positionableType).update(...methodArguments, data);
    else return getApi(positionableType).update(data.projectId, data);
  } else {
    return getApi(positionableType).create(...methodArguments, data);
  }
};

const apiDeletePositionable = (feature) => {
  if (feature.get('positionables_id')) {
    return positionableApi
      .bulkDelete([feature.get('positionables_id')])
      .then(() => feature);
  }

  return Promise.resolve(feature);
};

/**
 * @param {number} sourceModelId
 * @return {Promise<number>}
 */
const apiDeleteSourceModel = (
  sourceModelId,
  positionableType,
  methodArguments,
) => {
  return getApi(positionableType)
    .destroy(sourceModelId, ...methodArguments)
    .then(() => sourceModelId);
};

const updateInstanceModelDataContext = (instanceModels, updateItem) => {
  instanceModels.forEach((model) => {
    const { positionable_type, target_type, target_id, start_inspection_id } =
      model;

    updateItem(
      getTargetKey(
        positionable_type,
        target_type,
        target_id,
        start_inspection_id,
      ),
      model,
    );
  });

  return instanceModels;
};

/**
 * @param {InstanceModelPendingUpdates} pendingUpdates
 */
const discardInstanceModelUpdates = (pendingUpdates, getLayer) => {
  Object.keys(pendingUpdates)
    .filter((k) => pendingUpdates[k] !== undefined)
    .map((k) => pendingUpdates[k].feature)
    .forEach((feature) => {
      if (
        feature.get('positionables_id') &&
        typeof feature.get('handleRevert') === 'function'
      ) {
        feature.get('handleRevert').call(null, []);
      } else {
        getLayer(feature).getSource().removeFeature(feature);
      }
    });
};

const discardSourceModelUpdates = (pendingUpdates, removeItem) => {
  Object.keys(pendingUpdates)
    .map((k) => pendingUpdates[k])
    .forEach(({ positionableType, id }) => {
      removeItem(positionableType, id);
    });
};

/**
 * PositionableInstanceDataContext
 *
 * Provides context to manage positionable instance data
 *
 * Implements update/delete for positionable instances via XHR
 * Provides API to report data changes (used by map interactions)
 * Provides API to save or discard changes
 *
 * @odo @maybe Provides API to track "selection" of positionable instances
 * @odo @maybe Provides API to delete selected positionable instances
 */
function PositionableInstanceDataContext(props) {
  const dataSource = useContext(DataSourceContext);
  const mapContext = useContext(MapContextType);
  const [arr, setArr] = useState([]);

  const [hasPendingUpdates, setHasPendingUpdates] = useState(false);

  /** @type {[PendingUpdates, Function]} */
  const [pendingUpdates, dispatch] = useReducer(
    pendingUpdateReducer,
    defaultPendingUpdateState(),
  );

  useEffect(() => {
    setHasPendingUpdates(
      Object.keys(pendingUpdates.instance).length >= 1 ||
        Object.keys(pendingUpdates.source).length >= 1,
    );
  }, [pendingUpdates]);

  const contextValue = {
    hasPendingUpdates,
    /**
     * Add or update an instance model
     */
    setPendingUpdate: (
      feature,
      positionableType,
      targetType,
      targetId,
      startInspectionId,
      endInspectionId,
    ) => {
      dispatch({
        type: 'updateInstance',
        payload: {
          [feature.ol_uid]: {
            feature,
            positionableType,
            targetType,
            targetId,
            startInspectionId,
            endInspectionId,
            sourceModelId: feature.get('positionable_source_id'),
          },
        },
      });
    },

    /**
     * Save a source model
     */
    createSourceModel: (sourceModelFormData, positionableType) => {
      const { positionableIndexArguments } =
        dataSource.getDataTypeArguments(positionableType);

      let args = positionableIndexArguments || [];

      if (typeof args === 'function') {
        args = args(sourceModelFormData);
      }
      sourceModelFormData = {
        ...sourceModelFormData,
        projectId: args[0],
        divisionId: args[1],
      };

      return apiSaveSourceModel(sourceModelFormData, positionableType, args)
        .then((data) => ({
          positionableType,
          ...data,
        }))
        .then((data) => {
          dataSource.updateArrayItem(positionableType, data);

          return data;
        });
    },

    save: () => {
      return apiSave(dataSource, pendingUpdates.instance)
        .then((instanceModels) => {
          /** @todo update source models */
          dispatch({
            type: 'clear',
          });

          updateInstanceModelDataContext(
            instanceModels,
            dataSource.updateArrayItem,
          );

          arr.length &&
            arr.forEach((feature) => {
              const layer = mapContext.getLayer(
                feature.get('positionable_type'),
                dataSource.getById(
                  feature.get('positionable_source_id'),
                  feature.get('positionable_type'),
                ),
              );

              layer.getSource().removeFeature(feature);
            });

          setArr([]);
        })
        .catch((err) => {
          console.error('save', err, pendingUpdates);
          /** @todo send some updates back to the queue */
          // if (this.props.onError) {
          //   this.props.onError("There was an error saving. Please try again.");
          // }
        });
    },
    /**
     * Discard pending updates
     *
     * remove & detach new/unsaved features
     * revert changes to existing features via callback function
     */
    discard: () => {
      discardInstanceModelUpdates(pendingUpdates.instance, (feature) =>
        mapContext.getLayer(
          feature.get('positionable_type'),
          dataSource.getById(
            feature.get('positionable_source_id'),
            feature.get('positionable_type'),
          ),
        ),
      );

      discardSourceModelUpdates(
        pendingUpdates.source,
        dataSource.removeArrayItem,
      );

      dispatch({
        type: 'clear',
      });
    },

    undo: () => {
      const pendingUndo = [...dataSource.undo];
      const data = pendingUndo.pop();

      dataSource.setUndo(pendingUndo);
      const {
        feature,
        positionableType,
        targetType,
        targetId,
        startInspectionId,
      } = data;

      setArr([...arr, feature]);

      dispatch({
        type: 'updateInstance',
        payload: {
          [feature.ol_uid]: {
            feature,
            positionableType,
            targetType,
            targetId,
            sourceModelId: feature.get('positionable_source_id'),
            startInspectionId: startInspectionId,
          },
        },
      });

      const layer = mapContext.getLayer(
        feature.get('positionable_type'),
        dataSource.getById(
          feature.get('positionable_source_id'),
          feature.get('positionable_type'),
        ),
      );

      layer.getSource().addFeature(feature);
    },

    /**
     * Delete feature
     *
     */
    delete: (feature) =>
      apiDeletePositionable(feature).then(() => {
        if (pendingUpdates.instance.hasOwnProperty(feature.ol_uid)) {
          dispatch({
            type: 'removeItem',
            payload: {
              groupKey: 'instance',
              itemKey: feature.ol_uid,
            },
          });
        }

        const positionableType = feature.get('positionable_type');

        const { targetType, targetId, startInspectionId } =
          dataSource.getDataTypeArguments(positionableType);

        if (feature.get('positionables_id')) {
          dataSource.setUndo([
            ...dataSource.undo,
            {
              feature,
              positionableType,
              targetType,
              targetId,
              startInspectionId,
            },
          ]);

          dataSource.removeArrayItem(
            [positionableType, targetType, targetId, startInspectionId].join(
              '--',
            ),
            feature.get('positionables_id'),
          );
        } else {
          const layer = mapContext.getLayer(
            feature.get('positionable_type'),
            dataSource.getById(
              feature.get('positionable_source_id'),
              feature.get('positionable_type'),
            ),
          );

          dataSource.setUndo([
            ...dataSource.undo,
            {
              feature,
              positionableType,
              targetType,
              targetId,
              startInspectionId,
            },
          ]);

          layer.getSource().removeFeature(feature);
        }

        return feature;
      }),

    deleteSourceModel: (positionableType, sourceModel) => {
      const { positionableIndexArguments } =
        dataSource.getDataTypeArguments(positionableType);

      let args = positionableIndexArguments || {};

      if (typeof args === 'function') {
        args = args(sourceModel);
      }

      return apiDeleteSourceModel(sourceModel.id, positionableType, args).then(
        () => {
          dataSource.removeArrayItem(positionableType, sourceModel.id);
        },
      );
    },

    countInstances: (
      positionableType,
      targetType,
      targetId,
      sourceModel,
      startInspectionId,
    ) => {
      const pending = Object.keys(pendingUpdates.instance)
        .map((id) => pendingUpdates.instance[id])
        .filter(
          (data) =>
            !data.feature.get('positionables_id') &&
            data.sourceModelId === sourceModel.id &&
            data.positionableType === positionableType &&
            data.targetType === targetType &&
            data.targetId === targetId &&
            data.inspectionId === startInspectionId,
        ).length;

      const saved = dataSource.filter(
        getTargetKey(positionableType, targetType, targetId, startInspectionId),
        {
          positionable_type: positionableType,
          positionable_id: sourceModel.id,
          target_type: targetType,
          target_id: targetId,
          inspection_id: startInspectionId,
        },
      ).length;

      return pending + saved;
    },

    /** dev only! */
    pendingUpdates: pendingUpdates,
  };

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
}

export { Context };
export default PositionableInstanceDataContext;
