import wsUtil from "../ws";
import xhrService from "../xhrService";

// socket namespace
const ns = "print";

const printApi = {
  init: () => wsUtil.connect(ns),
  deinit: () => wsUtil.disconnect(ns),

  /**
   * Begin the job and subscribe to status updates
   *
   * @param {{[k: string]: any, [idKey: string]: any}} jobData
   * @param {Function} onStatusUpdate Callback to handle status updates
   * @param {string} [idKey] Property on {jobData}, used to generate the channel name for status updates
   */
  run: (jobName, jobData, onStatusUpdate, statusUpdateEventName) => {
    wsUtil.send(ns, `begin:${jobName}`, jobData);
    wsUtil.on(
      ns,
      `status:report:${jobName}:${statusUpdateEventName}:`,
      onStatusUpdate,
    );
  },

  cancelJob: (jobId) => {
    return xhrService.put(`/api/projects/canceljob/${jobId}`, jobId)
    .then(response => response.data);
  },
};

export default printApi;
export { printApi };
