import { useState, useMemo, ChangeEvent, MouseEvent } from 'react';
import ProjectActivityLogReportModal from './ProjectActivityLogReportModal';
import { FormContextProvider } from '@sw-sw/lib-form';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { visuallyHidden } from '@mui/utils';
import _ from 'lodash';
import { parseISO, format } from 'date-fns';
import ExcelJs from 'exceljs';
import React from 'react';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: 'rgb(80, 101, 126);',
  },
}));

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  width?: string;
}

const headCells: HeadCell[] = [
  {
    id: 'activityDate',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    width: '10%',
  },
  {
    id: 'activityType',
    numeric: false,
    disablePadding: false,
    label: 'Activity',
    width: '5%',
  },
  {
    id: 'clientName',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
    width: '14%',
  },
  {
    id: 'clientCode',
    numeric: false,
    disablePadding: false,
    label: 'Client Code',
    width: '10%',
  },
  {
    id: 'permitteeName',
    numeric: false,
    disablePadding: false,
    label: 'Sub-Client Name',
    width: '12%',
  },
  {
    id: 'projectName',
    numeric: false,
    disablePadding: false,
    label: 'Project Name',
    width: '15%',
  },
  {
    id: 'inspectionIntervalName',
    numeric: false,
    disablePadding: false,
    label: 'Frequency',
    width: '10%',
  },
  {
    id: 'projectType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    width: '10%',
  },
  {
    id: 'notes',
    numeric: false,
    disablePadding: false,
    label: 'Additional Notes',
  },
];

interface TableProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof any) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: TableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof any) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              color: 'rgb(80,101,130)',
              fontWeight: 'bold',
              borderBottom: '1px solid rgb(163, 163, 163)',
            }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const ProjectActivityLogReport = () => {
  const [showModal, setShowModal] = useState(true);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState<Order>('asc');

  const [orderBy, setOrderBy] = useState<keyof any>('date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [formData, setFormData] = useState({
    regions: [],
  });

  const [isModify, setIsModify] = useState(false);

  const handleRequestSort = (_event: unknown, property: keyof any) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownload = () => {
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Project Activity Log');

    worksheet.columns = [
      {
        header: 'Date',
        key: 'activityDate',
        width: 15,
        style: { numFmt: 'mm/dd/yyyy' },
      },
      { header: 'Activity', key: 'activityType', width: 20 },
      { header: 'Client Name', key: 'clientName', width: 20 },
      { header: 'Client Code.', key: 'clientCode', width: 20 },
      {
        header: 'Sub-Client Name',
        key: 'permitteeName',
        width: 20,
      },
      {
        header: 'Project Name',
        key: 'projectName',
        width: 20,
      },
      {
        header: 'Frequency',
        key: 'inspectionIntervalName',
        width: 20,
      },
      { header: 'Type', key: 'projectType', width: 20 },
      { header: 'Additional Notes', key: 'notes', width: 20 },
    ];

    data.forEach((item: any) => {
      const row = worksheet.addRow([
        format(parseISO(item.activityDate), 'MM/dd/yyyy'),
        item.activityType,
        item.clientName,
        item.clientCode,
        item.permitteeName,
        item.projectName,
        item.inspectionIntervalName,
        item.projectType,
        item.notes,
      ]);

      if (item.changed && item.changed.length) {
        for (const prop of item.changed) {
          if (prop !== 'id' && prop !== 'activityDate' && prop !== 'activityType')
            row.getCell(prop).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'F00FF00' },
            };
        }
      }
    });

    worksheet.getRow(1).font = { bold: true };

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.href = url;
      a.download = `ProjectActivityReport_${new Date()
        .toJSON()
        .slice(0, 10)}.xlsx`;
      a.click();
    });
  };

  const handleModify = () => {
    setIsModify(true);
    setShowModal(true);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = useMemo(
    () =>
      _.orderBy(data, [orderBy], [order]).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),

    [order, orderBy, page, rowsPerPage, data],
  );

  return (
    <div className='project-activity-log'>
      <FormContextProvider>
        <div className='header'>
          <button className='button-primary' onClick={() => handleModify()}>
            Modify Reports
          </button>
          <button className='button-primary' onClick={() => handleDownload()}>
            Download
          </button>
        </div>
        <TableContainer
          component={'div'}
          sx={{ borderRadius: '4px', border: '1px solid rgb(163, 163, 163)' }}
        >
          <Table aria-label='Project Activity Table'>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy as string}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {data &&
                visibleRows &&
                visibleRows.map((item: any) => (
                  <TableRow
                    key={item.id}
                    className='project-activity-log__body-data'
                  >
                    <StyledTableCell>
                      <Typography>
                        {format(parseISO(item.activityDate),'MM/dd/yyyy')}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>{item.activityType}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>{item.clientName}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>{item.clientCode}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>{item.permitteeName}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>{item.projectName}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>{item.inspectionIntervalName}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>{item.projectType}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>{item.notes}</Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {showModal && (
          <ProjectActivityLogReportModal
            setShowModal={setShowModal}
            setData={setData}
            formData={formData}
            setFormData={setFormData}
            isEdit={isModify}
          />
        )}
      </FormContextProvider>
    </div>
  );
};
