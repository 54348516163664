import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import GroupAdd from "./GroupAdd";
import DivisionError from "../Shared/DivisionError/DivisionError";
import RolesContext from "../../contexts/RolesContext";
import ResourceIndex from "../Shared/ResourceIndex/ResourceIndex";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import { getDivisionResourceName } from "../../hooks/divisionResource";

/** Regulations (Document Group) Index */
const Groups: React.FC<{}> = () => {
  const roleStore = useContext(RolesContext);
  const appDivisionContext = useContext(AppDivisionContext);

  if (!appDivisionContext.appDivisionId) {
    return <DivisionError resource="regulations" />;
  }

  return (
    <ResourceIndex
      resourceName={getDivisionResourceName("regulations")}
      resourceQueryProps={{ divisionId: appDivisionContext.appDivisionId }}
      resourceTableHeaders={[
        ["Name", "name"],
        ["# of Documents", "num_documents"],
        ["Date Modified", "f_updated_at"],
      ]}
      enableRead={roleStore.userHasPermission("read", "Regulations")}
      enableEdit={false}
      enableCallToAction={roleStore.userHasPermission("create", "Regulations")}
      renderReader={group => (
        <Redirect push to={appDivisionContext.getPath(`/groups/${group.id}`)} />
      )}
      renderCallToAction={(handleClose, refetch) => {
        return <GroupAdd handleClose={handleClose} onAdd={() => refetch()} />;
      }}
      enablePagination
      hideCallToActionInMobile={false}
    />
  );
};

export default Groups;
