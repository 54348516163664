import {
  ISignatureEditorData,
  SignatureEditorApi,
} from "@sw-sw/lib-certification";
import xhrService from "../xhrService";

function getSignatureEditorApiUrl(id: number) {
  return `/api/templates/${id}/signatures`;
}

export const signatureEditorApi: SignatureEditorApi = {
  async index(templateId: number): Promise<ISignatureEditorData> {
    const response = await xhrService.get(getSignatureEditorApiUrl(templateId));

    return response.data;
  },
  async update(
    templateId: number,
    data: ISignatureEditorData,
  ): Promise<ISignatureEditorData> {
    const response = await xhrService.post(
      getSignatureEditorApiUrl(templateId),
      data,
    );

    return response.data;
  },
};
