import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import FormModal from "../Shared/form/modal/FormModal";
import CustomMessageForm from "./CustomMessageForm";

const CustomMessageModalUI = ({ hideModal, handleSubmit, subTitle }) => {
  const formContext = useContext(FormContext);

  return (
    <FormModal
      modalProps={{
        title: "Default Email Message",
        subTitle: subTitle,
      }}
      onCancel={hideModal}
      onSubmit={() => handleSubmit(formContext.value)}
      submitText="Save"
    >
      <CustomMessageForm onChange={formContext.set} value={formContext.value} />
    </FormModal>
  );
};

const CustomMessageModal = ({
  show,
  hideModal,
  handleSubmit,
  subTitle,
  defaultEmailMessage,
}) => {
  if (!show) {
    return null;
  }

  return (
    <FormContextProvider initialValue={{ customMessage: defaultEmailMessage }}>
      <CustomMessageModalUI
        hideModal={hideModal}
        handleSubmit={handleSubmit}
        subTitle={subTitle}
      />
    </FormContextProvider>
  );
};

CustomMessageModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default CustomMessageModal;
