import React, { useCallback, useContext, useState } from "react";
import AppContext from "../../contexts/AppContext";
import UserDetail from "../Users/Detail";
import EditUserModal from "../Users/EditUser/EditUserModal";
import ElectronicSignatureControl from "./ElectronicSignatureControl";
import EmailSettings from "./EmailSettings";
import NotificationsSettingsList from "./NotificationsSettingsList";

/**
 * controller for viewing and editing own profile
 */
function UserProfile() {
  const appStore = useContext(AppContext);

  const [showForm, setShowForm] = useState(false);

  const onSubmit = useCallback(user => {
    appStore.set("user", user);
    setShowForm(false);
  }, []);

  const user = appStore.get("user");
  const role = [
    "Regulator",
    "Billing Admin",
    'COA Regulator'
  ]
  
  return (
    <section>
      <UserDetail
        {...appStore.get("user")}
        onEdit={() => setShowForm(true)}
        classNames="current-user-profile"
      />
      {/* Do not display notification settings if user role is regulator */}
      {(!role.includes(user.roleName)) && (
        <div className="settings-controls">
          <NotificationsSettingsList isRegulator2={user.roleName === "Regulator - With Photos"} />
          {user.roleName === "Regulator - With Photos" ? null : <EmailSettings />}
          {user.roleName === "Regulator - With Photos" ? null : <ElectronicSignatureControl />}
        </div>
      )}
      {showForm && (
        <EditUserModal
          userId={user.id}
          roleId={user.role_id}
          name={user.name}
          onSubmit={onSubmit}
          onClose={() => setShowForm(false)}
          isProfile
          isEdit
          isMyProfile
        />
      )}
    </section>
  );
}

UserProfile.propTypes = {};

export default UserProfile;
