import React, { useState, useContext, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

import Loading from "../Shared/ResourceIndex/Loading";

import ResourceDetailTabs from "../Shared/ResourceDetail/ResourceDetailTabs";
import { RolesContext } from "../../contexts/RolesContext";
import AppContext from "../../contexts/AppContext";
import ResourceDetailTitle from "../Shared/ResourceDetail/ResourceDetailTitle";
import DivisionUpdate from "./DivisionUpdate";
import DivisionDelete from "./DivisionDelete";
import { DivisionContext } from "../../contexts/DivisionContext";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import DivisionResourceIndex from "./Resources/DivisionResourceIndex";
import Error from "../Shared/ResourceIndex/Error";

const DivisionDetail: React.FC = () => {
  const match = useRouteMatch<{ id?: string }>();
  const divisionIdParam = match.params.id;
  const divisionContext = useContext(DivisionContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const appStore = useContext(AppContext);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const { tabs, resources } = divisionContext;
  const permCheck = useContext(RolesContext).userHasPermission;

  const onDelete = () => {
    const divisions = appStore.get("user.divisions");

    // appStore.set("user.divisions.length", 0);
    if (
      !divisions
        .map((_: { id: number }) => _.id)
        .includes(appDivisionContext.appDivisionId)
    ) {
      appDivisionContext.saveAppDivisionId(divisions[0].id);
    }
    appStore.set("user.divisions", divisions);
  };

  useEffect(() => {
    if (divisionIdParam) {
      divisionContext
        .selectDivision(parseInt(divisionIdParam))
        .then(() => setLoaded(true))
        .catch(err => {
          setError(err.message);
          setLoaded(true);
        });
    }

    divisionContext.setDisableSwitcher(true);

    return () => {
      divisionContext.setDisableSwitcher(false);
      divisionContext.resetSelectedTab();
    };
  }, []);

  return (
    <section className="project-detail division-detail">
      {!loaded ? <Loading /> : null}
      {loaded && error ? <Error error={error} /> : null}
      {loaded && !error ? (
        <React.Fragment>
          <div className="division-header">
            {divisionContext.selectedDivision &&
            divisionContext.selectedDivision.name ? (
              <React.Fragment>
                <ResourceDetailTitle
                  title={divisionContext.selectedDivision.name}
                />
                <div className="project-buttons">
                  {permCheck("update", "Divisions") && (
                    <DivisionUpdate
                      onUpdate={divisionContext.selectDivision}
                    />
                  )}
                  <div className="project-delete-button">
                    {permCheck("delete", "Divisions") && (
                      <DivisionDelete onDelete={onDelete} />
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <Loading />
            )}
          </div>
          <ResourceDetailTabs
            headers={resources.map(key => tabs[key].header)}
            onSelect={index => divisionContext.setSelectedTab(resources[index])}
            Component={DivisionResourceIndex}
            urlPrefix="/resources/"
            showTab={true}
          />
        </React.Fragment>
      ) : null}
    </section>
  );
};

export default DivisionDetail;
