import { fabric } from "fabric";
import DraggableShapeTool from "./DraggableShape";

/**
 * text tool
 */
class TextTool extends DraggableShapeTool {
  makeShape(options) {
    return new fabric.Text("example", options);
  }

  getDefaultShapeConfig() {
    return {
      fontSize: 22,
      fill: "#333333",
      selectable: false,
      opacity: 0.9,
    };
  }

  calculateDimensions(x, y, left, top) {
    return {};
  }
}

export default TextTool;
