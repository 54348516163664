import React, { useContext, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faEllipsisV,
  faExternalLinkAlt,
  faSort,
  faRedoAlt
} from "@fortawesome/free-solid-svg-icons";
import { AppDivisionContext } from "../../../../contexts/AppDivisionContext";
import { customDashboard } from "../../../../utils/api/dashboard";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Shared/ResourceIndex/Loading";
import DashboardContext from "../../../../contexts/DashboardContext";
import EllipsisPopover from '../../shared/EllipsisPopover/EllipsisPopover'
import { Tooltip } from "@mui/material";



const OpenFindings = ({ providedProps, changeShowMore, mdSize }) => {
  const [search, setSearch] = useState('')
  const { getPath } = useContext(AppDivisionContext);
  const [clientIsAscending, setClientIsAscending] = useState(true)
  const [siteIsAscending, setSiteIsAscending] = useState(true)
  const { displayStatsForOF, isLoadingForOF, allProjectsForOF, setDisplayStatsForOF, setIsLoadingForOF, findingTypeListForOF, fetchStatsFuncForOF } = useContext(DashboardContext)
  const widgetRef = useRef(0);
  const [widgetWidth, setWidgetWidth] = useState(widgetRef.current.clientWidth)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    function handleResize() {
      setWidgetWidth(widgetRef.current.clientWidth)
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  })

  const handleSelect = (event) => {
    setIsLoadingForOF(true)
    customDashboard.fetchWithParams(getPath('/openFindings/getOpenFindingStats'), { "type": event.target.value })
      .then(res => {
        setDisplayStatsForOF(res.data.statistics)
        setIsLoadingForOF(false)
      })
      .catch((error) => console.log(error))
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  //SEARCH
  useEffect(() => {
    const updatedList = allProjectsForOF && [...allProjectsForOF].filter((el) => {
      return el.siteName.toLowerCase().includes(search.toLowerCase())
    })

    setDisplayStatsForOF(updatedList)
  }, [search])

  //SORT
  const sortBySiteName = () => {
    const updatedList = displayStatsForOF.sort((a, b) => {
      let A = a.siteName.toString().toLowerCase()

      let B = b.siteName.toString().toLowerCase()

      if (siteIsAscending === true) {
        setSiteIsAscending(!siteIsAscending)

        return A > B ? -1 : 1 // descending
      }

      else {
        setSiteIsAscending(!siteIsAscending)

        return A > B ? 1 : -1 //ascending
      }
    })

    setDisplayStatsForOF(updatedList)
  }

  const sortByClientName = () => {
    const updatedList = displayStatsForOF.sort((a, b) => {
      let A = a.clientName.toString().toLowerCase()

      let B = b.clientName.toString().toLowerCase()

      if (clientIsAscending === true) {
        setClientIsAscending(!clientIsAscending)

        return A > B ? -1 : 1 // descending
      }

      else {
        setClientIsAscending(!clientIsAscending)

        return A > B ? 1 : -1 //ascending
      }
    })

    setDisplayStatsForOF(updatedList)
  }


  let oneColumnHeader = {
    // gridTemplateAreas: '"title title title title . bars bars bars bars bars bars ellipsis"'
  }


  return (
    <>
      <div className="open-findings" ref={widgetRef}>
        <header className="open-findings__header" style={widgetWidth > 700 ? oneColumnHeader : null}>
          <h2 className="open-findings__title">Open Findings</h2>
          <Tooltip
            title={'Click to refresh data/results'}
            placement='top'
            arrow
          >
            <h5 className="open-findings__reload-button" onClick={() => fetchStatsFuncForOF.refetch()}>
              <FontAwesomeIcon icon={faRedoAlt} />
            </h5>
          </Tooltip>
          <div className="open-findings__bars">
            <select name="finding-type" id="finding-type" placeholder="Select" onChange={(event) => handleSelect(event)}>
              <option value=" ">All Findings</option>
              {findingTypeListForOF.map((ele) => {
                return (
                  ele === "CA" ? <option value={ele}>Corrective Action</option> :
                    ele === "MI" ? <option value={ele}>Maintenance Item</option> :
                      <option value={ele}>{ele}</option>
                )
              })}
            </select>
            <input onChange={(event) => setSearch(event.target.value)} placeholder="Search" />
          </div>
          {/* <div className="open-findings__ellipsis-button">
            <span onClick={(event) => setAnchorEl(event.currentTarget)}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          </div> */}
          <EllipsisPopover handleCloseFunc={handleClose} id={id} open={open} anchorEl={anchorEl} />

        </header>

        <div
          data-rbd-drag-handle-context-id={providedProps}
          data-rbd-drag-handle-draggable-id="gibberish"
          style={{
            // When you set the data-rbd-drag-handle-context-id, RBD applies cursor: grab, so we need to revert that
            cursor: "auto"
          }}
        >
          <div className="open-findings__body">
            <div className="open-findings__content">
              <section className="open-findings__table-wrapper">
                <div className="open-findings__table-left">
                  <div className="open-findings__table-head">
                    <h3 onClick={sortBySiteName}>Site Name <span><FontAwesomeIcon icon={faSort} /></span></h3>
                    <h3 onClick={sortByClientName}>Client Name <span><FontAwesomeIcon icon={faSort} /></span></h3>
                  </div>

                  {
                    !isLoadingForOF ?
                      (displayStatsForOF && displayStatsForOF.map((ele, index) => {
                        return (
                          <div key={index} className="open-findings__table-body">
                            <li>{ele.siteName}</li>
                            <li>{ele.clientName}</li>
                          </div>
                        )
                      })) 
                      :
                      (<Loading what="data" />)
                  }
                </div>

                <div className="open-findings__table-right">
                  <div className="open-findings__table-right-inner-wrapper">
                    <div className="open-findings__table-head">
                      <h3>Open Findings </h3>
                      <h3>Max Days Opened </h3>
                      <h3>Action</h3>
                    </div>

                    {
                      !isLoadingForOF ?
                        (displayStatsForOF && displayStatsForOF.map((ele, index) => {
                          return (
                            <div key={index} className="open-findings__table-body">
                              <li>{ele.openFindingsCount}</li>
                              <li>{ele.maxDaysOpened}</li>
                              <li>
                                <Link to={`/divisions/${ele.divisionId}/projects/${ele.projectId}/findings`}>
                                  <span>View Project</span>
                                </Link>
                              </li>
                            </div>
                          )
                        })) :
                        (<Loading what="data" />)
                    }
                  </div>
                </div>
              </section>
            </div>

            <footer className="open-findings__footer">
              <span onClick={changeShowMore}>Show {(mdSize === 6) ? 'More' : 'Less'} <FontAwesomeIcon icon={faExternalLinkAlt} /></span>
            </footer>
          </div>
        </div>
      </div>
    </>
  )
}


export default OpenFindings