import React, { useContext } from "react";
import {
  FormContext,
  FormContextProvider,
  FormSchema,
  FormSchemaFields,
} from "@sw-sw/lib-form";
import FormModal from "../../Shared/form/modal/FormModal";
import InspectionContext from "../../../contexts/InspectionContext";
import findingApi from "../../../utils/api/finding";
import { UIControlType } from "@sw-sw/lib-form-control-types";

export interface IDeleteFindingProps {
  show: boolean;
  handleClose: () => void;
  onDelete: () => Promise<void>;
  findingId: number;
}

const DeleteFindingModal: React.FC<IDeleteFindingProps> = ({
  show,
  handleClose,
  onDelete,
  findingId,
}) => {
  const inspectionContext = useContext(InspectionContext);

  const schema: FormSchema = {
    note: {
      label: "Deletion Note",
      controlType: UIControlType.textarea,
      validation: {
        required: true,
      },
    },
  };

  const handleDelete = async (note: string) => {
    if (!note.trim()) {
      throw new Error("Finding Deletion Note is required.");
    }

    return findingApi.destroy(findingId, note.trim()).then(() => {
      inspectionContext.setFindings(
        inspectionContext.findings.filter(
          (f: { id: number }) => f.id !== findingId,
        ),
      );
      handleClose();
      onDelete();
    });
  };

  if (!show) {
    return null;
  }

  return (
    <FormContextProvider>
      <FormContext.Consumer>
        {formContext => (
          <FormModal
            modalProps={{
              title: "Delete Finding",
            }}
            onSubmit={() => handleDelete(formContext.value.note)}
            onCancel={handleClose}
          >
            <FormSchemaFields
              schema={schema}
              formData={formContext.value}
              onChange={formContext.set}
            />
          </FormModal>
        )}
      </FormContext.Consumer>
    </FormContextProvider>
  );
};

export default DeleteFindingModal;
