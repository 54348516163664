import React, { useRef, ChangeEvent, useEffect, useState, useContext } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Typography,
  Checkbox,
  Card,
  Button,
  Grid,
} from '@mui/material';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Bluebird from 'bluebird';
import { useHistory, useRouteMatch } from "react-router-dom";
import { Inspection, InspectionTemplate } from "@sw-sw/lib-inspection-templates";


import Loading from "../../../Shared/ResourceIndex/Loading";
import inspectionApi from "../../../../utils/api/inspection";
import PrecipitationPopover from '../../shared/PrecipitationPopover';
import projectWeather from '../../../../utils/api/projectWeather';
import { AppDivisionContext } from '../../../../contexts/AppDivisionContext';
import SuccessNotification from "../../../Shared/SuccessNotification/SuccessNotification";
import LoadingModal from "../../../Shared/Loading/LoadingModal";
import SuccessModal from "../../../Shared/SuccessModal/SuccessModal";
import DashboardContext from '../../../../contexts/DashboardContext';
import RolesContext from "../../../../contexts/RolesContext";
import ProjectPermissionContext from "../../../../contexts/ProjectPermissionContext";

import './precipitation.scss'


type Props = {
  providedProps: any;
  changeShowMore: Function;
  mdSize: number;
}

export type ProjectWeatherDataClientType = {
  id: number;
  name: string;
}

export type ProjectWeatherDataAddressType = {
  id: number;
  latitude: number;
  longitude: number;
}

export type UserNotificationTypes = 'in-app' | 'email';

export type ProjectWeatherDataUserNotificationSettingType = {
  default_email_message: string | null | undefined;
  id: number;
  is_enabled: Array<UserNotificationTypes>;
}

export type ProjectWeatherDataUserType = {
  id: number;
  notification_user_settings: ProjectWeatherDataUserNotificationSettingType;
}

export type ProjectWeatherDataType = {
  id?: number;
  project_id: number;
  rain_accumulation: number;
  snow_accumulation: number;
  unit: string;
  datetime: Date | string;
};

type sortingTypes = "asc" | "desc";

export type FilterColumnsType = {
  siteName?: sortingTypes;
  clientName?: sortingTypes;
}

export interface ProjectWeatherDataIF {
  id: number;
  name: string;
  timezone: string;
  precipitation_flag: boolean;
  precipitation_threshold: number;
  snow_threshold: number;
  client_id: number;
  client: ProjectWeatherDataClientType;
  address_id: number;
  address: ProjectWeatherDataAddressType;
  users: ProjectWeatherDataUserType[];
  project_weather_data: ProjectWeatherDataType[];
  inspections: Inspection[];
  template: InspectionTemplate | null;
}

const index = (props: Props) => {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const appDivisionContext = useContext(AppDivisionContext);
  const divisionId = _.get(
    routeMatch.params,
    'division_id',
    appDivisionContext.appDivisionId,
  );
  const permCheck = useContext(RolesContext).userHasPermission;
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const { fetchStatsFuncForID } = useContext(DashboardContext)

  const searchInput = useRef<any>("");
  const [projectWeatherData, setProjectWeatherData] = useState<Array<ProjectWeatherDataIF>>([]);
  const [selectedCerts, setSelectedCerts] = useState<Array<number>>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [filterColumns, setFilterColumns] = useState<FilterColumnsType>({});
  const [siteNameIcon, setSiteNameIcon] = useState<any>(faSort);
  const [clientNameIcon, setClientNameIcon] = useState<any>(faSort);
  const [widgetLoading, setWidgetLoading] = useState<boolean>(false);

  const fetchProjectPrecipitationData = async (search: string = "") => {
    const queryParams = (search) ? { s: search } : {};
    const projectWeatherDataTemp = await projectWeather.index({ queryParams, divisionId });
    (projectWeatherDataTemp && projectWeatherDataTemp.data) && setProjectWeatherData(projectWeatherDataTemp.data);
    setWidgetLoading(false); 
  }

  const handleSearchChange = _.debounce((e: ChangeEvent<HTMLInputElement>) => {
    fetchProjectPrecipitationData(e.target.value.trim())
  }, 500);

  const handleSort = (columnName: string) => {

    let tempFilterColumns: any = { ...filterColumns };

    if (Object.keys(tempFilterColumns)[0] !== columnName) {
      tempFilterColumns = {};
    }

    if (!tempFilterColumns[columnName]) {
      tempFilterColumns[columnName] = "desc";
    } else if (tempFilterColumns[columnName] && tempFilterColumns[columnName] === "desc") {
      tempFilterColumns[columnName] = "asc";
    } else {
      if (tempFilterColumns[columnName]) { delete tempFilterColumns[columnName] };
    }

    setFilterColumns(tempFilterColumns);

  }

  const changeCheckboxSelection = (id: number, currentState: boolean): void => {

    let tempSelectionArr: any = [...selectedCerts];

    if (currentState == false && tempSelectionArr.includes(id)) {
      tempSelectionArr = tempSelectionArr.filter((e: number) => e !== id);
    } else {
      tempSelectionArr.push(id);
    }

    setSelectedCerts(tempSelectionArr);

  }

  const onStartInspection = (project_id: number, canCreateNewInspections: boolean) => {

    if (canCreateNewInspections) {
      inspectionApi.create(project_id, "Post Storm").then(result => {

        history.push({
          pathname: `/inspection/${result.id}/questionnaire`,
        });

      }).catch(err => {
        console.log(err);
      });
    }

  }

  const onViewProject = (project_id: number) => {
    (project_id) && history.push({
      pathname: `/projects/${project_id}`,
    });
  }

  const handleAddToSchedule = () => {

    if (selectedCerts.length > 0) {

      setShowLoading(true);

      const errorFlags = [];

      selectedCerts.length && Bluebird.mapSeries(selectedCerts, async (project_id: number) => {

        // create new post storm inspection
        return inspectionApi.create(project_id, "Post Storm").then(result => {

          return result;

        }).catch(err => {

          console.log(err)
          errorFlags.push({ project_id, status: false });

        });

      }).then(() => {

        setShowLoading(false);

        if (!(errorFlags.length > 0)) {

          setShowSuccess(true);
          fetchProjectPrecipitationData();
          fetchStatsFuncForID.refetch();
          searchInput.current.value = "";

          setTimeout(() => {
            setShowSuccess(false);
          }, 1500);

        } else {

          setShowError(true);
          fetchProjectPrecipitationData();

        }

      });

    }

  }

  const updateSiteNameIcon = (order: string) => {
    // handleSaveAnimation();
    if (order == "desc") {
      setSiteNameIcon(faSortDown);
    } else if (order == "asc") {
      setSiteNameIcon(faSortUp);
    } else {
      setSiteNameIcon(faSort);
    }
    setClientNameIcon(faSort);
  }

  const updateClientNameIcon = (order: string) => {
    setSiteNameIcon(faSort);
    if (order == "desc") {
      setClientNameIcon(faSortDown);
    } else if (order == "asc") {
      setClientNameIcon(faSortUp);
    } else {
      setClientNameIcon(faSort);
    }
  }

  useEffect(() => {

    const temp = [...projectWeatherData];

    let newSortOrderList = [];

    if (filterColumns.siteName) {
      newSortOrderList = _.orderBy(temp, [(o) => o.name.toLowerCase()], [filterColumns.siteName]);
      updateSiteNameIcon(filterColumns.siteName);
    } else if (filterColumns.clientName) {
      newSortOrderList = _.orderBy(temp, [(o) => o.client.name.toLowerCase()], [filterColumns.clientName]);
      updateClientNameIcon(filterColumns.clientName);
    } else {
      newSortOrderList = _.orderBy(temp, [(o) => o.id], ["desc"]);
      updateSiteNameIcon("");
    }

    setProjectWeatherData(newSortOrderList);

  }, [filterColumns]);

  useEffect(() => {
    setWidgetLoading(true);
    fetchProjectPrecipitationData();
  }, [divisionId]);

  return (
    <section className='precipitation-widget-section'>
      <Box sx={{ flexGrow: 1 }} className={'widget-toolbar'}>

        <Grid container spacing={1} className={`heading-section`}>
          <Grid item xs={8}>
            <Typography sx={{ fontSize: '22px' }} component={'span'} className={`widget-headline`} variant="h6" gutterBottom >
              Precipitation
            </Typography>
          </Grid>

          <Grid item xs={4}>
            {/* <Button aria-describedby={id} variant="contained"
              onClick={handleClick}
              className={`widget-popover-btn`} >
              <FontAwesomeIcon icon={faEllipsisV} />
            </Button>
            <EllipsisPopover handleCloseFunc={handleClose} id={id} open={open} anchorEl={anchorEl} /> */}
          </Grid>
        </Grid>

        <Grid container spacing={1} className={`heading-action-section`}>
          <Grid item xs={5}>
            <Button onClick={handleAddToSchedule} className={`add-to-schedule-btn`}>
              Add to schedule
            </Button>
          </Grid>

          <Grid item xs={7}>
            <input type="text" placeholder="Search" className='widget-search-input' ref={searchInput} onChange={handleSearchChange} />
          </Grid>
        </Grid>

      </Box>

      <Box>
        <div
          data-rbd-drag-handle-context-id={props.providedProps}
          data-rbd-drag-handle-draggable-id="gibberish"
          style={{
            // When you set the data-rbd-drag-handle-context-id, RBD applies cursor: grab, so we need to revert that
            cursor: "auto"
          }}
        >
          <Card className='widget-container' >
            <Box sx={{ width: '100%', overflow: 'hidden' }} >
              <TableContainer sx={{ height: 403 }} className='precip-widget-table-container' >
                <Table stickyHeader aria-label="sticky table" size='small'>

                  <TableHead className='widget-head'>
                    <TableRow>
                      <TableCell ></TableCell>
                      <TableCell className='table-fix-th-1'>
                        <Typography component={'span'} variant="body1" fontWeight={'bold'} gutterBottom onClick={() => { handleSort("siteName") }} >Site Name <FontAwesomeIcon icon={siteNameIcon} size={'sm'} className={''} /> </Typography>
                      </TableCell>
                      <TableCell className='table-fix-th-2'>
                        <Typography component={'span'} variant="body1" fontWeight={'bold'} gutterBottom onClick={() => { handleSort("clientName") }} >Client <FontAwesomeIcon icon={clientNameIcon} size={'sm'} className={''} /></Typography>
                      </TableCell>
                      <TableCell className='table-fix-th-precip-val'>
                        <Typography component={'span'} variant="body1" fontWeight={'bold'} gutterBottom >{moment().format("MM-DD-YY")} <span className='rain-snow-identification'> Rain / Snow </span> </Typography>
                      </TableCell>
                      <TableCell className='table-fix-th-precip-val'>
                        <Typography component={'span'} variant="body1" fontWeight={'bold'} gutterBottom >{moment().subtract(1, "days").format("MM-DD-YY")} <span className='rain-snow-identification'> Rain / Snow </span> </Typography>
                      </TableCell>
                      <TableCell className='table-fix-action'>
                        <Typography component={'span'} variant="body1" fontWeight={'bold'} gutterBottom >Action</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className='widget-body'>

                    {
                      projectWeatherData.length ? projectWeatherData.map((data: ProjectWeatherDataIF) => {

                        const hasIncompleteInspections = (data.inspections && data.inspections.length > 0) ? true : false;
                        const canCreateNewInspections = (data.template && data.template.name === "CDOT") ? true : !hasIncompleteInspections;
                        const hasPermToStartInspection = permCheck("create", "Inspection") && !projectPermissionContext.readOnly;

                        /**
                         * We are adding two different checks to show checkbox for add to schedule , and start inspection.
                         * 1st is if user has any incomplete inspections or not, there must be none.
                         * 2nd is if user has permission to start inspection or not, it should be there.
                         * 
                         * This is based on current logic of starting new post storm inspections.
                         */

                        const isChecked = selectedCerts.includes(data.id);

                        return (
                          <TableRow
                            hover
                            selected={isChecked}
                            key={data.id}
                            className='precipitation-body-data'
                          >
                            <TableCell className='table-fix-td-checkbox'>
                              {(canCreateNewInspections && hasPermToStartInspection) ? <Checkbox className='precip-checkbox'
                                checked={isChecked}
                                onChange={() => { return changeCheckboxSelection(data.id, !isChecked) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                                value={isChecked}
                              /> : <></>}
                            </TableCell>
                            <TableCell className='table-fix-td-1'>
                              <Typography component={'span'} variant="body1" className='cell-elm'>{data.name}</Typography>
                            </TableCell>
                            <TableCell className='table-fix-td-2'>
                              <Typography component={'span'} variant="body1" className='cell-elm'>{data.client.name}</Typography>
                            </TableCell>
                            <TableCell className='table-fix-td-precip-val'>
                              <PrecipitationPopover projectData={data} currentDate={moment().toISOString()} />
                            </TableCell>
                            <TableCell className='table-fix-td-precip-val'>
                              <PrecipitationPopover projectData={data} currentDate={moment().subtract(1, "days").toISOString()} />
                            </TableCell>
                            <TableCell className='table-fix-action'>
                              {(canCreateNewInspections && hasPermToStartInspection) ?
                                <Button variant='contained' onClick={() => onStartInspection(data.id, canCreateNewInspections)} className={`start-inspection-btn`}>Start Inspection</Button>
                                :
                                <Button variant='contained' onClick={() => onViewProject(data.id)} className={`view-project-btn`}>View Project</Button>
                              }
                            </TableCell>
                          </TableRow>
                        )

                      })
                        :
                        (widgetLoading) ?
                          <TableRow>
                            <TableCell sx={{ borderBottom: "none", textAlign: "center" }} className={`table-fix-td`} colSpan={6}>
                              <Loading />
                            </TableCell>
                          </TableRow>
                          :
                          <TableRow>
                            <TableCell sx={{ borderBottom: "none", textAlign: "center" }} className={`table-fix-td`} colSpan={6}>
                              <Typography component={'span'} variant="body1" className='cell-elm'>There are no projects with precipitaion on.</Typography>
                            </TableCell>
                          </TableRow>
                    }

                  </TableBody>

                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ flexGrow: 1 }} className={'widget-footer'}>
              <Grid container >
                <Grid item xs={12} className='showmore-btn-wrapper'>
                  <Button onClick={() => props.changeShowMore()} > Show {(props.mdSize === 6) ? 'More' : 'Less'} <FontAwesomeIcon icon={faExternalLinkAlt} size={'lg'} className={'external-link-icon'} /></Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </div>
      </Box>

      <SuccessNotification show={showSuccess} />
      <LoadingModal show={showLoading} />
      <SuccessModal
        show={showError}
        handleClose={() => setShowError(false)}
        handleSubmit={() => setShowError(false)}
        submitBtnText="OK"
        message={"Something went wrong! Some of the projects have not started post storm inspections."}
        title="Error"
        isAlert
      />

    </section>
  )
}

export default index
