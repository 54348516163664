import React, {forwardRef} from 'react';

export default forwardRef(function MenuItem({
    children,
    ...props
}, ref) {
    return (
        <div className="sw-dropdown__menu-item" {...props} ref={ref}>
            {children}
        </div>
    )
});