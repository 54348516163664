import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { InspectionReportButton } from "./Button";
import { env } from "../../config";

import "./_InspectionReportDownloadPage.scss";

export default function InspectionReportDownloadPage({ location }: any) {
  const search = new URLSearchParams(location.search);
  const reportId = search.get("reportId");
  const reportTitle = search.get("reportTitle");
  const downloadUrl = `${env.REACT_APP_API_BASE_URL}/api/mailedInspectionReport/download/${reportId}.pdf`;
  const filename = `${reportTitle}.pdf`;

  useEffect(() => {
    setTimeout(async () => {
      await downloadFile();
    }, 2000);
  }, []);

  if (!reportId || !reportTitle) {
    return <Redirect to="/download-not-found" />;
  }

  async function downloadFile() {
    const response = await fetch(downloadUrl);
    const data = await response.blob();
    var a = document.createElement("a");

    a.href = URL.createObjectURL(data);
    a.setAttribute("download", filename);
    a.click();
  }

  return (
    <div className="report-page">
      <img
        className="report-page__logo"
        alt = "SW Logo"
        src="/static/media/SW-Logo-White.svg"
      />

      <div className="report-page__synopsis">
        <h2 className="report-page__title">Download Report</h2>
        <h4>{reportTitle}</h4>
      </div>

      <p className="report-page__manual-download">
        Your download will start automatically.{" "}
        <button onClick={downloadFile} className="report-page__manual-download__button" >Click Here</button> to download the file manually
      </p>

      <InspectionReportButton text="My Dashboard" to="/dashboard" />
    </div>
  );
}
