import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { formDataShape } from "./EditUser/EditUserModal";

/**
 * UI to display a user's information
 *
 */
function UserDetail({ roleName, email, name, address, onEdit, classNames }) {
  const { phone, phone_ext } = address || {};

  return (
    <div className={classnames("user-details", classNames)}>
      <div className="card">
        <div className="card-body">
          <div className="card-title">{name}</div>

          {onEdit && (
            <div className="card-toolbar">
              <div
                className="card-toolbar-item pure-button button-outline-dark"
                onClick={onEdit}
                title="Edit Profile"
              >
                <span className="fa fa-pencil" />
              </div>
            </div>
          )}
        </div>
        <div className="card-body">
          <div className="card-content">
            <p>
              <i className="fa fa-envelope" />
              <span>{email}</span>
            </p>

            {roleName && (
              <p>
                <i className="fa fa-user" />
                <span>{roleName}</span>
              </p>
            )}

            {phone && (
              <p>
                <i className="fa fa-phone" />
                <span>{phone}</span>
                {phone_ext && (
                  <span className="pad-left">ext: {phone_ext}</span>
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

UserDetail.propTypes = {
  ...formDataShape,
  classNames: PropTypes.string,
  name: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
};

export default UserDetail;
