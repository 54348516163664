import React, { useEffect, useRef } from "react";
import { toLower, trim, debounce, get } from "lodash";
import { SearchInputProps, SearchInput } from "./SearchInput";

interface ILocalDataState<D = any> {
  data: D[];
  setData: (filteredData: D[]) => void;
}

interface ILocalDataAttributes {
  attributes: string[];
}

export type LocalSearchProps = ILocalDataState & ILocalDataAttributes;

const localDataFilter = ({
  query,
  data,
  setData,
  attributes,
}: ILocalDataState & ILocalDataAttributes & { query: string }): void => {
  if (query) {
    const searchVal = trim(toLower(query));

    /**
     * @todo maintain local data index by concatenating search fields
     */
    const filteredSet = data.reduce(
      (filtered, item) => {
        attributes.forEach(att => {
          if (toLower(get(item, att)).indexOf(searchVal) > -1) {
            filtered.add(item);
          }
        });

        return filtered;
      },
      // using a set, so we don't need to care about duplicates
      new Set(),
    );

    // return an array, not a set
    setData([...filteredSet]);
  } else {
    if (data && data.length) {
      setData(data);
    }
  }
};

/**
 * SearchInput for lists of data objects with pre-defined props or via custom function
 */
export const LocalSearch: React.FC<SearchInputProps & LocalSearchProps> = ({
  data,
  setData,
  attributes,

  ...searchProps
}) => {
  const filterRef = useRef(debounce(localDataFilter, 130));

  useEffect(() => {
    filterRef.current({
      attributes,
      data,
      query: searchProps.query,
      setData,
    });
  }, [searchProps.query]);

  return <SearchInput {...searchProps} />;
};

export default LocalSearch;
