import xhrService from '../xhrService';

export const baseUrl = '/api/inspections';
const getUrl = (id) => `${baseUrl}/${id}`;

export const inspectionApi = {
  get: (id) => xhrService.get(getUrl(id)).then((res) => res.data).catch((e) => {
    throw e;
  }),
  create: (projectId, type) => {
    return xhrService
      .post(baseUrl, {
        projectId,
        type,
      })
      .then((res) => res.data)
      .catch((e) =>{
        throw e
      });
  },
  update: (id, data) => {
    return xhrService.put(getUrl(id), data).then((res) => res.data);
  },
  destroy: (id) => xhrService.delete(getUrl(id)).then((res) => res.data),
  complete: (id, findings, dateCompleted, comments = undefined) => {
    const reqBody = {
      findings,
      dateCompleted,
    };

    if (comments) {
      reqBody.comments = comments;
    }

    return xhrService
      .put(`${baseUrl}/${id}/findings`, reqBody)
      .then((res) => res.data);
  },
  createQuestionComment: (questionId, inspectionId, data) => {
    return xhrService
      .post(`${baseUrl}/comment/${questionId}/${inspectionId}`, data)
      .then((res) => res.data);
  },
  reassign: (inspectionId, userId, projectId) => {
    return xhrService
      .post(`${baseUrl}/${inspectionId}/inspector/${userId}`, {
        projectId: projectId,
      })
      .then((res) => res.data);
  },
  reassignV2: (userId, projectIds, clientIds) => {
    return xhrService
      .post(`${baseUrl}/reassign/${userId}`, {
        projectIds: projectIds,
        clientIds: clientIds,
      })
      .then((res) => res.data);
  },
  getInspectionLinkedAllData: (id) => {
    return xhrService.get(`/api/inspection/${id}`)
    .then((res) => res.data)
    .catch((error) => console.log(error))
  }
};

export default inspectionApi;
