import { camelCase } from "lodash";
import React from "react";
import ButtonGroup from "../../Fields/ButtonGroup";

export const strokeStyles = [
  {
    label: "Solid",
  },
  {
    label: "Dash",
  },
  {
    label: "Dot",
  },
  {
    label: "Tick",
  },
].map(x => ({
  ...x,
  value: camelCase(x.label.toLowerCase()),
}));

/**
 */
class StrokeStyle extends React.Component {
  render() {
    const { onChange, ...rest } = this.props;

    return (
      <ButtonGroup
        onChange={i => onChange(strokeStyles[i].value)}
        options={strokeStyles}
        {...rest}
      />
    );
  }
}

export default StrokeStyle;
