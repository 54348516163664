import {
  FormContext,
  FormContextProvider,
  FormSchemaFields,
} from '@sw-sw/lib-form';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { Inspection } from '@sw-sw/lib-inspection-templates';
import classnames from 'classnames';
import { isArray, isEmpty, isNull } from 'lodash';
import React, {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  ProjectContext,
  ProjectContextValue,
} from "../../../contexts/ProjectContext";
import ProjectPermissionContext from "../../../contexts/ProjectPermissionContext";
import RolesContext from "../../../contexts/RolesContext";
import InspectionAdd from "../../Inspections/InspectionAdd";
import InspectionListing from "../../Inspections/Listing/InspectionListing";
import SelectAll from "../../Inspections/SelectAll";
import Loading from "../../Shared/ResourceIndex/Loading";
import SuccessNotification from "../../Shared/SuccessNotification/SuccessNotification";
import InspectionDate from "../InspectionDate";
import BulkCertifyModal from "./BulkCertifyModal";
import AppContext from "../../../contexts/AppContext";
import DashboardContext from "../../../contexts/DashboardContext";
import useResource from "../../../hooks/resource";
import ExternalProjectsInspectionListing from '../../Inspections/Listing/ExternalProjectsInspectionListing';

const controlField = "inspectionFilter";
const schema = {
  [controlField]: {
    controlType: UIControlType.select,
    placeholder: 'Filter Inspections',
    className: 'filter small',
    options: [
      { label: "All Inspections", value: "all" },
      { label: "Routine", value: "Routine" },
      { label: "Routine + Post Storm", value: "Routine + Post Storm" },
      { label: "Post Storm", value: "Post Storm" },
      { label: "Other", value: "Other" },
    ],
  },
};

export const findingsNotRequiredForCompliance = (template: any, findings: any) => {
  let isNotRequired = true

  findings.forEach((ele: any) => {
    if (!ele.date_completed) {
      template && ele.finding_type && ele.finding_type.details && ele.finding_type.details.forEach((detail: any) => {
        if (template.id === detail.inspection_template_id) {
          if (detail.is_required_for_cert) {
            isNotRequired = false
          }
        }
      })
    }
  })

  return isNotRequired
}

const InspectionFilter = (props: { handleChange: Dispatch<string> }) => {
  const formContext = useContext(FormContext);

  return (
    <FormSchemaFields
      className='filter-form'
      formData={formContext.value}
      initialFormData={{ [controlField]: 'all' }}
      onChange={(field, val) => {
        formContext.set(field, val);
        props.handleChange(val);
      }}
      schema={schema}
    />
  );
};

const ResetButton = (props: { handleClick: Dispatch<void> }) => {
  const formContext = useContext(FormContext);

  return (
    <button
      className='primary'
      onClick={() => {
        formContext.set(controlField, 'all');
        props.handleClick();
      }}
    >
      Reset filter
    </button>
  );
};

const filterInspections = (
  input: ProjectContextValue['inspections'],
  filter: string,
) => {
  return input.filter((inspection) => {
    if (filter === 'all') {
      return true;
    }

    return inspection.type === filter;
  });
};

const getAddType = (inspections: Array<Inspection>) => {
  if (!inspections.length) {
    return 'Routine';
  }

  return 'Post Storm';
};

const Inspections = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const match = useRouteMatch<{ id: string }>([
    "/divisions/:division_id/projects/archive/:id/inspections",
    "/divisions/:divisions_id/projects/:id/inspections",
    "/public/divisions/:divisions_id/projects/:id/inspections"
  ]);

  if (!match || !match.params.id) {
    throw new Error('URL parameter `id` is required');
  }

  const permCheck = useContext(RolesContext).userHasPermission;
  const role = useContext(AppContext).get("user").roleName
  const projectStore = useContext(ProjectContext);
  const inspections = projectStore.inspections;
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const startInspectionButtonRef = useRef<any>();
  const [busyCreating, setBusyCreating] = useState(false);
  const [filter, setFilter] = useState<string>("all");
  const { fetchStatsFuncForID, getTeamManagementData } = useContext(DashboardContext)
  const [filteredInspections, setFilteredInspections] = useState<
    typeof inspections
  >(inspections || []);
  const [hasSelectedAllInspections, setHasSelectedAllInspections] =
    useState(false);
  const [selectedInspections, setSelectedInspections] = useState<Array<number>>(
    [],
  ); // id list
  const [showCertifyModal, setShowCertifyModal] = useState(false);
  const [showSuccess, setSuccessVis] = useState(false);
  const [showSelectAllBtn, setShowSelectAllBtn] = useState(false);
  const [pageVal, setPageVal] = useState();

  const { pagination, data, query } = useResource({
    resource: 'projectInspections',
    disablePagination: false,
    projectId: projectStore.project ? projectStore.project.id : undefined,
    pageSize: pageVal,
  });

  const noInspections = useMemo(() => {
    return (
      projectStore.inspectionsQuery.isFetched &&
      isEmpty(projectStore.inspectionsQuery.data || []) &&
      isNull(projectStore.nextInspectionDate)
    );
  }, [
    projectStore.inspectionsQuery.data,
    projectStore.inspectionsQuery.isFetched,
    projectStore.nextInspectionDate,
  ]);

  /**
   * Inspections that are awaiting compliance signature
   *
   * Must be signed by inspector
   */
  const inspectionsReadyForCompliance = filteredInspections.filter(
    (inspection) =>
      inspection.compliance_date === null &&
      inspection.certification_date !== null &&
      inspection.status === "complete" &&
      findingsNotRequiredForCompliance(inspection.inspection_template, inspection.findings),
  );

  const handleSaveAnimation = useCallback(() => {
    setSelectedInspections([]);
    window.setTimeout(() => setSuccessVis(false), 2000);

    return setSuccessVis(true);
  }, [showSuccess]);

  const selectInspection = (id: number) => {
    // manage selected list
    if (selectedInspections.includes(id)) {
      setSelectedInspections(
        [...selectedInspections].filter((inspectionId) => inspectionId !== id),
      );
      setHasSelectedAllInspections(false);
    } else {
      setSelectedInspections([...selectedInspections, id]);
    }
  };

  const onAddInspection = (inspection: Inspection, redirect = true) => {
    projectStore.inspectionsQuery.refetch();

    if (redirect && inspection.id) {
      (!projectStore.project?.external_project)? goToInspection(inspection.id) : window.location.reload();
    }
  };

  const goToInspection = (inspectionId: number) => {
    history.push({
      pathname: `/inspection/${inspectionId}`,
    });
  };

  /** on load */
  useEffect(() => {
    if (location.state && location.state.checkAll && projectStore.inspections) {
      setSelectedInspections(
        projectStore.inspections.map(
          (inspection: { id: number }) => inspection.id,
        ),
      );
    }
  }, [projectStore.inspections]);

  /** watch data source and filter to set filtered inspections */
  useEffect(() => {
    if (projectStore.inspections) {
      setFilteredInspections(
        filterInspections(projectStore.inspections, filter),
      );

      if (projectStore.inspections.length > 0 && projectStore.inspections[0].inspection_template && projectStore.inspections[0].inspection_template.has_compliance_cert === true) {
        setShowSelectAllBtn(true);
      } else {
        setShowSelectAllBtn(false);
      }
    }
  }, [projectStore.inspections, filter]);

  useEffect(() => {
    if (hasSelectedAllInspections) {
      setSelectedInspections(
        inspectionsReadyForCompliance.reduce<number[]>((list, inspection) => {
          if (inspection.id) {
            list.push(inspection.id);
          }

          return list;
        }, []),
      );

      return;
    }

    if (inspectionsReadyForCompliance.length >= selectedInspections.length) {
      setSelectedInspections([]);
    }
  }, [hasSelectedAllInspections]);

  useEffect(() => {
    projectStore.inspectionsQuery.refetch();
  }, []);

  let buttonDisabledReason = '';
  const hasIncompleteInspections =
    projectStore.inspections.filter(
      (inspection: any) =>
        inspection.status !== 'complete' ||
        inspection.certification_date === null,
    ).length > 0;

  const canCreateNewInspections =
    projectStore.template && projectStore.template.name === 'CDOT'
      ? true
      : !hasIncompleteInspections;

  if (!canCreateNewInspections) {
    buttonDisabledReason =
      'Complete the existing inspections(s) before creating a new one.';
  }

  const inspectionDueToday = projectStore.inspectionDueToday();
  const restrictedRoles = ["Regulator", "Regulator - With Photos", "Certifying Regulator", "COA Regulator", "Inspector", "Supervising Engineer"]

  return (
    <section
      className={classnames('project-detail-inspections tab-pane-content', {
        'project-detail-inspections-cta-routine': noInspections,
      })}
    >
      {isArray(projectStore.inspectionsQuery.data) ? (
        <React.Fragment>
          {/** @todo move this closer to the form where it's needed...where is it needed? */}
          <FormContextProvider>
            <div
              className={classnames('tab-pane-actions', {
                'begin-inspection-schedule-actions': noInspections,
              })}
            >
              <div className='tab-pane-actions-secondary'>
                {inspectionDueToday &&
                  inspectionDueToday.status !== 'complete' &&
                  permCheck('update', 'Inspection') && (
                    <div className='action-due-today-cta'>
                      <span>You have an inspection due today</span>

                      <ReactTooltip />

                      <button
                        className='small'
                        ref={startInspectionButtonRef.current}
                        onMouseOver={() => {
                          if (!canCreateNewInspections) {
                            ReactTooltip.show(startInspectionButtonRef.current);
                          }
                        }}
                        data-tip={buttonDisabledReason}
                        onClick={
                          canCreateNewInspections
                            ? () => {
                              setBusyCreating(true);

                              return projectStore
                                .startRoutineInspection()
                                .then(inspectionId => {
                                  /** @todo rework into switch with Redirect component  */
                                  history.push(
                                    `/inspection/${inspectionId}/questionnaire`,
                                  );
                                });
                            }
                            : () => { }
                        }
                        disabled={!canCreateNewInspections || busyCreating}
                      >
                        {inspectionDueToday.status === 'new'
                          ? 'Start Inspection'
                          : 'Complete Inspection'}
                      </button>
                    </div>
                  )}

                {permCheck('update', 'Project') && (
                  <InspectionDate className='action-edit-date' />
                )}
              </div>

              <div
                className={classnames('tab-pane-actions-primary', {
                  'right-aligned': !noInspections,
                })}
              >
                {permCheck('all', 'Certify') &&
                  selectedInspections.length > 0 && (
                    <button
                      className='outline small certify-button'
                      onClick={() => setShowCertifyModal(true)}
                    >
                      Certify {selectedInspections.length} Inspection
                      {selectedInspections.length > 1 ? 's' : null}
                    </button>
                  )}

                {permCheck("all", "Certify") &&
                  !restrictedRoles.includes(role) &&
                  inspectionsReadyForCompliance.length > 0 &&
                  showSelectAllBtn &&
                  projectStore.project &&
                  projectStore.project.inspection_compliance_required === true ? (
                  <SelectAll
                    label={
                      hasSelectedAllInspections
                        ? `Deselect All Inspections`
                        : `Select All Inspections`
                    }
                    value={hasSelectedAllInspections}
                    onChange={() =>
                      setHasSelectedAllInspections(!hasSelectedAllInspections)
                    }
                  />
                ) : null}

                <div>
                  {permCheck('create', 'Inspection') &&
                    !projectPermissionContext.readOnly && (
                      <>
                        {noInspections && (
                          <p>
                            Begin the regular inspection schedule by creating
                            the first routine inspection.
                          </p>
                        )}

                        <InspectionAdd
                          disabled={!canCreateNewInspections}
                          onInspectionAdd={onAddInspection}
                          className={noInspections ? 'primary' : undefined}
                          reason={buttonDisabledReason}
                          type={getAddType(projectStore.inspections)}
                          inspectionRefetch={fetchStatsFuncForID.refetch}
                          teamManagementRefetch={getTeamManagementData.refetch}
                        />
                      </>
                    )}

                  {!permCheck('create', 'Inspection') && noInspections && (
                    <p>No inspections scheduled</p>
                  )}

                  {noInspections ? null : (
                    <InspectionFilter handleChange={setFilter} />
                  )}
                </div>
              </div>
            </div>

            {
              (projectStore.project?.external_project)? 
                <ExternalProjectsInspectionListing 
                  inspections={filteredInspections}
                  pagination={pagination}
                  data={data}
                  setPageval={setPageVal}
                  pageVal={pageVal}
                />
              :
                <InspectionListing
                  inspections={filteredInspections}
                  checkedInspections={selectedInspections}
                  handleInspectionCheck={selectInspection}
                  userCanSign={permCheck("all", "Certify") && !restrictedRoles.includes(role)}
                  isPublic={role === "Public" || role === "Public No Image"}
                  setSelectedInspections={setSelectedInspections}
                  setHasSelectedAllInspections={setHasSelectedAllInspections}
                  pagination={pagination}
                  data={data}
                  setPageval={setPageVal}
                  pageVal={pageVal}
                />
            }
            

            {projectStore.inspections.length > 0 &&
              filteredInspections.length === 0 ? (
              <div className="empty-filter">
                <p>No inspections available.</p>

                <ResetButton handleClick={() => setFilter('all')} />
              </div>
            ) : null}

            {showCertifyModal && (
              <BulkCertifyModal
                hideModal={() => setShowCertifyModal(false)}
                inspections={projectStore.inspections.filter(
                  (inspection: { id: number }) =>
                    selectedInspections.includes(inspection.id),
                )}
                handleSaveAnimation={handleSaveAnimation}
                onSubmit={() => query.refetch()}
              />
            )}
          </FormContextProvider>
          <SuccessNotification show={showSuccess} />
        </React.Fragment>
      ) : (
        <Loading what='Inspections' />
      )}
    </section>
  );
};

export default Inspections;
