import { useContext, useCallback, useEffect } from 'react';
import FormModal from '../../Shared/form/modal/FormModal';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { FormContext, FormSchemaFields } from '@sw-sw/lib-form';
import { regionApi } from '../../../utils/api/region';
import divisionApi from '../../../utils/api/division';
import reportApi from '../../../utils/api/report';
import _ from 'lodash';
import React from 'react';

const getProjectOptions = (availableClients: any, selectedClients: any) => {
  availableClients.sort((a: any, b: any) => a.name.localeCompare(b.name));
  if (selectedClients) {
    const selectedClientIds = selectedClients.map((client: any) => client.id);

    let clients = availableClients.filter((ac: any) =>
      selectedClientIds.includes(ac.id),
    );

    if (selectedClientIds.length === 1 && selectedClientIds[0] === 0) {
      clients = availableClients;
    }

    return clients.reduce(
      (acc: any, client: any) => acc.concat(client.projects),
      [],
    );
  }

  return [];
};

const getSchema = (formData: any) => {
  const formContext = useContext(FormContext);

  const divisions = formData.divisions;
  const regions = formData.regions;

  const clients = formData.clients;

  const schema: any = {
    regionId: {
      controlType: UIControlType.select,
      openOnFocus: true,
      labelKey: 'name',
      valueKey: 'id',
      label: 'Select Region',
      options: regions,
      noOptionMessage: 'No Regions found',
      placeholder: 'Search for Regions',
      validation: {
        required: true,
      },
    },
    divisionIds: {
      controlType: UIControlType.customTagSelect,
      labelKey: 'name',
      valueKey: 'id',
      isMulti: true,
      openOnFocus: true,
      showSelectAll: true,
      label: 'Select Division(s)',
      options: divisions,
      noOptionMessage: 'No Divisions found',
      showClearAll: true,
      placeholder: 'Search for Divisions',
      hideBadgesOnSelectAll: true,
      validation: {
        required: true,
      },
    },
    clients: {
      controlType: UIControlType.customTagSelect,
      labelKey: 'name',
      valueKey: 'id',
      isMulti: true,
      openOnFocus: true,
      label: 'Select Client(s)',
      options: clients,
      noOptionMessage: 'No clients found',
      placeholder: 'Search for clients',
      showSelectAll: true,
      hideBadgesOnSelectAll: true,
      showClearAll: true,
      validation: {
        required: true,
      },
    },
    projects: {
      controlType: UIControlType.customTagSelect,
      labelKey: 'name',
      valueKey: 'id',
      isMulti: true,
      openOnFocus: true,
      showSelectAll: true,
      hideBadgesOnSelectAll: true,
      label: 'Select Project(s)',
      options:
        (clients && getProjectOptions(clients, formContext.value.clients)) ||
        [],
      noOptionMessage: 'No projects found',
      placeholder: 'Search for projects',
      showClearAll: true,
      validation: {
        required: true,
      },
    },
    dateSelection: {
      label: 'Date Selection',
      controlType: UIControlType.select,
      options: [
        { id: 1, name: 'Specific Month' },
        { id: 2, name: 'Custom Range' },
      ],
      labelKey: 'name',
      valueKey: 'id',
      validation: {
        required: true,
      },
      parse: (val: any) => Number.parseInt(val, 10),
    },
  };

  if (regions && regions.length === 0) {
    delete schema.regionId;
  }

  if (formContext.value.dateSelection === 2) {
    schema.start_date = {
      controlType: UIControlType.date,
      label: 'Start Date',
      style: { flex: '0 1 50%' },
      validation: {
        maxDate: new Date(),
        required: true,
      },
    };

    schema.end_date = {
      controlType: UIControlType.date,
      label: 'End Date',
      style: { flex: '0 1 50%' },
      validation: {
        minDate: formContext.value.start_date,
        maxDate: new Date(),
        required: true,
      },
    };
  } else if (formContext.value.dateSelection === 1) {
    schema.monthYear = {
      controlType: UIControlType.date,
      isMonthYear: true,
      label: 'Month',
      style: { flex: '0 1 50%' },
      validation: {
        maxDate: new Date(),
        required: true,
      },
    };
  }

  return schema;
};

const ProjectActivityLogReportModal = ({
  setShowModal,
  setData,
  setFormData,
  formData,
  isEdit,
}: {
  setShowModal: Function;
  setData: Function;
  setFormData: Function;
  formData: any;
  isEdit: boolean;
}) => {
  const formContext = useContext(FormContext);

  useEffect(() => {
    if (!formContext.value.dateSelection) {
      formContext.set('dateSelection', 1);
      const date = new Date();

      date.setMonth(date.getMonth() - 1);
      formContext.set('monthYear', date);
    }
    if (!formContext.value.regionId) {
      regionApi.index().then((data) => {
        setFormData({
          ...formData,
          regions: data.length ? [{ id: 0, name: 'Select all' }, ...data] : [],
        });
        formContext.set('regionId', '0');
      });
    }
  }, []);

  useEffect(() => {
    if (formContext.value.regionId && formContext.value.regionId.length) {
      setDivisions(formContext.value.regionId);
    }
  }, [formContext.value.regionId]);

  useEffect(() => {
    if (formContext.value.divisionIds) {
      setClients();
    }
  }, [formContext.value.divisionIds]);

  const setDivisions = useCallback(
    (regionId) => {
      regionApi.divisions
        .index(regionId)
        .then((data) => {
          setFormData({
            ...formData,
            divisions: data.length ? data : [],
          });

          if (
            formContext.value.divisionIds &&
            formContext.value.divisionIds.length
          ) {
            const allSelected =
              formContext.value.divisionIds.length === 1 &&
              formContext.value.divisionIds[0].id === 0;

            formContext.set(
              'divisionIds',
              allSelected
                ? formContext.value.divisionIds
                : formContext.value.divisionIds.filter((c: any) =>
                    data.map((_) => _.id).includes(c.id),
                  ),
            );
          } else {
            formContext.set('divisionIds', []);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [formContext.value.regionId],
  );

  const setClients = useCallback(async () => {
    if (formContext.value.divisionIds && formContext.value.divisionIds.length) {
      let divisionIds = formContext.value.divisionIds.map((d: any) => d.id);

      if (divisionIds.length === 1 && divisionIds[0] === 0) {
        divisionIds = formData.divisions.map((d: any) => d.id);
      }

      const clients = await divisionApi.clients.getClientsForMultipleDivisions(
        divisionIds,
      );

      setFormData({
        ...formData,
        clients: clients.length ? clients : [],
      });

      if (formContext.value.clients && formContext.value.clients.length) {
        const allSelected =
          formContext.value.clients.length === 1 &&
          formContext.value.clients[0].id === 0;

        formContext.set(
          'clients',
          allSelected
            ? formContext.value.clients
            : formContext.value.clients.filter((c: any) =>
                clients.map((_: any) => _.id).includes(c.id),
              ),
        );
      } else {
        formContext.set('clients', []);
      }
    }
    formContext.setBusy(false);
  }, [formContext.value.divisionIds]);

  const submit = async () => {
    let clientIds = formContext.value.clients.map((c: any) => c.id);
    let divisionIds = formContext.value.divisionIds.map((d: any) => d.id);
    let projectIds = formContext.value.projects.map((p: any) => p.id);

    if (clientIds.length === 1 && clientIds[0] === 0) {
      clientIds = formData.clients.map((c: any) => c.id);
    }

    if (divisionIds.length === 1 && divisionIds[0] === 0) {
      divisionIds = formData.divisions.map((d: any) => d.id);
    }

    if (projectIds.length === 1 && projectIds[0] === 0) {
      projectIds = getProjectOptions(
        formData.clients,
        formContext.value.clients,
      ).map((p: any) => p.id);
    }

    let startDate = null;
    let endDate = null;

    if (formContext.value.dateSelection === 1) {
      let date = new Date(formContext.value.monthYear);

      startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      const userTimezoneOffset = date.getTimezoneOffset() * 60000;

      startDate = new Date(startDate.getTime() - userTimezoneOffset);
      endDate = new Date(endDate.getTime() - userTimezoneOffset);
    } else {
      startDate = formContext.value.start_date;
      endDate = formContext.value.end_date;
    }

    reportApi
      .getProjectActivityLogs({
        clientIds,
        divisionIds,
        minDate: startDate,
        maxDate: endDate,
        divisionId: divisionIds[0],
        projectIds,
      })
      .then((data) => {
        setData(data);
        setShowModal(false);
      });

    return Promise.resolve();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <FormModal
      onSubmit={submit}
      onCancel={handleClose}
      modalProps={{
        title: isEdit ? 'Modify Report' : 'Create Report',
        submitBtnText: 'Save',
      }}
    >
      <section className='project-form'>
        <section className='container'>
          <FormSchemaFields
            schema={getSchema(formData)}
            onChange={formContext.set}
            formData={formContext.value}
            initialFormData={formContext.value}
          />
        </section>
      </section>
    </FormModal>
  );
};

export default ProjectActivityLogReportModal;
