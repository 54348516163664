import { FormSchemaFields } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import React from "react";
import { withoutKeys } from "../../../utils";
import validator from "../../../utils/FormValidator";
import { getPositionField } from "./UserStepOne";
import ElectronicSignatureAgreementControl from "./ElectronicSignatureAgreementControl";
import DataPrivacyPolicyControl from "./DataPrivacyPolicyControl";
import { env } from '../../../config'

const schema = {
  firstName: {
    label: "First Name",
    validation: {
      required: true,
      maxLength: 100,
    },
    autoComplete: "given-name",
    style: {
      flexBasis: "50%",
    },
  },
  lastName: {
    label: "Last Name",
    validation: {
      required: true,
      maxLength: 100,
    },
    autoComplete: "family-name",
    style: {
      flexBasis: "50%",
    },
  },

  phone: {
    label: "Phone",
    mask: env.REACT_APP_REGION === "UK" ? "phoneUK" : "phone",
    validation: {
      required: env.REACT_APP_REGION === "UK" ? false : true,
      format: env.REACT_APP_REGION === "UK" ? "" : "phone",
    },
    style: {
      flexBasis: "70%",
    },
  },

  phoneExt: {
    label: "Extension",
    validation: {},
    style: {
      flexBasis: "30%",
    },
    parse: validator.parseNumber,
  },
  position: getPositionField(true),
  password: {
    label: "Password",
    controlType: UIControlType.password,
    validation: {
      required: true,
    },
  },
  electronicSignatureAgreement: {
    controlType: UIControlType.custom,
    renderControl: constrolProps => {
      return <ElectronicSignatureAgreementControl />;
    },
  },
  dataPolicySignature: {
    controlType: UIControlType.custom,
    renderControl: constrolProps => {
      return <DataPrivacyPolicyControl />;
    },
  },
};



/**
 * UI for user registration
 */
function RegisterUserForm(props) {
  return (
    <FormSchemaFields
      {...props}
      schema={schema}
      className="user-register-form"
    />
  );
}

RegisterUserForm.propTypes = {
  ...withoutKeys(FormSchemaFields.propTypes, [
    "schema",
    "className",
    "initialFormData",
  ]),
};

export default RegisterUserForm;
