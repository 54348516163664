import { SignatureLine } from "@sw-sw/lib-certification";
import { FormSchema, SchemaFieldsWithContextUI } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { get, omit } from "lodash";
import React, { useContext } from "react";
import { SignatureEditorContext } from "../../../../contexts/SignatureEditorContext";
import FormModal from "../../../Shared/form/modal/FormModal";
import { SignatureEditorFormProps } from "./SignatureEditorForm";

const inspectionLineSchema: FormSchema = {
  label: {
    label: "Label",
    controlType: UIControlType.text,
    validation: {
      required: false,
    },
  },

  roleFieldLabel: {
    label: "Role Field Label",
    controlType: UIControlType.text,
    validation: {
      required: true,
    },
  },

  userFieldLabel: {
    label: "User Field Label",
    controlType: UIControlType.text,
    validation: {
      required: true,
    },
  }
};

const acknowledgementLineSchema: FormSchema = {
  label: {
    label: "Label",
    controlType: UIControlType.text,
    validation: {
      required: false,
    },
  },

  roleFieldLabel: {
    label: "Role Field Label",
    controlType: UIControlType.text,
    validation: {
      required: true,
    },
  },

  userFieldLabel: {
    label: "User Field Label",
    controlType: UIControlType.text,
    validation: {
      required: true,
    },
  },

  defaultDateLogic: {
    label: "Date Logic",
    controlType: UIControlType.select,
    validation: {
      required: true,
    },
    options: [{
      name: "Date of Inspection",
      value: "doi" //acceptable values : doi/current
    },
    {
      name: "Current Date",
      value: "current" //acceptable values : doi/current
    }],
    labelKey: "name",
    valueKey: "value",
  },
};

const complianceLineSchema: FormSchema = {
  label: {
    label: "Label",
    controlType: UIControlType.text,
    validation: {
      required: false,
    },
  },

  roleFieldLabel: {
    label: "Role Field Label",
    controlType: UIControlType.text,
    validation: {
      required: true,
    },
  },

  userFieldLabel: {
    label: "User Field Label",
    controlType: UIControlType.text,
    validation: {
      required: true,
    },
  },

  defaultDateLogic: {
    label: "Date Logic",
    controlType: UIControlType.select,
    validation: {
      required: true,
    },
    options: [{
      name: "Date of Inspection/Date of Finding Closure",
      value: "doi" //acceptable values : doi/current
    },
    {
      name: "Current Date",
      value: "current" //acceptable values : doi/current
    }],
    labelKey: "name",
    valueKey: "value",
  },
};

type LineFormData = Omit<SignatureLine, "id" | "type">;

const LineForm: React.FC<SignatureEditorFormProps> = ({
  value,
  onSubmit: onSubmitProp,
}) => {
  const signatureEditorContext = useContext(SignatureEditorContext);

  if (!signatureEditorContext.formState) {
    console.trace(signatureEditorContext);

    throw new Error("signatureEditorContext.formState must be defined");
  }

  const initialFormData = omit(
    get(
      value.lines[signatureEditorContext.formState.groupType],
      signatureEditorContext.formState.resourceIndex,
      {
        label: "",
        roleFieldLabel: "Position",
        userFieldLabel: "Full Name",
      },
    ),
    ["id", "type"],
  );

  const onSubmit = async (formData: LineFormData) => {
    const data: typeof value = JSON.parse(JSON.stringify(value));

    if (signatureEditorContext.formState) {
      if (
        !value["lines"][signatureEditorContext.formState.groupType][
          signatureEditorContext.formState.resourceIndex
        ]
      ) {
        data["lines"][signatureEditorContext.formState.groupType].push({
          label: formData.label,
          userFieldLabel: formData.userFieldLabel,
          roleFieldLabel: formData.roleFieldLabel,
          defaultDateLogic: formData.defaultDateLogic,
          type: signatureEditorContext.formState.groupType,
        });
      } else {
        data["lines"][signatureEditorContext.formState.groupType][
          signatureEditorContext.formState.resourceIndex
        ].label = formData["label"];

        data["lines"][signatureEditorContext.formState.groupType][
          signatureEditorContext.formState.resourceIndex
        ].userFieldLabel = formData["userFieldLabel"];

        data["lines"][signatureEditorContext.formState.groupType][
          signatureEditorContext.formState.resourceIndex
        ].roleFieldLabel = formData["roleFieldLabel"];

        data["lines"][signatureEditorContext.formState.groupType][
          signatureEditorContext.formState.resourceIndex
        ].defaultDateLogic = formData["defaultDateLogic"];
      }
    }

    onSubmitProp({ ...data });
    signatureEditorContext.setModal();
  };

  return (
    <FormModal
      onCancel={() => signatureEditorContext.setModal()}
      modalProps={{
        title: `${signatureEditorContext.formState.formType} Signature Line`,
      }}
      onSubmit={onSubmit}
    >
      <SchemaFieldsWithContextUI
        schema={(signatureEditorContext.formState && signatureEditorContext.formState.groupType === "acknowledgement") ?
         acknowledgementLineSchema : (signatureEditorContext.formState && signatureEditorContext.formState.groupType === "compliance") ? complianceLineSchema : inspectionLineSchema}
        initialFormData={initialFormData}
      />
    </FormModal>
  );
};

export default LineForm;
