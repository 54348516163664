import { useContext, useEffect } from 'react';
import { QueryClient, QueryObserverOptions, useQuery } from 'react-query';
import { Inspection } from '@sw-sw/lib-inspection-templates';
import AppContext from '../contexts/AppContext';
import projectInspectionApi, {
  ProjectInspection,
} from '../utils/api/projectInspection';
import projectSiteMapInspectionApi from '../utils/api/projectSiteMapInspection';
import {
  getFindingCounts,
  InspectionFindingCount,
} from '../utils/findingCount';
import customPrintInspectionApi from '../utils/api/custommPrintInspection';

export type InspectionWithFindingCount = ProjectInspection & {
  findingCount: InspectionFindingCount;
  commentables?: any;
  inspectionNumber?: number;
};

function getQueryKey(projectId: number) {
  return ['project', 'inspections', projectId];
}

export function addFindingCount(
  inspection: ProjectInspection,
): InspectionWithFindingCount {
  return { ...inspection, findingCount: getFindingCounts(inspection) };
}

export function useProjectInspections(
  projectId: number,
  queryOptions: Omit<
    QueryObserverOptions<InspectionWithFindingCount[]>,
    'queryKey' | 'queryFn' | 'enabled' | 'keepPreviousData'
  > = {},
  page?: number,
  pageSize?: number,
  isMap?: boolean
) {
  const appContext = useContext(AppContext);
  const query = useQuery<InspectionWithFindingCount[]>({
    queryKey: getQueryKey(projectId),
    queryFn: () =>
      appContext.loadData(async () => {
        if (projectId === 0) {
          return [];
        }
        if (isMap) {
          const data: any = projectId ? await projectInspectionApi.indexIds(projectId) : []

          return data
        }


        const data: any = projectId
          ? await projectInspectionApi.index(projectId, page, pageSize)
          : [];

        return data.pageData.map(addFindingCount);
      }),
    enabled: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...queryOptions,
  });

  useEffect(() => {
    query.refetch();
  }, [projectId]);

  return query;
}

export function customPrintInspections(
  projectId: number,
  queryOptions: Omit<
    QueryObserverOptions<InspectionWithFindingCount[]>,
    'queryKey' | 'queryFn' | 'enabled' | 'keepPreviousData'
  > = {},
) {
  const appContext = useContext(AppContext);
  const query = useQuery<InspectionWithFindingCount[]>({
    queryFn: () => {
      return appContext.loadData(async () => {
        if (projectId === 0) {
          return [];
        }

        const data: any = projectId
          ? await customPrintInspectionApi.index(projectId)
          : [];

        return data;
      })
    },
    enabled: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...queryOptions
  });

  useEffect(() => {
    query.refetch();
  }, [projectId]);

  return query;
}

export function useProjectSiteMapInspections(
  projectId: number,
  siteMapId: number,
  queryOptions: Omit<
    QueryObserverOptions<Inspection[]>,
    'queryKey' | 'queryFn' | 'enabled' | 'keepPreviousData'
  > = {},
) {
  const appContext = useContext(AppContext);
  const query = useQuery<Inspection[]>({
    queryKey: ['project', 'inspections', projectId, siteMapId],
    queryFn: () =>
      appContext.loadData(async () => {
        if (projectId === 0) {
          return [];
        }

        if (!projectId) {
          return [];
        }

        const data: Inspection[] = siteMapId
          ? await projectSiteMapInspectionApi.index(projectId, siteMapId)
          : await projectSiteMapInspectionApi.show(projectId);

        return data;
      }),
    enabled: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...queryOptions,
  });

  useEffect(() => {
    query.refetch();
  }, [projectId, siteMapId]);

  return query;
}
export function setProjectInspectionsQueryData({
  projectId,
  data,
  queryClient,
}: {
  projectId: number;
  data: ProjectInspection[];
  queryClient: QueryClient;
}): void {
  const key = getQueryKey(projectId);

  queryClient.setQueryData<InspectionWithFindingCount[]>(
    key,
    data.map(addFindingCount),
  );
  queryClient.invalidateQueries(key);
}