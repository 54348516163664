import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormContext } from "@sw-sw/lib-form";
import { get } from "lodash";
import React, { useContext, useState } from "react";
import ElectronicSignatureConsentModal from "../../Shared/ElectronicSignatureConsentModal";

const ElectronicSignatureAgreementControl: React.FC<{}> = () => {
  const formContext = useContext(FormContext);
  const [showConsentModal, setShowConsentModal] = useState<boolean>(false);

  return (
    <div className="electronic-signature-control-register">
      <input
        type="checkbox"
        checked={get(formContext.value, "electronicSignatureAuthorization")}
        onChange={() =>
          formContext.set(
            "electronicSignatureAuthorization",
            !get(formContext.value, "electronicSignatureAuthorization"),
          )
        }
      />
      <p>
        I consent to the use of electronic signatures.{" "}
        <FontAwesomeIcon
          className="modal-icon"
          icon={faQuestionCircle}
          onClick={() => setShowConsentModal(true)}
        />
      </p>
      {showConsentModal && (
        <ElectronicSignatureConsentModal
          show={showConsentModal}
          handleClose={() => setShowConsentModal(false)}
        />
      )}
    </div>
  );
};

export default ElectronicSignatureAgreementControl;
