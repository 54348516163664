import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { customDashboard } from '../../utils/api/dashboard';
import { AppDivisionContext } from '../../contexts/AppDivisionContext';
import { Typography, Checkbox, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@sw-sw/lib-ui';
import AppContext from '../../contexts/AppContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DashboardContext from '../../contexts/DashboardContext';
import GridItem from './GridItem';

export const CustomDashboardLite = () => {
  return (
      <CustomDashboard />
  );
};

const createOptionList = (
  optionData: any,
  options: any[] = [],
  isCustom = false,
) => {
  const OptionArray: any[] = [];

  if (isCustom) {
    optionData.forEach((option: string) => {
      OptionArray.push({ option: option, isEnable: options.includes(option) });
    });

    return OptionArray;
  }
  optionData.forEach((option: string) => {
    OptionArray.push({ option: option, isEnable: true });
  });

  return OptionArray;
};

const CustomDashboard = () => {
  const { getPath } = useContext(AppDivisionContext);
  const { list, setList, dashboardWidgetSizeHolder, setDashboardWidgetSizeHolder } = useContext(DashboardContext);
  const {
    state: { user },
  } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [drag, setDrag] = useState<boolean>(false);
  const { data, isLoading } = useQuery('customdashboard', () =>
    customDashboard.fetch(getPath('/customdashboard')),
  );

  const updateDashboardWidgetSize = (tempWidgetList: any) => {
    if(!!localStorage.getItem("widgetSizeHolder") === false){
      let tempArr: any = {};

      tempWidgetList.map((item: any) => {
        tempArr[item] = dashboardWidgetSizeHolder[item] ? dashboardWidgetSizeHolder[item] : 6;
      });

      setDashboardWidgetSizeHolder(tempArr);
      localStorage.setItem("widgetSizeHolder", JSON.stringify(tempArr))
    }
  }

  const onCheck = (ele: any) => {
    const newOption = options.map((option) => {
      if (ele.option === option.option) {
        option.isEnable = !option.isEnable;

        return option;
      }

      return option;
    });

    setOptions(newOption);
  };

  const onSave = async () => {
    localStorage.setItem("hasChanged", "false")
    let newArr: any = list;

    options.forEach((ele: { option: any; isEnable: boolean }) => {
      if (ele.isEnable && !list.includes(ele.option)) {
        newArr.push(ele.option);
      } else if (!ele.isEnable && list.includes(ele.option)) {
        const filteredArr = newArr.filter((value: any) => {
          return value !== ele.option;
        });

        newArr = filteredArr;
      }
    });

    customDashboard
      .post(getPath('/customdashboard1'), { widgets: newArr })
      .then((res: any) => {
        setList(res.widgets);
        updateDashboardWidgetSize(res.widgets);
      });
    setOpen(false);
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);
    setList(items);
    updateDashboardWidgetSize(items);
    setDrag(true);
  };

  const onDragSave = () => {
    customDashboard.post(getPath('/customdashboard1'), { widgets: list });
    setDrag(false);
  };

  useEffect(() => {
    if (!user.custom_dashboard && !isLoading) {
      const optionArr: any[] = createOptionList(data);

      setOptions(optionArr);
      setList(data);
      updateDashboardWidgetSize(data);
    }
    if (user.custom_dashboard && !isLoading) {
      customDashboard.fetch(getPath('/customdashboard1')).then((res) => {
        const optionArr = createOptionList(data, res, true);

        setList(res);
        updateDashboardWidgetSize(res);
        setOptions(optionArr);
      });
    }
  }, [isLoading]);


  return (
    <div className='custom-dashboard'>
      <div className='custom-dashboard__menu'>
        <div
          className='custom-dashboard__cog-wrapper'
          onClick={() => setOpen(!open)}
        >
          <FontAwesomeIcon icon={faCog} className='pad-left' />
        </div>

        <div
          className='custom-dashboard__drawer-wrapper'
          style={{ display: open ? 'block' : 'none' }}
        >
          <div className='custom-dashboard__drawer'>
            <p>Widgets Setting</p>
            <hr />
            <div className='custom-dashboard__widget-list'>
              {options.length
                ? options.map((ele: any) => (
                  <div className='custom-dashboard__widget-list-item'>
                    <Checkbox
                      onChange={() => onCheck(ele)}
                      checked={ele.isEnable}
                    />
                    <Typography variant='h6'>{ele.option}</Typography>
                  </div>
                ))
                : null}
            </div>
            <Button onClick={onSave}>Save</Button>
          </div>
        </div>
      </div>

      {drag ? <Button className='custom-dashboard__drag-save-button-top' onClick={onDragSave}>Save</Button> : null}

      {/* custom-dashboard__grid-ul             ---- add this class for custom grid on ul*/}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId='characters'>
          {(provided) => (
            <ul
              className='characters '
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ marginLeft: '5px', marginRight: '5px' }}
            >
              <Grid container spacing={2}>
                {list.length === 1 ? 
                  list.map((ele: any, index: number) => {
                    return (
                      <Draggable
                        key={index}
                        draggableId={`${index}`}
                        index={index}
                      >
                        {(provide) => (
                          <GridItem provide={provide} ele={ele} index={index} showMore={true} />
                        )}
                      </Draggable>
                    );
                  })
                :list.map((ele: any, index: number) => {
                  return (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(provide) => (
                        <GridItem provide={provide} ele={ele} index={index} showMore={false} />
                      )}
                    </Draggable>
                  );
                })}
              </Grid>
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      {/* <CertDueWidget /> */}
      {drag ? <Button onClick={onDragSave} className='custom-dashboard__drag-save-button-bottom'>Save</Button> : null}
    </div>
  );
};
