import { VersionContext } from "@sw-sw/lib-ui";
import React, { PropsWithChildren, useState } from "react";

export const VersionContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  /**
   * This context helps control the date that is used for versioning images
   */
  const [date, setDate] = useState<number>(Date.now());

  const resetDate = () => {
    setDate(Date.now());
  };

  return (
    <VersionContext.Provider
      value={{
        date,
        resetDate,
      }}
    >
      {children}
    </VersionContext.Provider>
  );
};
