import React from "react";
import ReactDOM from "react-dom";
import { oneOfType, number, string, func } from "prop-types";

import ContextMenu from "../../../../Shared/ContextMenu";

/**
 * UI Only for source model's context menu
 */
function SourceModelMenuUI({ onEdit, onDelete, left, top }) {
  const menuProps = {
    left,
    top,
    options: [
      {
        label: "Edit",
        onClick: () => {
          onEdit();
        },
      },
      {
        label: "Delete",
        onClick: () => {
          onDelete();
        },
      },
    ],
  };

  const Menu = <ContextMenu {...menuProps} />;

  return ReactDOM.createPortal(Menu, document.querySelector("#modal"));
}

SourceModelMenuUI.propTypes = {
  left: oneOfType([number, string]).isRequired,
  top: number.isRequired,
  onEdit: func.isRequired,
  onDelete: func.isRequired,
};

export default SourceModelMenuUI;
