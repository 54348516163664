import React from 'react'
import { toNumber } from 'lodash';

import Select from '../../Fields/Select';

const getOptions = fillType => {
	switch (fillType) {
		case 'hatch':
			return [
				{value: 0, text: 'Normal'},
				{value: 45, text: 'Diagonal'},
			];
		default:
			return [
				{value: 0, text: 'Vertical'},
				{value: 90, text: 'Horizontal'},
				{value: 45, text: 'To Top'},
				{value: -45, text: 'To Bottom'},
			];
	}
};

function LineDirection({fillType, ...props}) {
	return <Select label="Direction" options={getOptions(fillType)} {...props} cast={toNumber} />
}

export default LineDirection;
