import { useContext } from "react";

import { MapContextType } from "@sw-sw/common";

import usePositionableSourceModel from "./usePositionableSourceModel";

/**
 * Get or create the map layer of active source model
 */
function usePositionableLayer() {
  const sourceModel = usePositionableSourceModel();
  const mapContext = useContext(MapContextType);

  if (sourceModel) {
    return mapContext.getLayer(sourceModel.positionableType, sourceModel);
  }

  return null;
}

export default usePositionableLayer;
