import React, { useState, createRef, Fragment } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  string,
  bool,
  arrayOf,
  func,
  shape,
  number,
  any,
  object,
} from "prop-types";
import { upperFirst, map, pick, kebabCase, last } from "lodash";
import classnames from "classnames";

import Popper from "../../Shared/Popper/Popper";

function TableHeader({ tableKeys, showCTA }) {
  return (
    <thead className="list-header">
      <tr>
        {tableKeys.map(key => {
          return (
            <th key={key} className={kebabCase(key)}>
              {upperFirst(key.replace(/([A-Z])/g, " $1"))}
            </th>
          );
        })}

        {showCTA && <th key="actions" aria-label="Actions" />}
      </tr>
    </thead>
  );
}
TableHeader.propTypes = {
  tableKeys: arrayOf(string).isRequired,
};

function TableBody(props) {
  const [ctaRow, setCtaRow] = useState({});
  const [coordinates, setCoordinates] = useState({ top: null, right: null });
  const [projectId, setProjectId] = useState();
  const [showMenu, setShowMenu] = useState(false);

  function handlePopperClick(targetRef, row) {
    const { right, top } = targetRef.current.getBoundingClientRect();

    setCoordinates({
      right: window.innerWidth - right,
      top,
    });
    setProjectId(row.projectId);
    setCtaRow(row);
    setShowMenu(true);
  }

  function renderCTACell(data) {
    const targetRef = createRef();
    const ctaText = props.getCtaText(data);

    return (
      <td className="right">
        {ctaText ? (
          <Link to={props.getCtaRoute(data)}>
            <button
              className={classnames("table-button cta-button", {
                "cta-hider": props.dynamicCta,
              })}
              onClick={props.onCtaClick ? () => props.onCtaClick(data) : null}
            >
              {ctaText}
            </button>
          </Link>
        ) : null}

        {props.showPopper && (
          <button
            onClick={() => handlePopperClick(targetRef, data)}
            ref={targetRef}
            className="table-button"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </button>
        )}
      </td>
    );
  }

  return (
    <Fragment>
      {props.tableData.map((data, i) => {
        return (
          <tr key={i}>
            {map(pick(data, props.tableKeys), (val, key, row) => {
              const handleClick = props.getClickHandler[key];

              return (
                <Fragment key={key}>
                  <td
                    className={classnames(kebabCase(key), {
                      clickable: !!handleClick,
                    })}
                    onClick={() =>
                      typeof handleClick === "function"
                        ? handleClick(data)
                        : null
                    }
                  >
                    {val}
                  </td>

                  {last(Object.keys(row)) === key && props.showCTA
                    ? renderCTACell(data)
                    : null}
                </Fragment>
              );
            })}
          </tr>
        );
      })}

      {showMenu && (
        <Popper
          coordinates={coordinates}
          handleClose={() => setShowMenu(false)}
          classes="table-popper"
        >
          <ul>
            <Link
              to={`projects/${projectId}/${props.popperRoute}`}
              className="unStyled-link"
            >
              <li>View Project</li>
            </Link>

            {props.getMenuOptions && props.getMenuOptions(ctaRow)}

            {props.dynamicCta && (
              <Link to={props.getCtaRoute(ctaRow)} className="unStyled-link">
                <li className="popper-cta">{props.getCtaText(ctaRow)}</li>
              </Link>
            )}
          </ul>
        </Popper>
      )}
    </Fragment>
  );
}

const DashTable = props => {
  return (
    <section className="table-holder">
      <table className="list-view pure-table pure-table-horizontal">
        {props.tableData.length === 0 && props.hideHeaderOnEmpty ? null : (
          <TableHeader {...props} />
        )}
        <tbody>
          {props.tableData.length === 0 ? (
            <tr>
              <td colSpan={props.tableKeys.length + 2}>{props.emptyMessage}</td>
            </tr>
          ) : (
            <TableBody {...props} />
          )}
        </tbody>
      </table>
    </section>
  );
};

DashTable.propTypes = {
  tableKeys: arrayOf(string).isRequired,
  getClickHandler: object,
  tableData: arrayOf(
    shape({
      projectId: number.isRequired,
    }),
  ).isRequired,
  menuOptions: arrayOf(any),
  popperRoute: string,
  getCtaRoute: func.isRequired,
  getCtaText: func.isRequired,
  showPopper: bool,
  dynamicCta: bool,
  onCtaClick: func,
  emptyMessage: string.isRequired,
  hideHeaderOnEmpty: bool,
};

DashTable.defaultProps = {
  menuOptions: [],
  popperRoute: "inspections",
  showPopper: true,
  dynamicCta: false,
  onCtaClick: undefined,
  getClickHandler: {},
  getCtaRoute: () => "#",
  emptyMessage: "No data available",
  showCTA: true,
};

export default DashTable;
