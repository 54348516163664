import { Modal } from "@sw-sw/lib-ui";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { questionApi } from "../../../../utils/api/question";

export interface IQuestionDeleteProps {
  questionId?: number;
  groupId: number;
  show: boolean;
  onClose: () => void;
}

const QuestionDelete: React.FC<IQuestionDeleteProps> = ({
  questionId,
  groupId,
  show,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const [disabled, setDisabled] = useState<boolean>(true);

  const handleSubmit = () => {
    if (questionId) {
      return questionApi.delete(questionId).then(() => {
        const questionGroup: any = queryClient.getQueryData([
          "questionGroups",
          groupId,
        ]);
        const questions = questionGroup.questions;
        const index = questionGroup.questions.findIndex(
          (_: { id: number }) => _.id === questionId,
        );

        questions.splice(index, 1);

        queryClient.setQueryData(["questionGroups", groupId], {
          ...questionGroup,
          questions: [...questions],
        });

        onClose();
      });
    }

    return;
  };

  const customButtons = (
    <div className="buttons">
      <button className="reversed outline" type="button" onClick={onClose}>
        Cancel
      </button>
      &nbsp;
      <button
        className="warn reversed"
        disabled={disabled}
        onClick={handleSubmit}
      >
        Delete
      </button>
    </div>
  );

  const toggleDisabled = () => {
    setDisabled(!disabled);
  };

  return (
    <Modal
      show={show}
      title="Delete Question"
      handleClose={onClose}
      hideButtons={true}
    >
      <p>Are you sure that you want to delete this question?</p>
      <div className="removal-confirmation">
        <input
          type="checkbox"
          id="proceed"
          name="proceed"
          onClick={toggleDisabled}
        />
        <label>Yes, I understand</label>
      </div>
      {customButtons}
    </Modal>
  );
};

export default QuestionDelete;
