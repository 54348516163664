import React, { useContext } from "react";
import AppContext from "../../../../contexts/AppContext";
import RolesContext from "../../../../contexts/RolesContext";
import { commentableToString, ICommentable } from "../../../../utils/notes";
import { FormHelpText } from "@sw-sw/lib-form";
import TinyIconButton from "../../../Shared/TinyIconButton";

function ObservationsList({
  data,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
  noteType = "observation",
  readOnly,
}: {
  data: Array<ICommentable>;
  canEdit: Boolean;
  canDelete: Boolean;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  noteType?: string;
  readOnly?: false;
}) {
  // only render when list of observations is set
  if (!Array.isArray(data)) {
    return null;
  }

  const appStore = useContext(AppContext);
  const permCheck = useContext(RolesContext).userHasPermission;
  const userId = appStore.get("user.id");

  return (
    <div className="observations-list">
      {data.map((val, i) => {
        const isOwner = userId === val.author.id;

        return (
          <div className="observations-list-item" key={i}>
            <FormHelpText content={commentableToString(val)} theme="info" />
            {/**
             * enable editing and deleting
             * based on permission *or* ownership of observation
             */}

            {((canEdit && isOwner) ||
              permCheck("all", "adminInspectionNotes")) &&
            !readOnly ? (
              <TinyIconButton
                iconName="pencil"
                title={`Edit ${noteType}`}
                onClick={onEdit.bind(null, val)}
                className=""
              />
            ) : null}
            {((canDelete && isOwner) ||
              permCheck("all", "adminInspectionNotes")) &&
            !readOnly ? (
              <TinyIconButton
                iconName="trash"
                title={`Delete ${noteType}`}
                onClick={onDelete.bind(null, val)}
                className=""
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default ObservationsList;
