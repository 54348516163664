import { useEffect, useState, useContext } from 'react';
import { QueryObserverOptions, useQuery } from 'react-query';
import { ProjectInspection } from '../utils/api/projectInspection';
import inspectionsCertificationDue from '../utils/api/inspectionsCertificationDue';
import { InspectionFindingCount } from '../utils/findingCount';
import { customDashboard } from '../utils/api/dashboard';
import AppDivisionContext from '../contexts/AppDivisionContext';

export type InspectionWithFindingCount = ProjectInspection & {
  findingCount: InspectionFindingCount;
};

// get all certification due list
export function useInspectionsCertificationDue(
  queryParams: any,
  divisionId: number,
  loadData: boolean,
  queryOptions: Omit<
    QueryObserverOptions<InspectionWithFindingCount[]>,
    'queryKey' | 'queryFn' | 'enabled' | 'keepPreviousData'
  > = {},
) {

  const query = useQuery<any[]>({
    queryKey: ['Inspections', 'CertificationDue', 'All'],
    queryFn: async () => {
      const data = loadData ? await inspectionsCertificationDue.index(queryParams, divisionId) : [];

      return data;
    },
    enabled: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...queryOptions,
  });

  useEffect(() => {
    query.refetch();
  }, [queryParams, divisionId, loadData]);

  return query;
}

// inspection certification
export function useFetchListForInspectionCert(list:any) {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [ projectsListForInpectionCert, setProjectsListForInpectionCert] = useState([]);
  const [isLoadingForInspectionCert, setIsLoadingForInspectionCert] = useState(true);
  const [ searchQueryForInspectionCert, setSearchQueryForInspectionCert ] = useState({})
  const fetchFuncForInspectionCert = useQuery({
    queryKey: ['projectsListForInpectionCert'],
    queryFn: () => {
      if(list.includes("Certifications Due")){
        return customDashboard.fetchWithParams(`/divisions/${appDivisionId}/getInspectionCert`, searchQueryForInspectionCert)
        .then(res => { return res.data })
      }
      else{
        return []
      }
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  })

  useEffect(() => {
    if (!appDivisionId) return

    fetchFuncForInspectionCert.refetch();
  }, [appDivisionId, list.includes("Certifications Due")]);

  useEffect(() => {
    fetchFuncForInspectionCert.data ? setProjectsListForInpectionCert(fetchFuncForInspectionCert && fetchFuncForInspectionCert.data) : setProjectsListForInpectionCert([])
  },[fetchFuncForInspectionCert && fetchFuncForInspectionCert.data])

  useEffect(() => {
    setIsLoadingForInspectionCert(fetchFuncForInspectionCert && fetchFuncForInspectionCert.isFetching)
  },[fetchFuncForInspectionCert])

  return [
    isLoadingForInspectionCert,
    projectsListForInpectionCert,
    setProjectsListForInpectionCert,
    setSearchQueryForInspectionCert,
    fetchFuncForInspectionCert
  ];
}

// acknowledgement certification
export function useFetchListForAcknowledgementCert(list:any) {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [ projectsListForAcknowledgementCert, setProjectsListForAcknowledgementCert] = useState<any>([]);
  const [isLoadingForAcknowledgementCert, setIsLoadingForAcknowledgementCert] = useState(true);
  const [ searchQueryForAcknowledgementCert, setSearchQueryForAcknowledgementCert ] = useState({})
  const fetchFuncForAcknowledgementCert = useQuery({
    queryKey: ['projectsListForAcknowledgementCert'],
    queryFn: () => {
      if(list.includes("Certifications Due")){
        return customDashboard.fetchWithParams(`/divisions/${appDivisionId}/getAcknowledgementCert`, searchQueryForAcknowledgementCert)
        .then(res => { return res.data })
      }
      else{
        return []
      }
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  })

  useEffect(() => {
    if (!appDivisionId) return

    fetchFuncForAcknowledgementCert.refetch();
  }, [appDivisionId, list.includes("Certifications Due"), searchQueryForAcknowledgementCert]);

  useEffect(() => {
    fetchFuncForAcknowledgementCert.data ? setProjectsListForAcknowledgementCert(fetchFuncForAcknowledgementCert && fetchFuncForAcknowledgementCert.data) : setProjectsListForAcknowledgementCert([])
  },[fetchFuncForAcknowledgementCert && fetchFuncForAcknowledgementCert.data])

  useEffect(() => {
    setIsLoadingForAcknowledgementCert(fetchFuncForAcknowledgementCert && fetchFuncForAcknowledgementCert.isFetching)
  },[fetchFuncForAcknowledgementCert])

  return [
    isLoadingForAcknowledgementCert,
    projectsListForAcknowledgementCert,
    setProjectsListForAcknowledgementCert,
    setSearchQueryForAcknowledgementCert,
    fetchFuncForAcknowledgementCert
  ];
}


// open Findings widget
export function useOpenFindingsData(list:any) {
  const [displayStatsForOF, setDisplayStatsForOF] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [allProjectsForOF, setAllProjectsForOF] = useState([]);
  const [isLoadingForOF, setIsLoadingForOF] = useState(true);
  const fetchStatsFuncForOF = useQuery({
    queryKey: ['displayStatsForOF'],
    queryFn: () => {
      if(list.includes("Open Findings")){
        return customDashboard.fetch(`/divisions/${appDivisionId}/openFindings/getOpenFindingStats`)
        .then(res => { return res.data })
      }
      else{
        return []
      }
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  })

  useEffect(() => {
    if (!appDivisionId) return

    fetchStatsFuncForOF.refetch();
  }, [appDivisionId, list.includes("Open Findings")]);

  useEffect(() => {
    setDisplayStatsForOF(fetchStatsFuncForOF && fetchStatsFuncForOF.data && fetchStatsFuncForOF.data.statistics)
    setAllProjectsForOF(fetchStatsFuncForOF && fetchStatsFuncForOF.data && fetchStatsFuncForOF.data.statistics)
  },[fetchStatsFuncForOF && fetchStatsFuncForOF.data])

  useEffect(() => {
    setIsLoadingForOF(fetchStatsFuncForOF && fetchStatsFuncForOF.isFetching)
  },[fetchStatsFuncForOF])

  return [
    displayStatsForOF,
    allProjectsForOF,
    isLoadingForOF,
    setDisplayStatsForOF,
    setIsLoadingForOF,
    fetchStatsFuncForOF
  ];
}

export function useFetchAllFindingTypes(list:any) {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [findingTypeListForOF, setFindingTypeListForOF] = useState([]);
  const queryFindingTypesForOF = useQuery({
    queryKey: ['findingTypeListForOF'],
    queryFn: () => { 
      if(list.includes("Open Findings")){
        return customDashboard.fetch(`/divisions/${appDivisionId}/openFindings/fetchFindingTypesUsed`)
        .then(res => { return res })
      }
      else{
        return []
      }
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  })

  useEffect(() => {
    if (!appDivisionId) return

    queryFindingTypesForOF.refetch();
  }, [appDivisionId, list.includes("Open Findings")]);

  useEffect(() => {
    setFindingTypeListForOF(queryFindingTypesForOF.data);
  },[queryFindingTypesForOF && queryFindingTypesForOF.data])

  return [findingTypeListForOF];
}

// team management widget
export function useTeamManagement(list:any) {
  const [teamManagementData, setTeamManagementData] = useState(null);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [isLoadingForTM, setIsLoadingForTM] = useState(true);
  const getTeamManagementData = useQuery({
    queryKey: ['displayStatsForTM'],
    queryFn: () => list.includes("Team Management") ? customDashboard.fetch(`/divisions/${appDivisionId}/getTeamManagementData`) : [],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  })

  useEffect(() => {
    if (!appDivisionId) return

    getTeamManagementData.refetch();
  }, [appDivisionId, list.includes("Team Management")]);

  useEffect(() => {
    setTeamManagementData(getTeamManagementData && getTeamManagementData.data && getTeamManagementData.data.data)
  },[getTeamManagementData && getTeamManagementData.data])

  useEffect(() => {
    setIsLoadingForTM(getTeamManagementData && getTeamManagementData.isFetching)
  },[getTeamManagementData])

  return [
    getTeamManagementData,
    teamManagementData,
    isLoadingForTM,
    setIsLoadingForTM,
  ];
}

// My Projects Widget

export function useMyProjectsData(list:any) {
  const [displayStatsForMP, setDisplayStatsForMP] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [allProjectsForMP, setAllProjectsForMP] = useState([]);
  const [isLoadingForMP, setIsLoadingForMP] = useState(true);
  const fetchStatsFuncForMP = useQuery({
    queryKey: ['displayStatsForMP'],
    queryFn: () => list.includes("My Projects") ? customDashboard.fetch(`/divisions/${appDivisionId}/myProjectsList/getMyProjectsListStats`) : [],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  })

  useEffect(() => {
    if (!appDivisionId) return

    fetchStatsFuncForMP.refetch();
  }, [appDivisionId, list.includes("My Projects")]);

  useEffect(() => {
    setDisplayStatsForMP(fetchStatsFuncForMP && fetchStatsFuncForMP.data)
    setAllProjectsForMP(fetchStatsFuncForMP && fetchStatsFuncForMP.data)
  },[fetchStatsFuncForMP && fetchStatsFuncForMP.data])

  useEffect(() => {
    setIsLoadingForMP(fetchStatsFuncForMP && fetchStatsFuncForMP.isFetching)
  },[fetchStatsFuncForMP])

  return [
    displayStatsForMP,
    allProjectsForMP,
    isLoadingForMP,
    setDisplayStatsForMP,
    setIsLoadingForMP,
    fetchStatsFuncForMP
  ];
}

  //Inspections Due
export function useInspectionsDueData(list:any) {
  const [displayStatsForID, setDisplayStatsForID] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [allProjectsForID, setAllProjectsForID] = useState([]);
  const [isLoadingForID, setIsLoadingForID] = useState(true);
  const fetchStatsFuncForID = useQuery({
    queryKey: ['displayStatsForID'],
    queryFn: () => list.includes("My Projects") ? customDashboard.fetch(`/divisions/${appDivisionId}/inspectionsDue/getInspectionsDueStats`) : [],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  })

  useEffect(() => {
    if (!appDivisionId) return

    fetchStatsFuncForID.refetch();
  }, [appDivisionId, list.includes("My Projects")]);

  useEffect(() => {
    setDisplayStatsForID(fetchStatsFuncForID && fetchStatsFuncForID.data && fetchStatsFuncForID.data.data && fetchStatsFuncForID.data.data.inspectionsDue)
    setAllProjectsForID(fetchStatsFuncForID && fetchStatsFuncForID.data && fetchStatsFuncForID.data.data && fetchStatsFuncForID.data.data.inspectionsDue)
  },[fetchStatsFuncForID && fetchStatsFuncForID.data])

  useEffect(() => {
    setIsLoadingForID(fetchStatsFuncForID && fetchStatsFuncForID.isFetching)
  },[fetchStatsFuncForID])

  return [
    displayStatsForID,
    allProjectsForID,
    isLoadingForID,
    setDisplayStatsForID,
    setIsLoadingForID,
    fetchStatsFuncForID
  ];
}


// Findings Overview Widget

export function useFindingsOverviewData(list:any) {
  const [displayStatsForFO, setDisplayStatsForFO] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [allProjectsForFO, setAllProjectsForFO] = useState([]);
  const [isLoadingForFO, setIsLoadingForFO] = useState(true);
  const fetchStatsFuncForFO = useQuery({
    queryKey: ['statsFO'],
    queryFn: () => list.includes("Findings Overview") ? customDashboard.fetch(`/divisions/${appDivisionId}/findingsOverview/getStats`) : [],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  })

  useEffect(() => {
    if (!appDivisionId) return

    fetchStatsFuncForFO.refetch();
  }, [appDivisionId, list.includes("Findings Overview")]);

  useEffect(() => {
    setDisplayStatsForFO(fetchStatsFuncForFO && fetchStatsFuncForFO.data && fetchStatsFuncForFO.data.data && fetchStatsFuncForFO.data.data.statistics)
    setAllProjectsForFO(fetchStatsFuncForFO && fetchStatsFuncForFO.data && fetchStatsFuncForFO.data.data && fetchStatsFuncForFO.data.data.statistics)
  },[fetchStatsFuncForFO && fetchStatsFuncForFO.data])

  useEffect(() => {
    setIsLoadingForFO(fetchStatsFuncForFO && fetchStatsFuncForFO.isFetching)
  },[fetchStatsFuncForFO])

  return [
    displayStatsForFO,
    allProjectsForFO,
    isLoadingForFO,
    setDisplayStatsForFO,
    setIsLoadingForFO,
  ];
}

export function useFetchAllFindingTypesForFO(list:any) {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [findingTypeListForFO, setFindingTypeListForFO] = useState([]);
  const queryFindingTypesForFO = useQuery({
    queryKey: ['findingTypeListForFO'],
    queryFn: () => { 
      if(list.includes("Findings Overview")){
        return customDashboard.fetch(`/divisions/${appDivisionId}/findingsOverview/fetchFindingTypesUsed`)
        .then(res => { return res })
      }
      else{
        return []
      }
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  })

  useEffect(() => {
    if (!appDivisionId) return

    queryFindingTypesForFO.refetch();
  }, [appDivisionId, list.includes("Findings Overview")]);

  useEffect(() => {
    setFindingTypeListForFO(queryFindingTypesForFO.data);
  },[queryFindingTypesForFO && queryFindingTypesForFO.data])

  return [findingTypeListForFO];
}

// Calendar Widget

export function useFetchProjectsListForCal(list:any) {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [ projectsListForCal, setProjectsListForCal] = useState([]);
  const fetchStatsFuncForCal = useQuery({
    queryKey: ['projectsListForCal'],
    queryFn: () => { 
      if(list.includes("Calendar")){
        return customDashboard.fetch(`/divisions/${appDivisionId}/calendar/fetchAllUserProjects`)
        .then(res => { return res })
      }
      else{
        return []
      }
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  })

  useEffect(() => {
    if (!appDivisionId) return

    fetchStatsFuncForCal.refetch();
  }, [appDivisionId, list.includes("Calendar")]);

  useEffect(() => {
    setProjectsListForCal(fetchStatsFuncForCal.data);
  },[fetchStatsFuncForCal && fetchStatsFuncForCal.data])

  return [projectsListForCal];
}
