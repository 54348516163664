// parse a YMD string into a date the local timezone
// have to add the timezone offset, otherwise date picker will show the wrong date
import moment from "moment";

export const parseYMD = ymdStr => {
  const d = new Date(ymdStr);

  return new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours() + d.getTimezoneOffset() / 60,
  )
};

export const parseMDYformat = ymdStr => {
  const [year, month, day] = ymdStr.split('-').map(Number);
  const date = new Date(year, month - 1, day); // Month is zero-indexed

  const formattedDate = [
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getDate()).padStart(2, '0'),
    date.getFullYear(),
  ].join('-');

  return formattedDate;
};


moment.fn.roundNext15Min = function () {
  let intervals = Math.floor(this.minutes() / 15);

  if (this.minutes() % 15 !== 0) {
    intervals++;
    if (intervals === 4) {
      this.add(1, "hours");
      intervals = 0;
    }
    this.minutes(intervals * 15);

    return this;
  }
};