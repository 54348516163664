
import React, { Fragment, useContext, useState } from "react";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import ClientContext, { ClientStore } from "../../contexts/ClientContext";
import RolesContext from "../../contexts/RolesContext";
import clientApi from "../../utils/api/client";
import projectApi from "../../utils/api/project";
import AddContactModal from "../Contacts/AddContactModal";
import ProjectForm from "../Projects/Forms/ProjectForm";
import { extractInitialData } from "../Projects/Forms/projectFormData";
import ConfirmationModal from "../Shared/ConfirmationModal/ConfirmationModal";
import SuccessNotification from "../Shared/SuccessNotification/SuccessNotification";
import ResourceDetailTabs from "./../Shared/ResourceDetail/ResourceDetailTabs";
import { ClientContactsTab } from "./Tabs/ClientContactsTab";
import ClientProjectsTab from "./Tabs/ClientProjectsTab";
import { env } from '../../config'
import { projectTabsApi } from "../../utils/api/docTypes";
import { useQuery } from "react-query";
import ClientSettingsTab from "./Tabs/ClientSettingsTab";

const ClientDetail = props => {
  const { getPath } = useContext(AppDivisionContext);
  const [deleteError, setDeleteError] = useState("");

  // modals
  const [showAddProjectModal, setProjectModalVisibility] = useState(false);
  const [showAddUserModal, setUserModalVisibility] = useState(false);
  const [showSuccessNotification, setSuccessNotificationVisibility] =
    useState(false);
  const [showDeleteModal, setDeleteModalVisibility] = useState(false);

  const permCheck = useContext(RolesContext).userHasPermission;
  const userCanDelete = permCheck("delete", "Client");
  const userCanEdit = permCheck("update", "Client");

  const [newDocTypes, setNewDocTypes] = useState([])
  const [toSubmitOrderData, setToSubmitOrderData] = useState([])

  let clientId = window.location.pathname.split('/')[4]
  const clientdDocTypesQuery = useQuery("ClientDocTypesData", () => clientApi.getClientsDocTypes.index(clientId), {
    refetchOnWindowFocus: false,
  })

  const ClientProjectsTabQuery = useQuery("ClientProjectTab", () => clientApi.getClientsProjectTab.index(clientId), {
    refetchOnWindowFocus: false,
  })

  const getProjectList = useQuery("ProjectList", () => projectTabsApi.get(), {
    refetchOnWindowFocus: false,
  })

  const checkedProjectId = ClientProjectsTabQuery.isFetched && ClientProjectsTabQuery.data.map((tab) => tab.project_tab_id)

  const initialProjectTab = (custom_tab) => {
    if (getProjectList.isFetched && custom_tab) {
      delete getProjectList.data[1]

      return getProjectList.data.map((tab) => {
        if (checkedProjectId && checkedProjectId.includes(tab.id)) {
          return {
            ...tab,
            isChecked: true
          }
        }

        return {
          ...tab,
          isChecked: false
        }
      })
    } else {
      delete getProjectList.data[1]

      return getProjectList.data.map((tab) => {
        return {
          ...tab,
          isChecked: true
        }
      })
    }
  }

  const getFormattedAddress = address => {
    let stateOrCountry = env.REACT_APP_REGION === "UK" ? (address.countryObj ? address.countryObj.name : null) : (address.state ? address.state.abbreviation : null)

    return (
      <Fragment>
        <h3 style={{ display: "inline-block" }}>{address.street_1}</h3>
        <h3 style={{ display: "inline-block" }}>{address.street_2 ? `, ${address.street_2}` : null}</h3>
        <br />
        <h3 style={{ display: "inline-block" }}>{address.city},</h3>
        <h3 style={{ display: "inline-block" }}>&nbsp;{stateOrCountry}</h3>
        <h3 style={{ display: "inline-block" }}>&nbsp;{address.zip}</h3>
        <h5>{address.phone}</h5>
      </Fragment>
    );
  };

  const appendNewlyAddedDocTypesData = ({ newClientDocTypesResponse, newDocTypesResponse }) => {
    const twoArraysCombined = []
    const allArraysCombined = []

    newClientDocTypesResponse.forEach(ele => {
      newDocTypesResponse.forEach(e => {
        if (ele.doc_type_id === e.id) {
          twoArraysCombined.push({
            ...ele, ...e, client_doc_type_id: ele.id
          })
        }
      })
    })

    twoArraysCombined.forEach(ele => {
      toSubmitOrderData && toSubmitOrderData.length && toSubmitOrderData.remainingDocTypes.forEach(e => {
        if (ele.name === e.name) {
          allArraysCombined.push({
            ...ele, order: e.order
          })
        }
      })
    })

    setToSubmitOrderData(() => {
      return (allArraysCombined.forEach(ele => {
        ele.client_doc_type_id && toSubmitOrderData.deleteRows.push(ele.client_doc_type_id)

        ele.id && toSubmitOrderData.addRows.push({
          client_id: clientId,
          doc_type_id: ele.id,
          is_checked: ele.is_checked,
          order: ele.order
        })
      }))
    })
  }

  function handleAddProject(formData) {
    return projectApi.create(formData).then(data => {
      window.setTimeout(() => {
        setSuccessNotificationVisibility(false);
        props.history.push(getPath(`/projects/${data.id}`));
      }, 2000);

      setSuccessNotificationVisibility(true);

      return { errorMsg: "" };
    });
  }

  function handleDelete(client) {
    clientApi
      .destroy(client.id)
      .then(() => {
        setDeleteModalVisibility(false);
        props.history.push(getPath("/clients"));
      })
      .catch(error => {
        setDeleteError(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "An error has occurred",
        );
      });
  }

  function handleEdit(formData, client, updateClient) {
    let document_date = formData.document_date;

    document_date[1] = !formData.document_date[1]
    
    return clientApi.update(client.id, { ...formData, newDocTypes: newDocTypes }).then(data => {
      updateClient();
    });
  }

  async function handleAddNewDocType(id) {
    return await clientApi.addNewClientDocTypes.index(id, newDocTypes)
  }

  return (
    <Fragment>
      <ClientStore>
        <ClientContext.Consumer>
          {clientContext => (
            <Fragment>
              <section className="client-detail">
                <header className="pure-g">
                  <div className="detail-content pure-u pure-u-md-1-2 pure-u-1">
                    <h2>{clientContext.client.name}</h2>

                    {clientContext.client.address &&
                      getFormattedAddress(clientContext.client.address)}
                  </div>

                  <div className="detail-actions pure-u pure-u-md-1-2 pure-u-1">
                    {permCheck("create", "Project") && (
                      <button
                        className="primary mobile-hidden"
                        onClick={() => setProjectModalVisibility(true)}
                      >
                        Create New Project
                      </button>
                    )}

                    {userCanEdit && (
                      <button
                        className="outline add-contact-button"
                        onClick={() => setUserModalVisibility(true)}
                      >
                        Add Contact
                      </button>
                    )}

                    {userCanDelete && (
                      <button
                        className="outline warn delete-client-button"
                        onClick={() => setDeleteModalVisibility(true)}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    )}
                  </div>
                </header>
                {userCanEdit ?
                  (
                    <ResourceDetailTabs
                      headers={["Contacts", "Projects", "Client Settings"]}
                      panels={[
                        () => <ClientContactsTab />,
                        () => <ClientProjectsTab />,
                        () => <ClientSettingsTab
                          client={clientContext.client}
                          docTypes={clientdDocTypesQuery.data}
                          newDocTypes={newDocTypes}
                          setNewDocTypes={setNewDocTypes}
                          projectTabs={initialProjectTab(clientContext.client.custom_tab)}
                          docTypesRefetch={clientdDocTypesQuery}
                          toSubmitOrderData={toSubmitOrderData}
                          setToSubmitOrderData={setToSubmitOrderData}
                          document_date={clientContext.client.document_date}
                          handleSubmit={async (formData) => {

                            await handleEdit(
                              formData,
                              clientContext.client,
                              clientContext.updateClient,
                            )
                            const newlyAddedDocTypes = await handleAddNewDocType(clientContext.client.id)

                            newlyAddedDocTypes && await appendNewlyAddedDocTypesData(newlyAddedDocTypes)
                            await clientdDocTypesQuery.refetch()
                            await ClientProjectsTabQuery.refetch()
                            setNewDocTypes([])
                          }}
                        />,
                      ]}
                      showTab={true}
                    />
                  ) : (
                    <ResourceDetailTabs
                      headers={["Contacts", "Projects"]}
                      panels={[
                        () => <ClientContactsTab />,
                        () => <ClientProjectsTab />,
                      ]}
                      showTab={true}
                    />
                  )}
              </section>

              {/* modals */}
              {showAddProjectModal && (
                <ProjectForm
                  handleClose={() => setProjectModalVisibility(false)}
                  submitBtnText="Create"
                  handleSubmit={handleAddProject}
                  initialData={extractInitialData({
                    client_id: clientContext.client.id,
                  })}
                  disableClient={true}
                />
              )}

              {userCanEdit ? (
                <AddContactModal
                  show={showAddUserModal}
                  hideModal={() => setUserModalVisibility(false)}
                  currentContactIds={clientContext.contactIds}
                  handleAdd={userIds =>
                    clientApi.contacts
                      .create(clientContext.client.id, userIds)
                      .then(clientContext.addContacts)
                  }
                />
              ) : null}

              <ConfirmationModal
                title={`Are you sure you want to delete ${clientContext.client.name}?`}
                subTitle="All associated projects and contacts will also be deleted."
                show={showDeleteModal}
                handleClose={() => setDeleteModalVisibility(false)}
                handleConfirm={() => handleDelete(clientContext.client)}
                buttonText="Delete Client"
                errorMsg={deleteError}
              />

              <SuccessNotification show={showSuccessNotification} />
            </Fragment>
          )}
        </ClientContext.Consumer>
      </ClientStore>
    </Fragment>
  );
};

export default ClientDetail;
