import { Modal } from "@sw-sw/lib-ui";
import React, { useContext, useState } from "react";
import QuestionGroupContext from "../../../../contexts/QuestionGroupContext";
import TemplateEditorContext from "../../../../contexts/TemplateEditorContext";
import { questionGroupApi } from "../../../../utils/api/questionGroup";

export interface IQuestionGroupDeleteProps {
  show: boolean;
  onClose: () => void;
  name?: string;
}

const QuestionGroupDelete: React.FC<IQuestionGroupDeleteProps> = ({
  show,
  onClose,
  name = "",
}) => {
  const templateEditorContext = useContext(TemplateEditorContext);
  const questionGroupContext = useContext(QuestionGroupContext);
  const [disabled, setDisabled] = useState<boolean>(true);

  const handleSubmit = () => {
    if (questionGroupContext.questionGroup.id) {
      return questionGroupApi
        .delete(
          templateEditorContext.templateId,
          questionGroupContext.questionGroup.id,
        )
        .then(() => {
          templateEditorContext.refetchQuestionGroups();
          onClose();
        });
    }

    return Promise.resolve();
  };

  const customButtons = (
    <div className="buttons">
      <button className="reversed outline" type="button" onClick={onClose}>
        Cancel
      </button>
      &nbsp;
      <button
        className="warn reversed"
        disabled={disabled}
        onClick={handleSubmit}
      >
        Delete
      </button>
    </div>
  );

  const toggleDisabled = () => {
    setDisabled(!disabled);
  };

  return (
    <Modal
      show={show}
      title="Delete Question Group"
      handleClose={onClose}
      hideButtons={true}
    >
      <p>Are you sure that you want to delete the Question Group "{name}"?</p>
      <div className="removal-confirmation">
        <input
          type="checkbox"
          id="proceed"
          name="proceed"
          onClick={toggleDisabled}
        />
        <label>Yes, I understand</label>
      </div>
      {customButtons}
    </Modal>
  );
};

export default QuestionGroupDelete;
