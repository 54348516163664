/**
 * Custom routes with special guards in the render function
 */

import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import AuthenticationContext from "../../contexts/AuthenticationContext";
import RolesContext from "../../contexts/RolesContext";
import Loading from "../Shared/ResourceIndex/Loading";
import { env } from "../../config";

/**
 * Private route. Only accessible by logged-in user.
 */
export const PrivateRoute = routeProps => {
  const { Component, Layout, checking, ...rest } = routeProps;
  const appStore = useContext(AppContext);
  const authStore = useContext(AuthenticationContext);
  const rolesStore = useContext(RolesContext);
  const user = appStore.get("user");

  if (!appStore.initialDataLoaded) {
    return <Loading />;
  }

  return (
    <Route
      exact
      {...rest}
      render={props =>
        authStore.loggedIn &&
          rolesStore.navigationIsAllowed(props.location.pathname) ? (
          <Layout user={user} checking={checking} {...props}>
            <Component user={user} {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: `?to=${props.location.pathname}${props.location.search}`,
              state: { from: props.location.pathname },
            }}
          />
        )
      }
    />
  );
};

export const PublicUrlRoute = (routeProps) => {
  const { Component, Layout, ...rest } = routeProps;
  const authStore = useContext(AuthenticationContext);
  const isPublicToken = sessionStorage.getItem('publictoken');
  const hasImage = routeProps.location.search.startsWith('?image');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (
      window.location.host.split('.').length > 1 &&
      ( window.location.origin !== process.env.REACT_APP_LOGIN_URL)
    ) {
      const tenantName = window.location.host.split('.')[0];

      const tenantData = await getTenant(tenantName);

      if (!isPublicToken && tenantData) {
        if (hasImage) {
          authStore
            .publiclogin(
              env.REACT_APP_USERNAME_NO_IMAGE,
              env.REACT_APP_PASSWORD,
              tenantData.domain,
            )
            .then((res) => {
              console.log('Public login success');
            });
        } else {
          authStore
            .publiclogin(
              env.REACT_APP_USERNAME,
              env.REACT_APP_PASSWORD,
              tenantData.domain,
            )
            .then((res) => {
              console.log('Public login success');
            });
        }
      } else {
        //get second last
        const projectId = window.location.pathname
          .split('projects/')[1]
          .split('/')[0];

        if (projectId) {
          if (hasImage) {
            authStore
              .publicLoginWithProject(
                env.REACT_APP_USERNAME_NO_IMAGE,
                env.REACT_APP_PASSWORD,
                projectId,
              )
              .then((res) => {
                console.log('Public login success');
              });
          } else {
            authStore
              .publicLoginWithProject(
                env.REACT_APP_USERNAME,
                env.REACT_APP_PASSWORD,
                projectId,
              )
              .then((res) => {
                console.log('Public login success');
              });
          }
        } else {
          window.location.href = `${process.env.REACT_APP_LOGIN_URL}/login`;
        }
      }
    }
  }, [window.location.host]);

  const getTenant = async (name) => {
    try {
      const tenantData = await authStore.getTenantByName(name);

      if (!tenantData) {
        window.location.href = `${process.env.REACT_APP_LOGIN_URL}/login`;
      }

      return tenantData;
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_LOGIN_URL}/login`;
    }
  };

  return (
    <Route
      exact
      {...rest}
      render={props => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

/**
 * Public route. Only acessible by annon/guest user
 */
export const PublicRoute = routeProps => {
  const { Component, Layout, ...rest } = routeProps;
  const authStore = useContext(AuthenticationContext);

  /** When logged in, force logout, before rendering */
  useEffect(() => {
    if (authStore.loggedIn) {
      authStore.logout();
    }
  }, []);

  return (
    <Route
      exact
      {...rest}
      render={props => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

/**
 * Generic route. Accesible by anyone.
 */
export const GenericRoute = routeProps => {
  const { Component, Layout, ...rest } = routeProps;

  return (
    <Route
      exact
      {...rest}
      render={props => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export { DivisionRoute } from "./DivisionRoute";
export { RedirectRoute } from "./RedirectRoute";
