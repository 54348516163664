import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../../contexts/AppContext';
import RolesContext from '../../../contexts/RolesContext';
import ToggleSmall from '../../Shared/Toggle/ToggleSmall';
import tenantApi from '../../../utils/api/tenant';
import { toast } from 'react-toastify';
import { useTenantFeature } from '../../../hooks/features';

interface EVPFormat {
  feature: {
    feature_type: string;
    name: string;
  };
  id: any;
  is_enabled: boolean;
}

export const EVP = () => {
  const [collapse, setCollapse] = useState(false);
  const [evp, setEVP] = useState<EVPFormat[]>([]);
  const appContext = useContext(AppContext);
  const tenantFeatureQuery = useTenantFeature();

  const permCheck = useContext(RolesContext).userHasPermission;
  const userTenantId =
    appContext &&
    appContext.state &&
    appContext.state.user &&
    appContext.state.user.tenantId;

  const loadInitialData = () => {
    tenantApi.features.index(userTenantId, 'evp').then((res) => {
      setEVP(res);
    });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const handleToggle = async (id: number, currentInclude: boolean) => {
    const updatedEVP = evp.map((ele) => {
      if (ele.id === id) {
        return {
          feature: {
            feature_type: ele.feature.feature_type,
            name: ele.feature.name,
          },
          id: id,
          is_enabled: !currentInclude,
        };
      }

      return ele;
    });

    setEVP(updatedEVP);
    tenantApi.features
      .update(userTenantId, id, !currentInclude)
      .then(() => {
        toast.success('EVP updated successfully');
        tenantFeatureQuery.refetch();
      })
      .catch(() => {
        toast.error('Failed to update EVP');
        const errorhandledEVP = evp.map((ele) => {
          if (ele.id === id) {
            return {
              feature: {
                feature_type: ele.feature.feature_type,
                name: ele.feature.name,
              },
              id: id,
              is_enabled: currentInclude,
            };
          }

          return ele;
        });

        setEVP(errorhandledEVP);
      });
  };

  return (
    <div className='settings-tenant-main-container'>
      <div
        className='settings-main-tenant-text'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '30px',
          cursor: 'pointer',
        }}
        onClick={() => setCollapse(!collapse)}
      >
        <h3>
          EVP{' '}
          <i
            className='fa fa-question-circle'
            aria-hidden='true'
            style={{ fontSize: '20px' }}
            data-toggle='tooltip'
            data-placement='top'
            title='When enabled users will be able to create External Verification Project which can be used to track inspections and/or other activities that are performed outside of the SW2 platform.'
          />
        </h3>
        <i
          className={collapse ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
          aria-hidden='true'
        />
      </div>
      <hr></hr>
      <div
        className={
          collapse ? 'administration-hidden' : 'administration-visible'
        }
        style={{ marginBottom: '20px' }}
      >
        {evp.map((ele) => {
          return (
            <div className='features-table-body'>
              <h3>{ele.feature.name}</h3>
              <h3 className='features-table-body-toggle'>
                <ToggleSmall
                  isChecked={ele.is_enabled}
                  handleChange={() => handleToggle(ele.id, ele.is_enabled)}
                  disabled={!permCheck('all', 'Administration Features')}
                />
              </h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};
