import React, { Component } from "react";
import { forIn } from "lodash";

import FeatureableEditorContextProvider, {
  Context as FeatureableEditorContext,
  formField,
} from "../Context";
import IconEditorContext from "./Context";
import { mapUtil } from "@sw-sw/common";
import IconEditorToolbar from "./Toolbar";
import IconEditorSelectionOptions from "./SelectionOptions";
import SelectionActions from "./SelectionActions";
import Canvas from "../Canvas";
import { OptionsWrapper } from "../Options";
import { mergeKeyVal } from "../../../../utils";

/** @todo layout of shape editor modules */
class IconEditorUI extends Component {
  static contextType = FeatureableEditorContext;

  htmlCanvas = React.createRef();
  /** @type {fabric.Canvas} */
  canvas = null;

  init = () => {
    this.canvas = this.context.initCanvas(
      this.htmlCanvas.current,
      [mapUtil.ICON_SIZE, mapUtil.ICON_SIZE],
      this.props.initialData,
    );
    // force update because the <IconEditorContext /> needs {this.canvas}
    this.forceUpdate();
  };

  onToolChange = () => {
    forIn(this.context.form, (value, field) => {
      // this is required to sync the icon editor tool theme, when the selected tool changes
      this.canvas.trigger("featureable-form:updated", { field, value });
    });
  };

  getFeatureableConfig = () => {
    /** @todo center objects before exporting by grouping, centering, and ungrouping */
    return this.canvas.toObject();
  };

  render() {
    return (
      <IconEditorContext
        canvas={this.canvas}
        onSelectionUpdated={() => this.context.resetForm()}
      >
        <div className="pure-g pure-g-options">
          <div className="pure-u pure-u-1-1">
            <Canvas ref={this.htmlCanvas} onMounted={this.init} />
          </div>
        </div>
        <div className="pure-g pure-g-options">
          <div className="pure-u pure-u-1-2 icon-editor-toolbar">
            <OptionsWrapper>
              <IconEditorToolbar onToolChange={this.onToolChange} />
              <SelectionActions />
            </OptionsWrapper>
          </div>
          <div className="pure-u pure-u-1-2">
            <IconEditorSelectionOptions />
          </div>
        </div>
      </IconEditorContext>
    );
  }
}

class FeatureableIconEditor extends Component {
  editor = React.createRef();

  render() {
    return (
      <FeatureableEditorContextProvider
        editorName="icon"
        formInitialData={{}}
        dataUrlOpts={{
          multiplier: mapUtil.EXPORT_ICON_SCALE,
        }}
        dataTransformer={data => {
          return mergeKeyVal(
            data,
            `${formField}.config`,
            this.editor.current.getFeatureableConfig(),
          );

          // data[formField].config = this.editor.current.getFeatureableConfig();
          // return data;
        }}
      >
        <IconEditorUI
          ref={this.editor}
          initialData={this.props.initialData || null}
        />
      </FeatureableEditorContextProvider>
    );
  }
}

export default FeatureableIconEditor;
