import React, { useContext } from "react";
import ReactMde from "react-mde";
import Showdown from "showdown";
import { FormContext } from "@sw-sw/lib-form";

export interface IMarkdownEditorProps {
  formField: string;
}

const mdConverter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

const MarkdownEditor: React.FC<IMarkdownEditorProps> = ({ formField }) => {
  const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">(
    "write",
  );
  const formContext = useContext(FormContext);

  return (
    <>
      <ReactMde
        value={formContext.value.attestationStatement}
        onChange={(value: string) => {
          formContext.set(formField, value);
        }}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={markdown =>
          Promise.resolve(mdConverter.makeHtml(markdown))
        }
        toolbarCommands={[["header", "bold", "italic"]]}
      />
    </>
  );
};

export default MarkdownEditor;
