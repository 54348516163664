import React, { PropsWithChildren, useState } from "react";

export type PaginationState = {
  numModels: number;
  pageSize: number;
};

export interface PaginationContextValue {
  setPage(nextPage: number): void;
  setPaginationState(state: PaginationState | null): void;
  page: number | null;
  paginationState: PaginationState | null;
  setPageSize(pageSize: number): void;
  pageSize: number | null
}

const Context = React.createContext<PaginationContextValue>({
  setPage() {},
  setPageSize() {},
  setPaginationState() {},
  page: null,
  paginationState: null,
  pageSize: null
});

export function usePagination(): PaginationContextValue {
  const [page, setPage] = useState<null | number>(null);
  const [pageSize, setPageSize] = useState<null | number>(null);
  const [
    paginationState,
    setPaginationState,
  ] = useState<PaginationState | null>(null);

  return {
    /** throttle setPage to support multiple effects using the function */
    setPage: setPage,
    setPageSize: setPageSize,
    setPaginationState,
    page,
    paginationState,
    pageSize
  };
}

export const PaginationProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const value = usePagination();

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Context;
export const PaginationContext = Context;
