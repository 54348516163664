import React from "react";
import ButtonGroup from "../../Fields/ButtonGroup";

export const strokeTypes = [
  {
    label: "Single",
  },
  {
    label: "Double",
  },
  {
    label: "Triple",
  },
].map((x, $x) => ({
  ...x,
  value: $x + 1,
}));

/**
 */
class StrokeType extends React.Component {
  render() {
    const { onChange, ...rest } = this.props;

    return (
      <ButtonGroup
        onChange={i => onChange(strokeTypes[i].value)}
        options={strokeTypes}
        {...rest}
      />
    );
  }
}

export default StrokeType;
