import tc from "tinycolor2";

export const setAlpha = (color, alpha) => {
  const c = new tc(color);

  c.setAlpha(alpha);

  return c.toRgbString();
};

export const getAlpha = color => {
  const c = new tc(color);

  return c.getAlpha();
};
