import { TooltipContextProvider } from '@sw-sw/lib-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import uploadApi from '../../utils/api/upload';
import ConfirmationModal from '../Shared/ConfirmationModal/ConfirmationModal';
import FileRenameModal from '../Shared/FileRow/FileRenameModal';
import FileRowList from '../Shared/FileRow/List';
import WebViewerModal from '../Shared/PDFEditor/WebViewerModal';
import SuccessModal from '../Shared/SuccessModal/SuccessModal';
import SuccessNotification from '../Shared/SuccessNotification/SuccessNotification';
import { RolesContext } from '../../contexts/RolesContext';
import { toast } from "react-toastify";
class GroupDocuments extends Component {
  static propTypes = {
    documentGroups: PropTypes.arrayOf(PropTypes.object),
    onDocumentDelete: PropTypes.func,
    onDocumentUpload: PropTypes.func.isRequired,
    onDocumentEdit: PropTypes.func,
    document_group_id: PropTypes.number,
    canRename: PropTypes.bool,
    label: PropTypes.string,
    roleCanDelete: PropTypes.bool,
    clientData: PropTypes.arrayOf(PropTypes.object),
    documents: PropTypes.arrayOf(PropTypes.object),

    /** @todo remove distinction between bmp & regulations... both use concept of group id...bmp has extra request data ? */
    /** @todo API object prop */
    scope: PropTypes.oneOf(['bmp', 'regulations']),
    readOnly: PropTypes.bool,
    replace: PropTypes.bool,
    fixedDetails: PropTypes.arrayOf(PropTypes.object),
    canEdit: PropTypes.bool,
  };

  static defaultProps = {
    canRename: true,
    readOnly: false,
    replace: false
  };

  static contextType = RolesContext

  constructor(props) {
    super(props);

    this.state = {
      showConfirm: false,
      showEditor: false,
      showSuccessNotification: false,
      showRename: false,
      filename: '',
      docGuid: null,
      docId: null,
      featureableId: -1,
      timeoutId: -1,
      groupId: -1,
      error: '',
      docType: {},
      canDrag: false,
      showDownload: false
    };

    this.featureableType = 'group_uploads';
  }

  showConfirm = () => {
    this.setState({
      showConfirm: true,
    });
  };

  hideConfirm = () => {
    this.setState({
      showConfirm: false,
    });

    return false;
  };

  handleConfirmSubmit = () => {
    this.setState({
      showConfirm: false,
      filename: '',
    });

    return true;
  };

  showRename = (document, docType) => {
    if(document.doc_type){
      this.setState({
        showRename: true,
        docGuid: document.upload.GUID,
        docId: document.upload.id,
        filename: document.upload.name,
        docType: docType,
      });
    }
    else this.setState({
      showRename: true,
      docGuid: document.GUID,
      docId: document.id,
      filename: document.name,
      docType: docType,
    });
  };

  hideRename = () => {
    this.setState({
      docGuid: null,
      docId: null,
      filename: '',
      showRename: false,
      docType: {},
    });
  };

  handleRename = async (newName, groupId, docType) => {
    const file = await uploadApi.rename(this.state.docGuid, newName, docType);

    await this.props.onDocumentEdit(file.upload, this.props.scope);
    this.hideRename();
  };

  hideEditor = () => {
    this.setState({ showEditor: false, showDownload: false, id: '' });
  };

  handleUpload = ({ file, groupId, shouldUpdate }) => {
    const match = window.location.pathname.split('/')

    const projectId = window.location.pathname.split('/')[4]


    // send to control_measures or group bridge tables
    switch (this.props.scope) {
      case 'bmp':
        if(groupId === 0){
          if(match[match.length - 1] === 'bmp-docs'){
            const projDocId = this.props.clientData.filter(e => e.name === "Fixed Details Project")[0].project_doc_type_id
            
            return uploadApi.project.create(file.id, projectId, projDocId)
            .then((response) => {
              const detailedFile = { ...file, group_uploads: response };
  
              // update documents array?
              if (shouldUpdate) {              
                return this.props.onDocumentUpload(
                  detailedFile,
                  this.props.scope,
                  groupId
                );
              } else return detailedFile;
            })
          }

          return uploadApi.group
          .create(this.props.document_group_id, file.id, "Fixed Details")
          .then((response) => {
            const detailedFile = { ...file, group_uploads: response };

            // update documents array?
            if (shouldUpdate) {              
              return this.props.onDocumentUpload(
                detailedFile,
                this.props.scope,
                groupId
              );
            } else return detailedFile;
          })
          .catch((err) =>
            this.setState({
              error:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : 'An error has occurred. The document failed to upload',
            }),
          );
        }
        
        return uploadApi.groupControl
          .create(this.props.document_group_id, groupId, file.id, match[match.length - 1] === 'bmp-docs' ? Number(projectId) : null)
          .then(() => {
            // update documents array?
            if (shouldUpdate) {
              return this.props.onDocumentUpload(
                file,
                this.props.scope,
                groupId,
              );
            } else return file;
          })
          .catch((err) =>
            this.setState({
              error:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : 'An error has occurred. The document failed to upload',
            }),
          );

      case 'regulations':
      default:
        return uploadApi.group
          .create(this.props.document_group_id, file.id, "Regulations")
          .then((response) => {
            const detailedFile = { ...file, group_uploads: response };
            // update documents array?

            if (shouldUpdate) {
              return this.props.onDocumentUpload(
                detailedFile,
                this.props.scope,
              );
            } else return detailedFile;
          })
          .catch((err) =>
            this.setState({
              error:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : 'An error has occurred. The document failed to upload',
            }),
          );
    }
  };

  handleDocumentDelete = (doc, groupId) => {
    const fileId = doc.doc_type ? doc.upload.id : doc.id;

    let p = null;
    const match = window.location.pathname.split('/')

    const projectId = window.location.pathname.split('/')[4]

    switch (this.props.scope) {
      case 'bmp':
        if(groupId === 0){
          if(match[match.length - 1] === 'bmp-docs'){

            p = uploadApi.project.destroy(projectId, fileId)
          }
           else {
  
             p = uploadApi.group.destroy(this.props.document_group_id, fileId);
            }
        }
        else {
          p = uploadApi.groupControl.destroy(
          this.props.document_group_id,
          groupId,
          fileId,
        );
      }

        break;
      case 'regulations':
      default:
        p = uploadApi.group.destroy(this.props.document_group_id, fileId);
        break;
    }
    
    return (p || Promise.reject()).then(() => {
      return this.props.onDocumentDelete(fileId, this.props.scope, groupId);
    });
  };

  handleEdit = (document, docType) => {
    if(document.doc_type){
      this.setState({
        showEditor: true,
        docId: document.upload.id,
        docGuid: document.upload.GUID,
        featureableId: document.group_uploads_jt
          ? document.group_uploads_jt.id
          : document.upload_id,
        groupId: docType.id,
        filename: document.upload.name,
        oldDoc: document,
      });
    }
    else this.setState({
      showEditor: true,
      docId: document.id,
      docGuid: document.GUID,
      featureableId: document.group_uploads
        ? document.group_uploads.id
        : document.upload_id,
      groupId: docType.id,
      filename: document.name,
      oldDoc: document,
    });
  };             

  handleDownload = (document, docType) => {
    toast("Download has started")
    if (document.doc_type) {
      this.setState({
        showEditor: true,
        docId: document.upload.id,
        docGuid: document.upload.GUID,
        featureableId: document.group_uploads_jt
          ? document.group_uploads_jt.id
          : document.upload_id,
        groupId: docType.id,
        filename: document.upload.name,
        oldDoc: document,
        showDownload: true,
      });
    } else {
      this.setState({
        showEditor: true,
        docId: document.id,
        docGuid: document.GUID,
        featureableId: document.group_uploads
          ? document.group_uploads.id
          : document.upload_id,
        groupId: docType.id,
        filename: document.name,
        oldDoc: document,
        showDownload: true,
      });
    }
  };

  onEdit = (data) => {
    this.props.onDocumentUpload();
  };

  handleDocumentReplace = async ({ oldDoc, newDoc, groupId }) => {
    // POST to uploads table
    return uploadApi
      .replace(oldDoc.GUID, newDoc)
      .then((result) =>
        this.props.onDocumentEdit(result.upload, this.props.scope),
      )
      .catch((err) =>
        this.setState({
          error:
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : 'An error has occurred. The document failed to upload',
        }),
      );
  };

  componentWillUnmount() {
    if (this.state.timeoutId > -1) {
      window.clearTimeout(this.state.timeoutId);
    }
  }

  searchFunc = (documentGroups) => {
     if(documentGroups){
       if(this.props.scope === "regulations"){
        return this.props.documentGroups
     }
     else return documentGroups.filter((e) => {
      if(this.props.searchValue){
        const mapName = e.name.toLowerCase();
        const docsName = e.group_docs.length ? e.group_docs[0].name.toLowerCase() : ''
        const searchValue = this.props.searchValue.query.toLowerCase();

        return mapName.match(searchValue) || docsName.match(searchValue) ? true : false
      }

      return e
    })
    }
  }


  render() {
    const isFixedDetails = (fixedDetails) => {
      if(fixedDetails){
        if(fixedDetails.length === 1 && fixedDetails[0].id === -1){
          return []
        }
        else return fixedDetails
      }
      else return []
    }


    return (
      <TooltipContextProvider>
        <div className="documents">
        {this.props.scope === "bmp" && 
     
            <FileRowList
            key={0}
            groupName={"Fixed Details"}
            onMove={({ hoverIndex, dragIndex }) =>
              this.props.onMove({
                scope: this.props.scope,
                hoverIndex,
                dragIndex,
                legendItemId: 0,
              })
            }
            onDrop={({ hoverIndex, dragIndex, document, index }) =>
              this.props.onDrop({
                scope: this.props.scope,
                hoverIndex,
                dragIndex,
                document,
                index,
              })
            }
            documents={isFixedDetails(this.props.fixedDetails)}
            canEdit={!this.props.readOnly}
            canPrint
            canDownload={this.context.userHasPermission("all", "Download")}
            canDelete={this.props.roleCanDelete}
            canReplace={this.props.replace}
            canUpload={!this.props.readOnly}
            canDrag={this.props.canDrag}
            hideTitle={this.props.scope === "regulations"}
            handleDelete={doc => this.handleDocumentDelete(doc, 0)}
            handleEdit={doc => this.handleEdit(doc, this.props.fixedDetails)}
            handleDownload={doc => this.handleDownload(doc, 0)}
            handleReplace={(newDoc, oldDoc) =>
              this.handleDocumentReplace({
                oldDoc,
                newDoc,
                groupId: 0,
              })
            }
            canRename={!this.props.readOnly &&  this.props.canRename}
            handleRename={doc => this.showRename(doc, 0)}
            onUpload={file =>
              this.handleUpload({
                file,
                groupId:0,
                shouldUpdate: true,
              })
            }
            label={this.props.label}
            showMoreThreshold={
              this.props.scope === "regulations" ? 15 : undefined
            }
            document_date={true}
            location={window.location.pathname.split('/').at(-1)}
          />
          
        }
          
          {this.searchFunc(this.props.documentGroups || [])
            .map(docType => {
              const doctypes = docType.group_docs_jt ? docType.group_docs_jt : docType.group_docs
             const filtereDocuments = doctypes.filter((e) => {
                if(this.props.searchValue && e.doc_type){
                  const searchname = e.upload.name
                  const mapName = searchname.toLowerCase();
                  const searchValue = this.props.searchValue.query.toLowerCase();

                  return mapName.match(searchValue)
                }

                return e
              })

              return (
                <>
                <FileRowList
                  key={docType.id}
                  groupName={docType.name}
                  onMove={({ hoverIndex, dragIndex }) =>
                    this.props.onMove({
                      scope: this.props.scope,
                      hoverIndex,
                      dragIndex,
                      legendItemId: docType.id,
                    })
                  }
                  onDrop={({ hoverIndex, dragIndex, document, index }) =>
                    this.props.onDrop({
                      scope: this.props.scope,
                      hoverIndex,
                      dragIndex,
                      document,
                      index,
                    })
                  }
                  documents={filtereDocuments}
                  canEdit={!this.props.readOnly}
                  canPrint
                  canDownload={this.context.userHasPermission("all", "Download")}
                  canDelete={this.props.roleCanDelete}
                  canReplace={this.props.replace}
                  canUpload={!this.props.readOnly}
                  canDrag={this.props.canDrag}
                  hideTitle={this.props.scope === "regulations"}
                  handleDelete={doc => this.handleDocumentDelete(doc, docType.id)}
                  handleEdit={doc => this.handleEdit(doc, docType)}
                  handleDownload={doc => this.handleDownload(doc, docType)}
                  handleReplace={(newDoc, oldDoc) =>
                    this.handleDocumentReplace({
                      oldDoc,
                      newDoc,
                      groupId: docType.id,
                    })
                  }
                  canRename={!this.props.readOnly && this.props.canRename}
                  handleRename={doc => this.showRename(doc, docType)}
                  onUpload={file =>
                    this.handleUpload({
                      file,
                      groupId: docType.id,
                      shouldUpdate: true,
                    })
                  }
                  label={this.props.label}
                  showMoreThreshold={
                    this.props.scope === "regulations" ? 15 : undefined
                  }
                  document_date={true}
                  location={window.location.pathname.split('/').at(-1)}
                />
                </>
              )
            })}
        </div>

        {/* load modals into DOM */}
        <ConfirmationModal
          show={this.state.showConfirm}
          handleClose={this.hideConfirm}
          handleConfirm={this.handleConfirmSubmit}
          title={`A document with the name ${this.state.filename} already exists.`}
          subTitle='Would you like to overwrite this document?'
          buttonText='Overwrite'
        />

        <WebViewerModal
          show={this.state.showEditor}
          onClose={this.hideEditor}
          onSave={(data) => this.onEdit(data.upload)}
          guid={this.state.docGuid}
          id={this.state.docId}
          filename={this.state.filename}
          disableDownload={!this.context.userHasPermission("all", "Download")}
          canEdit={this.context.userHasPermission("update", "Project Documents") && this.props.canEdit}
        />

        <WebViewerModal
          show={this.state.showDownload}
          onClose={this.hideEditor}
          onSave={(data) => this.onEdit(data.upload)}
          guid={this.state.docGuid}
          id={this.state.docId}
          filename={this.state.filename}
          canEdit={this.context.userHasPermission("update", "Project Documents") && this.props.canEdit}
          canDownload={true}
        />

        <SuccessNotification show={this.state.showSuccessNotification} />
        <SuccessModal
          show={this.state.error.length > 0}
          handleClose={() => this.setState({ error: '' })}
          handleSubmit={() => this.setState({ error: '' })}
          submitBtnText='OK'
          message={this.state.error}
          title='Error Uploading Document'
          isAlert
        />
        <FileRenameModal
          show={this.state.showRename}
          file={{ GUID: this.state.docGuid, name: this.state.filename }}
          onCancel={this.hideRename}
          onSubmit={(fileName) =>
            this.handleRename(fileName, this.state.docType.GUID, window.location.pathname.split('/')[5])
          }
        />
      </TooltipContextProvider>
    );
  }
}
export default GroupDocuments;
