import React, { useContext, useEffect, useState } from 'react';
import { isNumber } from 'lodash';
import { primaryAction } from 'ol/events/condition';

import { mapUtil } from '@sw-sw/common';
import usePositionableSourceModel from '../../hooks/usePositionableSourceModel';
import usePositionableLayer from '../../hooks/usePositionableLayer';
import { getUtil } from '../../../../utils/positionable';
import { Context as InteractionContext } from '../InteractionContext';
import { Context as PositionableInstanceDataContext } from '../../Positionable/InstanceDataContext';
import { Context as DataSourceContext } from '../../DataSourceContext';
import useMap from '../../useMap';

const { isDrawInteraction } = mapUtil;

/** Set feature custom attributes after drawing */
const setFeatureData = (feature, source, sourceModel, util) => {
  feature.set('text_content', util.getTextContent(sourceModel, feature), true);
  feature.set(
    'positionable_config',
    util.getInstanceConfig(sourceModel, feature),
    true,
  );

  feature.set('positionable_source_id', sourceModel.id, true);
  feature.set('positionable_type', sourceModel.positionableType, true);

  feature.set(
    'onSaveCallback',
    () => {
      source.removeFeature(feature);
    },
    true,
  );
};

/**
 * Provides feature drawing capabilities
 */
function DrawInteraction({ ...props }) {
  const sourceModel = usePositionableSourceModel();
  const layer = usePositionableLayer();
  const map = useMap();
  const ic = useContext(InteractionContext);
  const dataSource = useContext(DataSourceContext);
  const dataContext = useContext(PositionableInstanceDataContext);
  const [draw, setDraw] = useState(null);

  const onDrawend = (event, positionableUtil) => {
    const { feature } = event;
    const { targetType, targetId, startInspectionId, endInspectionId } =
      dataSource.getDataTypeArguments(sourceModel.positionableType);

    setFeatureData(feature, layer.getSource(), sourceModel, positionableUtil);

    dataContext.setPendingUpdate(
      feature,
      sourceModel.positionableType,
      targetType,
      targetId,
      startInspectionId,
      endInspectionId,
    );

    if (props.onDrawend) {
      props.onDrawend(feature);
    }

    /** When the drawing was created programatically, automatically go to the detail interaction */
    if (
      ic.modeData &&
      ic.modeData.mapEvent &&
      ic.modeData.mapEvent.coordinate
    ) {
      const mapSize = map.getSize();

      map
        .getView()
        .centerOn(ic.modeData.mapEvent.coordinate, mapSize, [
          mapSize[0] / 2,
          mapSize[1] / 2,
        ]);

      ic.setMode('detail', {
        feature,
      });
    }
  };

  useEffect(() => {
    const positionableUtil = getUtil(sourceModel.positionableType);
    /** @type { import('ol/interaction').Draw } */
    const drawInteraction = positionableUtil.getDrawInteraction(
      sourceModel,
      layer.getSource(),
    );

    drawInteraction.condition_ = primaryAction;

    drawInteraction.on('drawend', (event) =>
      onDrawend(event, positionableUtil),
    );

    ic.removeInteractions(isDrawInteraction);
    ic.addInteraction(drawInteraction, isDrawInteraction);

    setDraw(drawInteraction);

    //isDrawing state helps in preventing "LegendCard" inside map editor from opening while drawing inside another Legend item
    drawInteraction.on('drawstart', () =>
      ic.setIsDrawing(true)
    );

    drawInteraction.on('drawend', () =>
      ic.setIsDrawing(false)
    );

    return () => {
      ic.removeInteractions(isDrawInteraction);
      setDraw(null);
    };
  }, [sourceModel, layer]);

  /** stop interaction mode after max instances reached */
  useEffect(() => {
    const {
      targetType,
      targetId,
      maxInstances,
      startInspectionId,
      endInspectionId,
    } = dataSource.getDataTypeArguments(sourceModel.positionableType);

    if (
      isNumber(maxInstances) &&
      dataContext.countInstances(
        sourceModel.positionableType,
        targetType,
        targetId,
        sourceModel,
        startInspectionId,
        endInspectionId,
      ) >= maxInstances
    ) {
      ic.setDefaultMode();
    }
  }, [dataContext]);

  /**
   * Auto-draw, when a map event is passed into interaction mode data
   */
  useEffect(() => {
    if (
      draw &&
      ic.modeData &&
      ic.modeData.mapEvent &&
      ic.modeData.mapEvent.coordinate
    ) {
      draw.startDrawing_(ic.modeData.mapEvent);
      draw.finishDrawing();
    }
  }, [draw]);

  return <div className='map-interaction-draw' />;
}

// compose the {drawInteraction} with interaction context
function Draw(props) {
  const sourceModel = usePositionableSourceModel();
  const { modeData, positionableType, setPositionableSourceModel } =
    useContext(InteractionContext);
  const { getById } = useContext(DataSourceContext);

  /**
   * Preselect the source model, if available.
   *
   * Used when placing feature immediately after creating a source model
   */
  useEffect(() => {
    if (modeData && modeData.sourceModelId) {
      const model = getById(modeData.sourceModelId, positionableType);

      if (model && model.hasOwnProperty('index')) {
        setPositionableSourceModel(model.index, { ...modeData });
      }
    }
  }, []);

  if (!sourceModel) {
    return null;
  }

  return <DrawInteraction {...props} />;
}

export default Draw;
