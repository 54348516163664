import moment from "moment";

import instance from "../xhrService";

const baseUrl = "/api/weather";
const getBaseUrl = (a, b) => `${baseUrl}/${a}/${b}`;
const localStorageKey = "weather";

const weatherApi = {
  fetch: (latitude, longitude) => {
    return instance
      .get(getBaseUrl(latitude, longitude))
      .then(({ data }) => {
        if (data && data.name === "Error") {
          weatherApi.store([]);
        } else {
          weatherApi.store(data);
        }

        return data;
      })
      .catch(err => {
        console.log("err getting weather", err);
        weatherApi.clear();

        return "error";
      });
  },

  index: () => {
    const weatherData = localStorage.getItem(localStorageKey);

    if (!weatherData) return [];

    // wrap in try-catch in case localStorage value gets corrupted
    try {
      const today = moment().format(moment.HTML5_FMT.DATE);
      const weatherDate = localStorage.getItem("weather date")

      if (today === weatherDate) {
        return JSON.parse(weatherData);
      }

      return [];
    } catch (error) {
      weatherApi.clear();

      return [];
    }
  },

  store: data => {
    localStorage.setItem("weather date", moment().format(moment.HTML5_FMT.DATE));
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  },

  clear: () => {
    localStorage.removeItem(localStorageKey);
    localStorage.removeItem("weather date");
  },
};

export default weatherApi;
