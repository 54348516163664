import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import Loading from "../components/Shared/ResourceIndex/Loading";
import inspectionApi from "../utils/api/inspection";
import AppContext from "./AppContext";
import { InspectionContext } from "./InspectionContext";

interface iCommentData {
  value: string;
  inspectionId: number;
  questionId: number;
}
type QuestionCommentContextValue = {
  onSave: Dispatch<iCommentData>;
  onDelete: Dispatch<Pick<iCommentData, "questionId" | "inspectionId">>;
};

export const QuestionCommentContext =
  createContext<QuestionCommentContextValue>(null as any);

export function QuestionCommentProvider({ children }: PropsWithChildren<{}>) {
  const appContext = useContext(AppContext);
  const inspectionContext = useContext(InspectionContext) as any;
  const [loading, setLoading] = useState<boolean>(false);

  const setTemplate = (template: any) => {
    inspectionContext.setTemplate({
      ...inspectionContext.template,
      ...template,
    });

    appContext.triggerSuccessNotification();
  };

  const ctx: QuestionCommentContextValue = {
    onSave: ({ questionId, value, inspectionId }) => {
      api({
        questionId,
        inspectionId,
        comment: value,
        shouldDelete: false,
        setTemplate,
      });
    },
    onDelete: ({ questionId, inspectionId }) => {
      api({
        questionId,
        inspectionId,
        shouldDelete: true,
        setTemplate,
      });
    },
  };

  useEffect(() => {
    if (inspectionContext.template) {
      setLoading(false);
    }
  }, [inspectionContext.template]);

  if (loading) {
    return <Loading what="Inspection Template" />;
  }

  return (
    <QuestionCommentContext.Provider value={ctx}>
      {children}
    </QuestionCommentContext.Provider>
  );
}

async function api({
  inspectionId,
  questionId,
  setTemplate,
  ...data
}: {
  questionId: number;
  inspectionId: number;
  comment?: string;
  shouldDelete?: boolean;
  setTemplate: Dispatch<any>;
}) {
  const updatedData = await inspectionApi.createQuestionComment(
    questionId,
    inspectionId,
    data,
  );

  setTemplate({ question_groups: updatedData.question_groups });
}
