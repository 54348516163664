import React from "react";
import ConfirmationModal from "../../../Shared/ConfirmationModal/ConfirmationModal";
import { commentableToString, ICommentable } from "../../../../utils/notes";

function ObservationDeleteModal({
  show,
  value,
  onCancel,
  onSubmit,
  noteType = "observation",
}: {
  show: Boolean;
  value: ICommentable | null;
  onCancel: () => void;
  onSubmit: () => void;
  noteType?: string;
}) {
  return (
    <ConfirmationModal
      show={show && value ? true : false}
      title={`Delete ${noteType[0].toUpperCase() + noteType.substring(1)}`}
      subTitle={
        value
          ? [
              `Are you sure you want to delete this ${noteType}?`,
              commentableToString(value),
            ]
          : undefined
      }
      handleClose={onCancel}
      handleConfirm={onSubmit}
      buttonText="Delete"
    />
  );
}

export default ObservationDeleteModal;
