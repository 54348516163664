import React from "react";
import { arrayOf, any } from "prop-types";
import { FormSchemaFields } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";

export function getInitialData(id) {
  return { inspector_user_id: id };
}

function getSchema(users) {
  return {
    inspector_user_id: {
      label: "Inspector",
      controlType: UIControlType.select,
      options: users,
      placeholder: "Please select a user",
      noOptionMessage: "There are no users available.",
    },
  };
}

function ReassignForm({ value, onChange, users }) {
  return (
    <FormSchemaFields
      schema={getSchema(users)}
      formData={value}
      onChange={onChange}
    />
  );
}

ReassignForm.propTypes = {
  users: arrayOf(any).isRequired,
};

ReassignForm.defaultProps = {
  initialData: {},
};

export default ReassignForm;
