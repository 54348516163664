import React, { useContext, useEffect, useState } from 'react';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import {
    FormContext,
    FormSchemaFields,
} from '@sw-sw/lib-form';
import userApi from '../../utils/api/user';
import { ActionButtons } from '../Shared/ActionButtons';
import { useCountries, useStates } from '../../hooks/address';
import { env } from '../../config';
import { toast } from 'react-toastify';
import { submitHandler } from '../Shared/form/modal/FormActions';
import ToastError from '../Shared/ToastError/ToastError';
import DivisionContext from '../../contexts/DivisionContext';
import validator from '../../utils/FormValidator'

export function SettingsTab() {
    const formContext = useContext(FormContext);
    const statesQuery =
        env.REACT_APP_REGION === 'UK' ? useCountries() : useStates();
    const [collapse, setCollapse] = useState(false);
    const divisionContext = useContext(DivisionContext);

    useEffect(() => {
        divisionContext.setDisableSwitcher(true);

        return () => divisionContext.setDisableSwitcher(false);
    }, []);

    useEffect(() => {
        const userDataFunc = async () => {
            const fetchdata = await userApi.tenantInfo();

            formContext.set("val", fetchdata.getTenantDetails)

        };

        userDataFunc();
    }, []);

    const phone: "phone" = "phone"

    const schema = {
        name: {
            label: 'Primary Contact Name',
            controlType: UIControlType.text,
            validation: {
                required: true,
            },
            style: {
                flexBasis: '50%',
            },
            disabled: false,
        },
        phone: {
            label: 'Phone',
            mask: phone,
            validation: {
                required: true,
                format: phone
            },
            style: {
                flexBasis: '50%',
            },
            disabled: false
        },
        street_1: {
            label: 'Street 1',
            controlType: UIControlType.text,
            validation: {
                required: true,
            },
            style: {
                flexBasis: '50%',
            },
            disabled: false,
        },
        street_2: {
            label: 'Street 2',
            controlType: UIControlType.text,
            validation: {
                required: true,
            },
            style: {
                flexBasis: '50%',
            },
            disabled: false,
        },
        city: {
            label: 'City',
            controlType: UIControlType.text,
            validation: {
                required: true,
            },
            style: {
                flexBasis: '25%',
            },
            disabled: false,
        },
        state_id: {
            label: 'State',
            controlType: UIControlType.select,
            validation: {
                required: true,
            },
            style: {
                flexBasis: '25%',
            },
            disabled: false,
            options: statesQuery.data,
            labelKey: 'name',
            valueKey: 'id',
            autoComplete: 'address-level1',
            className: 'administration-abc'
        },
        zip: {
            label: 'Zip',
            controlType: UIControlType.text,
            validation: {
                required: true,
            },
            maxLength: 5,
            style: {
                flexBasis: '25%',
                flexGrow: 1
            },
            inputMode: 'numeric',
            parse: validator.parseNumber,
            disabled: false,
        },
    };

    const onSave = async () => {

        const address = {
            street_1: formContext.value.street_1.trim(),
            street_2: formContext.value.street_2.trim(),
            city: formContext.value.city.trim(),
            state_id: formContext.value.state_id,
            zip: formContext.value.zip.trim(),
            phone: formContext.value.phone,
        };

        const primary_tenant_name = formContext.value.name.trim();

        const toastId = toast('Saving Primary Tenant');

        toast.update(toastId, {
            render: 'Primary Tenant saved!',
            type: 'success',
        });

        return await userApi.tenantUpdate({ address, primary_tenant_name }).then().catch(() => {
            toast.update(toastId, {
                render: (
                    <ToastError
                        message='Cannot save inspection because of error: '
                        error={"All fields must be filled"}
                    />
                ),
                autoClose: false,
                type: 'error',
            });
        })

    };


    if (formContext.value.val === null) {
        formContext.set("val", {
            address: {
                street_1: null,
                street_2: null,
                city: null,
                state_id: 1,
                phone: null,
                zip: null
            },
            primary_tenant_name: null
        })
    }

    if (formContext.value.val) {
        return (
          <>
            <div className='settings-tenant-main-container'>
                <div className='settings-main-tenant-text' style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => setCollapse(!collapse)}>
                    <h3> Tenant Information </h3>
                    <i
                        className={collapse ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
                        aria-hidden='true'
                    />
                </div>
                <hr></hr>
                <br />
                <div className={collapse ? 'administration-hidden' : 'administration-visible'}>
                    <FormSchemaFields
                        className='findings-form'
                        schema={schema}
                        onChange={formContext.set}
                        formData={formContext.value}
                        initialFormData={{
                            name: formContext.value.val.primary_tenant_name,
                            state_id: statesQuery.data?.filter(
                                (e) => e.id === formContext.value.val.address.state_id,
                            )[0].id,
                            phone: formContext.value.val.address.phone,
                            street_1: formContext.value.val.address.street_1,
                            street_2: formContext.value.val.address.street_2,
                            zip: formContext.value.val.address.zip,
                            city: formContext.value.val.address.city,
                        }}

                    />
                    <div style={{ marginTop: '10px', marginBottom:'10px' }}>
                    <ActionButtons
                        primary='Save'
                        onClick={(e: React.SyntheticEvent<Element, Event>) => submitHandler(formContext, e, onSave)}
                        primaryDisabled={!formContext.isValid ? true : false}
                    />
                </div>
            </div>
           </div>
          </>
        );
    }

    return <></>
}