import React, { Component } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { fabric } from "fabric";
import classnames from "classnames";
import { FormContext } from "@sw-sw/lib-form";
import { mergeKeyVal } from "../../../utils";

const Context = React.createContext();

export const formField = "featureable_data";

/**
 * Holds state for featureable editor
 *
 * Supports updating and resetting a form
 * Exports featureable data
 */
class FeatureableEditorContext extends Component {
  static contextType = FormContext;
  static propTypes = {
    editorName: PropTypes.string.isRequired,
    formInitialData: PropTypes.object,
    /**
     * Optional transformer for final form data
     */
    dataTransformer: PropTypes.func,
    /**
     * Options for the canvas.toDataUrl method
     */
    dataUrlOpts: PropTypes.object,
  };

  state = {
    form: {
      ...this.props.formInitialData,
    },
  };

  /** @type {fabric.Canvas} */
  canvas = null;

  get contextValue() {
    return {
      form: this.state.form,
      resetForm: this.resetForm,
      updateForm: this.updateForm,
      initCanvas: this.initCanvas,
      getFeatureableData: this.getFeatureableData,
    };
  }

  resetForm = () => {
    this.setState({
      form: {},
    });
  };

  updateForm = (field, value) => {
    this.setState(
      state => ({
        ...state,
        form: {
          ...state.form,
          [field]: value,
        },
      }),
      () => {
        this.canvas.trigger("featureable-form:updated", { field, value });
      },
    );
  };

  /**
   * Initialize Fabric.js canvas on specified DOM element
   * @return {fabric.Canvas}
   */
  initCanvas = (element, [width, height], initialData = null) => {
    /** @type fabric.Canvas */
    const cv = new fabric.Canvas(element, {
      selection: false,
    });

    cv.setWidth(width).setHeight(height);

    if (initialData) {
      cv.loadFromJSON(initialData, () => {
        cv.renderAll();
      });
    }

    this.canvas = cv;

    return cv;
  };

  getFeatureableData = (dataUrlOpts = {}) => {
    return {
      type: this.props.editorName,
      config: {
        ...this.state.form,
      },
      dataurl: this.canvas.toDataURL(this.props.dataUrlOpts),
    };
  };

  componentDidMount() {
    /**
     * Set data transformers
     *
     */

    /**
     * 1) Set featureable data
     */
    this.context.addDataTransformer(data =>
      mergeKeyVal(data, formField, this.getFeatureableData()),
    );

    /**
     * 2) Allow editor to transform data
     */
    if (this.props.dataTransformer) {
      this.context.addDataTransformer(this.props.dataTransformer);
    }

    /**
     * 3) Finally, stringify this field
     */
    this.context.addDataTransformer(data => {
      data[formField] = JSON.stringify(get(data, formField, {}));

      return data;
    });
  }

  /**
   * CLear all transformers
   */
  componentWillUnmount() {
    this.context.clearDataTransformers();
  }

  render() {
    const c = classnames(
      "featureable-editor",
      `${this.props.editorName}-featureable-editor`,
    );

    return (
      <div className={c}>
        <Context.Provider value={this.contextValue}>
          {this.props.children}
        </Context.Provider>
      </div>
    );
  }
}

export { Context };
export default FeatureableEditorContext;
