import { QuestionType } from "@sw-sw/lib-inspection-templates";
import React, { PropsWithChildren, useState } from "react";

export type QuestionTypeContextValue = {
  types: Record<number, QuestionType>;
  setTypes(types: QuestionType[]): void;
};

export const QuestionTypeContext =
  React.createContext<QuestionTypeContextValue>({
    types: [],
    setTypes() {},
  });

export const QuestionTypeProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [types, setTypes] = useState<QuestionTypeContextValue["types"]>([]);

  return (
    <QuestionTypeContext.Provider
      value={{
        types,
        setTypes(inputTypes) {
          setTypes(
            inputTypes.reduce<Record<number, QuestionType>>(
              (nextTypes, inputType) => {
                nextTypes[inputType.id] = inputType;

                return nextTypes;
              },
              {},
            ),
          );
        },
      }}
    >
      {children}
    </QuestionTypeContext.Provider>
  );
};

export function withQuestionTypeProvider<T>(Component: React.ComponentType<T>) {
  return (props: T) => (
    <QuestionTypeProvider>
      <Component {...props} />
    </QuestionTypeProvider>
  );
}
