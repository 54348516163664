import React from "react";
import PaginationUI from "react-js-pagination";
import { PaginationContextValue } from "../../../contexts/PaginationContext";

export const Pagination: React.FC<
  Pick<PaginationContextValue, "paginationState" | "setPage" | "page"> & {
    pageRangeDisplayed?: number;
  }
> = ({ paginationState, setPage, page, pageRangeDisplayed = 3 }) => {
  if (!paginationState || !page) {
    return null;
  }

  const { numModels, pageSize } = paginationState;

  if (pageSize < 1) {
    return null;
  }

  return (
    <div>
      <PaginationUI
        activePage={page}
        itemsCountPerPage={pageSize}
        totalItemsCount={numModels}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={setPage}
        itemClass="pagination-item"
      />
    </div>
  );
};

export default Pagination;
