import {
  LayoutTypes,
  QuestionGroup,
  Question,
} from "@sw-sw/lib-inspection-templates";
import React, {
  PropsWithChildren,
  useState,
  useContext,
  useEffect,
} from "react";
import { questionApi } from "../utils/api/question";
import TemplateEditorContext from "../contexts/TemplateEditorContext";
import { useQuestionGroup } from "../hooks/questionGroups";
import Loading from "../components/Shared/ResourceIndex/Loading";
import { RefetchOptions, QueryObserverResult } from "react-query";

export type IQuestionGroupProps = {
  questionGroup: QuestionGroup & {
    order: number;
    questions: Array<Question & { questionWidth: string }>;
  };
  moveActive: boolean;
  setMoveActive: (value: boolean) => void;
  selectedQuestionId: number | null;
  setSelectedQuestionId: (id: number | null) => void;
  reorderQuestions: (order: number) => void;
  refetchQuestionGroup: (
    options?: RefetchOptions | undefined,
  ) => Promise<
    QueryObserverResult<
      QuestionGroup & { order: number; questions: Array<Question> }
    >
  >;
};

const throwError = () => {
  throw new Error("QuestionGroupProvider not loaded");
};

const Context = React.createContext<IQuestionGroupProps>({
  questionGroup: { layout_type: LayoutTypes["FORM"], order: 0, questions: [] },
  moveActive: false,
  setMoveActive: throwError,
  selectedQuestionId: null,
  setSelectedQuestionId: throwError,
  reorderQuestions: throwError,
  refetchQuestionGroup: throwError,
});

export const QuestionGroupProvider: React.FC<
  PropsWithChildren<{ initialValue: { id: number } }>
> = ({ initialValue, children }) => {
  const templateEditorContext = useContext(TemplateEditorContext);
  const questionGroupQuery = useQuestionGroup(
    templateEditorContext.templateId,
    initialValue.id,
  );
  const [moveActive, setMoveActive] = useState<boolean>(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(
    null,
  );

  useEffect(() => {
    if (
      questionGroupQuery &&
      questionGroupQuery.data &&
      questionGroupQuery.data.id
    ) {
      templateEditorContext.addSortedQuestionGroup({
        id: questionGroupQuery.data.id,
        order: questionGroupQuery.data.order,
      });
    }

    return () => {
      if (
        questionGroupQuery &&
        questionGroupQuery.data &&
        questionGroupQuery.data.id
      ) {
        templateEditorContext.removeSortedQuestionGroup(
          questionGroupQuery.data.id,
        );
      }
    };
  }, [questionGroupQuery.data]);

  if (
    questionGroupQuery.isLoading ||
    !questionGroupQuery.data ||
    !questionGroupQuery.data.id
  ) {
    return <Loading />;
  }

  const questionGroup = questionGroupQuery.data;

  const reorderQuestions = async (order: number) => {
    if (selectedQuestionId && questionGroup.id) {
      await questionApi.update(selectedQuestionId, {
        sort_order: order,
        groupId: questionGroup.id,
      });
    }
  };

  return (
    <Context.Provider
      value={{
        questionGroup,
        moveActive,
        setMoveActive,
        selectedQuestionId,
        setSelectedQuestionId,
        reorderQuestions,
        refetchQuestionGroup: questionGroupQuery.refetch,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
