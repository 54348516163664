import xhrService from "../xhrService";
const baseUrl = "/api/divisions";

type queryParamsTypes = {
    s?: string;
}

export const projectWeather = {
    index: ({ queryParams, divisionId }: { queryParams: queryParamsTypes; divisionId: number; }) => {
        if (!divisionId) { return false };
        return xhrService.get(`${baseUrl}/${divisionId}/precipitation/fetchProjectsWithPrecipitation`, {
            params: {
                ...queryParams
            }
        }).then(response => response.data).catch(e=>{console.log(e.message);return false;});
    },
};

export default projectWeather;
