import React, { Component } from "react";
import { mapKeys, camelCase, toNumber } from "lodash";

import { Context } from "../Context";
import { lineOptions } from "../Line";
import FeatureableEditorOptions from "../Options";
import Color from "../Fields/Color";
import Range from "../Fields/Range";
import FillType from "./Fields/FillType";
import LineDirection from "./Fields/LineDirection";

/** @todo handle conflicting keys in a general manner, maybe with "prefix" prop in editor opts */
const options = {
  boundary: {
    /** @todo double & tripple line */
    /** @todo stroke dash array with more than one item */
    ...lineOptions,
    strokeWidth: props =>
      lineOptions.strokeWidth({ ...props, step: 1, max: 15, min: 1 }),
  },
  fill: {
    fill: props => <Color label="Color" {...props} />,
    fillOpacity: props => (
      <Range
        label="Opacity"
        step="0.05"
        min="0.05"
        max="1"
        cast={toNumber}
        {...props}
      />
    ),
    fillType: props => <FillType {...props} />,
  },
  line: mapKeys(
    {
      strokeWidth: props =>
        lineOptions.strokeWidth({ label: "Size", ...props }),
      separation: props =>
        lineOptions.strokeWidth({ label: "Separation", ...props }),
      direction: ({ form, ...props }) => (
        <LineDirection fillType={form.fillType} {...props} />
      ),
    },
    (_, key) => camelCase(["line", key].join(" ")),
  ),
};

export const optionDefaults = {
  fillType: "solid",
  fill: "#999999",
  stroke: "#4D4D4D",
  strokeWidth: 5,
  strokeDashArray: [0, 0],
  fillOpacity: 1,
  opacity: 1,
  lineStrokeWidth: 4,
  lineSeparation: 4,
  lineDirection: 0,
};

class AreaEditorOptions extends Component {
  static contextType = Context;

  render() {
    const form = this.context.form;

    let showLineForm = false;

    if (form && form.fillType && form.fillType !== "solid") {
      showLineForm = true;
    }

    return (
      <div className="pure-g pure-g-options">
        <div className="pure-u pure-u-1-2">
          <div>
            <b>Fill Style</b>
          </div>
          <FeatureableEditorOptions options={options.fill} />
          {showLineForm && (
            <React.Fragment>
              <div>
                <b>Line Style</b>
              </div>
              <FeatureableEditorOptions
                options={options.line}
                extraComponentProps={{ form }}
              />
            </React.Fragment>
          )}
        </div>
        <div className="pure-u pure-u-1-2">
          <div>
            <b>Boundary Style</b>
          </div>
          <FeatureableEditorOptions options={options.boundary} />
        </div>
      </div>
    );
  }
}

export default AreaEditorOptions;
