// Informed's Select component breaks in Edge. This is the solution.

import React from "react";
import { asField } from "informed";

const CustomSelect = asField(({ fieldState, fieldApi, ...props }) => {
  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const {
    forwardedRef,
    children,
    initialValue,
    onChange,
    onBlur,
    ...rest
  } = props;

  return (
    <select
      {...rest}
      value={value || initialValue || ""}
      ref={forwardedRef}
      onChange={e => {
        setValue(e.target.value);
        if (onChange) onChange(e);
      }}
      onBlur={e => {
        setTouched(true);
        if (onBlur) onBlur(e);
      }}
    >
      {children}
    </select>
  );
});

export default CustomSelect;
