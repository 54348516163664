import React, { MouseEventHandler, useContext, useEffect, useState } from "react";
import { Modal } from "@sw-sw/lib-ui";
import Loading from "../ResourceIndex/Loading";
import WebViewer, { WebViewerProps } from "./WebViewer";
import TenantStore from "../../../contexts/TenantContext";

function WebViewerModal({
  show,
  onClose,
  canEdit,
  ...viewerProps
}: {
  show: boolean;
  onClose: MouseEventHandler;
} & WebViewerProps) {
  
  const tenant = useContext(TenantStore);
  const [canEditPdf, setCanEditPdf] = useState(canEdit);

  useEffect(()=>{

    setCanEditPdf(tenant.tenantHasFeature('PDF Editing') && canEdit)

  },[tenant]);

  return (
    <Modal
      classes="webviewer-modal"
      handleClose={onClose}
      show={show}
      hideButtons
      disableClose
      dismissOnBgClick
      isExtraWide
    >
      {viewerProps.guid && viewerProps.filename ? (
        <WebViewer {...viewerProps} canEdit={canEditPdf} onClose={onClose} />
      ) : (
        <Loading />
      )}
    </Modal>
  );
}

WebViewerModal.propTypes = {};

WebViewerModal.defaultProps = {
  show: false,
};

export default WebViewerModal;
