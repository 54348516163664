import React, { MouseEventHandler, ReactNode } from "react";

export interface IResourceDetailUpdateProps {
  resource: string;
  onButtonClick: MouseEventHandler;
  children: ReactNode;
  iconClass?: string;
  editString?: string
}

const ResourceDetailUpdate: React.FC<IResourceDetailUpdateProps> = ({
  resource,
  onButtonClick,
  children,
  iconClass="fa fa-pencil",
  editString="Edit"
}) => {
  return (
    <React.Fragment>
      <button className="outline create-new contact.buttons" onClick={onButtonClick}>
        {editString} {resource} <i className={iconClass} />
      </button>
      {children}
    </React.Fragment>
  );
};

export default ResourceDetailUpdate;
