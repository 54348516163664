import { FormContext } from "@sw-sw/lib-form";
import classNames from "classnames";
import { get } from "lodash";
import React, { useContext } from "react";
import AppContext from "../../../contexts/AppContext";
import RolesContext from "../../../contexts/RolesContext";
import { useModalControls } from "../../../hooks/modalControls";
import commentableApi from "../../../utils/api/commentable";
import { ICommentable } from "../../../utils/notes";
import ObservationDeleteModal from "../Findings/Observations/ObservationDeleteModal";
import ObservationEditModal from "../Findings/Observations/ObservationEditModal";
import {
  getDeletedNoteFilter,
  getUpdatedNoteMapper,
} from "../Findings/Observations/ObservationsFormControl";
import ObservationsList from "../Findings/Observations/ObservationsList";

export interface INotesFormControlProps {
  value: Array<ICommentable>;
  className: string;
}

const NotesFormControl: React.FC<INotesFormControlProps> = ({
  value,
  className,
  ...props
}) => {
  if (!Array.isArray(value)) {
    return null;
  }

  const formContext = useContext(FormContext);
  const appContext = useContext(AppContext);
  const modalControls = useModalControls();
  const permCheck = useContext(RolesContext).userHasPermission;

  const onSuccess = () => {
    appContext.triggerSuccessNotification();
  };

  const onEdit = (data: any) => {
    if (modalControls.selected) {
      return commentableApi
        .update("inspections", modalControls.selected.commentable_id, data)
        .then(updatedNote => {
          const mapNotes = getUpdatedNoteMapper(updatedNote);

          formContext.set(
            "notes",
            get(formContext.value, "notes", []).map(
              (note: { id: number; content: string }) => mapNotes(note),
            ),
          );
        })
        .then(onSuccess)
        .catch(e => console.error(e));
    }

    return Promise.resolve();
  };

  const onDelete = () => {
    if (modalControls.selected) {
      commentableApi
        .destroy(modalControls.selected.id, "inspections", formContext.value)
        .then(() => {
          const filterNotes = modalControls.selected
            ? getDeletedNoteFilter(modalControls.selected.id)
            : () => {};

          formContext.set(
            "notes",
            get(formContext.value, "notes", []).filter(filterNotes),
          );
        })
        .then(onSuccess)
        .catch(e => console.error(e));
    }
  };

  return (
    <div className={classNames("form-control--no-theme", className)}>
      <ObservationsList
        data={value}
        onEdit={data => {
          modalControls.setShowEdit(true);
          modalControls.setSelected(data);
        }}
        onDelete={data => {
          modalControls.setShowDelete(true);
          modalControls.setSelected(data);
        }}
        noteType="note"
        {...props}
        canEdit={permCheck("update", "Inspection Notes")}
        canDelete={permCheck("delete", "Inspection Notes")}
      />
      <ObservationEditModal
        show={modalControls.showEdit}
        value={modalControls.selected}
        onCancel={() => modalControls.setSelected(null)}
        onSubmit={onEdit}
        noteType="note"
      />
      <ObservationDeleteModal
        show={modalControls.showDelete}
        value={modalControls.selected}
        onCancel={() => modalControls.setSelected(null)}
        onSubmit={onDelete}
        noteType="note"
      />
    </div>
  );
};

export default NotesFormControl;
