import React, { useEffect, useState } from 'react';
import Toggle from '../../Shared/Toggle/toggle';
import List from '../../Shared/List/List';
import ResourceDetailUpdate from '../../Shared/ResourceDetail/ResourceDetailUpdate';
import findingTypesApi from '../../../utils/api/findingTypes';
import InspectionTemplateDetailsForm from '../Forms/InspectionTemplateDetailsForm';
import { GithubPicker } from 'react-color';
import { Modal } from '@sw-sw/lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Prompt } from 'react-router-dom';

type InspectionDetails = {
  name: string;
  id: number;
  details: {
    inspection_template: {
      id: number;
      name: string;
    };
    is_enabled: boolean;
    is_required_for_cert: boolean;
  };
};

export default function InspectionTemplateFinding(props: {
  templateId?: number;
  name?: string;
}) {
  const [basicFindings, setBasicFindings] = useState<any[]>([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [toggleError, setToggleError] = useState('');
  const [deleteShow, setDeleteShow] = useState<false | number>(false);
  const [show, setShow] = useState<boolean>(false);
  const [EditModal, setEditModal] = useState<false | number>(false);
  const [colorSetter, setColorSetter] = useState<any>({ 1: '#2FF363' });
  const [tempArr, setTempArr] = useState<any[]>([]);
  const [isNameError, setIsnameError] = useState(false);
  const handleDelete = (id: number) => {
    setErrorMsg('');
    findingTypesApi
      .destroy(id)
      .then(() => {
        hideDeleteModal();
        setBasicFindings(
          basicFindings.filter((ele) => {
            return ele.id !== id;
          }),
        );
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.message) {
          setErrorMsg(e.response.data.message);
        } else if (e.message) {
          setErrorMsg(e.message);
        } else {
          setErrorMsg('An error has occurred');
        }
      });
  };

  const showModal = () => {
    setShow(true);
    setErrorMsg('');
  };

  const showEditModal = (id: number) => {
    setEditModal(id);
    setErrorMsg('');
  };

  const showDeleteModal = (id: number) => {
    setDeleteShow(id);
    setErrorMsg('');
  };

  const hideDeleteModal = () => {
    setDeleteShow(false);
  };

  const header = [
    {
      id: 1,
      head: 'Findings',
    },
    {
      id: 2,
      head: 'Include',
    },
    {
      id: 3,
      head: 'Required for Compliance Certification',
    },
  ];

  useEffect(() => {
    (async () => {
      if (props.templateId) {
        const data = await findingTypesApi.index(props.templateId);
        setBasicFindings(data);
        let newColor: any = {};

        for (let i = 0; i < data.length; i++) {
          newColor[data[i].id] = data[i].details.color;
        }
        setColorSetter(newColor);
      }
    })();

    return () => {
      setBasicFindings([]);
    }
    
  }, []);

  const getInitialEnable = basicFindings.filter((e) => e.details.is_enabled);

  const handleToggle = (id: number, name: string) => {
    const getSelectedFinding: any = basicFindings.filter((e) => e.id === id)[0];

    setBasicFindings(
      basicFindings.map((e) => {
        if (e.id === id) {
          if (
            name === 'is_enabled' &&
            (getInitialEnable.length > 1 || e.details.is_enabled === false)
          ) {
            e.details.is_enabled = !getSelectedFinding.details.is_enabled;
            setToggleError('');
            if (e.details.is_enabled === false) {
              e.details.is_required_for_cert = false;
            }
          } else if (name === 'is_required_for_cert') {
            if (e.details.is_enabled === false) {
              setToggleError('Include must be enabled');
            } else {
              e.details.is_required_for_cert =
                !getSelectedFinding.details.is_required_for_cert;
            }
          } else {
            setToggleError('Atleast one Include must be enabled');
          }
        }

        return e;
      }),
    );

    const getFilteredFind = basicFindings.filter((e) => e.id === id)[0];

    tempArr.push(getFilteredFind);
  };
  const saveToggle = async () => {
    const getData = tempArr.filter((e, i) => tempArr.indexOf(e) === i);

    const data = getData.map((e) => {
      e.inspection_template_id = props.templateId;

      return e;
    });

    try {
      findingTypesApi.update(data);
      setTempArr([]);
      const toastId = toast('Saving finding');

      toast.update(toastId, {
        render: 'Finding saved!',
        type: 'success',
      });
    } catch (x) {
      console.log(x);
    }
  };

  const handleSubmit = async (data: { name: string }) => {
    if (props.templateId && data.name) {
      try {
        await findingTypesApi
          .create({
            inspection_template_id: props.templateId,
            name: data.name,
          })
          .then((res) => {
            setBasicFindings(basicFindings.concat(res));
            setColorSetter({ ...colorSetter, [res.id]: res.details.color });
            setShow(false);
            setToggleError('');
            setIsnameError(false);
          })
          .catch(() => setIsnameError(true));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleUpdate = () => async (data: { id: number; name: string }) => {
    if (props.templateId && data.name) {
      const arr = [];

      arr.push(data);
      const getData = arr.map((e: any) => {
        e.inspection_template_id = props.templateId;

        return e;
      });

      try {
        await findingTypesApi
          .update(getData)
          .then(() => {
            setBasicFindings(
              basicFindings.map((e) => {
                if (e.id === data.id) {
                  e.name = data.name;

                  return e;
                }

                return e;
              }),
            );
            const getFilteredFind = basicFindings.filter(
              (e) => e.id === data.id,
            )[0];

            tempArr.push(getFilteredFind);
            setEditModal(false);
            setIsnameError(false);
          })
          .catch(() => {
            setIsnameError(true);
          });
      } catch (x) {
        console.log(x);
      }
    }
  };

  const handleColor = (findingId: number) => async (color: any) => {
    if (props.templateId && color) {
      setBasicFindings(
        basicFindings.map((e) => {
          if (e.id === findingId) {
            setColorSetter((prevState: any) => ({
              ...prevState,
              [findingId]: color.hex,
            }));
            e.details.color = color.hex;

            return e;
          }

          return e;
        }),
      );
    }
    const getFilteredFind = basicFindings.filter((e) => e.id === findingId)[0];

    tempArr.push(getFilteredFind);
  };

  return (
    <>
      <Prompt
        when={tempArr.length > 0}
        message='You have unchanged finding edit.Are you sure you want to exit?'
      />

      {toggleError ? (
        <h5 className='error-messages finding-error'>
          <>
            <em>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              {toggleError}
            </em>
          </>
        </h5>
      ) : null}
      <Modal
        handleSubmit={() => handleDelete(+deleteShow)}
        show={Boolean(deleteShow)}
        handleClose={hideDeleteModal}
        errorMsg={errorMsg}
        title={`Delete Finding`}
        hideButtons={true}
      >
        <h4>Warning: This cannot be undone</h4>
        <p>
          {
            <>
              Are you sure that you want to delete the <strong></strong> Finding
              Template?
            </>
          }
        </p>
        <div className='buttons'>
          <button
            className='reversed outline'
            type='button'
            onClick={hideDeleteModal}
          >
            Cancel
          </button>
          &nbsp;
          <button
            className='warn reversed'
            onClick={() => handleDelete(+deleteShow)}
          >
            Delete
          </button>
        </div>
      </Modal>

      {EditModal && (
        <InspectionTemplateDetailsForm
          handleSubmit={handleUpdate()}
          handleClose={() => {
            setEditModal(false);
            setIsnameError(false);
          }}
          title='Edit Finding Type'
          inspectionTemplate={basicFindings.find((e) => e.id === EditModal)}
          errorMsg={isNameError}
        />
      )}

      <div>
        <button
          className='action-buttons-primary primary'
          style={{ float: 'right' }}
          onClick={() => saveToggle()}
        >
          Save
        </button>
        <div>
          <List
            headers={header.map((heading) => ({ label: heading.head }))}
            rows={basicFindings
              .filter(({ is_static_type }) => is_static_type === true)
              .map(({ id, name, details }: InspectionDetails) => {
                return {
                  id: id,
                  cells: [
                    name === 'A'
                      ? 'Achievement'
                      : name === 'MI'
                        ? 'Maintenance Item'
                        : 'Corrective Action',
                    <Toggle
                      isChecked={details.is_enabled}
                      handleChange={() => handleToggle(id, 'is_enabled')}
                    />,
                    <Toggle
                      isChecked={details.is_required_for_cert}
                      handleChange={() =>
                        handleToggle(id, 'is_required_for_cert')
                      }
                    />,
                  ],
                };
              })}
            pageItems=''
          />
        </div>
      </div>
      <div>
        <ResourceDetailUpdate
          editString=''
          resource='Create New Finding Type'
          onButtonClick={() => showModal()}
        >
          {show && (
            <InspectionTemplateDetailsForm
              handleSubmit={handleSubmit}
              handleClose={() => {
                setShow(false);
                setIsnameError(false);
              }}
              title='Create New Finding'
              errorMsg={isNameError}
            />
          )}
        </ResourceDetailUpdate>
      </div>

      <div>
        <List
          headers={header.map((heading) => ({ label: heading.head }))}
          rows={basicFindings
            .filter(({ is_static_type }) => is_static_type === false)
            .map(({ id, name, details }: InspectionDetails) => {
              return {
                id: id,
                cells: [
                  name,
                  <Toggle
                    isChecked={details.is_enabled}
                    handleChange={() => handleToggle(id, 'is_enabled')}
                  />,
                  <Toggle
                    isChecked={details.is_required_for_cert}
                    handleChange={() =>
                      handleToggle(id, 'is_required_for_cert')
                    }
                  />,

                  <div
                    style={{
                      backgroundColor: `${colorSetter[id]}`,
                      borderRadius: '20px',
                      height: '40px',
                      width: '40px',
                      border: '1px solid rgba(56, 89, 128, 0.29)',
                    }}
                  ></div>,

                  <GithubPicker width='230px' onChange={handleColor(id)} />,

                  <button
                    className='outline create-new contact.buttons'
                    onClick={() => showEditModal(id)}
                  >
                    <i className={'fa fa-pencil'} />
                  </button>,
                  <button
                    className='icon-only-button outline warn'
                    onClick={() => showDeleteModal(id)}
                  >
                    <i className='fa fa-trash' />
                  </button>,
                ],
              };
            })}
          pageItems=''
        />
      </div>
    </>
  );
}
