import { Modal } from "@sw-sw/lib-ui";
import React from "react";

export interface IElectronicSignatureConsentModalProps {
  show: boolean;
  handleClose: () => void;
}

const ElectronicSignatureConsentModal: React.FC<
  IElectronicSignatureConsentModalProps
> = ({ show, handleClose }) => {
  return (
    <Modal
      title="Electronic Signature Consent Agreement"
      show={show}
      hideButtons
      handleClose={handleClose}
    >
      <p>
        <strong>
          By accepting these terms: “I agree that any documents to be delivered
          in connection herewith may be electronically signed, and that any
          electronic signatures appearing throughout Stormwater Software are the
          same as handwritten signatures for the purpose of validity,
          enforceability, and admissibility.”
        </strong>
      </p>
      <p>
        <strong>
          <u>Option to Opt-Out</u>: By opting out of electronic signatures you
          are declining consent to electronic signatures and will be unable to
          digitally sign documents throughout this application. It will be the
          user’s responsibility to download and or print all documents requiring
          wet signatures. Physical copies of these documents will need to be
          kept by the user and Stormwater Software assumes no responsibility or
          liability for non-compliance that may occur.
        </strong>
      </p>
      <p>
        <em>
          To opt-out of the use of electronic signatures, uncheck the "I agree
          to the use of electronic signatures" checkbox on the Profile page.
        </em>
      </p>
    </Modal>
  );
};

export default ElectronicSignatureConsentModal;
