import { textValidators } from "@sw-sw/lib-form";

const numberRegex = /^[\d]*$/;
// allow up to 4 decimals places
const decimalRegex = /^-?[\d]*\.?(?:\d{1,6})?$/;
const allowedDecimalRegex = /[^\d.-]*/g;

class FormValidator {
  /* VALIDATE */

  validateRequired(val) {
    if (!val || val.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  validateEmail(val) {
    return textValidators.email(val);
  }

  validatePhone(val) {
    return textValidators.phone(val);
  }

  validatePasswords(pw1, pw2) {
    return pw1 === pw2;
  }

  /* PARSE */

  parseNumber = val => {
    if (numberRegex.test(val)) return val;
  };

  parseDecimal = (val = "") => {
    const cleanedVal = val.trim().replace(allowedDecimalRegex, "");

    if (val === "" || decimalRegex.test(cleanedVal)) return cleanedVal;
  };

  /* ERROR MESSAGES */

  getRequiredError() {
    return "All fields are required";
  }

  getPhoneError(reason = "phone") {
    if (reason === "phone") {
      return "Phone number must be in (xxx) xxx-xxxx format";
    }

    return this.getRequiredError();
  }

  getEmailError() {
    return "Please enter a valid email";
  }
}

export default new FormValidator();
