import { useQuery } from "react-query";
import { questionTypeApi } from "../utils/api/questionType";

export function useQuestionTypes() {
  const questionTypesQuery = useQuery({
    queryFn: () => questionTypeApi.index(),
    queryKey: "questionTypes",
    refetchOnWindowFocus: false,
  });

  return questionTypesQuery;
}
