import { SignatureAttestation } from "@sw-sw/lib-certification";
import React from "react";
import MarkdownView from "react-showdown";

const InspectionCertificationStatement: React.FC<{
  attestations: SignatureAttestation[];
}> = ({ attestations }) => {
  if (!attestations.length) {
    return null;
  }

  return (
    <div className="inspection-certification-statement">
      {attestations.map(att =>
        att.content
          .split("\n")
          .map((content, index) => (
            <MarkdownView
              key={`${index}_${content}`}
              markdown={content}
              options={{ tables: true, emoji: false, simpleLineBreaks: true }}
            />
          )),
      )}
    </div>
  );
};

export default InspectionCertificationStatement;
