import { findIndex } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonGroup from "../Fields/ButtonGroup";
import BaseShapeEditorField from "../Fields/BaseField";
import { Context } from "./Context";

class IconEditorToolbar extends Component {
  static contextType = Context;
  static propTypes = {
    onToolChange: PropTypes.func.isRequired,
  };

  render() {
    const { currentTool, selectedObject, selectTool, allTools } = this.context;
    const options = Object.keys(allTools).reduce((opts, toolName) => {
      const tool = allTools[toolName];

      return [
        ...opts,
        {
          label: tool.label,
          value: toolName,
        },
      ];
    }, []);

    return (
      <BaseShapeEditorField
        className="icon-editor-tool"
        label="Icon Editor Tool"
      >
        {({ inputId }) => (
          <div id={inputId}>
            <ButtonGroup
              options={options}
              activeIndex={findIndex(options, { value: currentTool })}
              onChange={$t => {
                selectTool(options[$t].value, () => this.props.onToolChange());
              }}
            />
            {currentTool !== null &&
              currentTool === "select" &&
              !selectedObject && <p>{allTools[currentTool].helpText}</p>}
            
            {currentTool !== null &&
              currentTool !== "select" &&
              <p>{allTools[currentTool].helpText}</p>}

          </div>
        )}
      </BaseShapeEditorField>
    );
  }
}

export default IconEditorToolbar;
