import { FormContextProvider } from "@sw-sw/lib-form";
import c from "classnames";
// import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import Context from "../../contexts/ProjectContext";
// import { dateFormat } from "../../utils";
import { parseYMD, parseMDYformat } from "../../utils/date";
import TinyIconButton from "../Shared/TinyIconButton";
import InspectionDateForm from "./Forms/InspectionDateForm";
import DashboardContext from "../../contexts/DashboardContext";
import { format } from "date-fns";


/**
 * Display and edit the next inspection date
 */
function InspectionDate({ className }) {
  const projectStore = useContext(Context);
  // source of input value
  const { inspectionDueToday, nextInspectionDate, nextInspectionDueToday } =
    projectStore;
  const { fetchStatsFuncForID, getTeamManagementData } = useContext(DashboardContext)
  const [showEditDate, setShowEditDate] = useState(false);
  const onSubmit = ({
    nextInspectionDate: nextDate,
    // if the inspection date has been changed to "today", this will be `true`
    nextInspectionDueToday: nextDueToday,
  }) => {
    if (nextDate) {
      projectStore.setProject({
        ...(projectStore.project || {}),
        next_inspection_date: nextDate,
      });

      if (nextDueToday) {
        projectStore.setNextInspectionDueToday(nextDueToday);
      }
    }
    fetchStatsFuncForID.refetch()
    getTeamManagementData.refetch()
    setShowEditDate(false);
  };

  /**
   * When the inspection is due today and incomplete, don't allow changing the due date.
   *
   * (user could still change the date, but they need to "start" the inspection, first)
   */
  if (
    nextInspectionDueToday ||
    (inspectionDueToday &&
      inspectionDueToday.status &&
      inspectionDueToday.status !== "complete")
  ) {
    return null;
  }


  if (nextInspectionDate) {
    return (
      <div className={c(className, "inspection-date inspection-date-mobile")}>
        <div>
          {projectStore.inspections && projectStore.inspections.length === 0 ? (
            <span>First&nbsp;</span>
          ) : (
            <span>Next&nbsp;</span>
          )}
          <span>inspection due on:&nbsp;</span>
        </div>
        {showEditDate ? (
          <FormContextProvider>
            <InspectionDateForm
              projectId={projectStore.project.id}
              initialValue={nextInspectionDate}
              handleClose={() => setShowEditDate(false)}
              onSubmit={onSubmit}
            />
          </FormContextProvider>
        ) : (
          <span>
            <b>{parseMDYformat(nextInspectionDate)}</b>
            <TinyIconButton
              iconName="pencil"
              title="Click to edit the next inspection due date"
              onClick={() => setShowEditDate(true)}
            />
          </span>
        )}
      </div>
    );
  }

  return null;
}

InspectionDate.propTypes = {
  className: PropTypes.string,
};

export default InspectionDate;
