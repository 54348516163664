import { findIndex } from "lodash";
import React from "react";
import ButtonGroupControl from "../../../Shared/ButtonGroup/ButtonGroup";
import BaseShapeEditorField from "./BaseField";

export default function ButtonGroup({
  value,
  label,
  onChange,
  options,
  ...props
}) {
  let inputValue = 0;

  if (value) {
    inputValue = findIndex(options, { value });

    if (inputValue < 0) {
      inputValue = 0;
    }
  }

  return (
    <BaseShapeEditorField label={label}>
      {() => (
        <ButtonGroupControl
          onChange={onChange}
          options={options}
          activeIndex={inputValue}
          {...props}
        />
      )}
    </BaseShapeEditorField>
  );
}
