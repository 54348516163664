import { fabric } from "fabric";
import { uniqueId } from "lodash";
import IconEditorTool from "./IconEditorTool";

const config = {
  initialState() {
    return {
      points: [],
      lines: [],
      shape: null,
    };
  },
  defaultDrawingGuide: {
    selectable: false,
    hasBorders: false,
    hasControls: false,
    evented: false,
  },
  defaultPoint: {
    radius: 2,
    stroke: "#333333",
    fill: "#cccccc",
    strokeWidth: 1,
    originX: "center",
    originY: "center",
  },
  innerPoint: {},
  firstPoint: {
    evented: true,
    radius: 3,
    fill: "transparent",
  },
  defaultLine: {
    strokeWidth: 1,
    fill: "#999999",
    stroke: "#999999",
    originX: "center",
    originY: "center",
    objectCaching: false,
  },
  defaultActiveShape: {
    stroke: "#333333",
    strokeWidth: 1,
    fill: "#cccccc",
    opacity: 0.9,
    objectCaching: false,
  },
  defaultPolygon: {
    stroke: "#333333",
    strokeWidth: 2,
    fill: "#aaaaaa",
    opacity: 0.8,
  },
};

/** @todo merge defaults with user-provided shape styles */
class PolygonTool extends IconEditorTool {
  label = "Polygon";
  helpText =
    "Click to place shape nodes. Click the first node to complete the shape.";

  state = config.initialState();

  /**
   * @param {fabric.IEvent} event
   */
  handleMouseDown(event) {
    event.e.preventDefault();

    if (this.eventTargetIsFirstPoint(event)) {
      this.canvas.trigger("tool:complete", {
        shape: this.makePolygon(),
        applyTheme: true,
        autoSelect: true,
      });
    } else {
      this.addPoint(this.canvas, event.e);
    }
  }

  /**
   * @param {fabric.IEvent} event
   */
  handleMouseMove(event) {
    if (this.state.lines.length && this.state.shape !== null) {
      const pointer = this.canvas.getPointer(event.e);

      this.updateActiveLine(pointer.x, pointer.y);
      this.updateActiveShapeLastNode(pointer.x, pointer.y);
    }
  }

  /**
   * When the tool is selected from the toolbar
   */
  handleSelected() {
    this.canvas.selection = false;
    this.state = config.initialState();
  }

  /**
   * When tool is deselected
   */
  handleDeselected() {
    this.state.points.forEach(_ => this.canvas.remove(_));
    this.state.lines.forEach(_ => this.canvas.remove(_));

    if (this.state.shape) {
      this.canvas.remove(this.state.shape);
    }

    this.state = config.initialState();
  }

  //

  /**
   * Check if the event's target is the first point on the shape
   * @param {fabric.IEvent} event
   */
  eventTargetIsFirstPoint(event) {
    if (this.state.points.length && event.target && event.target.id) {
      return event.target.id === this.state.points[0].id;
    }

    return false;
  }

  /**
   *
   * @param {fabric.IEvent} event
   */
  addPoint(event) {
    const { x, y } = this.canvas.getPointer(event);
    const pt = new fabric.Circle({
      id: uniqueId("point"),
      left: x,
      top: y,
      ...config.defaultDrawingGuide,
      ...config.defaultPoint,
      ...(this.state.points.length ? config.innerPoint : config.firstPoint),
    });

    this.state.points.push(pt);
    this.canvas.add(pt);

    // drawing guides
    this.addActiveLine(x, y);

    if (!this.state.shape) {
      this.addActiveShape(this.canvas, x, y);
    } else {
      this.addActiveShapeNode(this.canvas, x, y);
    }
  }

  addActiveLine(x, y) {
    const ln = new fabric.Line([x, y, x, y], {
      ...config.defaultDrawingGuide,
      ...config.defaultLine,
    });

    this.state.lines.push(ln);
    this.canvas.add(ln);
  }

  updateActiveLine(x, y) {
    /** @type fabric.Line */
    const activeLine = this.state.lines[this.state.lines.length - 1];

    activeLine.set({
      x2: x,
      y2: y,
    });

    this.canvas.renderAll();
  }

  addActiveShape(x, y) {
    const shape = new fabric.Polygon([{ x, y }], {
      ...config.defaultDrawingGuide,
      ...config.defaultActiveShape,
    });

    this.state.shape = shape;
    this.canvas.add(shape);
  }

  addActiveShapeNode(x, y) {
    const points = this.state.shape.get("points");

    points.push({ x, y });
    this.state.shape.set({ points });
    this.canvas.renderAll();
  }

  updateActiveShapeLastNode(x, y) {
    const points = this.state.shape.get("points");

    points[this.state.points.length] = { x, y };

    this.state.shape.set({ points });
    this.canvas.renderAll();
  }

  /**
   * Combine points to create a polygon
   */
  makePolygon() {
    const points = this.state.points.map(p => ({
      x: p.left,
      y: p.top,
    }));

    return new fabric.Polygon(points, {
      id: uniqueId("polygon_"),
      ...config.defaultPolygon,
    });
  }
}

export default PolygonTool;
